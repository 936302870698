<template>
  <select
    @change="$emit('changePageSize', pageSize)"
    v-model.number="pageSize"
    class="form-control form-control--page-size"
  >
    <option v-for="(size, index) in pageSizeArray" :key="index">{{ size }}</option>
  </select>
</template>

<script>
const pageSizeArray = [10, 25, 50];

export default {
  name: 'PageSize',

  props: {
    pageSizeFilter: {
      type: [Number, String],
      required: true
    }
  },

  data() {
    return {
      pageSize: 50,
      pageSizeArray
    }
  },

  created () {
    this.pageSize = this.pageSizeFilter;
  },
}
</script>

<style>
.form-control--page-size {
  width: auto !important;
  cursor: pointer;
}
</style>