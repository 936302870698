<template>
  <div class="school-cabinet__lessons-card d-flex justify-content-between flex-wrap flex-md-nowrap mb-2">
    <div
      v-if="transaction.status !== 'confirmed' && transaction.status !== 'rejected'"
      @click="$emit('changeTransactionIds')"
      :class="{ active: selected }" class="school-cabinet_card-check"
    ></div>

    <div class="school-cabinet_card-content d-flex flex-column">
      <div class="d-flex mb-3">
        <router-link :to="`/instructors/${transaction.recipient.instructor.instructor_id}/profile`" class="school-cabinet_card-icon" style="width: 48px; height: 48px">
          <img v-if="transaction.recipient.instructor.avatar" :src="transaction.recipient.instructor.avatar.url" alt="Фото инструктора">
          <img v-else src="@/assets/img/noavatar.svg" alt="Фото инструктора">
        </router-link>
        <div class="school-cabinet_card-text mt-1 width-244">
          <router-link :to="`/instructors/${transaction.recipient.instructor.instructor_id}/profile`" class="school-cabinet_card-name text-decoration-none">{{ transaction.recipient.instructor.name.full }}</router-link>
          <div class="school-cabinet_card-facts mr-md-3">{{ formatDate(transaction.created_at) }}</div>
        </div>
      </div>

      <div class="school-cabinet_card-info-title mt-auto"><strong>Сумма:</strong> {{ transaction.amount.toLocaleString() }} Р.</div>
    </div>

    <div class="school-cabinet__lessons-card-content mt-3 mt-md-1">
      <div class="d-flex justify-content-between">
        <div class="school-cabinet_card-info-item w-100">
          <div class="school-cabinet_card-info-text mb-1">Плательщик: {{ transaction.payer.school.name }}</div>
          <div class="school-cabinet_card-info-title">Статус: <strong>{{ checkStatus(transaction.status) }}</strong></div>
          <div v-if="transaction.last_error" class="school-cabinet_card-info-title mt-3"><span class="notification">!</span> {{ transaction.last_error }}</div>

          <div class="d-flex align-items-center flex-wrap mt-3 gap-30">
            <div @click="showRequisites" class="school-cabinet_card-info-title school-cabinet_card-info-title--underline">Реквизиты</div>

            <transition name="fade">
              <div v-if="transaction.status === 'received' || transaction.status === 'pending'" class="d-flex flex-wrap gap-15 ml-auto">
                <div class="d-flex ml-auto">
                  <button
                    @click="modalConfirm('подтвердить', transactionConfirm)"
                    type="button"
                    :disabled="disabledBtn"
                    class="btn btn-green btn--transaction-card mr-3"
                  >
                    <AppLoader2
                      v-if="loadingConfirm"
                      :mini="true"
                      :btn="true"
                      :color="'#ffffff'"
                    />

                    <span :class="{ 'opacity--0': loadingConfirm }">Подтвердить</span>
                  </button>

                  <button
                    @click="modalConfirm('отклонить', transactionReject)"
                    type="button"
                    :disabled="disabledBtn"
                    class="btn btn-red btn--transaction-card"
                  >
                    <AppLoader2
                      v-if="loadingReject"
                      :mini="true"
                      :btn="true"
                      :color="'#ffffff'"
                    />

                    <span :class="{ 'opacity--0': loadingReject }">Отклонить</span>
                  </button>
                </div>

                <!-- <button v-if="transaction.status === 'received'" @click="transactionPend" type="button" class="btn btn-blue btn--transaction-card ml-auto">
                  <AppLoader2
                    v-if="loadingPend"
                    :mini="true"
                    :btn="true"
                    :color="'#ffffff'"
                  />

                  <span :class="{ 'opacity--0': loadingPend }">Создать п/п</span>
                </button> -->
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import moment from 'moment'

import ModalRequisites from '@/components/ModalRequisites.vue'
import AppLoader2 from '@/components/AppLoader/2.vue';

export default {
  name: 'TransactionCard',

  components: {
    AppLoader2,
  },

  props: {
    transaction: {
      typeof: Object,
      required: true
    },
    selected: {
      typeof: Boolean,
      required: true
    },
    disabledBtn: {
      typeof: Boolean,
      required: true
    },
  },

  data() {
    return {
      loadingConfirm: false,
      loadingReject: false,
      loadingPend: false
    }
  },

  computed: {
    loadingButtons() {
      return this.loadingConfirm || this.loadingReject || this.loadingPend;
    }
  },

  methods: {
    ...mapActions('transactions', [
      'confirmTransaction',
      'rejectTransaction',
      'pendTransaction'
    ]),
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm:ss')
    },
    showRequisites() {
      this.$modal.show(
        ModalRequisites,
        { requisites: {
            payer: this.transaction.payer.requisites,
            recipient: this.transaction.recipient.requisites
          }
        },
        { height: 'auto', width: '900px', adaptive: true },
      )
    },
    modalConfirm(text, method) {
      if (this.loadingButtons) {
        return;
      }

      this.$modal.show('dialog', {
        text: `Вы действительно хотите ${text}?`,
        buttons: [
          {
            title: 'Да',
            handler: () => {
              method();
              this.$modal.hide('dialog');
            },
          },
          {
            title: 'Нет',
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    transactionConfirm() {
      this.loadingConfirm = true;

      this.confirmTransaction(this.transaction.transaction_id)
        .finally(() => this.loadingConfirm = false);
    },
    transactionReject() {
      this.loadingReject = true;

      this.rejectTransaction(this.transaction.transaction_id)
        .finally(() => this.loadingReject = false);
    },
    transactionPend() {
      if (this.loadingButtons) {
        return;
      }

      this.loadingPend = true;

      this.pendTransaction(this.transaction.transaction_id)
        .finally(() => this.loadingPend = false);
    },
    checkStatus(status) {
      switch (status) {
        case 'received':
          return 'Новая'
        case 'pending':
          return 'Создано п/п'
        case 'confirmed':
          return 'Подтверждена'
        case 'rejected':
          return 'Отклонена'
      }
    }
  }
}
</script>

<style>
.school-cabinet_card-info-title .notification {
  font-weight: 600;
  font-size: 18px;
  color: red;
}

.school-cabinet_card-info-title--underline {
  font-size: 16px;
  font-weight: 700;
  color: #555;
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-underline-offset: 3px;
  cursor: pointer;
}

.btn--transaction-card {
  position: relative;
  height: 30px !important;
  padding: 0 15px !important;
  font-weight: bold;
}

.gap-30 {
  gap: 30px;
}

.gap-15 {
  gap: 15px;
}

.opacity--0 {
  opacity: 0;
}

.school-cabinet__lessons-card {
  position: relative;
}

.school-cabinet_card-check {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  border: 1px solid #0033CC;
  border-radius: 50%;
  transition: .2s ease;
  cursor: pointer;
}

.school-cabinet_card-check.active {
  background-color: #0033CC;
}

.school-cabinet_card-check::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 13px;
  height: 13px;
  background-image: url(~@/assets/img/checkbox.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%);
  transition: .2s ease;
}

.school-cabinet_card-check.active::before {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .btn--transaction-card {
    height: 37px !important;
    padding: 0 35px;
  }
}
</style>