<template>
  <div
    v-if="isGoBack"
    @click="$router.back()"
    class="cursor-pointer account-back d-flex align-items-center mb-3"
  >
    <i class="fas fa-chevron-left"></i>

    <div class="account-back-text">Назад</div>
  </div>
</template>

<script>
export default {
  name: 'GoBack',

  computed: {
    isGoBack() {
      return window.history.length <= 2 ? false : true
    }
  }
}
</script>