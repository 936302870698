<template>
  <div class="account-content">
    <GoBack />

    <div class="settings">
      <div class="settings-title settings-title--small">Инструктор <template v-if="instructor">{{ instructor.instructor.name.full }}</template> <template v-if="instructor">{{ instructor.instructor.name.middle }}</template></div>
    </div>

    <AppLoader v-if="profile.user.permission.instructor.notes ? loading.instructorProfile || loading.notes : loading.instructorProfile" />

    <div v-else class="school-cabinet">
      <div class="school-cabinet_white-box school-cabinet_white-box--smaller mb-4">
        <div class="school-cabinet_card">
          <div class="school-cabinet_card-top d-flex align-items-center">
            <div class="school-cabinet_card-icon" style="width: 48px; height: 48px">
              <img v-if="instructor.instructor.avatar" :src="instructor.instructor.avatar.url" :alt="instructor.instructor.name.full">
              <img v-else src="@/assets/img/noavatar.svg" :alt="instructor.instructor.name.full">
            </div>

            <div class="school-cabinet_card-text">
              <div class="d-flex align-items-center">
                <div class="school-cabinet_card-name">{{ instructor.instructor.name.full }} {{ instructor.instructor.name.middle }}</div>
                <div v-if="instructor.instructor.pro.status === 'active'" class="school-cabinet_card-pro d-flex align-items-center justify-content-center">PRO</div>
              </div>
              <div class="school-cabinet_card-facts">{{ instructor.instructor.email }}<template v-if="instructor.instructor.phone">,</template> <span v-if="instructor.instructor.phone" class="white-space-nowrap">{{ instructor.instructor.phone }}</span></div>
            </div>

            <div class="align-items-center ml-auto d-none d-md-flex">
              <p v-if="activated" class="color-green">Профиль активен</p>
              <p v-else class="color-red">Профиль неактивен</p>

              <router-link
                v-if="profile.user.permission.instructor.edit"
                :to="`/instructors/${instructor.instructor.instructor_id}/edit`"
                class="school-cabinet_card-edit ml-4"
              ></router-link>
            </div>
          </div>

          <div class="school-cabinet_card-info mt-4 mb-3 pb-md-4 mb-md-1">
            <div class="school-cabinet_card-info-list d-flex flex-wrap">
              <div class="school-cabinet_card-info-item school-cabinet_card-info-item--middle mb-3 mb-md-0">
                <div class="school-cabinet_card-info-title">Дата регистрации</div>
                <div class="school-cabinet_card-info-text">{{ moment(instructor.instructor.created_at).format('DD MMMM YYYY') }}</div>
              </div>

              <div class="school-cabinet_card-info-item school-cabinet_card-info-item--middle mb-3 mb-md-0">
                <div class="school-cabinet_card-info-title">Профиль</div>
                <div class="school-cabinet_card-info-text">
                  <a :href="`${instructor.instructor.profile.link}`" target="_blank">{{ instructor.instructor.profile.link }}</a></div>
              </div>

              <div class="school-cabinet_card-info-item school-cabinet_card-info-item--middle">
                <div class="school-cabinet_card-info-title">Полный рейтинг</div>
                <div class="school-cabinet_card-info-text">{{ instructor.instructor.rate.total }} = {{ instructor.instructor.rate.calculated }} - {{ instructor.instructor.rate.addition }}</div>
              </div>

              <div v-if="profile.user.permission.instructor.notes" class="school-cabinet_card-info-item school-cabinet_card-info-item--middle">
                <div class="school-cabinet_card-info-title">Заметки</div>

                <div class="school-cabinet_card-info-text">
                  <span v-if="notes.length" class="text-decoration-none">{{ notesAllData.meta.entries.total }}</span>
                  <span v-else class="text-decoration-none">Нет</span>
                </div>
              </div>
            </div>
          </div>

          <div class="school-cabinet_card-data school-cabinet_card-data--smaller">
            <div class="school-cabinet_card-data-list d-flex flex-wrap">
              <div class="school-cabinet_card-data-item d-flex align-items-center mb-2 mb-xl-3">
                <div class="school-cabinet_card-data-check school-cabinet_card-data-icon">
                  <img v-if="instructor.instructor.profile.complete_stages.profile_data === 'valid'" src="@/assets/img/check-2.svg" alt="check">
                  <img v-else src="@/assets/img/close-2.svg" alt="close">
                </div>
                <div class="school-cabinet_card-data-title">Профиль заполнен</div>
              </div>

              <div class="school-cabinet_card-data-item d-flex align-items-center mb-2 mb-xl-3">
                <div class="school-cabinet_card-data-check school-cabinet_card-data-icon">
                  <img v-if="instructor.instructor.profile.complete_stages.documents_and_experience === 'valid'" src="@/assets/img/check-2.svg" alt="check">
                  <img v-else src="@/assets/img/close-2.svg" alt="close">
                </div>
                <div class="school-cabinet_card-data-title">Документы и опыт</div>
              </div>

              <div class="school-cabinet_card-data-item d-flex align-items-center mb-2 mb-xl-3">
                <div class="school-cabinet_card-data-check school-cabinet_card-data-icon">
                  <img v-if="instructor.instructor.profile.complete_stages.city_areas === 'valid'" src="@/assets/img/check-2.svg" alt="check">
                  <img v-else src="@/assets/img/close-2.svg" alt="close">
                </div>
                <div class="school-cabinet_card-data-title">Район обучения</div>
              </div>

              <div class="school-cabinet_card-data-item d-flex align-items-center mb-2 mb-xl-3">
                <div class="school-cabinet_card-data-check school-cabinet_card-data-icon">
                  <img v-if="instructor.instructor.profile.complete_stages.mobile_app === 'valid'" src="@/assets/img/check-2.svg" alt="check">
                  <img v-else src="@/assets/img/close-2.svg" alt="close">
                </div>
                <div class="school-cabinet_card-data-title">Моб. приложение LevelApp</div>
              </div>

              <div class="school-cabinet_card-data-item d-flex align-items-center mb-2 mb-xl-0">
                <div class="school-cabinet_card-data-check school-cabinet_card-data-icon">
                  <img v-if="instructor.instructor.profile.complete_stages.tariffs === 'valid'" src="@/assets/img/check-2.svg" alt="check">
                  <img v-else src="@/assets/img/close-2.svg" alt="close">
                </div>
                <div class="school-cabinet_card-data-title">Тарифы</div>
              </div>

              <div class="school-cabinet_card-data-item d-flex align-items-center mb-2 mb-xl-0">
                <div class="school-cabinet_card-data-check school-cabinet_card-data-icon">
                  <img v-if="instructor.instructor.profile.complete_stages.cars === 'valid'" src="@/assets/img/check-2.svg" alt="check">
                  <img v-else src="@/assets/img/close-2.svg" alt="close">
                </div>
                <div class="school-cabinet_card-data-title">Авто</div>
              </div>

              <div class="school-cabinet_card-data-item d-flex align-items-center">
                <div class="school-cabinet_card-data-check school-cabinet_card-data-icon">
                  <img v-if="instructor.instructor.profile.complete_stages.rate === 'valid'" src="@/assets/img/check-2.svg" alt="check">
                  <img v-else src="@/assets/img/close-2.svg" alt="close">
                </div>
                <div class="school-cabinet_card-data-title">Рейтинг >= 0</div>
              </div>

              <div class="school-cabinet_card-data-item d-flex align-items-center">
                <div class="school-cabinet_card-data-check school-cabinet_card-data-icon">
                  <img v-if="instructor.instructor.profile.complete_stages.search === 'valid'" src="@/assets/img/check-2.svg" alt="check">
                  <img v-else src="@/assets/img/close-2.svg" alt="close">
                </div>
                <div class="school-cabinet_card-data-title">Доступен для поиска</div>
              </div>
            </div>
          </div>

          <div class="school-cabinet_card-buttons d-flex flex-wrap mt-4">
            <router-link
              v-if="profile.user.permission.instructor.lessons"
              :to="`/instructors/${instructor.instructor.instructor_id}/lessons`"
              class="school-cabinet_card-button d-flex justify-content-center align-items-center"
            >
              <div class="school-cabinet_card-button-icon">
                <i class="far fa-calendar-alt"></i>
              </div>
              <div class="school-cabinet_card-button-title">Занятия</div>
            </router-link>

            <router-link
              v-if="profile.user.permission.instructor.tariffs"
              :to="`/instructors/${instructor.instructor.instructor_id}/tariffs`"
              class="school-cabinet_card-button d-flex justify-content-center align-items-center"
            >
              <div class="school-cabinet_card-button-icon">
                <i class="fas fa-bars"></i>
              </div>
              <div class="school-cabinet_card-button-title">Тарифы</div>
            </router-link>

            <router-link
              v-if="profile.user.permission.instructor.clients"
              :to="{ name: 'Clients', query: { instructor_id: instructor.instructor.instructor_id } }"
              class="school-cabinet_card-button d-flex justify-content-center align-items-center"
            >
              <div class="school-cabinet_card-button-icon">
                <i class="fas fa-users"></i>
              </div>
              <div class="school-cabinet_card-button-title">Клиенты</div>
            </router-link>
          </div>

          <div class="align-items-center d-flex d-md-none justify-content-end mt-4">
            <p v-if="activated" class="color-green">Профиль активен</p>
            <p v-else class="color-red">Профиль неактивен</p>

            <router-link
              v-if="profile.user.permission.instructor.edit"
              :to="`/instructors/${instructor.instructor.instructor_id}/edit`"
              class="school-cabinet_card-edit ml-4"
            ></router-link>
          </div>
        </div>
      </div>

      <template v-if="profile.user.permission.instructor.notes">
        <div class="account-subtitle pt-0 mb-3">Заметки по инструктору</div>

        <InstructorNotes />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import AppLoader from '@/components/AppLoader/1.vue';
import InstructorNotes from '@/components/Instructor/Notes/List.vue';
import GoBack from '@/components/GoBack.vue';

moment.locale('ru');

export default {
  name: 'InstructorProfile',

  components: {
    AppLoader,
    InstructorNotes,
    GoBack
  },

  data() {
    return {
      activated: null,
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loadingInstructors',
      instructor: 'instructorProfile',
      profile: 'profile',
      notes: 'instructorNotes',
      notesAllData: 'instructorNotesAllData',
    })
  },

  async created() {
    await this.getInstructorProfile(this.$route.params.id);

    this.activated = !this.instructor.instructor.paused;

    if (this.profile.user.permission.instructor.notes) {
      const payload = {
        idInstructor: this.$route.params.id,
        loading: true
      }

      this.getInstructorNotes(payload);
    }
  },

  beforeDestroy() {
    this.clearInstructorProfile();
  },

  methods: {
    ...mapActions([
      'getInstructorProfile',
      'clearInstructorProfile',
      'getInstructorNotes',
    ]),
    moment,
  }
}
</script>

<style>
.pointer {
  cursor: pointer;
}

.color-green {
  color: #4CD964;
}

.color-red {
  color: red;
}

.title-notes {
  font-weight: bold;
  font-size: 18px;
}
</style>