<template>
   <div>
      <a href="javascript:void(0)" class="account-back d-flex align-items-center mb-5" @click="$emit('closeDoc')">
         <i class="fas fa-chevron-left"></i>
         <div class="account-back-text">Назад</div>
      </a>
      <div class="account-subtitle pt-0 mb-3">Документы</div>

      <div class="settings-body pl-4 pr-4">
         <div class="school-cabinet">
            <div class="school-cabinet_info pt-2">
               <div class="school-cabinet_info-form school-cabinet_info-form--full">
                  <div class="school-cabinet_info-row" v-if="client && documents">
                     <div class="school-cabinet_info-row-title">Паспортные данные</div>
                     <div class="d-flex justify-content-between flex-wrap">
                        <div class="school-cabinet_info-field school-cabinet_info-field--small" v-if="client.name.last">
                           <div class="form-title">Фамилия</div>
                           <input type="text" readonly :value="client.name.last"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="client.name.first">
                           <div class="form-title">Имя</div>
                           <input type="text" readonly :value="client.name.first"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="client.name.middle">
                           <div class="form-title">Отчество</div>
                           <input type="text" readonly :value="client.name.middle"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.birthdate">
                           <div class="form-title">Дата рождения</div>
                           <input type="text" readonly :value="getDate(documents.birthdate)"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.passport && documents.passport.nationality">
                           <div class="form-title">Гражданство</div>
                           <input type="text" readonly :value="documents.passport.nationality"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.passport && documents.passport.place_of_birth">
                           <div class="form-title">Место рождения</div>
                           <input type="text" readonly :value="documents.passport.place_of_birth"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.passport && documents.passport.registration_address">
                           <div class="form-title">Адрес регистрации</div>
                           <input type="text" readonly :value="documents.passport.registration_address"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.passport && documents.passport.series">
                           <div class="form-title">Серия</div>
                           <input type="text" readonly :value="documents.passport.series"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.passport && documents.passport.number">
                           <div class="form-title">Номер</div>
                           <input type="text" readonly :value="documents.passport.number"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.passport && documents.passport.issuance_date">
                           <div class="form-title">Дата выдачи</div>
                           <input type="text" readonly :value="getDate(documents.passport.issuance_date)"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.passport && documents.passport.issuing_authority">
                           <div class="form-title">Выдавшая организация</div>
                           <input type="text" readonly :value="documents.passport.issuing_authority"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.passport && documents.passport.department_code">
                           <div class="form-title">Код подразделения</div>
                           <input type="text" readonly :value="documents.passport.department_code"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small" v-if="client.phone">
                           <div class="form-title">Номер телефона</div>
                           <input type="text" readonly :value="client.phone"
                              class="form-control form-control--bordered">
                        </div>
                     </div>
                  </div>
                  <div class="school-cabinet_info-row mt-3 mt-md-0" v-if="documents && documents.snils">
                     <div class="school-cabinet_info-row-title">СНИЛС</div>
                     <div class="d-flex justify-content-between flex-wrap">
                        <div class="school-cabinet_info-field school-cabinet_info-field--small mb-4">
                           <input type="text" readonly :value="documents.snils"
                              class="form-control form-control--bordered"><!---->
                        </div>
                     </div>
                  </div>
                  <div class="school-cabinet_info-row" v-if="documents && documents.representativePassport">
                     <div class="school-cabinet_info-row-title">Данные законного представителя</div>
                     <div class="d-flex justify-content-between flex-wrap">
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.representativePassport && documents.representativePassport.lastname">
                           <div class="form-title">Фамилия</div>
                           <input type="text" readonly :value="documents.representativePassport.lastname"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.representativePassport && documents.representativePassport.name">
                           <div class="form-title">Имя</div>
                           <input type="text" readonly :value="documents.representativePassport.name"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.representativePassport && documents.representativePassport.phone">
                           <div class="form-title">Номер телефона</div>
                           <input type="text" readonly :value="documents.representativePassport.phone"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.representativePassport && documents.representativePassport.series">
                           <div class="form-title">Серия</div>
                           <input type="text" readonly :value="documents.representativePassport.series"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.representativePassport && documents.representativePassport.number">
                           <div class="form-title">Номер</div>
                           <input type="text" readonly :value="documents.representativePassport.number"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.representativePassport && documents.representativePassport.issuance_date">
                           <div class="form-title">Дата выдачи</div>
                           <input type="text" readonly :value="getDate(documents.representativePassport.issuance_date)"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.representativePassport && documents.representativePassport.issuing_authority">
                           <div class="form-title">Выдавшая организация</div>
                           <input type="text" readonly :value="documents.representativePassport.issuing_authority"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.representativePassport && documents.representativePassport.department_code">
                           <div class="form-title">Код подразделения</div>
                           <input type="text" readonly :value="documents.representativePassport.department_code"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"></div>
                     </div>
                  </div>
                  <div class="school-cabinet_info-row"
                     v-if="documents && documents.categories.length > 0 && documents.driverLicense">
                     <div class="school-cabinet_info-row-title">Данные о водительском удостоверении</div>
                     <div class="d-flex justify-content-between flex-wrap"
                        v-if="documents && documents.categories.length">
                        <div class="school-cabinet_info-field school-cabinet_info-field--small">
                           <div class="form-title">Категории:
                              <span v-for="(item, index) in documents.categories" :key="index">
                                 {{ item.name }}<span v-if="index < documents.categories.length - 1">, </span>
                              </span>
                           </div>
                        </div>
                     </div>
                     <div class="d-flex justify-content-between flex-wrap">
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.driverLicense && documents.driverLicense.series">
                           <div class="form-title">Серия</div>
                           <input type="text" readonly :value="documents.driverLicense.series"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.driverLicense && documents.driverLicense.number">
                           <div class="form-title">Номер</div>
                           <input type="text" readonly :value="documents.driverLicense.number"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.driverLicense && documents.driverLicense.issuance_date">
                           <div class="form-title">Дата выдачи</div>
                           <input type="text" readonly :value="getDate(documents.driverLicense.issuance_date)"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.driverLicense && documents.driverLicense.valid_until">
                           <div class="form-title">Срок действия</div>
                           <input type="text" readonly :value="getDate(documents.driverLicense.valid_until)"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-if="documents && documents.driverLicense && documents.driverLicense.issuing_authority">
                           <div class="form-title">Выдавшая организация</div>
                           <input type="text" readonly :value="documents.driverLicense.issuing_authority"
                              class="form-control form-control--bordered">
                        </div>
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"></div>
                     </div>
                  </div>
                  <div class="school-cabinet_info-row" v-if="documents && documents.documents && documents.documents.length > 0">
                     <div class="d-flex align-items-center mb-3">
                        <div class="school-cabinet_info-row-title mb-0 mr-2">Фото документов</div>
                        <div class="school-cabinet_info-row-button">
                           <button class="btn btn-light-blue" @click="download(client.client_id)" v-if="!downloadLodaing">
                              <span class="save-icon"></span>
                              Скачать
                           </button>
                           <button class="btn btn-light-blue" @click="download(client.client_id)" v-else>
                              <span class="save-icon"></span>
                              Скачиваем...
                           </button>
                        </div>
                     </div>
                     <div class="d-flex justify-content-between flex-wrap">
                        <div class="school-cabinet_info-field school-cabinet_info-field--small"
                           v-for="document in documents.documents" :key="document.id">
                           <a :href="document.documentUrl" target="_blank" class="form-link"><span>&#128248;</span>
                              {{ getPhotoLabel(document.type) }}</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
   name: 'ClientDocuments',
   props: {
      client: {
         required: true,
         type: Object,
      }
   },

   data() {
      return {
         downloadLodaing: false,
      }
   },

   mounted() {
      this.getClient(this.client.client_id);
   },

   computed: {
      ...mapGetters({
         clientInstructors: 'assignmentClientInstructors',
         loading: 'loadingAssignment',
         profile: 'profile',
         tariffs: 'tariffs',
         tariffsAllData: 'tariffsAllData',
         loadingInstructors: 'loadingInstructors',
         tariffsLevelAppAllData: 'tariffsLevelAppAllData',
         tariffsLevelApp: 'tariffsLevelApp',
         documents: 'clientDocuments',
      }),
   },
   methods: {
      ...mapActions({
         getClient: 'getClient',
         downloadDocuments: 'downloadDocuments',
      }),

      getDate(date) {
         if (date == null) {
            return 'Не заполнена'
         }
         return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
      },
      getPhotoLabel(code) {
         switch (code) {
            case " passport_pages_2_3":
               return "Паспорт (страницы 2 и 3)"
            case "passport_address":
               return "Паспорт (адрес)"
            case "passport_page_19":
               return "Паспорт (страница 19)"
            case "driving_license_front":
               return "Водительское удостоверение (лицевая сторона)"
            case "driving_license_back":
               return "Водительское удостоверение (обратная сторона)"
            case "personal_photo":
               return "Личное фото"
            case "snils":
               return "СНИЛС"
            case "parent_passport_pages_2_3":
               return "Паспорт представителя (страницы 2 и 3)"
            case "parent_child_or_birthday":
               return "Страница паспорта 'Дети' (либо свидетельство о рождении)"
            default:
               return "Документ"
         }
      },
      async download(id) {
         this.downloadLodaing = true;
         await this.downloadDocuments(id)
         this.downloadLodaing = false;

      },
   },
}
</script>

<style scoped>
.font-style {
   color: #555;
}

.mr-20 {
   margin-right: 20px;
}

.fs-25 {
   font-size: 25px;
}

.form-title {
   margin-bottom: 5px;
}

.form-link {
   margin-bottom: 5px;
   color: #555;
   display: flex;
   line-height: 1.2;
   padding-right: 5px;
   font-size: 15px;
}

.form-link span {
   display: block;
   padding-right: 5px;
}
</style>
