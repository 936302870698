<template>
  <div class="account-content" :class="{'hasSelected': checkedIds.length > 0}">
    <router-link to="/transactions" class="cursor-pointer account-back d-flex align-items-center mb-3">
      <i class="fas fa-chevron-left"></i><div class="account-back-text">Назад</div>
    </router-link>
    <div class="settings">
      <div class="settings-top d-md-flex align-items-end mb-4">
        <div class="settings-head d-flex align-items-center w-100">
          <div class="settings-title">Акты по выплатам</div>
        </div>
        <div class="settings-search ml-auto d-flex mt-4 mt-md-0">
          <input
            v-model.trim="search"
            v-debounce:1000ms="searchInstructor"
            type="text"
            class="form-control form-control--search pl-2 pl-md-2"
            placeholder="Поиск по инструкторам"
          >

          <button type="button"></button>
        </div>
      </div>
      <AppLoader v-if="loading.instructors" />

      <div v-else class="school-cabinet wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
        <div class="filters d-flex align-items-center flex-wrap mb-4">
          <div class="flex-grow-1 flex-xl-grow-0 d-flex flex-wrap flex-md-nowrap">
            <div class="filters-item filters-item--middle mr-3 mr-md-4">
              <DropDownMenuCalendar
                :placeholder="'Период'"
                :dateCalendar="dateCalendar"
                :actualDates="[filters.date_from, filters.date_to]"
                @changeDate="changeDate"
              />
            </div>
          </div>
          <a href="javascript:void(0)" class="text-link" @click="checkAll" v-if="checkedIds.length == 0 && instructors.length > 0">Выбрать всех</a>
          <a href="javascript:void(0)" class="text-link" @click="uncheckAll" v-if="checkedIds.length > 0 && instructors.length > 0">Очистить выбор</a>
        </div>
        <div class="school-cabinet__head mb-3" v-if="instructors.length > 0">
          <div class="school-cabinet__box-title school-cabinet__box-title--acts ml-auto">Сумма выплат за период</div>
        </div>
        <template v-if="instructors.length > 0">
          <div class="school-cabinet_card school-cabinet_card--acts d-flex align-items-center" v-for="instructor in instructors" :key="instructor.instructor_id">
            <div class="custom-checkbox custom-checkbox--round">
              <input type="checkbox" name="client" :id="`instructor-${instructor.instructor_id}`" disabled :checked="checkedIds.includes(instructor.instructor_id)">
              <label :for="`instructor-${instructor.instructor_id}`" class="d-flex" @click="checkRow(instructor.instructor_id)"></label>
            </div>
            <div class="d-flex align-items-center ml-3 ml-md-4">
              <div class="school-cabinet_card-icon">
                <img :src="instructor.avatar.url" v-if="instructor.avatar.url" alt="">
                <img src="@/assets/img/noavatar.svg" v-else alt="">
              </div>
              <router-link :to="`/instructors/${instructor.instructor_id}/profile`" class="school-cabinet_card-name">{{instructor.last_name}} {{instructor.first_name}}</router-link>
            </div>
            <div class="school-cabinet_card-text school-cabinet_card-text--small ml-auto"><strong>{{instructor.income.amount}} Р.</strong></div>
          </div>
        </template>
        <p v-else>Нет данных, удовлетворяющих условиям поиска</p>
      </div>
      <div class="fix-block" v-if="checkedIds.length > 0">
        <div class="fix-block__column">
          <div class="fix-block__text"><strong>Количество инструкторов, для <br>которых будут выгружены акты:</strong> {{checkedIds.length}}</div>
        </div>
        <div class="fix-block__column">
          <div class="fix-block__text">Нажмите на кнопку, чтобы сформировать архив актов. Как только он будет готов, вы сможете его скачать. Подготовка архива может занять до пары минут.</div>
        </div>
        <div class="fix-block__column">
          <div class="fix-block__button">
            <button class="btn btn-blue" v-if="!loading.request" @click="sendRequest">
              Сформировать архив
            </button>
            <button class="btn btn-blue position-relative cusrsor-default" v-else>
                <AppLoader2
                  :mini="true"
                  :btn="true"
                  :color="'#ffffff'"
                />
            </button>
          </div>
        </div>
      </div>
      <div class="archive-block-wrapper" v-if="acts && acts.length > 0">
        <div class="archive-block" v-for="act in acts" :key="act.report_id" :class="{'archive-block--add': act.status == 'processing', 'archive-block--done':  act.status == 'processed'}">
          <template v-if="act.status == 'processing'">
            <div class="archive-block__top">
              <div class="archive-block__icon">
                <img src="@/assets/img/archive.svg" alt="">
              </div>
              <div class="archive-block__title">Добавляем акты в архив<span class="one">.</span><span class="two">.</span><span class="three">.</span></div>
            </div>
            <div class="archive-block__text">Ссылка на скачивание скоро будет готова</div>
          </template>
          <template v-if="act.status == 'processed'">
            <div class="archive-block__top">
              <div class="archive-block__icon">
                <img src="@/assets/img/check-white.svg" alt="">
              </div>
              <div class="archive-block__title">Архив актов успешно сформирован</div>
            </div>
            <div class="archive-block__button">
              <a :href="act.link" donwload>Скачать</a>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import DropDownMenuCalendar from '@/components/DropDownMenu/Calendar.vue';
import moment from 'moment';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'Acts',

  components: {
    AppLoader,
    AppLoader2,
    DropDownMenuCalendar,
  },

  data() {
    return {
        isLoadingPage: false,
        checkedIds: [],
        dateCalendar: [],
        resetHover: false,
        disabledFilters: false,
        search: '',
    }
  },

  computed: {
    ...mapGetters('acts', {
      acts: 'acts',
      instructors: 'instructors',
      filters: 'filters',
      loading: 'loading',
      filtersApplied: 'filtersApplied',
    }),
  },

  async created() {
    try {
        if (this.$route.query.search) {
          this.changeSearchInstructors(this.$route.query.search);
          this.search = this.$route.query.search;
        }
        if (this.$route.query.date_from && this.$route.query.date_to) {
          const date = {
            from: moment(this.$route.query.date_from, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            to: moment(this.$route.query.date_to, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          }

          this.changeFiltersDate(date);

          this.dateCalendar = [moment(this.$route.query.date_from, 'DD-MM-YYYY')._d, moment(this.$route.query.date_to, 'DD-MM-YYYY')._d];
      } else {
        if (parseInt(moment().format('DD')) < 10) {
          const date = {
            from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            to: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
          }

          this.changeFiltersDate(date);

          this.dateCalendar = [moment().subtract(1, 'months').startOf('month')._d, moment().subtract(1, 'months').endOf('month')._d];
        } else {
          const date = {
            from: moment().startOf('month').format('YYYY-MM-DD'),
            to: moment().endOf('month').format('YYYY-MM-DD'),
          }

          this.changeFiltersDate(date);

          this.dateCalendar = [moment().startOf('month')._d, moment().endOf('month')._d];
        }
      }
      await this.getInstructors();
      await this.getActs();
      setInterval(() => {
        this.checkProcessedArchives();
      }, 6000)
    } catch (e) {
      this.errorHandlerForResponse(e);
    }
  },

  methods: {
    ...mapActions('acts', [
      'getActs',
      'getInstructors',
      'changeFiltersDate',
      'requestActs',
      'changeSearchInstructors',
    ]),
    searchInstructor(value) {
      this.isLoadingPage = true;

      const search = value.trim();

      let query = Object.assign({}, this.$route.query);

      if (!search) {
        delete query.search;
      } else {
        query.search = search;
      }

      this.$router.push({ query }).catch(() => {});

      this.changeSearchInstructors(search);

      const loading = false;

      this.getInstructors(loading)
        .finally(() => this.isLoadingPage = false);
    },
    checkAll() {
      this.checkedIds = this.instructors.map(s=>s.instructor_id);
    },
    checkProcessedArchives() {
      if (this.acts && this.acts.length > 0) {
        if (this.acts.filter(act => act.status == 'processing').length > 0) {
          this.getActs();
        }
      }
    },
    uncheckAll() {
      this.checkedIds = [];
    },
    checkRow(id) {
      if (this.checkedIds.includes(id)) {
        const index = this.checkedIds.indexOf(id);
        if (index > -1) {
          this.checkedIds.splice(index, 1);
        }
      } else {
        this.checkedIds.push(id);
      }
    },
    sendRequest() {
      let payload = {
        instructor_ids: this.checkedIds,
        date_from: moment(this.dateCalendar[0]).format('YYYY-MM-DD HH:mm:ss'),
        date_to: moment(this.dateCalendar[1]).format('YYYY-MM-DD HH:mm:ss'),
      }
      console.log(payload);
      this.requestActs(payload).then(() => {
        this.getActs();
        this.checkedIds = [];
      })
    },
    changeDate(dateCalendar) {
      if (this.isLoadingPage) {
        return;
      }
      this.isLoadingPage = true;

      let date = null;
      this.dateCalendar = dateCalendar;
      if (dateCalendar) {
        date = {
          from: moment(dateCalendar[0]).format('YYYY-MM-DD'),
          to: moment(dateCalendar[1]).format('YYYY-MM-DD'),
        }

        let query = Object.assign({}, this.$route.query);
        query.date_from = moment(dateCalendar[0]).format('DD-MM-YYYY');
        query.date_to = moment(dateCalendar[1]).format('DD-MM-YYYY');
        this.$router.push({ query }).catch(() => {});
        this.changeFiltersDate(date);
      } else {
        date = {
          from: null,
          to: null,
        }

        let query = Object.assign({}, this.$route.query);
        delete query.date_form;
        delete query.date_to;
        this.$router.push({ query }).catch(() => {});
        this.changeFiltersDate(date);
      }
      const loading = false;

      this.getInstructors(loading)
        .finally(() => this.isLoadingPage = false);
    },
    errorHandlerForResponse
  }
}
</script>

<style>
  .archive-block {
    position: static !important;
    margin-bottom: 10px;
  }
  .archive-block-wrapper {
    position: fixed;
    transition: all 300ms;
    bottom: 20px;
    right: 48px;
  }
  .hasSelected .archive-block-wrapper {
    bottom: 120px;
  }
  @media screen and (max-width: 760px) {
    .hasSelected .archive-block-wrapper {
      bottom: 250px;
      left: 0;
      right: 0;
    }
  }
.one {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.0s;
    animation: dot 1.3s infinite;
    animation-delay: 0.0s;
}

.two {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.2s;
    animation: dot 1.3s infinite;
    animation-delay: 0.2s;
}

.three {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.3s;
    animation: dot 1.3s infinite;
    animation-delay: 0.3s;
}

@-webkit-keyframes dot {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes dot {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
