<template>
  <div class="modal-requisites d-md-flex justify-content-between p-3">
    <div @click="$emit('close')" class="modal-requisites__close">&#10006;</div>

    <div class="w-md-50 mr-md-3 mb-3 mb-md-0">
      <h3 class="modal-requisites__title mb-1">Получатель</h3>

      <p>
        <span class="modal-requisites__name">Дата постановки на учет:</span>
        {{ formatDate(requisites.recipient.registration_date) }}
      </p>
      <p v-if="requisites.recipient.certificate">
        <span class="modal-requisites__name">Номер справки:</span>
        {{ requisites.recipient.certificate.number }}
      </p>
      <p v-if="requisites.recipient.certificate">
        <span class="modal-requisites__name">Дата справки:</span>
        {{ formatDate(requisites.recipient.certificate.date) }}
      </p>
      <p>
        <span class="modal-requisites__name">ИНН:</span>
        {{ requisites.recipient.inn }}
      </p>
      <p>
        <span class="modal-requisites__name">Банк:</span>
        {{ requisites.recipient.bank_name }}
      </p>
      <p>
        <span class="modal-requisites__name">БИК банка:</span>
        {{ requisites.recipient.bank_bik }}
      </p>
      <p>
        <span class="modal-requisites__name">р/с:</span>
        {{ requisites.recipient.bank_account }}
      </p>
      <p>
        <span class="modal-requisites__name">к/с: </span>
        {{ requisites.recipient.bank_correspondent_account }}
      </p>

      <a
        v-if="requisites.recipient.certificate"
        :href="requisites.recipient.certificate.url"
        target="_blank"
      >
        Справка о постановке на учет
      </a>
    </div>

    <div class="w-md-50">
      <h3 class="modal-requisites__title mb-1">Плательщик</h3>
      <p>
        <span class="modal-requisites__name">Название компании:</span>
        {{ requisites.payer.name }}
      </p>
      <p>
        <span class="modal-requisites__name">ИНН:</span>
        {{ requisites.payer.inn }}
      </p>
      <p>
        <span class="modal-requisites__name">КПП:</span>
        {{ requisites.payer.kpp }}
      </p>
      <p>
        <span class="modal-requisites__name">ОГРН:</span>
        {{ requisites.payer.ogrn }}
      </p>
      <p>
        <span class="modal-requisites__name">Почтовый адрес:</span>
        {{ requisites.payer.postal_address }}</p>
      <p>
        <span class="modal-requisites__name">Юридический адрес:</span>
        {{ requisites.payer.legal_address }}
      </p>
      <p>
        <span class="modal-requisites__name">Р/С:</span>
        {{ requisites.payer.bank_account }}
      </p>
      <p>
        <span class="modal-requisites__name">Банк:</span>
        {{ requisites.payer.bank_name }}
      </p>
      <p>
        <span class="modal-requisites__name">ИНН банка:</span>
        {{ requisites.payer.bank_inn }}
      </p>
      <p>
        <span class="modal-requisites__name">БИК:</span>
        {{ requisites.payer.bank_bik }}
      </p>
      <p>
        <span class="modal-requisites__name">К/С:</span>
        {{ requisites.payer.bank_correspondent_account }}
      </p>
      <p>
        <span class="modal-requisites__name">Адрес банка:</span>
        {{ requisites.payer.bank_address }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ModalRequisites',

  props: {
    requisites: {
      type: Object,
      required: true
    },
  },

  methods: {
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
  },
}
</script>

<style>
.modal-requisites p {
  margin-bottom: 5px;
}

.modal-requisites__title {
  font-weight: 800;
}

.modal-requisites__name {
  font-weight: 600;
}

.modal-requisites__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 30px;
  line-height: 30px;
  color: rgb(119, 119, 119);
  cursor: pointer;
  transition: .1s ease;
}

.modal-requisites__close:hover {
  color: rgb(0, 0, 0);
}

@media screen and (min-width: 768px) {
  .w-md-50 {
    width: 50%;
  }
}
</style>