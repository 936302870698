<template>
  <div class="account-content">
    <div class="settings">
      <div class="settings-top d-md-flex align-items-end mb-4">
        <div class="settings-head d-flex align-items-center">
          <div class="settings-title">Журнал действий</div>
        </div>

        <div class="settings-search ml-auto d-flex mt-4 mt-md-0">
          <input
            v-model.trim="search"
            v-debounce:1000ms="searchQuery"
            type="text"
            class="form-control form-control--search pl-2 pl-md-2"
            placeholder="Поиск"
          >

          <button type="button"></button>
        </div>
      </div>

      <AppLoader v-if="loading || typesLoading" />

      <div v-else class="school-cabinet wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
        <div class="filters d-flex flex-wrap mb-3">
          <div class="flex-grow-1 flex-xl-grow-0 d-flex flex-wrap flex-md-nowrap">
            <div class="filters-item filters-item--200 flex-grow-1 mb-2 mb-md-0">
              <DropDownMenuCalendar
                :placeholder="'Период'"
                :dateCalendar="dateCalendar"
                :actualDates="[activityFilters.date_from, activityFilters.date_to]"
                @changeDate="changeDate"
              />
            </div>

            <div class="filters-item filters-item--200 flex-grow-1 mb-2 mb-md-0" :class="{'reset-hover': resetHover}">
              <v-select
                :options="users"
                @input="selectUser"
                @search="searchUser"
                :disabled="disabledFilters"
                :clearable="false"
                :searchable="true"
                placeholder="Оператор"
                :get-option-label='option => option.name ? option.name.full : null'
                :get-option-key='option => option.user_id'
                v-model="activeUser"
              >
                <template #open-indicator="{ attributes }">
                  <span class="form-control--arrow" v-bind="attributes"></span>
                </template>
                <template #list-header>
                  <li class="vs__dropdown-option vs__dropdown-option--reset" @mouseover="resetHover = true" @mouseleave="resetHover = false" v-if="activeUser" @click="resetUser">Все</li>
                </template>
                <template slot="no-options">
                  Пользователей не найдено
                </template>
                <template v-slot:option="option">
                  {{ option.name.full }}
                </template>
                <template #spinner="{ loading }">
                  <AppLoader2 :mini="true" v-if="loading" />
                </template>
                <template #list-footer>
                  <infinite-loading @infinite="infiniteHandler">
                    <div slot="spinner">
                      <AppLoader2 :mini="true" />
                    </div>
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                  </infinite-loading>
                </template>
              </v-select>
            </div>

            <div class="filters-item filters-item--200 flex-grow-1 mb-2 mb-md-0">
              <DropDownMenuType
                :types="types.types"
                :filters="activityFilters.type"
                :disabled="disabledFilters"
                @select="selectType"
                v-if="types"
              />
            </div>
          </div>

          <transition name="fade--top">
            <button
              v-if="filtersApplied"
              @click="resetFilters"
              type="button"
              class="reset-filters filters-item--100 align-self-center mt-3 mb-3 mt-xl-0 mb-md-0 ml-xl-auto"
            >
              Сбросить фильтры
            </button>
          </transition>
        </div>
        <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />

        <template v-if="activity && activity.activities.length && types">
          <ActivityCard
            v-for="log in activity.activities"
            :key="log.log_id"
            :log="log"
            :types="types.types"
            :class="{ 'school-cabinet__activity-card--last': log.log_id === activity.activities[activity.activities.length - 1].log_id }"
            class="school-cabinet__activity-card"
          />

          <div v-if="activity.meta.page.total > 1" class="d-flex justify-content-center">
            <paginate
              v-model="page"
              :pageCount="activity.meta.page.total"
              :clickHandler="clickCallback"
              :prevText="''"
              :nextText="''"
              :containerClass="'pagination'"
              :pageClass="'page-item'"
              :pageLinkClass="'page-link'"
              :prevClass="'page-item'"
              :prevLinkClass="'page-link'"
              :nextClass="'page-item'"
              :nextLinkClass="'page-link'"
              :activeClass="'active'"
            />
          </div>
        </template>

        <p v-else>Нет данных, удовлетворяющих условиям поиска</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Paginate from 'vuejs-paginate';
import moment from 'moment';
import _ from 'lodash';
import vSelect from 'vue-select';
import InfiniteLoading from 'vue-infinite-loading';

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';
import DropDownMenuCalendar from '@/components/DropDownMenu/Calendar.vue';
import DropDownMenuType from '@/components/DropDownMenu/Type.vue';
import ActivityCard from '@/components/ActivityCard.vue';

export default {
  name: 'Activity',

  components: {
    AppLoader,
    AppLoader2,
    AppLoader3,
    Paginate,
    DropDownMenuCalendar,
    DropDownMenuType,
    ActivityCard,
    vSelect,
    InfiniteLoading
  },

  data() {
    return {
      page: 1,
      isLoadingPage: false,
      dateCalendar: [],
      search: '',
      resetHover: false,
      disabledFilters: false,
      activeUser: null,
    }
  },

  computed: {
    ...mapGetters({
      activity: 'activity',
      types: 'activityTypes',
      activityPage: 'activityPage',
      loading: 'activityLoading',
      typesLoading: 'activityTypesLoading',
      activityFilters: 'activityFilters',
      users: 'filterUsers',
      filtersApplied: 'activityFiltersApplied',
      filterUsersMeta: 'filterUsersMeta',
      user: 'filterUser',
    }),
  },

  async created() {
    this.getTypes();
    this.clearActivity();
    this.setLoadingUsers();
    this.clearFilterUsers();
    this.resetFiltersFilterUsers();

    await this.getFilterUsers({loading: true});

    if (this.$route.query.user_id) {
      this.changeFiltersUserActivity(this.$route.query.user_id);
      await this.getFilterUser(this.$route.query.user_id);
      this.activeUser = this.user;
      this.addUserInFilterListUsers(this.user);
    }

    if (this.$route.query.page) {
      this.changePageActivity(Number(this.$route.query.page));
    }

    if (this.$route.query.type) {
      this.changeFiltersTypeActivity(this.$route.query.type);
    }

    if (this.$route.params.page === 1) {
      this.resetFiltersActivity();
    }

    this.page = this.activityPage;

    let query = Object.assign({}, this.$route.query);
    query.page = this.activityPage;

    this.$router.push({ query }).catch(() => {});

    if (this.$route.query.date_from && this.$route.query.date_to) {
      const date = {
        from: moment(this.$route.query.date_from, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        to: moment(this.$route.query.date_to, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      }

      this.changeFiltersDateActivity(date);

      this.dateCalendar = [moment(this.$route.query.date_from, 'DD-MM-YYYY')._d, moment(this.$route.query.date_to, 'DD-MM-YYYY')._d];
    }

    if (this.$route.query.search) {
      this.changeFiltersSearchActivity(this.$route.query.search);

      this.search = this.$route.query.search;
    }

    await this.getActivity();

    if (this.activity && this.activityPage > this.activity.meta.page.total) {
      await this.changePageActivity(this.activity.meta.page.total);

      this.page = this.activityPage;

      let query = Object.assign({}, this.$route.query);
      query.page = this.activityPage;
      this.$router.push({ query }).catch(() => {});

      this.getActivity();
    }
  },

  methods: {
    ...mapActions([
      'getActivity',
      'getTypes',
      'clearActivity',
      'changePageActivity',
      'changeFiltersDateActivity',
      'resetFiltersActivity',
      'changeFiltersSearchActivity',
      'getFilterUsers',
      'setLoadingUsers',
      'changeFiltersUserActivity',
      'changePageFilterUsers',
      'getFilterUser',
      'clearFilterUsers',
      'addUserInFilterListUsers',
      'resetFiltersFilterUsers',
      'changeFiltersFilterUsersSearch',
      'changeFiltersTypeActivity',
    ]),
    changeDate(dateCalendar) {
      if (this.isLoadingPage) {
        return;
      }

      this.isLoadingPage = true;

      this.changePageActivity(1);
      this.page = 1;

      let date = null;

      if (dateCalendar) {
        date = {
          from: moment(dateCalendar[0]).format('YYYY-MM-DD'),
          to: moment(dateCalendar[1]).format('YYYY-MM-DD'),
        }

        let query = Object.assign({}, this.$route.query);
        query.page = '1';
        query.date_from = moment(dateCalendar[0]).format('DD-MM-YYYY');
        query.date_to = moment(dateCalendar[1]).format('DD-MM-YYYY');
        this.$router.push({ query }).catch(() => {});
      } else {
        date = {
          from: null,
          to: null,
        }

        let query = Object.assign({}, this.$route.query);
        query.page = '1';
        delete query.date_from;
        delete query.date_to;
        this.$router.push({ query }).catch(() => {});
      }

      this.changeFiltersDateActivity(date);

      const loading = false;

      this.getActivity(loading)
        .finally(() => this.isLoadingPage = false);
    },
    clickCallback() {
      this.isLoadingPage = true;

      let query = Object.assign({}, this.$route.query);
      query.page = this.page;

      this.changePageActivity(this.page)
        .then(() => {
          this.$router.push({ query }).catch(() => {});

          const loading = false;

          this.getActivity(loading)
            .finally(() => this.isLoadingPage = false);
        });
    },
    searchQuery(value) {
      this.isLoadingPage = true;

      const search = value.trim();

      this.changePageActivity(1);
      this.page = 1;

      let query = Object.assign({}, this.$route.query);
      query.page = '1';

      if (!search) {
        delete query.search;
      } else {
        query.search = search;
      }

      this.$router.push({ query }).catch(() => {});

      this.changeFiltersSearchActivity(search);

      const loading = false;

      this.getActivity(loading)
        .finally(() => this.isLoadingPage = false);
    },
    infiniteHandler($state) {
      if (this.filterUsersMeta && this.filterUsersMeta.page.number < this.filterUsersMeta.page.total) {
        const payload = {
          loading: false,
        }

        this.getFilterUsers(payload)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    searchUser(search, loading) {
      this.clearFilterUsers();
      this.changePageFilterUsers(1);
      this.changeFiltersFilterUsersSearch(search);
      loading(true);
      this.searchUserAction(search, loading, this);
    },
    searchUserAction: _.debounce((search, loading, vm) => {
      const payload = {
        loading: false
      }
      vm.getFilterUsers(payload).then(() => {
        loading(false);
      })
    }, 1000),
    resetUser() {
      this.changePageFilterUsers(1);
      this.changeFiltersUserActivity(null);
      this.activeUser = null;
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.changePageActivity(1);

      const loading = false;

      this.getActivity(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      delete query.user_id;
      this.$router.push({ query }).catch(() => {});
    },
    selectUser(user) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.changeFiltersUserActivity(user.user_id);
      this.changePageActivity(1);

      const loading = false;

      this.getActivity(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      query.user_id = user.user_id;
      this.$router.push({ query }).catch(() => {});
    },
    selectType(type) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.changePageActivity(1);

      if (type === 'reset') {
        this.changeFiltersTypeActivity(null);

        let query = Object.assign({}, this.$route.query);
        query.page = 1;
        delete query.type;
        this.$router.replace({ query }).catch(() => {});
      } else {
        this.changeFiltersTypeActivity(type);

        let query = Object.assign({}, this.$route.query);
        query.page = 1;
        query.type = type;
        this.$router.replace({ query }).catch(() => {});
      }

      const loading = false;

      this.getActivity(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    resetFilters() {
      if (this.disabledFilters) {
        return;
      }

      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.resetFiltersActivity();

      this.search = '';

      const loading = false;

      this.getActivity(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      this.activeUser = null;
      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      delete query.date_from;
      delete query.date_to;
      delete query.user_id;
      delete query.type;
      delete query.search;
      this.$router.push({ query }).catch(() => {});
    },
  }
}
</script>

<style>
.school-cabinet__activity-card {
  margin-bottom: 10px;
}

.school-cabinet__activity-card--last {
  margin-bottom: 0;
}
</style>