<template>
  <div id="app">
    <Layout />

    <v-dialog />
  </div>
</template>

<script>
import Layout from '@/layouts/Base.vue';

export default {
  name: 'App',

  metaInfo() {
    return {
      title: 'LevelApp',
      titleTemplate: this.$route.meta.title
    }
  },

  components: {
    Layout,
  },
}
</script>

<style>
body {
  overflow-y: scroll;
}

.form-group {
  position: relative;
}

.text-error {
  position: absolute;
  top: 110%;
  left: 0;
  font-size: 14px;
  color: #ff7474;
}

.btn-blue--custom {
  width: 140px;
  height: 44px !important;
}

.breadcrumbs--custom ul {
  flex-wrap: wrap;
}

.breadcrumbs--custom ul li {
  color: #544a4a;
  white-space: nowrap;
}

.breadcrumbs--custom a {
  color: #0033CC;
}

.drop-down-menu {
  position: relative;
  font-size: 15px;
  line-height: 42px;
  border-radius: 5px;
  color: #555555;
  background-color: #ffffff;
  cursor: pointer;
  /* z-index: 20; */
}

.drop-down-menu input {
  padding: 0;
  max-width: 100%;
  font-size: 15px !important;
  color: #555555;
  border: none;
}

.drop-down-menu input:focus {
  outline: none;
}

.drop-down-menu input:disabled {
  background-color: inherit;
}

.filters .drop-down-menu {
  /* max-width: 200px; */
  width: 100%;
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .filters .drop-down-menu {
    width: 100%;
    max-width: none;
  }
}

.form-control--custom {
  position: absolute;
  width: 100%;
  outline: none;
  border: 1px solid #E1E1E1;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: none !important;
  top: 100%;
  height: auto !important;
  background: #ffffff;
  z-index: 10;
}

.form-control--custom li {
  padding-left: 15px;
  padding-right: 15px;
  white-space: nowrap;
}

.form-control--custom li:hover {
  background-color: #D2E1F7;
  cursor: pointer;
}

.form-control--instructors {
  height: 230px !important;
  overflow-y: auto;
}

.border-bottom-none {
  border-bottom: none !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-control--arrow {
  position: absolute;
  display: inline-block;
  top: 50%;
  right: 14px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-width: 5px 4px 0 4px;
  border-style: solid;
  border-color: #999999 transparent transparent transparent;
}

.form-control--arrow.rotate {
  transform: rotate(180deg);
}

.school-cabinet_info-table-text--custom {
  white-space: nowrap;
}

.opacity {
  opacity: .6;
  pointer-events: none;
}

.page-link:focus {
  box-shadow: none !important;
}

.page-item.disabled:first-of-type .page-link,
.page-item.disabled:last-of-type .page-link {
  background-color: #e4e4e4;
}

.page-item:first-of-type .page-link::before,
.page-item:last-of-type .page-link::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
}

.page-item:first-of-type .page-link::before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.page-item:last-of-type .page-link::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.fade-enter-active, .fade-leave-active {
  transition: .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.fade--top-enter-active, .fade--top-leave-active {
  transition: .4s;
  transform: translateY(0px);
}
.fade--top-enter, .fade--top-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(-5px);
}

.reset-filters {
  padding: 0;
  font-size: inherit;
  line-height: 14px;
  border: none;
  color: #868686;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .filters-item--100 {
    width: 100%;
    text-align: center;
  }
}

.site-body {
  overflow: visible !important;
}

.wrapper-for-spinner {
  position: relative;
}

.wrapper-for-spinner__spinner {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.width-23 {
  width: 23px;
}

.vs__selected-options {
  max-width: 100%;
}

.vs__search,
.vs__search:focus {
  text-overflow: ellipsis;
}

.vs--single .vs__selected {
  width: 90% !important;
}

.account-content {
  transition: .3s ease-in-out;
}

@media screen and (max-width: 400px) {
  .school-cabinet_card-button {
    width: 120px !important;
  }
}
</style>
