<template>
  <div class="account-content">
    <div class="settings">
      <div class="settings-top d-md-flex align-items-end">
        <div class="settings-head d-flex align-items-center">
          <div class="settings-title">Группы обучения</div>
          <router-link to="/study-groups/create" class="settings-head-icon"></router-link>
        </div>
        <div class="settings-search ml-auto d-flex mt-4 mt-md-0">
          <input
            v-model.trim="search"
            v-debounce:1000ms="searchGroups"
            type="text"
            class="form-control form-control--search pl-2 pl-md-2"
            placeholder="Поиск по группам"
          >
          <button type="button"></button>
        </div>
      </div>

      <AppLoader v-if="loading.list" />

      <div v-else class="school-cabinet">
        <div class="filters">
          <div class="filters-item">
            <vSelect
              :options="clients"
              @input="selectClient"
              @search="searchClient"
              :disabled="disabledFilters"
              :clearable="false"
              :searchable="true"
              placeholder="Клиент"
              :get-option-label='option => option.name ? `${option.name.first} ${option.name.last} ${option.name.middle}` : null'
              :get-option-key='option => option.client_id'
              v-model="activeClient"
            >
              <template #open-indicator="{ attributes }">
                <span class="form-control--arrow" v-bind="attributes"></span>
              </template>
              <template #list-header>
                <li
                  v-if="activeClient"
                  @click="resetClient"
                  @mouseover="resetHover = true"
                  @mouseleave="resetHover = false"
                  class="vs__dropdown-option vs__dropdown-option--reset"
                >
                  Все
                </li>
              </template>
              <template slot="no-options">
                Пользователей не найдено
              </template>
              <template v-slot:option="option">
                {{ option.name.first }} {{ option.name.last }} {{ option.name.middle }}
              </template>
              <template #spinner="{ loading }">
                <AppLoader2 :mini="true" v-if="loading" />
              </template>
              <template #list-footer>
                <infinite-loading @infinite="infiniteHandler">
                  <div slot="spinner">
                    <AppLoader2 :mini="true" />
                  </div>
                  <div slot="no-more"></div>
                  <div slot="no-results"></div>
                </infinite-loading>
              </template>
            </vSelect>
          </div>
        </div>

        <div class="wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
          <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />

          <template v-if="studyGroups.length">
            <StudyGroupsCard
              v-for="group in studyGroups"
              :key="group.study_group_id"
              :group="group"
            />

            <div v-if="meta.page.total > 1" class="d-flex justify-content-center">
              <paginate
                v-model="page"
                :pageCount="meta.page.total"
                :clickHandler="clickCallback"
                :prevText="''"
                :nextText="''"
                :containerClass="'pagination'"
                :pageClass="'page-item'"
                :pageLinkClass="'page-link'"
                :prevClass="'page-item'"
                :prevLinkClass="'page-link'"
                :nextClass="'page-item'"
                :nextLinkClass="'page-link'"
                :activeClass="'active'"
              />
            </div>
          </template>

          <p v-else>Данных нет...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Paginate from 'vuejs-paginate';
import vSelect from 'vue-select';
import _ from 'lodash';
import InfiniteLoading from 'vue-infinite-loading';

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';

import StudyGroupsCard from '@/components/StudyGroups/Card.vue';

export default {
  name: 'StudyGroups',

  components: {
    Paginate,
    vSelect,
    InfiniteLoading,
    AppLoader,
    AppLoader2,
    AppLoader3,
    StudyGroupsCard
  },

  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      isLoadingPage: false,
      disabledFilters: false,
      activeClient: null,
      search: '',
    }
  },

  computed: {
    ...mapGetters({
      profile: 'profile',
      clients: 'filterClients',
      filterClientsMeta: 'filterClientsMeta',
      client: 'client',
    }),
    ...mapGetters('studyGroups', {
      studyGroups: 'list',
      meta: 'meta',
      loading: 'loading',
    })
  },

  async created() {
    this.clearStudyGroups();
    this.addQueryFromUrl();
    this.changeFilters({ key: 'school_id', value: this.profile.school.school_id });
    this.getStudyGroups();

    this.clearFilterClients();
    this.changePageFilterClients(1);
    this.changeFiltersFilterClientsSearch(null);
    this.getFilterClients();

    if (this.$route.query.client_id) {
      await this.getClient(this.$route.query.client_id);
      this.activeClient = this.client;
    }

    if (this.$route.query.query) {
      this.search = this.$route.query.query;
    }
  },

  methods: {
    ...mapActions('studyGroups', {
      getStudyGroups: 'getList',
      changeFilters: 'changeFilters',
      clearStudyGroups: 'clearList',
    }),
    ...mapActions([
      'getFilterClients',
      'changePageFilterClients',
      'changeFiltersFilterClientsSearch',
      'clearFilterClients',
      'getClient',
    ]),
    clickCallback() {
      this.isLoadingPage = true;

      this.changeFilters({ key: 'page', value: this.page })

      let query = Object.assign({}, this.$route.query);
      query.page = this.page;
      this.$router.replace({ query }).catch(() => {});

      this.getStudyGroups()
        .finally(() => this.isLoadingPage = false);
    },
    addQueryFromUrl() {
      if (!this.isEmptyObj(this.$route.query)) {
        Object.entries(this.$route.query).forEach(([key, value]) => {
          this.changeFilters({ key, value });
        })
      }
    },
    isEmptyObj(obj) {
      for (let key in obj) {
        return false;
      }

      return true;
    },
    infiniteHandler($state) {
      if (this.clients.length < this.filterClientsMeta.entries.total) {
        this.getFilterClients(false)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    selectClient(client) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.changeFilters({ key: 'page', value: 1 });
      this.changeFilters({ key: 'client_id', value: client.client_id });

      this.getStudyGroups()
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      query.client_id = client.client_id;
      this.$router.replace({ query }).catch(() => {});
    },
    searchClient(search, loading) {
      this.clearFilterClients();
      this.changePageFilterClients(1);
      this.changeFiltersFilterClientsSearch(search);
      loading(true);
      this.searchClientsAction(search, loading, this);
    },
    searchClientsAction: _.debounce((search, loading, vm) => {
      vm.getFilterClients(false).then(() => {
        loading(false);
      })
    }, 1000),
    resetClient() {
      this.changeFilters({ key: 'page', value: 1 });
      this.changeFilters({ key: 'client_id', value: null });

      this.activeClient = null;
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.clearFilterClients();
      this.changePageFilterClients(1);

      this.getStudyGroups()
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      delete query.client_id;
      this.$router.replace({ query }).catch(() => {});
    },
    searchGroups(value) {
      this.isLoadingPage = true;

      const search = value.trim();

      this.changeFilters({ key: 'page', value: 1 });
      this.changeFilters({ key: 'query', value: search ? search : null });

      this.getStudyGroups()
        .finally(() => {
          this.page = 1;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = '1';
      if (!search) {
        delete query.query;
      } else {
        query.query = search;
      }
      this.$router.replace({ query }).catch(() => {});
    },
  }
}
</script>