<template>
  <div class="account-content">
    <GoBack />

    <div class="school-cabinet">
      <AppLoader v-if="loadingInstructors.lessons || loadingClients.clients" />

      <template v-else>
        <div class="filters d-flex flex-wrap">
          <div class="flex-grow-1 flex-xl-grow-0 d-flex flex-wrap flex-md-nowrap">
            <div class="filters-item filters-item--200 flex-grow-1 mb-2 mb-md-0">
              <v-select
                :options="clients"
                @input="selectClient"
                @search="searchClient"
                :disabled="disabledFilters"
                :clearable="false"
                :searchable="true"
                placeholder="Клиент"
                :get-option-label='option => option.name ? `${option.name.first} ${option.name.last} ${option.name.middle}` : null'
                :get-option-key='option => option.client_id'
                v-model="activeClient"
              >
                <template #open-indicator="{ attributes }">
                  <span class="form-control--arrow" v-bind="attributes"></span>
                </template>
                <template #list-header>
                  <li class="vs__dropdown-option vs__dropdown-option--reset" @mouseover="resetHover = true" @mouseleave="resetHover = false" v-if="activeClient" @click="resetClient">Все</li>
                </template>
                <template slot="no-options">
                  Пользователей не найдено
                </template>
                <template v-slot:option="option">
                  {{ option.name.first }} {{ option.name.last }} {{ option.name.middle }}
                </template>
                <template #spinner="{ loading }">
                  <AppLoader2 :mini="true" v-if="loading" />
                </template>
                <template #list-footer>
                  <infinite-loading @infinite="infiniteHandler">
                    <div slot="spinner">
                      <AppLoader2 :mini="true" />
                    </div>
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                  </infinite-loading>
                </template>
              </v-select>
            </div>

            <div class="filters-item filters-item--200 flex-grow-1 mb-2 mb-md-0">
              <DropDownMenuStatus
                :filtersStatus="filtersStatus"
                :disabled="disabledFilters"
                @select="changeStatus"
              />
            </div>

            <div class="filters-item filters-item--200 flex-grow-1 mb-2 mb-md-0 mr-0">
              <DropDownMenuCalendar
                :dateCalendar="dateCalendar"
                :filtersDate="filtersDate"
                :disabled="disabledFilters"
                @changeDate="changeDate"
              />
            </div>
          </div>


          <transition name="fade--top">
            <button
              v-if="filtersApplied"
              @click="resetFilters"
              type="button"
              class="reset-filters filters-item--100 align-self-center mt-3 mb-3 mt-xl-0 mb-md-0 ml-xl-auto"
            >
              Сбросить фильтры
            </button>
          </transition>
        </div>

        <div class="wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
          <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />

          <div v-if="lessons.lessons.length" class="school-cabinet_white-box">
            <div class="school-cabinet_info">
              <div class="school-cabinet_info-table mt-2 mt-md-1 mr-md-2">
                <div class="school-cabinet_info-table-head mb-3 d-none d-md-flex">
                  <div class="school-cabinet_info-table-title school-cabinet_info-table-title--1"></div>
                  <div class="school-cabinet_info-table-title school-cabinet_info-table-title--2 mr-3">Занятие</div>
                  <div class="school-cabinet_info-table-title school-cabinet_info-table-title--3">Статус</div>
                  <div class="school-cabinet_info-table-title"></div>
                </div>

                <InstructorLessonCard
                  v-for="lesson in lessons.lessons"
                  :key="lesson.lesson_id"
                  :lesson="lesson"
                />

                <div v-if="lessons.meta.page.total > 1" class="d-flex justify-content-center">
                  <paginate
                    v-model="page"
                    :pageCount="lessons.meta.page.total"
                    :clickHandler="clickCallback"
                    :prevText="''"
                    :nextText="''"
                    :containerClass="'pagination'"
                    :pageClass="'page-item'"
                    :pageLinkClass="'page-link'"
                    :prevClass="'page-item'"
                    :prevLinkClass="'page-link'"
                    :nextClass="'page-item'"
                    :nextLinkClass="'page-link'"
                    :activeClass="'active'"
                  />
                </div>
              </div>
            </div>
          </div>

          <p v-else>Занятий нет</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex';
import Paginate from 'vuejs-paginate'
import AppLoader2 from '@/components/AppLoader/2.vue';
import VModal from 'vue-js-modal';
import InfiniteLoading from 'vue-infinite-loading';
import moment from 'moment';
import _ from 'lodash';

import AppLoader from '@/components/AppLoader/1.vue';
import vSelect from 'vue-select';
import DropDownMenuStatus from '@/components/DropDownMenu/Status.vue';
import InstructorLessonCard from '@/components/Instructor/LessonCard.vue';
import DropDownMenuCalendar from '@/components/DropDownMenu/Calendar.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';
import GoBack from '@/components/GoBack.vue';

Vue.use(VModal, { dialog: true });

export default {
  name: 'InstructorsLessons',

  components: {
    AppLoader,
    vSelect,
    DropDownMenuStatus,
    InstructorLessonCard,
    Paginate,
    DropDownMenuCalendar,
    InfiniteLoading,
    AppLoader2,
    AppLoader3,
    GoBack
  },

  data() {
    return {
      page: 1,
      isLoadingPage: false,
      clientId: null,
      resetHover: false,
      activeClient: null,
      dateCalendar: [],
      disabledFilters: false
    }
  },

  computed: {
    ...mapGetters({
      lessons: 'instructorLessons',
      lessonsPage: 'instructorLessonsPage',
      loadingInstructors: 'loadingInstructors',
      loadingClients: 'filterClientsLoading',
      clients: 'filterClients',
      client: 'client',
      filtersApplied: 'instructorLessonsFiltersApplied',
      filtersDate: 'instructorLessonsFiltersDate',
      filtersClient: 'instructorLessonsFiltersClient',
      filtersStatus: 'instructorLessonsFiltersStatus',
      filterClientsMeta: 'filterClientsMeta',
    }),
  },

  async created() {
    this.setLoadingInstructorLessons();

    this.clearInstructorLessons();
    this.changePageInstructorLessons(1);
    this.resetFiltersInstructorLessons();

    this.clearFilterClients();
    this.changePageFilterClients(1);
    this.changeFiltersFilterClientsSearch(null);
    await this.getFilterClients();

    if (this.$route.query.page) {
      this.changePageInstructorLessons(Number(this.$route.query.page));
    }

    if (this.$route.query.client_id) {
      this.changeFiltersInstructorLessonsClientId(this.$route.query.client_id);

      this.clientId = this.$route.query.client_id;

      await this.getClient(this.$route.query.client_id);

      this.addClientInFilterListClients(this.client);

      this.activeClient = this.client;
    }

    if (this.$route.query.status) {
      this.changeFiltersInstructorLessonsStatus(this.$route.query.status);
    }

    if (this.$route.query.date_from && this.$route.query.date_to) {
      const date = {
        from: moment(this.$route.query.date_from, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        to: moment(this.$route.query.date_to, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      }

      this.changeFiltersInstructorLessonsDate(date);

      this.dateCalendar = [moment(this.$route.query.date_from, 'DD-MM-YYYY')._d, moment(this.$route.query.date_to, 'DD-MM-YYYY')._d];
    }

    this.page = this.lessonsPage;

    let query = Object.assign({}, this.$route.query);
    query.page = this.lessonsPage;
    this.$router.replace({ query }).catch(() => {});

    const payload = {
      idInstructor: this.$route.params.id,
      loading: true
    }

    await this.getInstructorLessons(payload);

    if (this.lessons && this.lessonsPage > this.lessons.meta.page.total) {
      await this.changePageInstructorLessons(this.lessons.meta.page.total);

      this.page = this.lessonsPage;

      let query = Object.assign({}, this.$route.query);
      query.page = this.lessonsPage;
      this.$router.replace({ query }).catch(() => {});

      const payload = {
        idInstructor: this.$route.params.id,
        loading: true
      }

      this.getInstructorLessons(payload);
    }
  },

  methods: {
    ...mapActions([
      'getInstructorLessons',
      'clearInstructorLessons',
      'changePageInstructorLessons',
      'getFilterClients',
      'getClient',
      'addClientInFilterListClients',
      'changePageFilterClients',
      'changeFiltersInstructorLessonsClientId',
      'changeFiltersInstructorLessonsDate',
      'changeFiltersInstructorLessonsStatus',
      'resetFiltersInstructorLessons',
      'changeFiltersClientsInstructorId',
      'changeFiltersFilterClientsSearch',
      'setLoadingInstructorLessons',
      'clearFilterClients',
    ]),
    clickCallback() {
      this.isLoadingPage = true;

      let query = Object.assign({}, this.$route.query);
      query.page = this.page;

      this.changePageInstructorLessons(this.page)
        .then(() => {
          this.$router.replace({ query }).catch(() => {});

          const payload = {
            idInstructor: this.$route.params.id,
            loading: false
          }

          this.getInstructorLessons(payload)
            .finally(() => this.isLoadingPage = false);
        });
    },
    resetClient() {
      this.changePageInstructorLessons(1);
      this.changeFiltersClientsInstructorId(null);
      this.activeClient = null;
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.clearFilterClients();
      this.changePageFilterClients(1);

     const payload = {
       idInstructor: this.$route.params.id,
       loading: false
     }

      this.getInstructorLessons(payload)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      delete query.client_id;
      this.$router.replace({ query }).catch(() => {});
    },
    searchClient(search, loading) {
      this.clearFilterClients();
      this.changePageFilterClients(1);
      this.changeFiltersFilterClientsSearch(search);
      loading(true);
      this.searchClientsAction(search, loading, this);
    },
    searchClientsAction: _.debounce((search, loading, vm) => {
      vm.getFilterClients(false).then(() => {
        loading(false);
      })
    }, 1000),
    infiniteHandler($state) {
      if (this.clients.length < this.filterClientsMeta.entries.total) {
        this.getFilterClients(false)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    selectClient(client) {
      this.isLoadingPage = true;
      this.disabledFilters = true;
      this.clientId = client.client_id;

      this.changeFiltersInstructorLessonsClientId(client.client_id);
      this.changePageInstructorLessons(1);

      const payload = {
        idInstructor: this.$route.params.id,
        loading: false
      }
      this.getInstructorLessons(payload)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      query.client_id = client.client_id;
      this.$router.replace({ query }).catch(() => {});
    },
    changeDate(dateCalendar) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.changePageInstructorLessons(1);

      let date = null;

      if (dateCalendar) {
        date = {
          from: moment(dateCalendar[0]).format('YYYY-MM-DD'),
          to: moment(dateCalendar[1]).format('YYYY-MM-DD'),
        }

        let query = Object.assign({}, this.$route.query);
        query.page = 1;
        query.date_from = moment(dateCalendar[0]).format('DD-MM-YYYY');
        query.date_to = moment(dateCalendar[1]).format('DD-MM-YYYY');
        this.$router.replace({ query }).catch(() => {});
      } else {
        if (!this.filtersDate) {
          return;
        }

        date = {
          from: null,
          to: null,
        }

        let query = Object.assign({}, this.$route.query);
        query.page = 1;
        delete query.date_from;
        delete query.date_to;
        this.$router.replace({ query }).catch(() => {});
      }

      this.changeFiltersInstructorLessonsDate(date);

      const payload = {
        idInstructor: this.$route.params.id,
        loading: false
      }

      this.getInstructorLessons(payload)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    changeStatus(status) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.changePageInstructorLessons(1);

      if (status === 'reset') {
        this.changeFiltersInstructorLessonsStatus(null);

        let query = Object.assign({}, this.$route.query);
        query.page = 1;
        delete query.status;
        this.$router.replace({ query }).catch(() => {});
      } else {
        this.changeFiltersInstructorLessonsStatus(status);

        let query = Object.assign({}, this.$route.query);
        query.page = 1;
        query.status = status;
        this.$router.replace({ query }).catch(() => {});
      }

      const payload = {
        idInstructor: this.$route.params.id,
        loading: false
      }

      this.getInstructorLessons(payload)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    resetFilters() {
      if (this.disabledFilters) {
        return;
      }

      this.isLoadingPage = true;
      this.disabledFilters = true;
      this.activeClient = null;

      this.resetFiltersInstructorLessons();
      this.changePageInstructorLessons(1);

      const payload = {
        idInstructor: this.$route.params.id,
        loading: false
      }

      this.getInstructorLessons(payload)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      delete query.status;
      delete query.date_from;
      delete query.date_to;
      delete query.client_id;
      this.$router.replace({ query }).catch(() => {});
    },
    moment
  }
}
</script>

<style>
.mx-datepicker {
  z-index: 20;
}

.mx-datepicker-inline {
  position: absolute;
}

.filters-item {
  width: 220px !important;
}

.filters-item--large {
  width: 82% !important;
}

@media screen and (max-width: 1199px) {
  .filters-item--large {
    width: 76% !important;
  }
}

@media screen and (max-width: 767px) {
  .filters-item {
    width: 100% !important;
  }
}

@media screen and (max-width: 1200px) {
  .filters-item--200 {
    width: 200px !important;
  }
}

@media screen and (max-width: 992px) {
  .filters-item--200 {
    width: 220px !important;
  }
}

@media screen and (max-width: 767px) {
  .filters-item--200 {
    width: 100% !important;
  }
}
</style>