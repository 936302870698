function getMetaValue(name, defaultVal = '') {
    let el = document.querySelector('meta[name=' + name + ']')
    return el ? el.content : defaultVal
}

const settings = {
    API_URL: getMetaValue('API_URL'),
    BEARER: getMetaValue('BEARER'),
}

export default settings
