import instance from '../instance';
import settings from '../settings';

export default {
  state: {
    analytics: null,
    filtersForAnalytics: {
      page: 1,
      page_size: 20,
      date_from: null,
      date_to: null,
      query: ''
    },
    loading: {
      analytics: true,
    }
  },

  getters: {
    analytics: state => state.analytics,
    pageAnalytics: state => state.filtersForAnalytics.page,
    loadingAnalytics: state => state.loading,
  },

  actions: {
    async getAnalytics({ commit, state }, loading = true) {
      if (loading) {
        commit('START_GET_ANALYTICS_LOADING');
      }

      try {
        const requestId = 'analytics';

        const params = Object.fromEntries(Object.entries(state.filtersForAnalytics).filter(([key, value]) => key && value));

        const analytics = await instance.get(`${settings.API_URL}/analytics`, { requestId, params });

        commit('SET_ANALYTICS', analytics.data);
      } finally {
        commit('FINISH_GET_ANALYTICS_LOADING');
      }
    },
    clearAnalytics({ commit }) {
      commit('CLEAR_ANALYTICS');
    },
    changePageAnalytics({ commit }, page) {
      commit('CHANGE_PAGE_ANALYTICS', page);
    },
    changeFiltersDateAnalytics({ commit }, date) {
      commit('CHANGE_FILTERS_DATE_ANALYTICS', date);
    },
    changeFiltersSearchAnalytics({ commit }, search) {
      commit('CHANGE_FILTERS_SEARCH_ANALYTICS', search);
    },
    resetFiltersAnalytics({ commit }) {
      commit('CHANGE_PAGE_ANALYTICS', 1);

      commit('CHANGE_FILTERS_DATE_ANALYTICS', { from: null, to: null });

      commit('CHANGE_FILTERS_SEARCH_ANALYTICS', null);
    },
  },

  mutations: {
    SET_ANALYTICS(state, analytics) {
      state.analytics = analytics;
    },
    CLEAR_ANALYTICS(state) {
      state.analytics = null;
    },
    CHANGE_PAGE_ANALYTICS(state, page) {
      state.filtersForAnalytics.page = page;
    },
    CHANGE_FILTERS_DATE_ANALYTICS(state, date) {
      state.filtersForAnalytics.date_from = date.from;

      state.filtersForAnalytics.date_to = date.to;
    },
    CHANGE_FILTERS_SEARCH_ANALYTICS(state, search) {
      state.filtersForAnalytics.query = search;
    },
    START_GET_ANALYTICS_LOADING(state) {
      state.loading.analytics = true;
    },
    FINISH_GET_ANALYTICS_LOADING(state) {
      state.loading.analytics = false;
    },
  },
};