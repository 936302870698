import instance from '../instance';
import settings from '../settings';
import moment from 'moment';

export default {
	namespaced: true,

	state: {
		payer: null,
		school: null,
		requisites: null,
		loading: {
			payer: true,
			updatePayer: false,
			school: false,
			requisites: false,
			updateSchool: false,
			updateRequisites: false,
			updateBankRequisites: false,
			logo: false,
			bank: false,
		}
	},

	getters: {
		payer: state => state.payer,
		loading: state => state.loading,
		school: state => state.school,
		requisites: state => state.requisites,
	},

	actions: {
		async getPayer({ commit }) {
			commit('START_PAYER_LOADING');

			try {
				const payer = await instance.get(`${settings.API_URL}/school/payer`);

				commit('SET_PAYER', payer.data.payer);
			} finally {
				commit('FINISH_PAYER_LOADING');
			}
		},

		async updatePayer({ commit }, payer) {
			commit('START_UPDATE_PAYER_LOADING');

			if (payer.payer.outgo_settings.min_amount == '') {
				payer.payer.outgo_settings.min_amount = 0;
			}

			try {
				await instance.patch(`${settings.API_URL}/school/payer`, payer);
			} finally {
				commit('FINISH_UPDATE_PAYER_LOADING');
			}
		},

		async getSchool({ commit }, id) {
			commit('START_SCHOOL_LOADING');

			try {
				const school = await instance.get(`${settings.API_URL}/school/${id}/general-info`);

				commit('SET_SCHOOL', school.data);
			} finally {
				commit('FINISH_SCHOOL_LOADING');
			}
		},

		async updateSchool({ commit }, organization) {
			commit('START_UPDATE_SCHOOL_LOADING');
			if (organization.date.length > 0) {
				organization.licence_issued_at = moment(organization.date, 'DD.MM.YYYY').format('YYYY-MM-DD');
			}

			try {
				const schoolData = await instance.patch(`${settings.API_URL}/school/${organization.school_id}/general-info`, organization);

				commit('SET_SCHOOL', schoolData.data);
			} finally {
				commit('FINISH_UPDATE_SCHOOL_LOADING');
			}
		},

		async getRequisites({ commit }, id) {
			commit('START_REQUISITES_LOADING');

			try {
				const requisites = await instance.get(`${settings.API_URL}/school/${id}/requisites`);

				commit('SET_REQUISITES', requisites.data)
			} finally {
				commit('FINISH_REQUISITES_LOADING');
			}
		},

		async updateRequisites({ commit }, organization_requisites) {
			commit('START_UPDATE_REQUISITES_LOADING');

			try {
				const requisitesData = await instance.patch(`${settings.API_URL}/school/${organization_requisites.school_id}/requisites`, organization_requisites);

				commit('SET_SCHOOL', requisitesData.data)
			} finally {
				commit('FINISH_UPDATE_REQUISITES_LOADING');
			}
		},

		async updateLogo({ commit }, payload) {
			commit('CHANGE_LOGO_LOADING', true);

			try {
				await instance.post(`${settings.API_URL}/school/${payload.id}/logo`, payload.logo);
			} finally {
				commit('CHANGE_LOGO_LOADING', false);
			}
		},

		async addBankRequisites({ commit }, payload) {
			commit('CHANGE_BANK_LOADING', true);
			await instance.put(`${settings.API_URL}/school/${payload.id}/requisites/bank`, payload.requisite);
			commit('CHANGE_BANK_LOADING', false);
		},

		async updateBankRequisites({ commit }, payload) {
			commit('CHANGE_BANK_LOADING', true);
			await instance.patch(`${settings.API_URL}/school/${payload.id}/requisites/bank/${payload.bank_id}`, payload.requisite);
			commit('CHANGE_BANK_LOADING', false);
		},

		async deleteBankRequisites({ commit }, payload) {
			commit('CHANGE_BANK_REQUISITES_LOADING', true);
			await instance.delete(`${settings.API_URL}/school/${payload.id}/requisites/bank/${payload.bank_id}`);
			commit('CHANGE_BANK_REQUISITES_LOADING', false);
		},
	},

	mutations: {
		SET_PAYER(state, payer) {
			state.payer = payer;
		},
		START_PAYER_LOADING(state) {
			state.loading.payer = true;
		},
		FINISH_PAYER_LOADING(state) {
			state.loading.payer = false;
		},

		START_UPDATE_PAYER_LOADING(state) {
			state.loading.updatePayer = true;
		},
		FINISH_UPDATE_PAYER_LOADING(state) {
			state.loading.updatePayer = false;
		},

		SET_SCHOOL(state, school) {
			state.school = school;
		},
		START_SCHOOL_LOADING(state) {
			state.loading.school = true;
		},
		FINISH_SCHOOL_LOADING(state) {
			state.loading.school = false;
		},

		START_UPDATE_SCHOOL_LOADING(state) {
			state.loading.updateSchool = true;
		},
		FINISH_UPDATE_SCHOOL_LOADING(state) {
			state.loading.updateSchool = false;
		},

		SET_REQUISITES(state, requisites) {
			state.requisites = requisites;
		},
		START_REQUISITES_LOADING(state) {
			state.loading.requisites = true;
		},
		FINISH_REQUISITES_LOADING(state) {
			state.loading.requisites = false;
		},

		START_UPDATE_REQUISITES_LOADING(state) {
			state.loading.updateRequisites = true;
		},
		FINISH_UPDATE_REQUISITES_LOADING(state) {
			state.loading.updateRequisites = false;
		},

		CHANGE_LOGO_LOADING(state, status) {
			state.loading.logo = status;
		},

		CHANGE_BANK_LOADING(state, status) {
			state.loading.bank = status;
		},

		CHANGE_BANK_REQUISITES_LOADING(state, status) {
			state.loading.updateBankRequisites = status;
		},
	},
};