<template>
  <div>
    <div class="account-subtitle pt-1 mt-4 mb-3">Отправленные пакеты занятий</div>

    <AppLoader2 v-if="loading.lessonsPackages" :middle="true" />

    <template v-else>
      <template v-if="packages.length">
        <ClientLessonsPackagesCard
          v-for="(lessonsPackage, index) in packages"
          :key="index"
          :lessonsPackage="lessonsPackage"
        />

        <infinite-loading @infinite="infiniteHandler" class="mt-4">
          <div slot="spinner">
            <AppLoader2 :mini="true" />
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </template>

      <p v-else>Пакетов нет</p>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import InfiniteLoading from 'vue-infinite-loading';

import AppLoader2 from '@/components/AppLoader/2.vue';
import ClientLessonsPackagesCard from '@/components/Client/Lessons/Packages/Card.vue';

export default {
  name: 'ClientLessonsPackagesList',

  components: {
    AppLoader2,
    ClientLessonsPackagesCard,
    InfiniteLoading
  },

  props: {
    client: {
      typeof: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      packagesAllData: 'clientLessonsPackagesAllData',
      packages: 'clientLessonsPackages',
      loading: 'loadingClients'
    })
  },

  methods: {
    ...mapActions(['getClientLessonsPackages']),
    infiniteHandler($state) {
      if (this.packagesAllData.meta.page.number !== this.packagesAllData.meta.page.total) {
        const payloadPackages = {
          idClient: this.client.client_id,
          loading: false
        }

        this.getClientLessonsPackages(payloadPackages)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
  }
}
</script>