<template>
  <div class="account-content">
    <GoBack />

    <div class="account-subtitle pt-0 mb-3">Редактировать профиль</div>

    <AppLoader v-if="loading.administrator" />

    <div v-else class="school-cabinet">
      <div class="school-cabinet_white-box">
        <div class="school-cabinet_info">
          <div class="school-cabinet_info-form school-cabinet_info-form--profile">
            <div class="school-cabinet_info-row mb-4">
              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="administrator.user.name.first"
                  @focus="hideErrorHighlight('name.first'); $v.administrator.user.name.first.$reset()"
                  type="text"
                  placeholder="Имя"
                  :class="{ 'form-control--error': $v.administrator.user.name.first.$error || errors.includes('name.first') }"
                  class="form-control form-control--bordered"
                >

                <div class="text-error" v-if="!$v.administrator.user.name.first.required && $v.administrator.user.name.first.$dirty">Поле обязательно для заполнения</div>
                <div class="text-error" v-if="!$v.administrator.user.name.first.alpha && $v.administrator.user.name.first.$dirty">Вводите только буквы</div>
              </div>

              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="administrator.user.name.last"
                  @focus="hideErrorHighlight('name.last'); $v.administrator.user.name.last.$reset()"
                  type="text"
                  placeholder="Фамилия"
                  :class="{ 'form-control--error': $v.administrator.user.name.last.$error || errors.includes('name.last') }"
                  class="form-control form-control--bordered"
                >

                <div class="text-error" v-if="!$v.administrator.user.name.last.required && $v.administrator.user.name.last.$dirty">Поле обязательно для заполнения</div>
                <div class="text-error" v-if="!$v.administrator.user.name.last.alpha && $v.administrator.user.name.last.$dirty">Вводите только буквы</div>
              </div>

              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="administrator.user.name.middle"
                  @focus="hideErrorHighlight('name.middle'); $v.administrator.user.name.middle.$reset()"
                  type="text"
                  placeholder="Отчество"
                  :class="{ 'form-control--error': $v.administrator.user.name.middle.$error || errors.includes('name.middle') }"
                  class="form-control form-control--bordered"
                >

                <div class="text-error" v-if="!$v.administrator.user.name.middle.alpha && $v.administrator.user.name.middle.$dirty">Вводите только буквы</div>
              </div>

              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="administrator.user.email"
                  @focus="hideErrorHighlight('email'); $v.administrator.user.email.$reset()"
                  type="text"
                  placeholder="E-mail"
                  :class="{ 'form-control--error': $v.administrator.user.email.$error || errors.includes('email') }"
                  class="form-control form-control--bordered"
                >

                <div class="text-error" v-if="!$v.administrator.user.email.required && $v.administrator.user.email.$dirty">Поле обязательно для заполнения</div>
                <div class="text-error" v-if="!$v.administrator.user.email.email && $v.administrator.user.email.$dirty">Введите вашу почту</div>
              </div>
            </div>

            <div class="school-cabinet_info-row form-group mb-5">
              <div class="school-cabinet_info-row-label mb-2">Разрешения</div>
              <div class="school-cabinet_info-field">
                <div class="radio d-flex flex-wrap align-items-center">
                  <div class="custom-checkbox" v-for="(premission, key) in permissions" :key="key">
                      <input
                        v-model="administrator.user.permissions"
                        type="checkbox"
                        name="district"
                        :id="key"
                        :value="key"
                      >
                      <label :for="key" class="d-flex">{{premission}}</label>
                    </div>
                </div>
              </div>

              <div class="text-error" v-if="!$v.administrator.user.permissions.required && $v.administrator.user.permissions.$dirty">Выберите разрешение</div>
            </div>

            <button v-if="loading.editAdministrator" class="btn btn-green btn-full btn-blue--custom">
              <AppLoader2 :mini="true" :color="'#ffffff'" />
            </button>

            <button v-else @click="administratorEdit" class="btn btn-green btn-full btn-blue--custom">Сохранить</button>

            <router-link :to="`/administrators/${administratorReal.user_id}/edit/change-password`" class="school-cabinet_card-link school-cabinet_card-link--bg school-cabinet_card-link--change-password justify-content-between mt-5">Сменить пароль</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Vuelidate from 'vuelidate';
import { VueMaskDirective }  from 'v-mask'

import { email, required, helpers } from 'vuelidate/lib/validators';
const alpha = helpers.regex('alpha', /^[a-zA-Zа-яА-Я]*$/);

import AppLoader2 from '@/components/AppLoader/2.vue';
import AppLoader from '@/components/AppLoader/1.vue';
import GoBack from '@/components/GoBack.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';
import hideErrorHighlight from '@/helpers/hideErrorHighlight';

Vue.use(VueToast);
Vue.use(Vuelidate);

export default {
  name: 'AdministratorEdit',

  components: {
    AppLoader2,
    AppLoader,
    GoBack
  },

  directives: {
    'mask': VueMaskDirective
  },

  data() {
    return {
      administrator: {
        user: {
          name: {
            first: '',
            last: '',
            middle: '',
          },
          email: '',
          permissions: []
        }
      },
      errors: []
    }
  },

  computed: {
    ...mapGetters({
      administratorReal: 'administrator',
      permissions: 'permissionsList',
      loading: 'loadingAdministrators',
      profile: 'profile'
    })
  },

  async created() {
    await this.getAdministrator(this.$route.params.id);

    this.administrator.user.email = this.administratorReal.email;
    this.administrator.user.name.first = this.administratorReal.name.first;
    this.administrator.user.name.last = this.administratorReal.name.last;
    this.administrator.user.name.middle = this.administratorReal.name.middle;
    this.administrator.user.permissions = this.administratorReal.permissions;

    this.getPermissionsList();
  },

  beforeDestroy() {
    this.clearAdministrator();
  },

  methods: {
    ...mapActions([
      'getAdministrator',
      'editAdministrator',
      'clearAdministrator',
      'setProfileAfterEdit',
      'getPermissionsList',
    ]),
    administratorEdit() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      } else {
        const administrator = {
          user: JSON.parse(JSON.stringify(this.administrator.user))
        };

        const payload = {
          idAdministrator: this.$route.params.id,
          administrator
        };

        this.editAdministrator(payload)
          .then((data) => {
            if (this.profile.user.user_id == this.$route.params.id) {
              this.setProfileAfterEdit(data);
            }

            this.$toast.open({
              message: 'Данные администратора успешно изменены',
              type: 'success'
            });
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);

            if (error.response.data.errors) {
              this.errorsCheck(error.response.data.errors);
            }
          });
      }
    },
    errorsCheck(errors) {
      Object.keys(errors).forEach(el => {
        switch (el) {
          case 'user.name.first':
            this.errors.push('name.first');
            break;
          case 'user.name.last':
            this.errors.push('name.last');
            break;
          case 'user.name.middle':
            this.errors.push('name.middle');
            break;
          case 'user.email':
            this.errors.push('email');
            break;
        }
      })
    },
    errorHandlerForResponse,
    hideErrorHighlight
  },

  validations: {
    administrator: {
      user: {
        email: {
          required,
          email
        },
        name: {
          first: {
            required,
            alpha
          },
          last: {
            required,
            alpha
          },
          middle: {
            alpha
          },
        },
        permissions: {
          required
        },
      }
    }
  }
}
</script>

<style>
.school-cabinet_card-link--change-password {
  border: 1px solid #e8e8e8;
}

.school-cabinet_info-form--profile {
  width: 460px !important;
}
</style>