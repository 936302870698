<template>
  <div class="account-content">
    <div class="breadcrumbs breadcrumbs--custom mt-2 mb-4">
      <ul class="d-flex">
        <li class="mr-1"><router-link to="/assignment" class="active">Распределение</router-link></li>
        <li class="mr-1">/</li>
        <li>Добавить занятия</li>
      </ul>
    </div>

    <AppLoader v-if="loading.clientInstructors" />

    <div v-else class="school-cabinet">
      <div class="school-cabinet_white-box">
        <div class="school-cabinet_info pt-2">
          <div class="school-cabinet_card school-cabinet_card--distribution mb-3 mb-md-4 pb-md-2">
            <div class="school-cabinet_card-top d-flex justify-content-between flex-wrap">
              <div class="school-cabinet_card-content school-cabinet_card-content--2 d-flex width-49 mb-3 mb-md-0">
                <div class="school-cabinet_card-icon" style="width: 34px; height: 34px">
                  <img v-if="clientInstructors.client.avatar" :src="clientInstructors.client.avatar.url" alt="">
                  <img v-else src="@/assets/img/noavatar.svg" :alt="clientInstructors.client.name.full">
                </div>

                <div class="school-cabinet_card-text">
                  <div class="school-cabinet_card-facts">Клиент</div>
                  <div class="school-cabinet_card-name text-decoration-none">{{ clientInstructors.client.name.full }}</div>
                </div>
              </div>

              <DropDownMenuLessonsOrPackage v-if="!isPackage && profile.school.features.lesson_packages" @select="selectAdd" class="width-49" />
            </div>
          </div>

          <div v-if="selectedForPackage" class="school-cabinet_info-form school-cabinet_info-form--full">
            <div class="school-cabinet_info-row">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="school-cabinet_info-field school-cabinet_info-field--large">
                  <div class="school-cabinet_info-row-label">Инструктор</div>

                  <v-select
                    @search="searchInstructor"
                    :options="clientInstructors.instructors"
                    @input="selectInstructorPackage"
                    :clearable="false"
                    :get-option-label='option => option.instructor.name.full'
                    :get-option-key='option => option.instructor.instructor_id'
                    v-model="activeInstructor"
                  >
                    <template #open-indicator="{ attributes }">
                      <span class="form-control--arrow" v-bind="attributes"></span>
                    </template>
                    <template slot="no-options">
                      Пользователей не найдено
                    </template>
                    <template v-slot:option="option">
                      {{ option.instructor.name.full }}
                    </template>
                    <template #spinner="{ loading }">
                      <AppLoader2 :mini="true" v-if="loading" />
                    </template>
                    <template #list-footer>
                      <infinite-loading @infinite="infiniteHandler">
                        <div slot="spinner">
                          <AppLoader2 :mini="true" />
                        </div>
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                      </infinite-loading>
                    </template>
                  </v-select>
                </div>

                <div class="school-cabinet_info-field school-cabinet_info-field--large">
                  <div class="school-cabinet_info-row-label">Тариф</div>

                  <!-- <DropDownMenuTariffsLevelApp
                    :instructorId="packageParent.package.instructor_id"
                    @selectedTariff="selectTariffLevelApp"
                  /> -->
                </div>
              </div>
            </div>

            <div class="school-cabinet_info-row">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="school-cabinet_info-field school-cabinet_info-field--large form-group mb-4">
                  <div class="school-cabinet_info-row-label">Количество занятий</div>
                  <input
                    v-model="packageParent.package.qty"
                    @focus="hideErrorHighlight('qty'); $v.packageParent.package.qty.$reset()"
                    @input="checkQty"
                    type="text"
                    :class="{ 'form-control--error': $v.packageParent.package.qty.$error || errors.includes('qty') }"
                    class="form-control form-control--bordered"
                  >

                  <div class="text-error" v-if="!$v.packageParent.package.qty.required && $v.packageParent.package.qty.$dirty">Поле обязательно для заполнения</div>
                </div>

                <div class="school-cabinet_info-field school-cabinet_info-field--large">
                  <div class="school-cabinet_info-row-label">Заметка для инструктора</div>
                  <input
                    v-model.trim="packageParent.note"
                    @focus="hideErrorHighlight('note')"
                    type="text"
                    :class="{ 'form-control--error': errors.includes('note') }"
                    class="form-control form-control--bordered"
                  >
                </div>
              </div>
            </div>

            <div class="school-cabinet_info-row">
              <div class="school-cabinet_info-field school-cabinet_info-field--large form-group mb-4">
                <div class="school-cabinet_info-row-label">Сообщение клиенту</div>

                <input
                  v-model.trim="packageParent.package.message"
                  @focus="hideErrorHighlight('message'); $v.packageParent.package.message.$reset()"
                  type="text"
                  :class="{ 'form-control--error': $v.packageParent.package.message.$error || errors.includes('message') }"
                  class="form-control form-control--bordered"
                >

                <div class="text-error" v-if="!$v.packageParent.package.message.required && $v.packageParent.package.message.$dirty">Поле обязательно для заполнения</div>
              </div>
            </div>

            <div class="school-cabinet_info-button text-center mb-3 mb-md-0">
              <button v-if="loading.createPackage" class="btn btn-blue btn-blue--custom">
                <AppLoader2 :mini="true" :color="'#ffffff'" />
              </button>

              <button v-else @click="packageCreate" class="btn btn-blue btn-blue--custom">Добавить</button>
            </div>
          </div>

          <div v-else class="school-cabinet_info-form school-cabinet_info-form--full">
            <div class="school-cabinet_info-row">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="school-cabinet_info-field school-cabinet_info-field--large">
                  <div class="school-cabinet_info-row-label">Инструктор</div>
                  <v-select
                    @search="searchInstructor"
                    :options="clientInstructors.instructors"
                    @input="selectInstructor"
                    :clearable="false"
                    :get-option-label='option => option.instructor.name.full'
                    :get-option-key='option => option.instructor.instructor_id'
                    v-model="activeInstructor"
                  >
                    <template #open-indicator="{ attributes }">
                      <span class="form-control--arrow" v-bind="attributes"></span>
                    </template>
                    <template slot="no-options">
                      Пользователей не найдено
                    </template>
                    <template v-slot:option="option">
                      {{ option.instructor.name.full }}
                    </template>
                    <template #spinner="{ loading }">
                      <AppLoader2 :mini="true" v-if="loading" />
                    </template>
                    <template #list-footer>
                      <infinite-loading @infinite="infiniteHandler">
                        <div slot="spinner">
                          <AppLoader2 :mini="true" />
                        </div>
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                      </infinite-loading>
                    </template>
                  </v-select>
                </div>

                <div class="school-cabinet_info-field school-cabinet_info-field--large">
                  <div class="school-cabinet_info-row-label" v-if="lessons.lessons.instructor_id">Тариф</div>

                  <!-- <DropDownMenuTariffs v-if="lessons.lessons.instructor_id" :tariffs="tariffs" @selectedTariff="selectTariff" /> -->
                </div>
              </div>
            </div>

            <div class="school-cabinet_info-row">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="school-cabinet_info-field school-cabinet_info-field--large form-group mb-4">
                  <div class="school-cabinet_info-row-label">Количество занятий</div>
                  <input
                    v-model="lessons.lessons.qty"
                    @focus="hideErrorHighlight('qty'); $v.lessons.lessons.qty.$reset()"
                    @input="checkQty"
                    type="text"
                    :class="{ 'form-control--error': $v.lessons.lessons.qty.$error || errors.includes('qty') }"
                    class="form-control form-control--bordered"
                  >

                  <div class="text-error" v-if="!$v.lessons.lessons.qty.required && $v.lessons.lessons.qty.$dirty">Поле обязательно для заполнения</div>
                </div>

                <div class="school-cabinet_info-field school-cabinet_info-field--large">
                  <div class="school-cabinet_info-row-label">Заметка для инструктора</div>
                  <input
                    v-model.trim="lessons.note"
                    @focus="hideErrorHighlight('note')"
                    type="text"
                    :class="{ 'form-control--error': errors.includes('note') }"
                    class="form-control form-control--bordered"
                  >
                </div>
              </div>
            </div>

            <div class="school-cabinet_info-button text-center mb-3 mb-md-0">
              <button v-if="loading.createLessons" class="btn btn-blue btn-blue--custom">
                <AppLoader2 :mini="true" :color="'#ffffff'" />
              </button>

              <button v-else @click="lessonsCreate" class="btn btn-blue btn-blue--custom">Добавить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';
import Vuelidate from 'vuelidate';
import InfiniteLoading from 'vue-infinite-loading';
import vSelect from 'vue-select';
import _ from 'lodash';
import 'vue-select/dist/vue-select.css';
import { required } from 'vuelidate/lib/validators';

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
// import DropDownMenuTariffs from '@/components/DropDownMenu/Tariffs.vue';
import DropDownMenuLessonsOrPackage from '@/components/DropDownMenu/LessonsOrPackage.vue';
// import DropDownMenuTariffsLevelApp from '@/components/DropDownMenuTariffsLevelApp.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';
import hideErrorHighlight from '@/helpers/hideErrorHighlight';

Vue.use(Vuelidate);

export default {
  name: 'AssignmentCreateLessons',

  components: {
    vSelect,
    AppLoader,
    AppLoader2,
    InfiniteLoading,
    // DropDownMenuTariffs,
    DropDownMenuLessonsOrPackage,
    // DropDownMenuTariffsLevelApp
  },

  data() {
    return {
      lessons: {
        lessons: {
          instructor_id: '',
          client_id: this.$route.params.id,
          tariff_id: '',
          qty: ''
        },
        note: ''
      },
      packageParent: {
        package: {
          instructor_id: '',
          client_id: this.$route.params.id,
          tariff_id: '',
          qty: '',
          message: ''
        },
        note: ''
      },
      tariffs: [],
      errors: [],
      selectedForPackage: false,
      activeInstructor: null,
      isPackage: false
    }
  },

  computed: {
    ...mapGetters({
      clientInstructors: 'assignmentClientInstructors',
      loading: 'loadingAssignment',
      profile: 'profile'
    })
  },

  async created() {
    const payload = {
      idClient: this.$route.params.id,
      loading: true
    }

    await this.getСlientInstructors(payload);

    if (this.clientInstructors.instructors[0].has_packages) {
      this.isPackage = true;
    }

    this.packageParent.package.instructor_id = this.clientInstructors.instructors[0].instructor.instructor_id;


    if (this.profile.school.features.lesson_packages) {
      const payloadTarriffsLevelApp = {
        idInstructor: this.clientInstructors.instructors[0].instructor.instructor_id,
        loading: true
      }

      this.getTariffsLevelApp(payloadTarriffsLevelApp);
    }
  },

  methods: {
    ...mapActions([
      'createLessons',
      'getСlientInstructors',
      'createPackage',
      'getTariffsLevelApp',
      'changeFiltersSearchСlientInstructors',
      'clearСlientInstructorsList',
    ]),

    searchInstructor(search, loading) {
      this.changeFiltersSearchСlientInstructors(search);
      loading(true);
      this.searchInstructorAction(search, loading, this);
    },
    searchInstructorAction: _.debounce((search, loading, vm) => {
      const payload = {
        idClient: vm.$route.params.id,
        loading: false
      }
      vm.getСlientInstructors(payload).then(() => {
        loading(false);
      })
    }, 1000),
    infiniteHandler($state) {
      if (this.clientInstructors.instructors.length < this.clientInstructors.meta.entries.total) {
        const payload = {
          idClient: this.$route.params.id,
          loading: false,
        }

        this.getСlientInstructors(payload)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    selectInstructor(instructor) {
      this.lessons.lessons.instructor_id = instructor.instructor.instructor_id;
      this.packageParent.package.instructor_id = instructor.instructor.instructor_id;

      if (instructor.has_packages) {
        this.isPackage = true;
      } else {
        this.isPackage = false;
      }

      this.tariffs = instructor.tariffs;
      this.activeInstructor = instructor;
    },
    selectInstructorPackage(instructor) {
      this.packageParent.package.instructor_id = instructor.instructor.instructor_id;
      this.lessons.lessons.instructor_id = instructor.instructor.instructor_id;

      if (instructor.has_packages) {
        this.$modal.show('dialog', {
          text: 'У данного инструктора нельзя сформировать пакет',
          buttons: [
            {
              title: 'Ок',
              handler: () => {
                this.selectedForPackage = false;
                this.isPackage = true;

                this.$modal.hide('dialog');
              },
            },
          ],
        });
      }
      this.activeInstructor = instructor;
    },
    selectTariff(tariff) {
      tariff === 'noTariffs'
        ? this.lessons.lessons.tariff_id = null
        : this.lessons.lessons.tariff_id = tariff.tariff_id;
    },
    selectTariffLevelApp(tariff) {
      tariff === 'noTariffs'
        ? this.packageParent.package.tariff_id = null
        : this.packageParent.package.tariff_id = tariff.tariff_id;
    },
    lessonsCreate() {
      if (!this.tariffs.length) {
        this.$toast.open({
          message: 'У выбранного инструктора не тарифов',
          type: 'warning'
        });

        return;
      }

      if (!this.lessons.lessons.instructor_id || !this.lessons.lessons.client_id || !this.lessons.lessons.tariff_id) {
        return;
      }

      this.$v.lessons.$touch()

      if (this.$v.lessons.$invalid) {
        return;
      } else {
        const message = this.lessons.lessons.qty == 1 && 'Занятие создано' || 'Занятия созданы';

        this.createLessons(this.lessons)
          .then(() => {
            this.$router.push('/assignment');

            this.$toast.open({
              message: message,
              type: 'success'
            });
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);

            if (error.response.data.errors) {
              this.errorsCheck(error.response.data.errors);
            }
          })
      }
    },
    packageCreate() {
      if (!this.packageParent.package.tariff_id) {
        this.$toast.open({
          message: 'У выбранного инструктора не тарифов',
          type: 'warning'
        });

        return;
      }

      if (!this.packageParent.package.instructor_id || !this.packageParent.package.client_id || !this.packageParent.package.tariff_id) {
        return;
      }

      this.$v.packageParent.$touch()

      if (this.$v.packageParent.$invalid) {
        return;
      } else {
        this.createPackage(this.packageParent)
          .then(() => {
            this.$router.push('/assignment');

            this.$toast.open({
              message: 'Пакет создан',
              type: 'success'
            });
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);

            if (error.response.data.errors) {
              this.errorsCheck(error.response.data.errors);
            }
          })
      }
    },
    errorsCheck(errors) {
      Object.keys(errors).forEach(el => {
        switch (el) {
          case 'lessons.qty':
            this.errors.push('qty');
            break;
          case 'lessons.note':
            this.errors.push('note');
            break;
        }
      })
    },
    checkQty() {
      this.lessons.lessons.qty = this.lessons.lessons.qty.replace(/\b0+|\D/g, '');

      this.packageParent.package.qty = this.packageParent.package.qty.replace(/\b0+|\D/g, '');
    },
    selectAdd(choice) {
      choice.value === 'package'
        ? this.selectedForPackage = true
        : this.selectedForPackage = false;
    },
    errorHandlerForResponse,
    hideErrorHighlight
  },

  validations: {
    lessons: {
      lessons: {
        qty: {
          required,
        },
      }
    },
    packageParent: {
      package: {
        qty: {
          required,
        },
        message: {
          required,
        },
      }
    }
  }
}
</script>

<style>
.width-49 {
  width: 49% !important;
}

.form-control--instructors {
  height: auto !important;
  max-height: 290px;
}

.vs__dropdown-toggle {
  height: 44px !important;
  border: 1px solid #E1E1E1 !important;
  padding: 0 !important;
  border-radius: 5px !important;
}

.vs__selected {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

@media screen and (max-width: 767px) {
  .width-49 {
    width: 100% !important;
  }
}
</style>