<template>
  <div>
    <AppLoader v-if="loading.comments" />

    <div v-else class="report">
      <div class="report__chat">
        <div class="report__messege"
          v-for="comment in comments"
          :key="comment.comment_id"
          :comment="comment"
        >
          <div class="report__messege-avatar">
            <img v-if="comment.user.avatar_url" :src="comment.user.avatar_url" class="report__messege-img">
            <img v-else src="@/assets/img/noavatar.svg" class="report__messege-img">
          </div>
          <div class="report__messege-body">
            <div class="report__messege-info">
              <div class="report__messege-name">
                {{ comment.user.name.first }} {{ comment.user.name.last }}
              </div>
              <div class="report__messege-date">
                {{ formatDate(comment.created_at) }}
              </div>
            </div>
            <div class="report__messege-text">
              <span v-html="comment.text"></span>
              <span v-if="comment && comment.is_task_completed_comment == true" v-html="comment.task_completed_postfix"></span>
            </div>
          </div>
        </div>
      </div>
      <form class="report__form">
        <div class="report__radio radio">
          <label class="radio-container"
            v-for="teacher in report.teachers.users" 
            :key="teacher.user_id"
            :teacher="teacher"
          >{{ teacher.name.first }} {{ teacher.name.last }}
            <input type="radio" name="instructors" :value="teacher.user_id" v-model="comment.comment.user_id">
            <span class="radio-checkmark"></span>
          </label>
        </div>
        <div class="report__checkbox" v-if="report.status !== 'completed'">
          <div class="custom-checkbox">
            <input v-model="comment.comment.is_completed" type="checkbox" name="district" id="district-2">
            <label for="district-2" class="d-flex">Занятие зачтено</label>
          </div>
        </div>
        <div class="report__textarea">
          <div class="form-control-container">
            <textarea 
              v-model="comment.comment.text"
              cols="30" 
              rows="10" 
              placeholder="Ответить" 
              class="form-control  form-control--bordered"
            >
            </textarea>
            <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />
            <button v-if="comment.comment.text.length > 1 && !isLoadingPage" @click="createComment" type="button" class="form-control-send" :style="`background-image: url(${require ('@/assets/img/send-active.svg')}`"></button>
            <button v-else type="button" class="form-control-send" style="cursor: default;"></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';
import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';
export default {
  name: 'DropDownReports',

  props: {
    report: {
      typeof: Object,
      required: true
    },
  },

  components: {
    AppLoader,
    AppLoader3,
  },

  data() {
    return {
      isLoadingPage: false,
      comment: {
        comment: {
          text: '',
          user_id: null,
          is_completed: false,
        }
      },
    }
  },

  created() {
    let payload_comments = {
      id: this.report.lesson_id,
      laoading: true,
    }
    this.getComments(payload_comments).then(() => {
      if (this.report.status === 'completed') {
        this.comment.comment.is_completed = true
      }
    })
  },

  destroyed() {
    this.clearReportsComments();
  },

  computed: {
    ...mapGetters({
      comments: 'comments',
      loading: 'loadingReports',
    })
  },

  methods: {
    ...mapActions([
      'getComments',
      'createReportComment',
      'clearReportsComments',
      'getReports',
    ]),
    formatDate(date) {
			return moment(date).format('DD.MM.YYYY, HH:mm');
		},
    createComment() {
      if (!this.comment.comment.text) {
        this.$toast.open({
          message: 'Введите текст',
          type: 'warning'
        });

        return;
      }

      this.isLoadingPage = true;

      const payload = {
        id: this.report.lesson_id,
        comment: this.comment,
      }

      this.createReportComment(payload)
        .then(() => {
          let payload_comments = {
            id: this.report.lesson_id,
            laoading: false,
          }
          this.getComments(payload_comments)
            .then(() => {
              this.comment.comment.text = '';
              this.comment.comment.user_id = null;
              this.$emit('closeComments');
              this.getReports();

              this.$toast.open({
                message: 'Комментарий создан',
                type: 'success'
              });
            })
            .catch((error) => {
              this.errorHandlerForResponse(error);
            })
            .finally(() => {
              this.isLoadingPage = false
            });
        })
        .catch((error) => {
          this.isLoadingPage = false;
          this.errorHandlerForResponse(error);
        });
    },
    errorHandlerForResponse
  }
}
</script>

<style>
.report__messege-text strong {
  font-weight: 700;
}
</style>