<template>
  <div class="account-content">
    <div class="settings">
      <div class="settings-title">Мой профиль</div>

      <AppLoader v-if="loading.profile || loadingChangeAvatar" />

      <template v-else>
        <div v-if="croppedImage" class="account-content">
          <ImageCropper
            :image="croppedImage"
            @save="onSaveAvatar"
            @cancel="croppedImage = null"
          />
        </div>

        <div v-else class="settings-body settings-body--height-auto">
          <div class="account-profile d-block d-md-flex">
            <div class="account-profile-photo">
              <img
                v-if="profileCurrent.user.avatar"
                :src="profileCurrent.user.avatar.url"
                :alt="profileCurrent.user.name.full"
                class="rounded-circle"
              >
              <img
                v-else
                src="@/assets/img/noavatar.svg"
                :alt="profileCurrent.user.name.full"
                class="rounded-circle"
              >
              <label class="upload-photo" style="cursor: pointer;">
                <input
                  type="file"
                  ref="avatar-input"
                  @change="changeAvatar"
                  style="width: 0; height: 0; opacity: 0;"
                >
              </label>
            </div>

            <div class="account-profile-info ml-md-4 pl-md-2">
              <div class="account-profile-info-row pt-3 pb-3">
                <div class="row flex align-items-center">
                  <div class="col-sm-3">
                    <div class="account-profile-info-label text-dark">Ваше имя</div>
                  </div>

                  <div class="col-sm-9">
                    <div v-if="!editFields.includes('nameFirst')" class="d-flex justify-content-between pl-md-4">
                      <div v-if="profileCurrent.user.name.first" class="account-profile-info-value"><strong>{{ profileCurrent.user.name.first }}</strong></div>
                      <div v-else class="account-profile-info-value"><strong>Не указано</strong></div>

                      <div class="account-profile-info-link">
                        <a @click="editFields.push('nameFirst')" href="javascript:void(0)">Изменить</a>
                      </div>
                    </div>

                    <div v-else class="d-flex pl-md-4 form-group">
                      <input
                        v-model.trim="profile.user.name.first"
                        @keyup.enter="profileEdit"
                        @focus="hideErrorHighlight('name.first'); $v.profile.user.name.first.$reset()"
                        type="text"
                        placeholder="Введите имя"
                        :class="{ 'form-control--error': $v.profile.user.name.first.$error || errors.includes('name.first') }"
                        class="form-control"
                      >

                      <div class="text-error pl-md-4" v-if="!$v.profile.user.name.first.required && $v.profile.user.name.first.$dirty">Поле обязательно для заполнения</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="account-profile-info-row pt-3 pb-3">
                <div class="row flex align-items-center">
                  <div class="col-sm-3">
                    <div class="account-profile-info-label text-dark">Ваша фамилия</div>
                  </div>

                  <div class="col-sm-9">
                    <div v-if="!editFields.includes('nameLast')" class="d-flex justify-content-between pl-md-4">
                      <div v-if="profileCurrent.user.name.last" class="account-profile-info-value"><strong>{{ profileCurrent.user.name.last }}</strong></div>
                      <div v-else class="account-profile-info-value"><strong>Не указана</strong></div>

                      <div class="account-profile-info-link">
                        <a @click="editFields.push('nameLast')" href="javascript:void(0)">Изменить</a>
                      </div>
                    </div>

                    <div v-else class="d-flex pl-md-4 form-group">
                      <input
                        v-model.trim="profile.user.name.last"
                        @keyup.enter="profileEdit"
                        @focus="hideErrorHighlight('name.last'); $v.profile.user.name.last.$reset()"
                        type="text"
                        placeholder="Введите фамилию"
                        :class="{ 'form-control--error': $v.profile.user.name.last.$error || errors.includes('name.last') }"
                        class="form-control"
                      >

                      <div class="text-error pl-md-4" v-if="!$v.profile.user.name.last.required && $v.profile.user.name.last.$dirty">Поле обязательно для заполнения</div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="account-profile-info-row pt-3 pb-3">
                <div class="row flex align-items-center">
                  <div class="col-sm-3">
                    <div class="account-profile-info-label text-dark">Город</div>
                  </div>

                  <div class="col-sm-9">
                    <div v-if="!editFields.includes('city')" class="d-flex justify-content-between pl-md-4">
                      <div v-if="profileCurrent.user.city" class="account-profile-info-value"><strong>{{ profileCurrent.user.city }}</strong></div>
                      <div v-else class="account-profile-info-value"><strong>Не указан</strong></div>

                      <div class="account-profile-info-link">
                        <a @click="editFields.push('city')" href="javascript:void(0)">Изменить</a>
                      </div>
                    </div>

                    <div v-else class="d-flex pl-md-4">
                      <input
                        v-model.trim="profile.user.city"
                        type="text"
                        placeholder="Введите город"
                        class="form-control"
                      >
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="account-profile-info-row pt-3 pb-3">
                <div class="row flex align-items-center">
                  <div class="col-sm-3">
                    <div class="account-profile-info-label text-dark">E-mail</div>
                  </div>

                  <div class="col-sm-9">
                    <div v-if="!editFields.includes('email')" class="d-flex justify-content-between pl-md-4">
                      <div v-if="profileCurrent.user.email" class="account-profile-info-value"><strong>{{ profileCurrent.user.email }}</strong></div>
                      <div v-else class="account-profile-info-value"><strong>Не указан</strong></div>

                      <div class="account-profile-info-link">
                        <a @click="editFields.push('email')" href="javascript:void(0)">Изменить</a>
                      </div>
                    </div>

                    <div v-else class="d-flex pl-md-4 form-group">
                      <input
                        v-model.trim="profile.user.email"
                        @keyup.enter="profileEdit"
                        @focus="hideErrorHighlight('email'); $v.profile.user.email.$reset()"
                        type="text"
                        placeholder="Введите почту"
                        :class="{ 'form-control--error': $v.profile.user.email.$error || errors.includes('email') }"
                        class="form-control"
                      >

                      <div class="text-error pl-md-4" v-if="!$v.profile.user.email.required && $v.profile.user.email.$dirty">Поле обязательно для заполнения</div>
                      <div class="text-error pl-md-4" v-if="!$v.profile.user.email.email && $v.profile.user.email.$dirty">Введите вашу почту</div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="account-profile-info-row pt-3 pb-3">
                <div class="row flex align-items-center">
                  <div class="col-sm-3">
                    <div class="account-profile-info-label text-dark">Телефон</div>
                  </div>

                  <div class="col-sm-9">
                    <div v-if="!editFields.includes('phone')" class="d-flex justify-content-between pl-md-4">
                      <div v-if="profileCurrent.user.phone" class="account-profile-info-value"><strong>{{ profileCurrent.user.phone }}</strong></div>
                      <div v-else class="account-profile-info-value"><strong>Не указан</strong></div>

                      <div class="account-profile-info-link">
                        <a @click="editFields.push('phone')" href="javascript:void(0)">Изменить</a>
                      </div>
                    </div>

                    <div v-else class="d-flex pl-md-4">
                      <input
                        v-model.trim="profile.user.phone"
                        v-mask="'+7 (###) ###-##-##'"
                        type="text"
                        placeholder="+7 (###) ###-##-##"
                        class="form-control"
                      >
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="links-item light-gray-bg mt-5">
                <router-link to="/settings/edit/change-password" class="d-flex justify-content-between">
                  <span class="links-item-text">Изменить пароль</span>
                  <span class="links-item-icon">
                    <i class="fas fa-chevron-right"></i>
                  </span>
                </router-link>
              </div>

              <div class="mt-4 mb-3 pt-2">
                <button v-if="loading.editProfile" class="btn btn-blue btn-blue--custom">
                  <AppLoader2 :mini="true" :color="'#ffffff'" />
                </button>

                <button v-else @click="profileEdit" type="submit" class="btn btn-blue btn-blue--custom">Сохранить</button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex';

import Vuelidate from 'vuelidate';
import { VueMaskDirective }  from 'v-mask'
import { email, required } from 'vuelidate/lib/validators'

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import ImageCropper from '@/components/ImageCropper.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';
import hideErrorHighlight from '@/helpers/hideErrorHighlight';

Vue.use(Vuelidate);

export default {
  name: 'ProfileEdit',

  components: {
    AppLoader,
    AppLoader2,
    ImageCropper
  },

  directives: {
    'mask': VueMaskDirective
  },

  data() {
    return {
      editFields: [],
      errors: [],

      profile: {
        user: {
          name: {
            first: '',
            last: ''
          },
          email: '',
          city: '',
          phone: ''
        }
      },

      croppedImage: null,
      loadingChangeAvatar: false
    }
  },

  computed: {
    ...mapGetters({
      profileCurrent: 'profile',
      loading: 'loadingProfile'
    })
  },

  async created() {
    await this.getProfile();

    this.profile.user.name.first = this.profileCurrent.user.name.first;
    this.profile.user.name.last = this.profileCurrent.user.name.last;
    this.profile.user.email = this.profileCurrent.user.email;
    this.profile.user.city = this.profileCurrent.user.city;
    this.profile.user.phone = this.profileCurrent.user.phone;
  },

  methods: {
    ...mapActions(['editProfile', 'getProfile', 'changeAvatarProfile']),
    profileEdit() {
      if (!this.editFields.length) {
        return
      }

      this.$v.$touch();

      if (this.$v.$invalid) {
        return
      } else {
        this.editProfile(this.profile)
          .then(() => {
            const loading = false;

            this.getProfile(loading)
              .then(() => {
                this.editFields = [];

                this.$toast.open({
                  message: 'Данные профиля успешно изменены',
                  type: 'success'
                });
              });
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);

            if (error.response.data.errors) {
              this.errorsCheck(error.response.data.errors);
            }
          });
      }
    },
    errorsCheck(errors) {
      Object.keys(errors).forEach(el => {
        switch (el) {
          case 'user.name.first':
            this.errors.push('name.first');
            break;
          case 'user.name.last':
            this.errors.push('name.last');
            break;
          case 'user.email':
            this.errors.push('email');
            break;
        }
      })
    },
    changeAvatar() {
      this.croppedImage = this.$refs['avatar-input'].files[0];
    },
    onSaveAvatar(image) {
      this.loadingChangeAvatar = true;

      image.toBlob(async (avatar) => {

        try {
          const formData = new FormData();

          formData.append('avatar', avatar);

          await this.changeAvatarProfile(formData);

          await this.getProfile();

          this.$toast.open({
            message: 'Аватар успешно изменен',
            type: 'success'
          });
        } catch (error) {
          this.errorHandlerForResponse(error);
        } finally {
          this.loadingChangeAvatar = false;
        }
      });
    },
    errorHandlerForResponse,
    hideErrorHighlight
  },

  validations: {
    profile: {
      user: {
        name: {
          first: {
            required
          },
          last: {
            required
          }
        },
        email: {
          required,
          email
        },
      }
    }
  }
}
</script>