<template>
  <div :class="{ btn: btn }" class="loader-2-wrapper">
    <div :class="{ mini: mini, middle: middle }" class="loader-2">
      <div :class="{ mini: mini, middle: middle }" :style="{ 'border-top-color': color }"></div>
      <div :class="{ mini: mini, middle: middle }" :style="{ 'border-top-color': color }"></div>
      <div :class="{ mini: mini, middle: middle }" :style="{ 'border-top-color': color }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLoader2',

  props: {
    color: {
      type: String
    },
    mini: {
      type: Boolean
    },
    btn: {
      type: Boolean
    },
    middle: {
      type: Boolean
    }
  }
};
</script>

<style>
.loader-2-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-2-wrapper.btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.loader-2 {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.loader-2.mini {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-2.middle {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-2 div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  border: 4px solid #0033CC;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0033CC transparent transparent transparent;
}

.loader-2 div.mini {
  width: 17px;
  height: 17px;
  border-width: 2px;
}

.loader-2 div.middle {
  width: 27px;
  height: 27px;
  border-width: 3px;
}

.loader-2 div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader-2 div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader-2 div:nth-child(3) {
  animation-delay: -0.15s;
}
</style>