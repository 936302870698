<template>
  <div class="school-cabinet__lessons mt-4 pt-1">
    <ClientLessonsGroupedInstructorList :clientLessons="clientLessons" />

    <ClientLessonsPackagesList v-if="profile.school.features.lesson_packages" :client="client" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ClientLessonsGroupedInstructorList from '@/components/Client/Lessons/GroupedInstructor/List.vue';
import ClientLessonsPackagesList from '@/components/Client/Lessons/Packages/List.vue';

export default {
  name: 'ClientLessonsGroupedInstructorAndPackagesList',

  components: {
    ClientLessonsGroupedInstructorList,
    ClientLessonsPackagesList
  },

  props: {
    client: {
      required: true,
      type: Object,
    }
  },

  computed: {
    ...mapGetters({
      clientLessons: 'clientLessonsGroupedInstructor',
      profile: 'profile',
    })
  },

  created() {
    const payload = {
      idClient: this.client.client_id,
      loading: true
    }

    this.getClientLessonsGroupedInstructor(payload);

    if (this.profile.school.features.lesson_packages) {
      this.clearClientLessonsPackages();
      this.changePageClientLessonsPackages(1);

      const payloadPackages = {
        idClient: this.client.client_id,
        loading: true
      }

      this.getClientLessonsPackages(payloadPackages);
    }
  },

  methods: {
    ...mapActions([
      'getClientLessonsGroupedInstructor',
      'getClientLessonsPackages',
      'clearClientLessonsPackages',
      'changePageClientLessonsPackages',
    ])
  }
}
</script>
