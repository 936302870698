<template>
  <section class="auth mb-5 mt-3 mt-md-5" id="auth">
    <div class="row justify-content-center">
      <div class="col-lg-12 col-md-12 col-12">
        <h4 class="title text-center mb-4 pb-2 auth-title">Задать новый пароль</h4>
        <form action="javascript:void(0)">
          <div class="form-group">
            <input
              type="text"
              class="form-control form-control--bordered"
              placeholder="Email"
              v-model.trim="user.email"
              @focus="$v.user.email.$reset()"
              disabled
              :class="{ 'form-control--error': $v.user.email.$error }"
            >
            <div class="d-block text text-red mt-2" v-if="!$v.user.email.required && $v.user.email.$dirty">Поле обязательно для заполнения</div>
            <div class="d-block text text-red mt-2" v-if="!$v.user.email.email && $v.user.email.$dirty">Введите корректный e-mail</div>
          </div>
          <div class="form-group">
            <input
              type="password"
              name="password"
              class="form-control form-control--bordered"
              placeholder="Новый пароль"
              @focus="$v.user.password.$reset()"
              v-model.trim="user.password"
              :class="{ 'form-control--error': $v.user.password.$error }"
            >
            <div class="d-block text text-red mt-2" v-if="!$v.user.password.required && $v.user.password.$dirty">Поле обязательно для заполнения</div>
            <div class="d-block text text-red mt-2" v-if="!$v.user.password.minLength && $v.user.password.$dirty">Минимальная длинна пароля - 8 символов</div>
          </div>
          <div class="form-group">
            <input
              type="password"
              name="password_confirmation"
              class="form-control form-control--bordered"
              placeholder="Повторите пароль"
              @focus="$v.user.password_confirmation.$reset()"
              v-model.trim="user.password_confirmation"
              :class="{ 'form-control--error': $v.user.password_confirmation.$error }"
            >
            <div class="d-block text text-red mt-2" v-if="!$v.user.password_confirmation.required && $v.user.password_confirmation.$dirty">Поле обязательно для заполнения</div>
            <div class="d-block text text-red mt-2" v-if="!$v.user.password_confirmation.sameAsPassword && $v.user.password_confirmation.$dirty">Пароли не совпадают</div>
          </div>
          <button type="submit" class="btn btn-green btn-block mb-1 btn-full" @click="submit" v-if="!loading">Изменить пароль</button>
          <button type="submit" class="btn btn-green btn-block mb-1 btn-full cursor-default bg-gray text-black" v-else>Отправляем...</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Vuelidate from 'vuelidate';

import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

Vue.use(VueToast);
Vue.use(Vuelidate);

export default {
  name: 'Auth',

  data() {
    return {
      user: {
        email: '',
        password: '',
        password_confirmation: '',
        token: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loadingResetPassword',
      tokenProfile: 'token',
    })
  },

  created() {
    if (this.tokenProfile) {
      this.$router.push('/').catch(() => {});
    }

    this.user.token = this.$route.params.token;
    this.user.email = this.$route.query.email;
  },

  methods: {
    ...mapActions(['resetPassword']),
    submit() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.$toast.open({
          message: 'Не все поля заполненны корректно',
          type: 'error'
        });
      } else {
        this.resetPassword(this.user)
          .then(() => {
            this.$toast.open({
              message: 'Пароль успешно сброшен',
              type: 'success'
            });
            this.$router.push('/login').catch(() => {});
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);
          });
      }
    },
    errorHandlerForResponse
  },

  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8)
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  }
}
</script>