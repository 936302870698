<template>
  <div class="account-content">
    <div class="settings" v-show="!showForm && !showDoc">
      <div class="settings-top d-md-flex align-items-end">
        <div class="settings-head d-flex align-items-center">
          <div class="settings-title">Клиенты</div>

          <router-link
            v-if="profile.user.permission.client.create"
            to="/clients/create"
            class="settings-head-icon"
          ></router-link>
        </div>

        <div class="settings-search ml-auto d-flex mt-4 mt-md-0">
          <input
            v-model.trim="search"
            v-debounce:1000ms="searchClient"
            type="text"
            class="form-control form-control--search pl-2 pl-md-2"
            placeholder="Поиск по клиентам"
          >

          <button type="button"></button>
        </div>
      </div>

      <AppLoader v-if="loadingClients.clients" />

      <div v-else class="school-cabinet">
        <div class="filters d-flex flex-wrap mb-3">
          <div class="flex-grow-1 flex-xl-grow-0 d-flex flex-wrap flex-md-nowrap">
            <div class="filters-item filters-item--200 flex-grow-1 mb-2 mb-md-0" :class="{'reset-hover': resetHover}">
              <v-select
                :options="instructors.instructors"
                @input="selectInstructor"
                @search="searchInstructor"
                :disabled="disabledFilters"
                :clearable="false"
                :searchable="true"
                placeholder="Инструктор"
                :get-option-label='option => option.name ? `${option.name.first} ${option.name.last} ${option.name.middle}` : null'
                :get-option-key='option => option.instructor_id'
                v-model="activeInstructor"
              >
                <template #open-indicator="{ attributes }">
                  <span class="form-control--arrow" v-bind="attributes"></span>
                </template>
                <template #list-header>
                  <li class="vs__dropdown-option vs__dropdown-option--reset" @mouseover="resetHover = true" @mouseleave="resetHover = false" v-if="activeInstructor" @click="resetInstructor">Все</li>
                </template>
                <template slot="no-options">
                  Пользователей не найдено
                </template>
                <template v-slot:option="option">
                  {{ option.name.first }} {{ option.name.last }} {{ option.name.middle }}
                </template>
                <template #spinner="{ loading }">
                  <AppLoader2 :mini="true" v-if="loading" />
                </template>
                <template #list-footer>
                  <infinite-loading @infinite="infiniteHandler">
                    <div slot="spinner">
                      <AppLoader2 :mini="true" />
                    </div>
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                  </infinite-loading>
                </template>
              </v-select>
            </div>

            <div class="filters-item filters-item--200 flex-grow-1 mb-2 mb-md-0">
              <DropDownMenuAssigned
                :filters="filtersAssigned"
                :disabled="disabledFilters"
                @select="selectAssigned"
              />
            </div>

            <div class="filters-item filters-item--200 flex-grow-1 mb-2 mb-md-0">
              <DropDownMenuNotifications
                :filters="filtersNotifications"
                :disabled="disabledFilters"
                @select="selectNotifications"
              />
            </div>
          </div>

          <transition name="fade--top">
            <button
              v-if="filtersApplied"
              @click="resetFilters"
              type="button"
              class="reset-filters filters-item--100 align-self-center mt-3 mb-3 mt-xl-0 mb-md-0 ml-xl-auto"
            >
              Сбросить фильтры
            </button>
          </transition>
        </div>

        <div class="wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
          <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />

          <template v-if="clients.clients.length">
            <ClientCard
              v-for="client in clients.clients"
              :key="client.client_id"
              :client="client"
              @open="openForm(client)"
              @openDoc="openDocs(client)"
            />

            <div v-if="clients.meta.page.total > 1" class="d-flex justify-content-center">
              <paginate
                v-model="page"
                :pageCount="clients.meta.page.total"
                :clickHandler="clickCallback"
                :prevText="''"
                :nextText="''"
                :containerClass="'pagination'"
                :pageClass="'page-item'"
                :pageLinkClass="'page-link'"
                :prevClass="'page-item'"
                :prevLinkClass="'page-link'"
                :nextClass="'page-item'"
                :nextLinkClass="'page-link'"
                :activeClass="'active'"
              />
            </div>
          </template>

          <p v-else>Клиентов нет</p>
        </div>
      </div>
    </div>
    <template v-if="showForm">
      <CreateLesson
        :client="activeClient"
        @closeForm="closeForm"
      />
      <ClientLessonsGroupedInstructorAndPackagesList
        :client="activeClient"
      />
    </template>

    <template v-if="showDoc">
      <ClientDocuments
        :client="activeClient"
        @closeDoc="closeDocs"
      />
    </template>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Paginate from 'vuejs-paginate';
import InfiniteLoading from 'vue-infinite-loading';
import vSelect from 'vue-select';
import _ from 'lodash';

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import ClientCard from '@/components/Client/Card.vue';
import DropDownMenuAssigned from '@/components/DropDownMenu/Assigned.vue';
import DropDownMenuNotifications from '@/components/DropDownMenu/Notifications.vue';
import CreateLesson from '@/components/Client/Lessons/Create.vue';
import ClientLessonsGroupedInstructorAndPackagesList from '@/components/Client/Lessons/GroupedInstructorAndPackagesList.vue';
import ClientDocuments from '@/components/Client/Documents/Documents.vue';

export default {
  name: 'AppClients',

  components: {
    AppLoader,
    AppLoader2,
    Paginate,
    AppLoader3,
    CreateLesson,
    ClientLessonsGroupedInstructorAndPackagesList,
    DropDownMenuNotifications,
    DropDownMenuAssigned,
    ClientCard,
    vSelect,
    InfiniteLoading,
    ClientDocuments,
  },

  data() {
    return {
      page: 1,
      activeClient: null,
      isLoadingPage: false,
      activeInstructor: null,
      disabledFilters: false,
      search: '',
      showForm: false,
      scrollTop: 0,
      resetHover: false,
      showDoc: false,
    }
  },

  computed: {
    ...mapGetters({
      clients: 'clients',
      loadingClients: 'loadingClients',
      pageClients: 'pageClients',
      instructors: 'assignmentClientInstructors',
      loadingInstructors: 'loadingInstructors',
      filtersForClients: 'filtersForClients',
      profile: 'profile',
      instructor: 'instructor',
      filtersAssigned: 'filterForClientsAssigned',
      filtersNotifications: 'filterForClientsNotifications',
      filtersApplied: 'clientsFiltersApplied',
    })
  },

  async created() {
    this.setLoadingClients();
    this.setLoadingInstructors();

    this.clearСlientInstructors();
    this.resetPageСlientInstructors();

    await this.getСlientInstructors({loading: true});

    if (this.$route.query.instructor_id) {
      this.changeFiltersClientsInstructorId(this.$route.query.instructor_id);
      await this.getInstructor(this.filtersForClients.instructor_id);
      this.addInstructorInListClientInstructors(this.instructor);
    }

    this.clearClients();

    if (this.$route.query.page) {
      this.changePageClients(Number(this.$route.query.page));
    }

    if (this.$route.query.search) {
      this.changeFiltersClientsSearch(this.$route.query.search);
    }

    if (this.$route.query.reminder) {
      this.changeFiltersClientsNotifications(this.$route.query.reminder);
    }

    if (this.$route.query.assigned) {
      this.changeFiltersClientsAssigned(this.$route.query.assigned);
    }

    if (this.$route.params.page === 1) {
      this.resetFiltersClients();
    }

    this.page = this.pageClients;

    let query = Object.assign({}, this.$route.query);
    query.page = this.pageClients;

    if (this.filtersForClients.instructor_id) {
      this.activeInstructor = this.filtersForClients.instructor_id;
      query.instructor_id = this.filtersForClients.instructor_id;
      await this.getInstructor(this.filtersForClients.instructor_id);
      this.addInstructorInListClientInstructors(this.instructor);
    }

    if (this.filtersForClients.query) {
      this.search = this.filtersForClients.query;

      query.search = this.filtersForClients.query;
    }

    this.$router.push({ query }).catch(() => {});

    await this.getClients();

    this.$nextTick(() => {
      if (this.$route.query.instructor_id) {
        this.activeInstructor = this.instructor;
      }
    });

    if (this.clients && this.pageClients > this.clients.meta.page.total) {
      await this.changePageClients(this.clients.meta.page.total);

      this.page = this.pageClients;

      let query = Object.assign({}, this.$route.query);
      query.page = this.pageClients;
      this.$router.push({ query }).catch(() => {});

      this.getClients();
    }
  },

  methods: {
    ...mapActions([
      'getClients',
      'clearClients',
      'changePageClients',
      'changePageInstructors',
      'changeSearchInstructors',
      'changeFiltersClientsInstructorId',
      'changeFiltersClientsAssigned',
      'changeFiltersClientsNotifications',
      'changeFiltersClientsSearch',
      'resetFiltersClients',
      'getInstructor',
      'addInstructorInListClientInstructors',
      'setLoadingInstructors',
      'getСlientInstructors',
      'setLoadingClients',
      'changeFiltersSearchСlientInstructors',
      'resetPageСlientInstructors',
      'clearСlientInstructors'
    ]),
    selectNotifications(reminder) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      if (reminder === 'reset') {
        this.changeFiltersClientsNotifications(null);
      } else {
        this.changeFiltersClientsNotifications(reminder.value);
      }

      this.changePageClients(1);


      const loading = false;

      this.getClients(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      query.reminder = reminder.value;
      this.$router.push({ query }).catch(() => {});
    },
    selectAssigned(assigned) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      if (assigned === 'reset') {
        this.changeFiltersClientsAssigned(null);
      } else {
        this.changeFiltersClientsAssigned(assigned.value);
      }

      this.changePageClients(1);


      const loading = false;

      this.getClients(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      query.assigned = assigned.value;
      this.$router.push({ query }).catch(() => {});
    },
    openForm(client) {
      this.showForm = true;
      this.activeClient = client;
      this.scrollTop = window.pageYOffset;
      this.$nextTick(() => {
        window.scrollTo( 0, 0 );
      })
    },
    openDocs(client) {
      this.showDoc = true;
      this.activeClient = client;
      this.scrollTop = window.pageYOffset;
      this.$nextTick(() => {
        window.scrollTo( 0, 0 );
      })
    },
    closeDocs() {
      this.showDoc = false;
      this.activeClient = null;
      this.$nextTick(() => {
        window.scrollTo( 0, this.scrollTop );
      })
    },
    closeForm() {
      this.showForm = false;
      this.activeClient = null;
      this.$nextTick(() => {
        window.scrollTo( 0, this.scrollTop );
      })
    },
    clickCallback() {
      this.isLoadingPage = true;

      let query = Object.assign({}, this.$route.query);
      query.page = this.page;

      this.changePageClients(this.page)
        .then(() => {
          this.$router.push({ query }).catch(() => {});

          const loading = false;

          this.getClients(loading)
            .finally(() => this.isLoadingPage = false);
        });
    },
    searchClient(value) {
      this.isLoadingPage = true;

      const search = value.trim();

      this.changePageClients(1);
      this.page = 1;

      let query = Object.assign({}, this.$route.query);
      query.page = '1';

      if (!search) {
        delete query.search;
      } else {
        query.search = search;
      }

      this.$router.push({ query }).catch(() => {});

      this.changeFiltersClientsSearch(search);

      const loading = false;

      this.getClients(loading)
        .finally(() => this.isLoadingPage = false);
    },
    resetInstructor() {
      this.changeFiltersClientsInstructorId(null);
      this.activeInstructor = null;
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.changePageClients(1);

      const loading = false;

      this.getClients(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      delete query.instructor_id;
      this.$router.push({ query }).catch(() => {});
    },
    searchInstructor(search, loading) {
      this.changeFiltersSearchСlientInstructors(search);
      this.resetPageСlientInstructors();
      loading(true);
      this.searchInstructorAction(search, loading, this);
    },
    searchInstructorAction: _.debounce((search, loading, vm) => {
      const payload = {
        loading: false
      }
      vm.getСlientInstructors(payload).then(() => {
        loading(false);
      })
    }, 1000),
    infiniteHandler($state) {
      if (this.instructors.instructors.length < this.instructors.meta.entries.total) {
        const payload = {
          loading: false,
        }

        this.getСlientInstructors(payload)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    selectInstructor(instructor) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.changeFiltersClientsInstructorId(instructor.instructor_id);
      this.changePageClients(1);

      const loading = false;

      this.getClients(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      query.instructor_id = instructor.instructor_id;
      this.$router.push({ query }).catch(() => {});
    },
    resetFilters() {
      if (this.disabledFilters) {
        return;
      }

      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.resetFiltersClients();

      const loading = false;

      this.getClients(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      this.activeInstructor = null;
      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      delete query.reminder;
      delete query.instructor_id;
      delete query.assigned;
      this.$router.push({ query }).catch(() => {});
    },
  }
}
</script>
<style>
  .vs__dropdown-toggle {
    background-color: #fff !important;
  }
  .vs__search {
    color: #555555 !important;
    line-height: 1 !important;
    font-size: 15px !important;
    padding-bottom: 4px !important;
    font-family: "Proxima Nova" !important;
  }
  .vs__actions {
    z-index: 99;
  }
  @media screen and (min-width: 760px) {
    .vs--open .vs__dropdown-menu {
      width: 160% !important;
    }
  }
  .vs--disabled .vs__clear, .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__open-indicator, .vs--disabled .vs__search, .vs--disabled .vs__selected {
    background-color: #fff !important;
  }
  .vs__dropdown-option--reset:hover {
    background-color: #5897fb !important;
    color: #fff;
  }
  .reset-hover .vs__dropdown-option {
    background: none !important;
    color: #000;
  }
  .reset-hover .vs__dropdown-option--reset:hover {
    background-color: #5897fb !important;
    color: #fff !important;
  }
</style>