<template>
  <div class="drop-down-menu">
    <div
      v-click-outside="hide"
      @click="opened = !opened"
      :class="{ 'border-bottom-none': opened && !disabled}"
      class="form-control form-control--bordered"
    >
      {{ selected || 'Напоминание' }}

      <span
        :class="{ rotate: opened && !disabled }"
        class="form-control--arrow"
      ></span>
    </div>

    <ul v-if="opened && !disabled" class="form-control--custom">
      <li v-if="selected" @click="reset">Все</li>

      <li
        v-for="(li, index) in list"
        :key="index"
        @click="select(li)"
      >
        {{ li.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropDownMenuNotifications',

  props: {
    filters: {
      typeof: Boolean,
      required: false
    },
    disabled: {
      typeof: Boolean,
      required: false
    }
  },

  directives: {
    ClickOutside
  },

  data() {
    return {
      opened: false,
      selected: '',
      list: [
        {
          name: 'Есть напоминание',
          value: '1'
        },
      ]
    }
  },

  watch: {
    filters(filters) {
      if (!filters) {
        this.selected = '';
      }
    }
  },

  created() {
    if (this.filters) {
      this.selected = this.list.find(el => el.value === this.filters).name;
    }
  },

  mounted () {
    this.popupItem = this.$el
  },

  methods: {
    hide() {
      this.opened = false;
    },
    select(li) {
      this.selected = li.name;

      this.hide();

      this.$emit('select', li);
    },
    reset() {
      this.selected = '';

      this.hide();

      this.$emit('select', 'reset');
    }
  }
}
</script>
