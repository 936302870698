<template>
  <div class="account-content">
    <div class="settings">
      <div class="settings-top d-md-flex align-items-end">
        <div class="settings-head d-flex align-items-center">
          <div class="settings-title">Распределение</div>
        </div>

        <div class="settings-search ml-auto d-flex mt-4 mt-md-0">
          <input
            v-model.trim="search"
            v-debounce:1000ms="searchClient"
            type="text"
            class="form-control form-control--search pl-2 pl-md-2"
            placeholder="Поиск по клиентам"
          >

          <button type="button"></button>
        </div>
      </div>

      <AppLoader v-if="loadingAssignment.lessonsGroupedByClients || loadingInstructors.instructors" />

      <div v-else class="school-cabinet">
        <div class="filters d-flex flex-wrap">
          <div class="filters-item mb-2 mb-md-0">
            <DropDownMenu
              :list="instructors.instructors"
              :instructorId="instructorId"
              :placeholder="'Инструктор'"
              :filters="filtersInstructor"
              :disabled="disabledFilters"
              @search="searchInstructors"
              @select="selectInstructor"
            />
          </div>

          <div class="filters-item mb-2 mb-md-0">
            <DropDownMenuAssigned
              :filters="filtersAssigned"
              :disabled="disabledFilters"
              @select="selectAssigned"
            />
          </div>

          <transition name="fade--top">
            <button
              v-if="filtersApplied"
              @click="resetFilters"
              type="button"
              class="reset-filters align-self-center ml-auto mr-auto ml-md-0 mr-md-0 mt-3 mb-3 mt-md-0 mb-md-0"
            >
              Сбросить фильтры
            </button>
          </transition>
        </div>

        <div class="wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
          <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />

          <template v-if="clientsLessons.clients_lessons.length">
            <!-- <ClientLessonsGroupedCard
              v-for="client in clientsLessons.clients_lessons"
              :key="client.client.client_id"
              :client="client"
            /> -->

            <div v-if="clientsLessons.meta.page.total > 1" class="d-flex justify-content-center">
              <paginate
                v-model="page"
                :pageCount="clientsLessons.meta.page.total"
                :clickHandler="clickCallback"
                :prevText="''"
                :nextText="''"
                :containerClass="'pagination'"
                :pageClass="'page-item'"
                :pageLinkClass="'page-link'"
                :prevClass="'page-item'"
                :prevLinkClass="'page-link'"
                :nextClass="'page-item'"
                :nextLinkClass="'page-link'"
                :activeClass="'active'"
              />
            </div>
          </template>

          <p v-else>Совпадений не найдено</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex';
import VModal from 'vue-js-modal';
import Paginate from 'vuejs-paginate'

import AppLoader from '@/components/AppLoader/1.vue';
import DropDownMenu from '@/components/DropDownMenu/DropDownMenu.vue';
import DropDownMenuAssigned from '@/components/DropDownMenu/Assigned.vue';
// import ClientLessonsGroupedCard from '@/components/ClientLessonsGroupedCard/ClientLessonsGroupedCard.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';

Vue.use(VModal, { dialog: true });

export default {
  name: 'AppAssignment',

  components: {
    AppLoader,
    DropDownMenu,
    DropDownMenuAssigned,
    // ClientLessonsGroupedCard,
    Paginate,
    AppLoader3
  },

  data() {
    return {
      page: 1,
      isLoadingPage: false,
      instructorId: null,
      clientId: null,
      disabledFilters: false,
      search: ''
    }
  },

  computed: {
    ...mapGetters({
      clientsLessons: 'assignmentLessonsGroupedByClients',
      pageLessonsGroupedByClients: 'pageLessonsGroupedByClients',
      loadingAssignment: 'loadingAssignment',
      loadingInstructors: 'loadingInstructors',
      instructors: 'instructors',
      instructor: 'instructor',
      clients: 'clients',
      filtersInstructor: 'assignmentLessonsGroupedByClientsFiltersInstructor',
      filtersAssigned: 'assignmentLessonsGroupedByClientsFiltersAssigned',
      filtersApplied: 'assignmentLessonsGroupedByClientsFiltersApplied',
      filtersClientsLessons: 'assignmentLessonsGroupedByClientsFilters',
    })
  },

  async created() {
    this.setLoadingLessonsGroupedByClients();
    this.setLoadingInstructors();

    this.clearLessonsGroupedByClients();

    this.changeSearchInstructors(null);
    this.changePageInstructors(1);

    await this.getInstructors();

    if (this.$route.query.page) {
      this.changePageLessonsGroupedByClients(Number(this.$route.query.page));
    }

    if (this.$route.params.page === 1) {
      this.resetFiltersLessonsGroupedByClients();
      this.changeFiltersSearchLessonsGroupedByClients(null);
    }

    if (this.$route.query.search) {
      this.changeFiltersSearchLessonsGroupedByClients(this.$route.query.search);

      this.search = this.$route.query.search;
    }

    if (this.$route.query.instructor_id) {
      this.changeFiltersInstructorIdLessonsGroupedByClients(this.$route.query.instructor_id);

      this.instructorId = this.$route.query.instructor_id;

      await this.getInstructor(this.$route.query.instructor_id);

      this.addInstructorInListInstructors(this.instructor);
    }

    if (this.$route.query.assigned) {
      this.changeFiltersAssignedLessonsGroupedByClients(this.$route.query.assigned);
    }

    this.page = this.pageLessonsGroupedByClients;

    let query = Object.assign({}, this.$route.query);
    query.page = this.pageLessonsGroupedByClients;

    if (this.filtersClientsLessons.instructor_id) {
      this.instructorId = this.filtersClientsLessons.instructor_id;

      query.instructor_id = this.filtersClientsLessons.instructor_id;

      await this.getInstructor(this.filtersClientsLessons.instructor_id);

      this.addInstructorInListInstructors(this.instructor);
    }

    if (this.filtersClientsLessons.query) {
      this.search = this.filtersClientsLessons.query;

      query.search = this.filtersClientsLessons.query;
    }

    if (this.filtersClientsLessons.assigned) {
      query.assigned = this.filtersClientsLessons.assigned;
    }

    this.$router.push({ query }).catch(() => {});

    await this.getLessonsGroupedByClients();

    if (this.clientsLessons && this.pageLessonsGroupedByClients > this.clientsLessons.meta.page.total) {
      await this.changePageLessonsGroupedByClients(this.clientsLessons.meta.page.total);

      this.page = this.pageLessonsGroupedByClients;

      let query = Object.assign({}, this.$route.query);
      query.page = this.pageLessonsGroupedByClients;
      this.$router.push({ query }).catch(() => {});

      this.getLessonsGroupedByClients();
    }
  },

  methods: {
    ...mapActions([
      'getLessonsGroupedByClients',
      'clearLessonsGroupedByClients',
      'changePageLessonsGroupedByClients',
      'getInstructors',
      'getInstructor',
      'addInstructorInListInstructors',
      'setLoadingInstructors',
      'changeSearchInstructors',
      'changeFiltersInstructorIdLessonsGroupedByClients',
      'changeFiltersClientIdLessonsGroupedByClients',
      'resetFiltersLessonsGroupedByClients',
      'changeFiltersAssignedLessonsGroupedByClients',
      'changePageInstructors',
      'changeFiltersSearchLessonsGroupedByClients',
      'setLoadingLessonsGroupedByClients'
    ]),
    clickCallback() {
      this.isLoadingPage = true;

      let query = Object.assign({}, this.$route.query);
      query.page = this.page;

      this.changePageLessonsGroupedByClients(this.page)
        .then(() => {
          this.$router.push({ query }).catch(() => {});

          const loading = false;

          this.getLessonsGroupedByClients(loading)
            .finally(() => this.isLoadingPage = false);
        });
    },
    searchInstructors(search) {
      this.changeSearchInstructors(search);

      const loading = false;

      this.getInstructors(loading);

      if (!search) {
        this.isLoadingPage = true;
        this.disabledFilters = true;

        this.changeFiltersInstructorIdLessonsGroupedByClients(null);
        this.changePageLessonsGroupedByClients(1);

        const loading = false;

        this.getLessonsGroupedByClients(loading)
          .finally(() => {
            this.page = 1;

            this.disabledFilters = false;
            this.isLoadingPage = false;
          });

        let query = Object.assign({}, this.$route.query);
        query.page = 1;
        delete query.instructor_id;
        this.$router.push({ query }).catch(() => {});
      }
    },
    selectInstructor(instructor) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.changeFiltersInstructorIdLessonsGroupedByClients(instructor.instructor_id);
      this.changePageLessonsGroupedByClients(1);

      const loading = false;

      this.getLessonsGroupedByClients(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      query.instructor_id = instructor.instructor_id;
      this.$router.push({ query }).catch(() => {});
    },
    searchClient(value) {
      this.isLoadingPage = true;

      const search = value.trim();

      this.changePageLessonsGroupedByClients(1);
      this.page = 1;

      let query = Object.assign({}, this.$route.query);
      query.page = '1';

      if (!search) {
        delete query.search;
      } else {
        query.search = search;
      }

      this.$router.push({ query }).catch(() => {});

      this.changeFiltersSearchLessonsGroupedByClients(search);

      const loading = false;

      this.getLessonsGroupedByClients(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    selectAssigned(assigned) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      if (assigned === 'reset') {
        this.changeFiltersAssignedLessonsGroupedByClients(null);
      } else {
        this.changeFiltersAssignedLessonsGroupedByClients(assigned.value);
      }

      this.changePageLessonsGroupedByClients(1);

      const loading = false;

      this.getLessonsGroupedByClients(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      query.assigned = assigned.value;
      this.$router.push({ query }).catch(() => {});
    },
    resetFilters() {
      if (this.disabledFilters) {
        return;
      }

      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.resetFiltersLessonsGroupedByClients();

      const loading = false;

      this.getLessonsGroupedByClients(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      delete query.instructor_id;
      delete query.assigned;
      this.$router.push({ query }).catch(() => {});
    },
  }
}
</script>