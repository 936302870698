<template>
  <div>
    <vue-cropper
      :aspect-ratio="angle || 1"
      :guides="true"
      :img-style="{ width: `${cropperSize}px`, height: `${cropperSize}px` }"
      :min-container-height="cropperSize"
      :min-container-width="cropperSize"
      :min-crop-box-height="150"
      :src="imageForCrop"
      :view-mode="2"
      :rotatable="true"
      :checkOrientation="true"
      drag-mode="crop"
      ref="cropper"
    />

    <div class="d-flex d-md-none justify-content-center mt-1">
      <button @click="$refs.cropper.rotate(90)" class="btn btn-default mr-auto d-flex w-100 justify-content-center"><i class="mr-2 fas fa-undo"></i> Перевернуть</button>
    </div>

    <div class="d-flex justify-content-center mt-3">
      <button @click="$refs.cropper.rotate(90)" class="btn btn-default mr-2 d-none d-md-flex align-items-center background-transparent"><i class="mr-2 fas fa-undo"></i> Перевернуть</button>
      <button @click="onCancel" class="btn btn-default mr-2 background-transparent" type="submit">Отмена</button>
      <button :disabled="disabled" @click="onSave" class="btn btn-blue" type="submit">Сохранить</button>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';

export default {
  name: 'ImageCropper',

  components: {
    VueCropper,
  },

  props: {
    image: {
      type: File,
      required: false,
    },
    angle: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },

  data() {
    return {
      imageForCrop: null,
    };
  },

  computed: {
    cropperSize() {
      return window.innerWidth < 580 ? 220 : 400;
    },
  },

  created() {
    if (typeof FileReader === 'function' && this.image) {
      const reader = new FileReader();

      reader.onload = (event) => {
        this.imageForCrop = event.target.result;
        this.$refs.cropper.replace(event.target.result);
      };

      reader.readAsDataURL(this.image);
    }
  },

  methods: {
    onCancel() {
      this.imageForCrop = null;
      this.$emit('cancel');
    },
    onSave() {
      this.$emit('save', this.$refs.cropper.getCroppedCanvas({ width: 480, height: 480 }));
      this.onCancel();
    },
  },
};
</script>

<style>
.background-transparent {
  background: transparent;
}
</style>