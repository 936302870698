<template>
  <div class="school-cabinet_info-table-item d-flex flex-wrap flex-lg-nowrap align-items-end" >
    <div class="school-cabinet_info-table-value school-cabinet_info-table-value--1 mb-3 mb-md-0">
      <div v-if="lesson.planned_at" class="school-cabinet_info-table-date">{{ moment(lesson.planned_at).format('DD.MM.YYYY, HH:mm') }}</div>
      <div class="school-cabinet_info-table-text">{{ lesson.instructor.name.full }}</div>
    </div>

    <div class="school-cabinet_info-table-value school-cabinet_info-table-value--2 mr-3 mr-md-0">
      <div class="school-cabinet_info-table-title d-md-none">Занятие</div>
      <div class="school-cabinet_info-table-text">{{ lesson.tariff.title }}</div>
    </div>

    <div class="school-cabinet_info-table-value school-cabinet_info-table-value--3">
      <div class="school-cabinet_info-table-title d-md-none">Статус</div>
      <div class="school-cabinet_info-table-text">{{ checkStatus(lesson.status) }}</div>
    </div>

    <div v-if="lesson.status === 'planned' && profile.user.permission.client.returnToAvailable" class="school-cabinet_info-table-value school-cabinet_info-table-value--4 ml-auto mt-3 mt-md-0">
      <div class="school-cabinet_info-table-text school-cabinet_info-table-text--custom text-right">
        <AppLoader2 v-if="isReturnToAvailable" :mini="true"  class="justify-content-end" />

        <a v-else @click="confirmationReturnToAvailable" href="javascript:void(0)">Вернуть в доступные</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import AppLoader2 from '@/components/AppLoader/2.vue';

import checkStatus from '@/helpers/checkStatus.js';
import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'ClientLessonCard',

  components: {
    AppLoader2
  },

  props: {
    lesson: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isReturnToAvailable: false
    }
  },

  computed: {
    ...mapGetters(['profile'])
  },

  methods: {
    ...mapActions(['returnToAvailable']),
    confirmationReturnToAvailable() {
      this.$modal.show('dialog', {
        title: 'Вернуть в доступные',
        text: 'Вы действительно хотите вернуть в доступные?',
        buttons: [
          {
            title: 'Да',
            handler: () => {
              this.returnAvailable();
              this.$modal.hide('dialog');
            },
          },
          {
            title: 'Нет',
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    returnAvailable() {
      this.isReturnToAvailable = true;

      const payload = {
        idClient: this.$route.params.id,
        idLesson: this.lesson.lesson_id
      }

      this.returnToAvailable(payload)
        .then(() => {
          this.$toast.open({
            message: 'Занятие возвращено в доступные',
            type: 'success'
          });
        })
        .catch((error) => {
          this.errorHandlerForResponse(error);
        })
        .finally(() => {
          this.isReturnToAvailable = false;
        });
    },
    moment,
    checkStatus,
    errorHandlerForResponse
  }
}
</script>