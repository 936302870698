<template>
  <section class="site-body pt-4">
    <div class="container-school">
      <div class="account d-flex justify-content-between">
        <AppSidebar />

        <router-view/>
      </div>
    </div>
  </section>
</template>

<script>
import AppSidebar from '@/components/layout/AppSidebar.vue';

export default {
  name: 'Main',

  components: {
    AppSidebar,
  },
}
</script>