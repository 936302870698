import instance from '../instance';
import settings from '../settings';
import * as iconv from 'iconv-lite';

export default {
  namespaced: true,

  state: {
    transactions: null,
    filters: {
      page: 1,
      page_size: 10,
      status: null,
      instructor_id: null,
      created_at_from: null,
      created_at_to: null,
      encoding: 'Windows-1251',
    },
    loading: {
      transactions: true,
      export: false,
    }
  },

  getters: {
    transactions: state => state.transactions,
    filters: state => state.filters,
    filtersApplied: state => state.filters.status || state.filters.instructor_id || state.filters.created_at_from && state.filters.created_at_to,
    loading: state => state.loading,
  },

  actions: {
    async getTransactions({ commit, state }, loading = true) {
      if (loading) {
        commit('START_TRANSACTIONS_LOADING');
      }

      try {
        const requestId = 'transactions';

        const transactions = await instance.get(`${settings.API_URL}/transactions/outgo`, { requestId, params: state.filters });

        commit('SET_TRANSACTIONS', transactions.data);
      } finally {
        commit('FINISH_TRANSACTIONS_LOADING');
      }
    },
    async exportTransactions({ commit, state }, charset) {
      commit('CHANGE_ENCODING', charset)
      commit('START_EXPORT_LOADING');
      try {
        const requestId = 'transactionsExport';

        const exportData = await instance.get(`${settings.API_URL}/transactions/outgo/csv`, { requestId, params: state.filters });

        var hiddenElement = document.createElement('a');
        if (charset === 'Windows-1251') {
          const encoded = iconv.encode (exportData.data,'win1251');

          var file = new Blob ([encoded], {type: 'text/csv;charset=windows-1251'});
          var link = document.createElement("a");
          var url = URL.createObjectURL(file);
          link.setAttribute("href", url);
          link.setAttribute("download", "ftransactions_export.csv");
          document.body.appendChild(link);
          link.click();
          setTimeout(function() {document.body.removeChild(link); window.URL.revokeObjectURL(url); }, 0);
        } else {
          hiddenElement.href = `data:text/csv;charset=${charset}, ${encodeURI(exportData.data)}`;
          hiddenElement.target = '_blank';
          hiddenElement.download = 'transactions_export.csv';
          hiddenElement.click();
        }
        
      } finally {
        commit('FINISH_EXPORT_LOADING');
      }
    },
    clearTransactions({ commit }) {
      commit('CLEAR_TRANSACTIONS');
    },
    changePage({ commit }, page) {
      commit('CHANGE_PAGE', page);
    },
    changeFiltersDate({ commit }, dates) {
      commit('CHANGE_FILTERS_DATES', dates);
    },
    changeFiltersInstructor({ commit }, id) {
      commit('CHANGE_FILTERS_INSTRUCTOR', id);
    },
    changeFiltersStatus({ commit }, status) {
      commit('CHANGE_FILTERS_STATUS', status);
    },
    changeFiltersPageSize({ commit }, pageSize) {
      commit('CHANGE_FILTERS_PAGE_SIZE', pageSize);
    },
    resetFilters({ commit }) {
      commit('CHANGE_PAGE', 1);
      commit('CHANGE_FILTERS_DATES', { created_at_from: null, created_at_to: null });
      commit('CHANGE_FILTERS_INSTRUCTOR', null);
      commit('CHANGE_FILTERS_STATUS', null);
    },
    async confirmTransaction({ commit }, transactionId) {
      const transaction = await instance.post(`${settings.API_URL}/transactions/${transactionId}/confirm`);

      commit('SET_TRANSACTION', transaction.data.transaction);
    },
    async rejectTransaction({ commit }, transactionId) {
      const transaction = await instance.post(`${settings.API_URL}/transactions/${transactionId}/decline`);

      commit('SET_TRANSACTION', transaction.data.transaction);
    },
    async pendTransaction({ commit }, transactionId) {
      const transaction = await instance.post(`${settings.API_URL}/transactions/${transactionId}/pend`);

      commit('SET_TRANSACTION', transaction.data.transaction);
    },
    async confirmTransactions({ commit }, transactionIds) {
      const transactions = await instance.post(`${settings.API_URL}/transactions/bulk/confirm`, transactionIds);

      commit('SET_TRANSACTIONS_AFTER_MASSIVE', transactions.data.transactions);
    },
    async rejectTransactions({ commit }, transactionIds) {
      const transactions = await instance.post(`${settings.API_URL}/transactions/bulk/decline`, transactionIds);

      commit('SET_TRANSACTIONS_AFTER_MASSIVE', transactions.data.transactions);
    },
  },

  mutations: {
    SET_TRANSACTIONS(state, transactions) {
      state.transactions = transactions;
    },
    CLEAR_TRANSACTIONS(state) {
      state.transactions = null;
      state.loading.transactions = true;
    },
    CHANGE_ENCODING(state, encoding) {
      state.filters.encoding = encoding;
    },
    CHANGE_PAGE(state, page) {
      state.filters.page = page;
    },
    CHANGE_FILTERS_DATES(state, dates) {
      state.filters.created_at_from = dates.from;
      state.filters.created_at_to = dates.to;
    },
    CHANGE_FILTERS_INSTRUCTOR(state, id) {
      state.filters.instructor_id = id;
    },
    CHANGE_FILTERS_STATUS(state, status) {
      state.filters.status = status;
    },
    CHANGE_FILTERS_PAGE_SIZE(state, pageSize) {
      state.filters.page_size = pageSize;
    },
    START_TRANSACTIONS_LOADING(state) {
      state.loading.transactions = true;
    },
    FINISH_TRANSACTIONS_LOADING(state) {
      state.loading.transactions = false;
    },
    START_EXPORT_LOADING(state) {
      state.loading.export = true;
    },
    FINISH_EXPORT_LOADING(state) {
      state.loading.export = false;
    },

    SET_TRANSACTION(state, transaction) {
      const transactionIndex = state.transactions.transactions.findIndex(el => el.transaction_id === transaction.transaction_id);

      state.transactions.transactions.splice(transactionIndex, 1, transaction);
    },

    SET_TRANSACTIONS_AFTER_MASSIVE(state, transactions) {
      transactions.forEach(el => {
        const transactionIndex = state.transactions.transactions.findIndex(el2 => el2.transaction_id === el.transaction_id);

        if (transactionIndex > -1) {
          state.transactions.transactions.splice(transactionIndex, 1, el);
        }
      })
    }
  },
};