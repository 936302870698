import instance from '../../instance';
import settings from '../../settings';

export default {
  state: {
    filterClients: [],
    filterClientsMeta: [],
    filtersForFilterClients: {
      page: 1,
      page_size: 20,
      query: '',
    },
    filterClientsLoading: {
      clients: true,
    }
  },

  getters: {
    filterClients: state => state.filterClients,
    filterClientsMeta: state => state.filterClientsMeta,
    filtersForFilterClients: state => state.filtersForFilterClients,
    filterClientsLoading: state => state.filterClientsLoading,
  },

  actions: {
    async getFilterClients({ commit, state }, loading = true) {
      if (loading) {
        commit('START_GET_FILTER_CLIENTS_LOADING');
      }

      try {
        const requestId = 'clientsFilter';

        const params = Object.fromEntries(Object.entries(state.filtersForFilterClients).filter(([key, value]) => key && value));

        const clients = await instance.get(`${settings.API_URL}/clients`, { requestId, params } );

        commit('SET_FILTER_CLIENTS', clients.data);
      } finally {
        commit('FINISH_GET_FILTER_CLIENTS_LOADING');
      }
    },
    async getFilterClientsForStudyGroups({ commit, state }) {
      const requestId = 'clientsFilter';

      const params = Object.fromEntries(Object.entries(state.filtersForFilterClients).filter(([key, value]) => key && value));

      const clients = await instance.get(`${settings.API_URL}/clients`, { requestId, params } );

      commit('CLEAR_FILTER_CLIENTS');
      commit('SET_FILTER_CLIENTS', clients.data);
    },
    clearFilterClients({ commit }) {
      commit('CLEAR_FILTER_CLIENTS');
    },
    changePageFilterClients({ commit }, page) {
      commit('CHANGE_PAGE_FILTER_CLIENTS', page);
    },
    changeFiltersFilterClientsSearch({ commit }, search) {
      commit('CHANGE_FILTERS_FILTERS_CLIENTS_SEARCH', search);
    },
    resetFiltersFilterClients({ commit }) {
      commit('CHANGE_PAGE_FILTER_CLIENTS', 1);
      commit('CHANGE_FILTERS_FILTERS_CLIENTS_SEARCH', null);
    },
    addClientInFilterListClients({ commit }, client) {
      commit('ADD_CLIENT_IN_FILTER_LIST_CLIENTS', client);
    },
  },

  mutations: {
    START_GET_FILTER_CLIENTS_LOADING(state) {
      state.filterClientsLoading.clients = true;
    },
    FINISH_GET_FILTER_CLIENTS_LOADING(state) {
      state.filterClientsLoading.clients = false;
    },
    CLEAR_FILTER_CLIENTS(state) {
      state.filterClients = [];
    },
    CHANGE_PAGE_FILTER_CLIENTS(state, page) {
      state.filtersForFilterClients.page = page;
    },
    SET_FILTER_CLIENTS(state, clients) {
      state.filterClients.push(...clients.clients);
      state.filterClientsMeta = clients.meta;
      state.filtersForFilterClients.page++;
    },
    CHANGE_FILTERS_FILTERS_CLIENTS_SEARCH(state, search) {
      state.filtersForFilterClients.query = search;
    },
    ADD_CLIENT_IN_FILTER_LIST_CLIENTS(state, client) {
      if (!state.filterClients.find(el => el.client_id === client.client_id)) {
        state.filterClients.unshift(client);
      }
    },

  },
};