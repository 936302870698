<template>
  <div class="account-content">
    <div class="settings">
      <div class="settings-top d-md-flex align-items-end mb-3">
        <div class="settings-head d-flex align-items-center">
          <div class="settings-title">Аналитика</div>
        </div>

        <div class="settings-search ml-auto d-flex mt-4 mt-md-0">
          <input
            v-model.trim="search"
            v-debounce:1000ms="searchInstructor"
            type="text"
            class="form-control form-control--search pl-2 pl-md-2"
            placeholder="Поиск по инструкторам"
          >

          <button type="button"></button>
        </div>
      </div>

      <AppLoader v-if="loading.analytics" />

      <div v-else class="school-cabinet">
        <div class="filters pt-1 mb-3">
          <div class="filters-item">
            <DropDownMenuCalendar
              :dateCalendar="dateCalendar"
              :disabled="disabledFilters"
              :placeholder="'Период'"
              @changeDate="changeDate"
            />
          </div>
        </div>

        <div class="wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
          <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />

          <template v-if="analytics.analytics.length">
            <AnalyticsInstructorCard
              v-for="instructor in analytics.analytics"
              :key="instructor.instructor.instructor_id"
              :instructor="instructor"
            />

            <div v-if="analytics.meta.page.total > 1" class="d-flex justify-content-center">
              <paginate
                v-model="page"
                :pageCount="analytics.meta.page.total"
                :clickHandler="clickCallback"
                :prevText="''"
                :nextText="''"
                :containerClass="'pagination'"
                :pageClass="'page-item'"
                :pageLinkClass="'page-link'"
                :prevClass="'page-item'"
                :prevLinkClass="'page-link'"
                :nextClass="'page-item'"
                :nextLinkClass="'page-link'"
                :activeClass="'active'"
              />
            </div>
          </template>

          <p v-else>Совпадений не найдено</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Paginate from 'vuejs-paginate';
import moment from 'moment';

import AppLoader from '@/components/AppLoader/1.vue';
import AnalyticsInstructorCard from '@/components/AnalyticsInstructorCard.vue';
import DropDownMenuCalendar from '@/components/DropDownMenu/Calendar.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';

export default {
  name: 'AppAnalytics',

  components: {
    AppLoader,
    Paginate,
    AnalyticsInstructorCard,
    DropDownMenuCalendar,
    AppLoader3
  },

  data() {
    return {
      page: 1,
      isLoadingPage: false,
      dateCalendar: [],
      disabledFilters: false,
      search: ''
    }
  },

  computed: {
    ...mapGetters({
      analytics: 'analytics',
      pageAnalytics: 'pageAnalytics',
      loading: 'loadingAnalytics'
    })
  },

  async created() {
    this.clearAnalytics();

    if (this.$route.params.page === 1) {
      this.resetFiltersAnalytics();
    }

    if (this.$route.query.page) {
      this.changePageAnalytics(Number(this.$route.query.page));
    }

    if (this.$route.query.registered_from && this.$route.query.registered_to) {
      const date = {
        from: moment(this.$route.query.registered_from, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        to: moment(this.$route.query.registered_to, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      }

      this.changeFiltersDateAnalytics(date);

      this.dateCalendar = [moment(this.$route.query.registered_from, 'DD-MM-YYYY')._d, moment(this.$route.query.registered_to, 'DD-MM-YYYY')._d];
    }

    if (this.$route.query.search) {
      this.changeFiltersSearchAnalytics(this.$route.query.search);

      this.search = this.$route.query.search;
    }

    this.page = this.pageAnalytics;

    let query = Object.assign({}, this.$route.query);
    query.page = this.pageAnalytics;
    this.$router.push({ query }).catch(() => {});

    await this.getAnalytics();

    if (this.analytics && this.pageAnalytics > this.analytics.meta.page.total) {
      await this.changePageAnalytics(this.analytics.meta.page.total);

      this.page = this.pageAnalytics;

      let query = Object.assign({}, this.$route.query);
      query.page = this.pageAnalytics;
      this.$router.push({ query }).catch(() => {});

      this.getAnalytics();
    }
  },

  methods: {
    ...mapActions([
      'getAnalytics',
      'clearAnalytics',
      'changePageAnalytics',
      'changeFiltersDateAnalytics',
      'changeFiltersSearchAnalytics',
      'resetFiltersAnalytics'
    ]),
    clickCallback() {
      this.isLoadingPage = true;

      let query = Object.assign({}, this.$route.query);
      query.page = this.page;

      this.changePageAnalytics(this.page)
        .then(() => {
          this.$router.push({ query }).catch(() => {});

          const loading = false;

          this.getAnalytics(loading)
            .finally(() => this.isLoadingPage = false);
        });
    },
    changeDate(dateCalendar) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.changePageAnalytics(1);
      this.page = 1;

      let date = null;

      if (dateCalendar) {
        date = {
          from: moment(dateCalendar[0]).format('YYYY-MM-DD'),
          to: moment(dateCalendar[1]).format('YYYY-MM-DD'),
        }

        let query = Object.assign({}, this.$route.query);
        query.page = '1';
        query.registered_from = moment(dateCalendar[0]).format('DD-MM-YYYY');
        query.registered_to = moment(dateCalendar[1]).format('DD-MM-YYYY');

        this.$router.push({ query }).catch(() => {});
      } else {
        date = {
          from: null,
          to: null,
        }

        let query = Object.assign({}, this.$route.query);
        query.page = '1';
        delete query.registered_from;
        delete query.registered_to;

        this.$router.push({ query }).catch(() => {});
      }

      this.changeFiltersDateAnalytics(date);

      const loading = false;

      this.getAnalytics(loading)
        .finally(() => {
          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    searchInstructor(value) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      const search = value.trim();

      this.changePageAnalytics(1);
      this.page = 1;

      let query = Object.assign({}, this.$route.query);
      query.page = '1';

      if (!search) {
        delete query.search;
      } else {
        query.search = search;
      }

      this.$router.push({ query }).catch(() => {});

      this.changeFiltersSearchAnalytics(search);

      const loading = false;

      this.getAnalytics(loading)
        .finally(() => {
          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    moment
  }
}
</script>