<template>
	<div class="account-content">
		<div class="settings mb-4 pb-1">
			<div class="settings-top d-flex align-items-end">
				<div class="settings-head d-flex align-items-center">
					<div class="settings-title">Настройки организации</div>
				</div>
			</div>
			<div class="settings-body pl-4 pr-4">
				<div class="settings_filters ml-md-2 mr-md-2 mb-4">
					<div class="settings_filters-content d-flex justify-content-between justify-content-md-start">
						<div class="settings_filters-item">
							<router-link to="/organization-settings">Общие сведения</router-link>
						</div>
						<div class="settings_filters-item active">
							<a href="javascript:void(0)">Реквизиты</a>
						</div>
					</div>
				</div>
				<div class="school-cabinet">
					<AppLoader v-if="loading.requisites" />
					<div class="school-cabinet_info pt-2" v-else>
						<div class="school-cabinet_info-form school-cabinet_info-form--full">
							<div class="school-cabinet_info-row">
								<div class="d-flex justify-content-between flex-wrap">
									<div class="school-cabinet_info-field school-cabinet_info-field--small">
										<input
											v-model.trim="organization_requisites.inn"
											@focus="hideErrorHighlight('inn'); $v.organization_requisites.inn.$reset()"
											type="text"
											placeholder="ИНН"
											:class="{ 'form-control--error': $v.organization_requisites.inn.$error || errors.includes('inn') }"
											class="form-control form-control--bordered"
										>

										<div class="text-error" v-if="!$v.organization_requisites.inn.required && $v.organization_requisites.inn.$dirty">Поле обязательно для заполнения</div>
										<div class="text-error" v-if="!$v.organization_requisites.inn.minLength && $v.organization_requisites.inn.$dirty || !$v.organization_requisites.inn.maxLength && $v.organization_requisites.inn.$dirty">Количество символов в поле должно быть от 10 до 12</div>
									</div>
									<div class="school-cabinet_info-field school-cabinet_info-field--small">
										<input
											v-model.trim="organization_requisites.kpp"
											@focus="hideErrorHighlight('kpp'); $v.organization_requisites.kpp.$reset()"
											type="text"
											placeholder="КПП"
											:class="{ 'form-control--error': $v.organization_requisites.kpp.$error || errors.includes('kpp') }"
											class="form-control form-control--bordered"
										>

										<div class="text-error" v-if="!$v.organization_requisites.kpp.required && $v.organization_requisites.kpp.$dirty">Поле обязательно для заполнения</div>
										<div class="text-error" v-if="!$v.organization_requisites.kpp.minLength && $v.organization_requisites.kpp.$dirty || !$v.organization_requisites.kpp.maxLength && $v.organization_requisites.kpp.$dirty">Количество символов в поле должно быть равным 9</div>
									</div>
									<div class="school-cabinet_info-field school-cabinet_info-field--small">
										<input
											v-model.trim="organization_requisites.ogrn"
											@focus="hideErrorHighlight('ogrn'); $v.organization_requisites.ogrn.$reset()"
											type="text"
											placeholder="ОГРН"
											:class="{ 'form-control--error': $v.organization_requisites.ogrn.$error || errors.includes('ogrn') }"
											class="form-control form-control--bordered"
										>

										<div class="text-error" v-if="!$v.organization_requisites.ogrn.required && $v.organization_requisites.ogrn.$dirty">Поле обязательно для заполнения</div>
										<div class="text-error" v-if="!$v.organization_requisites.ogrn.minLength && $v.organization_requisites.ogrn.$dirty || !$v.organization_requisites.ogrn.maxLength && $v.organization_requisites.ogrn.$dirty">Количество символов в поле должно быть равным 13</div>
									</div>
								</div>
							</div>
							<div class="school-cabinet_info-row mt-3 mt-md-0">
								<div class="school-cabinet_info-row-title">Реквизиты</div>
								<div class="d-flex justify-content-between flex-wrap" v-for="(requisite, index) in bank_requisites" :key="index">
									<div class="school-cabinet_info-field school-cabinet_info-field--small mb-4">
										<input
											v-model.trim="requisite.bank_name"
											type="text"
											placeholder="Наименование банка"
											:class="{ 'form-control--error': $v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].bank_name && !$v.bank_requisites.$each[0].bank_name.required && $v.bank_requisites.$each[0].bank_name.$dirty }"
											class="form-control form-control--bordered"
										>

										<div class="text-error" v-if="$v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].bank_name && !$v.bank_requisites.$each[0].bank_name.required && $v.bank_requisites.$each[0].bank_name.$dirty">Поле обязательно для заполнения</div>
									</div>
									<div class="school-cabinet_info-field school-cabinet_info-field--small mb-4">
										<input
											v-model.trim="requisite.city"
											type="text"
											placeholder="Город"
											:class="{ 'form-control--error': $v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].city && !$v.bank_requisites.$each[0].city.required && $v.bank_requisites.$each[0].city.$dirty }"
											class="form-control form-control--bordered"
										>

										<div class="text-error" v-if="$v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].city && !$v.bank_requisites.$each[0].city.required && $v.bank_requisites.$each[0].city.$dirty">Поле обязательно для заполнения</div>
									</div>
									<div class="school-cabinet_info-field school-cabinet_info-field--small mb-4">
										<input
											v-model="requisite.checking_account"
											type="text"
											placeholder="Расчетный счет"
											:class="{ 'form-control--error': $v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].checking_account && !$v.bank_requisites.$each[0].checking_account.required && $v.bank_requisites.$each[0].checking_account.$dirty || $v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].checking_account && !$v.bank_requisites.$each[0].checking_account.minLength && $v.bank_requisites.$each[0].checking_account.$dirty || $v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].checking_account && !$v.bank_requisites.$each[0].checking_account.maxLength && $v.bank_requisites.$each[0].checking_account.$dirty}"
											class="form-control form-control--bordered"
										>

										<div class="text-error" v-if="$v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].checking_account && !$v.bank_requisites.$each[0].checking_account.required && $v.bank_requisites.$each[0].checking_account.$dirty">Поле обязательно для заполнения</div>
										<div class="text-error" v-if="$v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].checking_account && !$v.bank_requisites.$each[0].checking_account.minLength && $v.bank_requisites.$each[0].checking_account.$dirty || $v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].checking_account && !$v.bank_requisites.$each[0].checking_account.maxLength && $v.bank_requisites.$each[0].checking_account.$dirty">Количество символов в поле должно быть равным 20</div>
									</div>
									<div class="school-cabinet_info-field school-cabinet_info-field--small mb-4">
										<input
											v-model="requisite.correspondent_account"
											type="text"
											placeholder="Корреспондентский счет"
											:class="{ 'form-control--error': $v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].correspondent_account && !$v.bank_requisites.$each[0].correspondent_account.required && $v.bank_requisites.$each[0].correspondent_account.$dirty || $v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].correspondent_account && !$v.bank_requisites.$each[0].correspondent_account.minLength && $v.bank_requisites.$each[0].correspondent_account.$dirty || $v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].correspondent_account && !$v.bank_requisites.$each[0].correspondent_account.maxLength && $v.bank_requisites.$each[0].correspondent_account.$dirty}"
											class="form-control form-control--bordered"
										>

										<div class="text-error" v-if="$v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].correspondent_account && !$v.bank_requisites.$each[0].correspondent_account.required && $v.bank_requisites.$each[0].correspondent_account.$dirty">Поле обязательно для заполнения</div>
										<div class="text-error" v-if="$v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].correspondent_account && !$v.bank_requisites.$each[0].correspondent_account.minLength && $v.bank_requisites.$each[0].correspondent_account.$dirty || $v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].correspondent_account && !$v.bank_requisites.$each[0].correspondent_account.maxLength && $v.bank_requisites.$each[0].correspondent_account.$dirty">Количество символов в поле должно быть равным 20</div>
									</div>
									<div class="school-cabinet_info-field school-cabinet_info-field--small mb-4">
										<input
											v-model="requisite.bik"
											type="text"
											placeholder="БИК"
											:class="{ 'form-control--error': $v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].bik && !$v.bank_requisites.$each[0].bik.required && $v.bank_requisites.$each[0].bik.$dirty || $v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].bik && !$v.bank_requisites.$each[0].bik.minLength && $v.bank_requisites.$each[0].bik.$dirty || $v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].bik && !$v.bank_requisites.$each[0].bik.maxLength && $v.bank_requisites.$each[0].bik.$dirty}"
											class="form-control form-control--bordered"
										>

										<div class="text-error" v-if="$v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].bik && !$v.bank_requisites.$each[0].bik.required && $v.bank_requisites.$each[0].bik.$dirty">Поле обязательно для заполнения</div>
										<div class="text-error" v-if="$v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].bik && !$v.bank_requisites.$each[0].bik.minLength && $v.bank_requisites.$each[0].bik.$dirty || $v.bank_requisites.$each[0] && $v.bank_requisites.$each[0].bik && !$v.bank_requisites.$each[0].bik.maxLength && $v.bank_requisites.$each[0].bik.$dirty">Количество символов в поле должно быть равным 9</div>
									</div>
									<div class="school-cabinet_info-field school-cabinet_info-field--small">
									</div>
									<div class="school-cabinet_info-field school-cabinet_info-field--small mb-4" v-if="bank_requisites.length > 0">
										<div class="add-block add-block--del" @click="deleteConfirmation" v-if="!loading.updateBankRequisites">
											<div class="school-cabinet_card-delete"></div>
											<div class="add-block__text">Удалить реквизиты</div>
										</div>
										<div class="add-block add-block--del" v-else>
											<div class="school-cabinet_card-delete"></div>
											<div class="add-block__text">Удаляем...</div>
										</div>
									</div>
								</div>
							</div>
							<div class="add-block" @click="addBankRequisites" v-if="bank_requisites.length == 0">
								<div class="add-block__icon"></div>
								<div class="add-block__text">Добавить банк</div>
							</div>
							<div class="school-cabinet_info-button text-center mb-3 mb-md-0 mt-5 pt-3">
								<button class="btn btn-blue" @click="save" v-if="!loading.updateRequisites">Сохранить</button>
								<button class="btn btn-blue" disabled v-else>Сохраняем...</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import { mapGetters, mapActions } from 'vuex';
	import AppLoader from '@/components/AppLoader/1.vue';
	import Vuelidate from 'vuelidate';

	import { minLength, maxLength, required } from 'vuelidate/lib/validators';

	import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';
	import hideErrorHighlight from '@/helpers/hideErrorHighlight';

	Vue.use(Vuelidate);

	export default {
		name: 'OrganizationRequisites',

		components: {
			AppLoader,
		},

		data() {
			return {
				organization_requisites: {
					school_id: '',
					inn: '',
					kpp: '',
					ogrn: '',
				},
				bank_requisites: [],
				errors: []
			}
		},

		mounted() {
			this.loadRequisites();
		},

		methods: {
			...mapActions({
				getRequisites: 'school/getRequisites',
				updateRequisites: 'school/updateRequisites',
				addBankRequisitesAction: 'school/addBankRequisites',
				updateBankRequisitesAction: 'school/updateBankRequisites',
				deleteBankRequisites: 'school/deleteBankRequisites',
			}),
			async loadRequisites() {
				await this.getRequisites(this.profile.school.school_id);
				this.organization_requisites.school_id = this.profile.school.school_id;
				this.organization_requisites.inn = this.requisites.school_requisites.inn;
				this.organization_requisites.kpp = this.requisites.school_requisites.kpp;
				this.organization_requisites.ogrn = this.requisites.school_requisites.ogrn;
				this.bank_requisites = [];

				if (this.requisites.school_requisites.bank_requisites && this.requisites.school_requisites.bank_requisites.length > 0) {
					let new_bank_requisites = {
						school_bank_requisites_id: this.requisites.school_requisites.bank_requisites[0].school_bank_requisites_id,
						checking_account: this.requisites.school_requisites.bank_requisites[0].checking_account,
						correspondent_account: this.requisites.school_requisites.bank_requisites[0].correspondent_account,
						bank_name: this.requisites.school_requisites.bank_requisites[0].bank_name,
						city: this.requisites.school_requisites.bank_requisites[0].city,
						bik: this.requisites.school_requisites.bank_requisites[0].bik,
					};
					this.bank_requisites.push(new_bank_requisites)
				}
			},
			save() {
				this.$v.$touch()

				if (this.$v.$invalid) {
					return
				} else {
					this.errors = [];
					this.updateRequisites(this.organization_requisites)
					.then(() => {
						if (this.bank_requisites.length > 0) {
							this.bank_requisites.forEach((requisite) => {
								if (this.bank_requisites[0].school_bank_requisites_id) {
									let payload = {
										id: this.profile.school.school_id,
										requisite: requisite,
										bank_id: this.bank_requisites[0].school_bank_requisites_id
									};
									this.updateBankRequisitesAction(payload)
									.then(() => {
										this.$toast.open({
											message: 'Данные успешно сохранены',
											type: 'success'
										})
										this.loadRequisites();
									})
									.catch((error) => {
										this.errorHandlerForResponse(error);

										if (error.response.data.errors) {
											this.errorsCheck(error.response.data.errors);
										}
									})
								} else {
									let payload = {
										id: this.profile.school.school_id,
										requisite: requisite
									};
									this.addBankRequisitesAction(payload)
									.then(() => {
										this.$toast.open({
											message: 'Данные успешно сохранены',
											type: 'success'
										})
										this.loadRequisites();
									})
									.catch((error) => {
										this.errorHandlerForResponse(error);

										if (error.response.data.errors) {
											this.errorsCheck(error.response.data.errors);
										}
									})
								}
							})
						} else {
							this.$toast.open({
								message: 'Данные успешно сохранены',
								type: 'success'
							})
						}
					})
					.catch((error) => {
						this.errorHandlerForResponse(error);

						if (error.response.data.errors) {
							this.errorsCheck(error.response.data.errors);
						}
					})
				}
			},
			deleteConfirmation() {
				this.$modal.show('dialog', {
					text: 'Вы действительно хотите удалить реквизиты?',
					buttons: [
						{
							title: 'Да',
							handler: () => {
								this.deleteRequisites();
								this.$modal.hide('dialog');
							},
						},
						{
							title: 'Нет',
							handler: () => {
								this.$modal.hide('dialog');
							},
						},
					],
				});
			},
			deleteRequisites() {
				if (this.bank_requisites[0].school_bank_requisites_id) {
					let payload = {
						id: this.profile.school.school_id,
						bank_id: this.bank_requisites[0].school_bank_requisites_id,
					}
					this.deleteBankRequisites(payload)
					.then(() => {
						this.loadRequisites();
					})
				} else {
					this.bank_requisites = []
				}
			},
			addBankRequisites() {
				let new_bank_requisites = {
					checking_account: '',
					correspondent_account: '',
					bank_name: '',
					city: '',
					bik: '',
				};
				this.bank_requisites.push(new_bank_requisites)
			},
			errorsCheck(errors) {
				console.log(errors);
				Object.keys(errors).forEach(el => {
					switch (el) {
						case 'inn':
							this.errors.push('inn');
							break;
						case 'kpp':
							this.errors.push('kpp');
							break;
						case 'ogrn':
							this.errors.push('ogrn');
							break;
					}
				})
			},
			hideErrorHighlight,
			errorHandlerForResponse
		},

		computed: {
			...mapGetters({
				requisites: 'school/requisites',
				profile: 'profile',
				loading: 'school/loading',
			})
		},

		validations: {
			bank_requisites: {
				$each: {
					bank_name: {
						required,
					},
					city: {
						required,
					},
					checking_account: {
						required,
						minLength: minLength(20),
						maxLength: maxLength(20),
					},
					correspondent_account: {
						required,
						minLength: minLength(20),
						maxLength: maxLength(20),
					},
					bik: {
						required,
						minLength: minLength(9),
						maxLength: maxLength(9),
					},
				},
			},
			organization_requisites: {
				inn: {
					required,
					minLength: minLength(10),
					maxLength: maxLength(12),
				},
				kpp: {
					required,
					minLength: minLength(9),
					maxLength: maxLength(9),
				},
				ogrn: {
					required,
					minLength: minLength(13),
					maxLength: maxLength(13),
				},
			}
		},
	}
</script>

<style>
	.add-block--del .add-block__text {
		color: #EA4242;
	}
	.add-block--del .school-cabinet_card-delete {
		margin-right: 11px;
	}
</style>