import instance from '../instance';
import settings from '../settings';

export default {
  state: {
    durations: null,
    menu: null,
    driverCategories: null,
    permissions: null,
    loading: {
      menu: true,
      durations: true,
      driverCategories: true,
    }
  },

  getters: {
    durations: state => state.durations,
    permissionsList: state => state.permissions,
    driverCategories: state => state.driverCategories,
    loadingDict: state => state.loading,
    menu: state => state.menu,
    loadingMenu: state => state.loading.menu,
  },

  actions: {
    async getMenu({ commit }) {
      commit('START_MENU_LOADING');

      try {
        const menu = await instance.get(`${settings.API_URL}/dict/menu-items`);

        commit('SET_MENU', menu.data);
      } finally {
        commit('FINISH_MENU_LOADING');
      }
    },
    async getDurations({ commit }) {
      commit('START_DURATIONS_LOADING');

      try {
        const durations = await instance.get(`${settings.API_URL}/dict/durations`);

        commit('SET_DURATIONS', durations.data.durations);
      } finally {
        commit('FINISH_DURATIONS_LOADING');
      }
    },
    async getDriverCategories({ commit }) {
      commit('START_DRIVER_CATEGORIES_LOADING');

      try {
        const driverCategories = await instance.get(`${settings.API_URL}/dict/driver-categories`);

        commit('SET_DRIVER_CATEGORIES', driverCategories.data.driver_categories);
      } finally {
        commit('FINISH_DRIVER_CATEGORIES');
      }
    },
    async getPermissionsList({ commit }) {
      const permissions = await instance.get(`${settings.API_URL}/users/permissions`);

      commit('SET_PERMISSIONS', permissions.data.permissions);
    },
  },

  mutations: {
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    SET_DURATIONS(state, durations) {
      state.durations = durations;
    },
    START_DURATIONS_LOADING(state) {
      state.loading.durations = true;
    },
    FINISH_DURATIONS_LOADING(state) {
      state.loading.durations = false;
    },

    SET_DRIVER_CATEGORIES(state, driverCategories) {
      state.driverCategories = driverCategories;
    },
    START_DRIVER_CATEGORIES_LOADING(state) {
      state.loading.driverCategories = true;
    },
    FINISH_DRIVER_CATEGORIES(state) {
      state.loading.driverCategories = false;
    },
    START_MENU_LOADING(state) {
      state.loading.menu = true;
    },
    FINISH_MENU_LOADING(state) {
      state.loading.menu = false;
    },
    SET_MENU(state, menu) {
      state.menu = menu;
    },
  },
};