<template>
  <div class="account-content">
    <div class="settings">
      <div class="settings-top d-md-flex align-items-end mb-4">
        <div class="settings-head d-flex align-items-center">
          <div class="settings-title">Заказы</div>
          <router-link 
            to="/orders/create" 
            class="settings-head-icon"
            v-if="profile.user.permission.orders.edit"
          ></router-link>
        </div>
        
        <div class="settings-search ml-auto d-flex mt-4 mt-md-0">
          <input 
            v-model.trim="search"
            v-debounce:1000ms="searchByClient" 
            type="text" 
            class="form-control form-control--search" 
            placeholder="Поиск по клиентам"
          >
          
          <button></button>
        </div>
      </div>
      <div class="filters d-flex flex-wrap filters--orders">
        <div class="filters-item mb-2 mb-md-0">
          <v-select
            :options="clients"
            v-if="clients"
            @input="selectClient"
            @search="searchClient"
            :disabled="disabledFilters"
            :clearable="false"
            :searchable="true"
            :filterable="false"
            placeholder="Клиент"
            :get-option-label='getUserName'
            :get-option-key='option => option.client_id'
            v-model="activeClient"
          >
            <template #open-indicator="{ attributes }">
              <span class="form-control--arrow" v-bind="attributes"></span>
            </template>
            <template #list-header>
              <li class="vs__dropdown-option vs__dropdown-option--reset" @mouseover="resetHover = true" @mouseleave="resetHover = false" v-if="activeClient" @click="resetClient">Все</li>
            </template>
            <template slot="no-options">
              Пользователей не найдено
            </template>
            <template v-slot:option="option">
              {{ option.name.first }} {{ option.name.last }} {{ option.name?.middle }}
            </template>
            <template #spinner="{ loading }">
              <AppLoader2 :mini="true" v-if="loading" />
            </template>
            <template #list-footer>
              <infinite-loading @infinite="infiniteHandler">
                <div slot="spinner">
                  <AppLoader2 :mini="true" />
                </div>
                <div slot="no-more"></div>
                <div slot="no-results"></div>
              </infinite-loading>
            </template>
          </v-select>
        </div>
        <div class="filters-item mb-2 mb-md-0">
          <v-select
            :options="status_list"
						label="name"
						:clearable="false"
            :searchable="false"
            :filterable="false"
						placeholder="Статус"
						@input="selectStatus"
            v-model="activeStatus"
          >
            <template #open-indicator="{ attributes }">
              <span class="form-control--arrow" v-bind="attributes"></span>
            </template>
          </v-select>
        </div>
        <div class="filters-item">
          <DropDownMenuCalendarOrders
            :placeholder="'Период'"
            @changeDate="changeDate"
            :actualDates="dates"
          />
        </div>
        <transition name="fade--top">
          <button
            v-if="filtersApplied"
            @click="resetFilters"
            type="button"
            class="reset-filters filters-item--100 align-self-center mt-3 mb-3 mt-xl-0 mb-md-0 ml-xl-auto"
          >
            Сбросить фильтры
          </button>
        </transition>
      </div>

      <AppLoader v-if="loading.orders" />

      <div v-else class="school-cabinet_wrapper wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
        <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />

        <div v-if="orders.length" class="school-cabinet_wrapper-content" data-simplebar data-simplebar-auto-hide="false">
          <div class="school-cabinet_table school-cabinet_table--orders">
            <div class="school-cabinet_table-title school-cabinet_table-title--1">Пользователь</div>
            <div class="school-cabinet_table-title school-cabinet_table-title--2">Статус</div>
            <div class="school-cabinet_table-title school-cabinet_table-title--3">Тариф</div>
            <div class="school-cabinet_table-title school-cabinet_table-title--4">Сумма / Оплачено / Долг</div>
          </div>
          <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />
          <div 
            class="school-cabinet_card school-cabinet_card--orders"
            v-for="order in orders"
            :key="order.order_id"
            :order="order"
          >
            <div class="school-cabinet_card-top d-flex align-items-start flex-wrap">
              <div class="school-cabinet_card-info school-cabinet_card-info--id">
                <div class="school-cabinet_card-info-item mr-0 pr-0">
                  <div class="school-cabinet_card-info-title">
                    <strong>ID</strong>
                  </div>
                  <div class="school-cabinet_card-info-text mb-2 mb-md-0">
                    <span class="light">{{ order.order_id }}</span>
                  </div>
                </div>
              </div>
              <div
                class="school-cabinet_card-content school-cabinet_card-content--orders d-flex align-items-center mb-3 mb-xl-0">
                <div class="school-cabinet_card-text">
                  <div class="school-cabinet_card-name">
                    {{ order.client.name.first }} {{ order.client.name.last }} {{ order.client.name.middle }}
                  </div>
                  <div class="school-cabinet_card-facts" v-if="order.client.email && order.client.phone">
                    {{ order.client.email }}, {{ order.client.phone }}
                  </div>
                </div>
              </div>
              <div class="school-cabinet_card-info school-cabinet_card-info--status">
                <div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
                  <div class="school-cabinet_card-info-title">
                    <strong>{{ getOrdersStatus(order.status) }}</strong>
                  </div>
                  <div class="school-cabinet_card-info-text mb-2 mb-md-0" v-if="order.created_at">
                    <span class="light">{{ formatDate(order.created_at) }}</span>
                  </div>
                </div>
              </div>
              <div class="school-cabinet_card-info school-cabinet_card-info--cat">
                <div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
                  <div class="school-cabinet_card-info-title">
                    <strong>{{ order.items[0].product.course_track.title }}</strong>
                  </div>
                  <div class="school-cabinet_card-info-text mb-2 mb-md-0" v-if="order.items && order.items.length > 0">
                    <span class="light">{{ order.items[0].tariff.name }}</span>
                  </div>
                </div>
              </div>
              <div class="school-cabinet_card-info school-cabinet_card-info--payment">
                <div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
                  <div class="school-cabinet_card-info-title">
                    <strong>{{ order.total }} ₽ / {{ order.paid }} ₽ / {{ order.due }} ₽</strong>
                  </div>
                  <!-- <div class="school-cabinet_card-info-text mb-2 mb-md-0">
                    <span class="light">Полностью (!)</span>
                  </div> -->
                </div>
              </div>
              <router-link v-if="order.status !== 'canceled'" :to="`/orders/${order.order_id}/edit`" class="d-flex align-items-center ml-auto mt-3 mt-md-0 mb-1 mb-md-0"><div class="school-cabinet_card-edit"></div></router-link>
            </div>
          </div>
          <div v-if="meta && meta.page && meta.page.total > 1" class="d-flex justify-content-center">
            <paginate
              v-model="page"
              :pageCount="meta.page.total"
              :clickHandler="clickCallback"
              :prevText="''"
              :nextText="''"
              :containerClass="'pagination'"
              :pageClass="'page-item'"
              :pageLinkClass="'page-link'"
              :prevClass="'page-item'"
              :prevLinkClass="'page-link'"
              :nextClass="'page-item'"
              :nextLinkClass="'page-link'"
              :activeClass="'active'"
            />
          </div>
        </div>

        <p v-else>Заказов нет</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';

import vSelect from 'vue-select';
import Paginate from 'vuejs-paginate';

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';
import InfiniteLoading from 'vue-infinite-loading';

import DropDownMenuCalendarOrders from '@/components/DropDownMenu/CalendarOrders.vue';
export default {
  name: 'Orders',

  data() {
    return {
      code: false,
      page: 1,
      isLoadingPage: false,
      search: '',
      clientId: null,
      resetHover: false,
      activeClient: null,
      activeStatus: null,
      disabledFilters: false,
      dates: null,
      status_list: [
        {
          id: 1,
          key: 'new',
          name: 'Создан'
        },
        {
          id: 2,
          key: 'pending',
          name: 'Ожидает оплаты'
        },
        {
          id: 3,
          key: 'installment',
          name: 'Рассрочка'
        },
        {
          id: 4,
          key: 'paid',
          name: 'Оплачен'
        },
        {
          id: 5,
          key: 'canceled',
          name: 'Отменен'
        },
      ]
    }
  },
  components: {
    AppLoader,
    AppLoader2,
    AppLoader3,
    InfiniteLoading,
    Paginate,
    vSelect,
    DropDownMenuCalendarOrders
  },

  computed: {
    ...mapGetters({
      orders: 'orders',
      meta: 'ordersMeta',
      loading: 'ordersLoading',
      pageOrders: 'pageOrders',
      filters: 'filters',
      clients: 'filterClients',
      profile: 'profile',
      filterClientsMeta: 'filterClientsMeta',
      filtersApplied: 'ordersFiltersApplied',
    }),
  },

  async created() {
    await this.getOrders(true);
    this.clearFilterClients();
    this.changePageFilterClients(1);
    this.changeFiltersFilterClientsSearch(null);
    await this.getFilterClients();
  },

  destroyed() {
    this.resetFiltersOrders();
  },

  methods: {
    ...mapActions([
      'getOrders',
      'changePageOrders',
      'changeFiltersSearchOrders',
      'changeFiltersForOrdersClient',
      'clearFilterClients',
      'changePageFilterClients',
      'changeFiltersFilterClientsSearch',
      'getFilterClients',
      'changeFiltersDateForOrders',
      'changeFiltersForOrdersStatus',
      'resetFiltersOrders',
    ]),
    getUserName(option) {
      if (option) {
        if (option.name.middle) {
          return `${option.name.first} ${option.name.last} ${option.name?.middle}`;
        } else {
          return `${option.name.first} ${option.name.last}`;
        }
      }
      return null;
    },
    getOrdersStatus(code) {
      if (code === 'new') {
        return 'Ожидает оплаты'
      }
      if (code === 'pending') {
        return 'Ожидает оплаты'
      }
      if (code === 'installment') {
        return 'Оплачен в рассрочку'
      }
      if (code === 'paid') {
        return 'Полностью оплачен'
      }
      if (code === 'canceled') {
        return 'Отменен'
      }
    },
    selectStatus(status) {
      this.isLoadingPage = true;
      this.disabledFilters = true;
      this.changeFiltersForOrdersStatus(status.key);

      this.changePageOrders(1);

      const loading = false;
      this.getOrders(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    selectClient(client) {
      this.isLoadingPage = true;
      this.disabledFilters = true;
      this.clientId = client.client_id;
      this.changeFiltersForOrdersClient(client.client_id);

      this.changePageOrders(1);

      const loading = false;
      this.getOrders(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    resetClient() {
      this.activeClient = null;
      this.isLoadingPage = true;
      this.disabledFilters = true;
      this.clientId = null;

      this.clearFilterClients();
      this.changePageFilterClients(1);
      this.changeFiltersForOrdersClient(null);

      const loading = false;
      this.getOrders(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    searchClient(search, loading) {
      this.clearFilterClients();
      this.changePageFilterClients(1);
      this.changeFiltersFilterClientsSearch(search);
      loading(true);
      this.searchClientsAction(search, loading, this);
    },
    searchClientsAction: _.debounce((search, loading, vm) => {
      vm.getFilterClients(false).then(() => {
        loading(false);
      })
    }, 1000),
    infiniteHandler($state) {
      if (this.clients.length < this.filterClientsMeta.entries.total) {
        this.getFilterClients(false)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    clickCallback() {
      this.isLoadingPage = true;

      let query = Object.assign({}, this.$route.query);
      query.page = this.page;

      this.changePageOrders(this.page)
        .then(() => {
          this.$router.push({ query }).catch(() => {});

          const loading = false;

          this.getOrders(loading)
            .finally(() => this.isLoadingPage = false);
        });
    },
    formatDate(date) {
			return moment(date).format('DD.MM.YYYY, HH:mm');
		},
    searchByClient(value) {
      this.isLoadingPage = true;

      const search = value.trim();

      this.changePageOrders(1);
      this.page = 1;

      let query = Object.assign({}, this.$route.query);
      query.page = '1';

      if (!search) {
        delete query.search;
      } else {
        query.search = search;
      }

      this.$router.push({ query }).catch(() => {});

      this.changeFiltersSearchOrders(search);

      const loading = false;

      this.getOrders(loading)
        .finally(() => this.isLoadingPage = false);
    },
    async changeDate(dateCalendar) {
      this.isLoadingPage = true;

      let date = null;

      this.changePageOrders(1);

      if (dateCalendar) {
        date = dateCalendar;
      } else {
        date = null;
      }

      this.dates = date;

      await this.changeFiltersDateForOrders(date);

      const loading = false;

      this.getOrders(loading)
        .finally(() => this.isLoadingPage = false);
    },
    resetFilters() {
      if (this.disabledFilters) {
        return;
      }

      this.isLoadingPage = true;
      this.disabledFilters = true;
      this.activeClient = null;
      this.dates = null;
      this.activeStatus = null;
      this.search = '';

      this.resetFiltersOrders();

      const loading = false;

      this.getOrders(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      delete query.client_id
      delete query.status;
      delete query.date;
      this.$router.push({ query }).catch(() => {});
    },
  }
}
</script>