import instance from '../instance';
import settings from '../settings';

export default {
  state: {
    user: null,
    token: localStorage.getItem('token') || null,
    firstPageWithoutAuthorization: null,
    isGlobalLoader: false,
    isSidebarClose: false,
    loading: {
      user: false,
      logOut: false,
      reset_request: false,
      reset_password: false,
    }
  },

  getters: {
    user: state => state.user,
    token: state => state.token,
    loadingAuth: state => state.loading,
    loadingResetRequest: state => state.loading.reset_request,
    loadingResetPassword: state => state.loading.reset_password,
    firstPageWithoutAuthorization: state => state.firstPageWithoutAuthorization,
    isGlobalLoader: state => state.isGlobalLoader,
    isSidebarClose: state => state.isSidebarClose,
  },

  actions: {
    async auth({ commit }, dataAuth) {
      commit('START_AUTH_LOADING');

      try {
        const user = await instance.post(`${settings.API_URL}/auth`, dataAuth);

        commit('SET_USER', user.data);
      } finally {
        commit('FINISH_AUTH_LOADING');
      }
    },
    async logOut({ commit }) {
      commit('START_LOGOUT_LOADING');

      try {
        await instance.delete(`${settings.API_URL}/auth`);
      } finally {
        commit('FINISH_LOGOUT_LOADING');
      }
    },
    async resetPasswordRequest({ commit }, email) {
      commit('START_RESET_REQUEST_LOADING');

      try {
        await instance.post(`${settings.API_URL}/auth/forgot-password`, {email: email});
      } finally {
        commit('FINISH_RESET_REQUEST_LOADING');
      }
    },
    async resetPassword({ commit }, dataAuth) {
      commit('START_RESET_PASSWORD_LOADING');

      try {
        const user = await instance.post(`${settings.API_URL}/auth/reset-password`, dataAuth);

        commit('SET_USER', user.data);
      } finally {
        commit('FINISH_RESET_PASSWORD_LOADING');
      }
    },
    clearToken({ commit }) {
      commit('CLEAR_TOKEN');

      commit('SET_FIRST_PAGE_WITHOUT_AUTHORIZATION', null);
    },
    setFirstPageWithoutAuthorization({ commit }, page) {
      commit('SET_FIRST_PAGE_WITHOUT_AUTHORIZATION', page);
    },
    changeGlobalLoader({ commit }) {
      commit('CHANGE_GLOBAL_LOADER');
    },
    toggleSidebarClose({ commit }) {
      commit('TOGGLE_SIDEBAR_CLOSE');
    }
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;

      state.token = user.api_token;
      localStorage.setItem('token', user.api_token);

      instance.defaults.headers['Authorization'] = `Bearer ${user.api_token}`;
    },
    START_AUTH_LOADING(state) {
      state.loading.user = true;
    },
    FINISH_AUTH_LOADING(state) {
      state.loading.user = false;
    },

    CLEAR_TOKEN(state) {
      state.token = null;

      localStorage.removeItem('token');

      instance.defaults.headers['Authorization'] = null;
    },

    START_LOGOUT_LOADING(state) {
      state.loading.logOut = true;
    },
    FINISH_LOGOUT_LOADING(state) {
      state.loading.logOut = false;
    },

    START_RESET_REQUEST_LOADING(state) {
      state.loading.reset_request = true;
    },
    FINISH_RESET_REQUEST_LOADING(state) {
      state.loading.reset_request = false;
    },

    START_RESET_PASSWORD_LOADING(state) {
      state.loading.reset_password = true;
    },
    FINISH_RESET_PASSWORD_LOADING(state) {
      state.loading.reset_password = false;
    },

    SET_FIRST_PAGE_WITHOUT_AUTHORIZATION(state, page) {
      state.firstPageWithoutAuthorization = page
    },

    CHANGE_GLOBAL_LOADER(state) {
      state.isGlobalLoader = !state.isGlobalLoader;
    },

    TOGGLE_SIDEBAR_CLOSE(state) {
      state.isSidebarClose = !state.isSidebarClose;
    }
  },
};
