<template>
  <div>
    <div
      v-click-outside="hide"
      @click.self="opened = !opened"
      class="filters-item_calendar"
    >
      <div @click.self="opened = !opened" class="filters-item_calendar-title">{{ dateFormat || placeholder }}</div>

      <div
        v-if="dateFormat && !disabled"
        @click="clearDate"
        class="ml-auto"
      >
        &#10006;
      </div>

      <div
        v-else
        @click.self="opened = !opened"
        class="filters-item_calendar-arrow"
      ></div>
    </div>

    <div v-if="opened && !disabled" ref="calendar">
      <date-picker
        v-model="date"
        range
        inline
        :shortcuts="shortcuts"
      >
        <template v-slot:footer="{ emit }">
          <div class="mb-2" style="text-align: left">
            <button class="mx-btn mx-btn-text" @click="emit([new Date(), new Date()])">Сегодня</button>
          </div>
          <div class="mb-2" style="text-align: left">
            <button class="mx-btn mx-btn-text" @click="yesterday(emit)">Вчера</button>
          </div>
          <div class="mb-2" style="text-align: left">
            <button class="mx-btn mx-btn-text" @click="last7days(emit)">Последние 7 дней</button>
          </div>
          <div class="mb-2" style="text-align: left">
            <button class="mx-btn mx-btn-text" @click="last30days(emit)">Последние 30 дней</button>
          </div>
          <div class="mb-2" style="text-align: left">
            <button class="mx-btn mx-btn-text" @click="thisMonth(emit)">Текущий месяц</button>
          </div>
          <div style="text-align: left">
            <button class="mx-btn mx-btn-text" @click="lastMonth(emit)">Прошлый месяц</button>
          </div>
        </template>
      </date-picker>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropDownMenuCalendar',

  components: {
    DatePicker
  },

  directives: {
    ClickOutside
  },

  props: {
    dateCalendar: {
      typeof: Array,
      required: false
    },
    filtersDate: {
      typeof: Boolean,
      required: false
    },
    disabled: {
      typeof: Boolean,
      required: false
    },
    placeholder: {
      typeof: String,
      default: 'Дата',
      required: false
    },
    actualDates: {
      typeof: Array,
      required: false
    }
  },

  data() {
    return {
      date: this.dateCalendar && this.dateCalendar.length ? this.dateCalendar : '' ,
      opened: false,
      shortcuts: [
        {
          text: 'Сегодня',
          onClick() {
            const date = new Date();
            return [date, date];
          },
        },
        {
          text: 'Вчера',
          onClick() {
            const date = moment().subtract(1, 'days')._d;
            return [date, date];
          },
        },
        {
          text: 'Последние 7 дней',
          onClick() {
            const from = moment().subtract(6, 'days')._d;
            return [from, new Date()];
          },
        },
        {
          text: 'Последние 30 дней',
          onClick() {
            const from = moment().subtract(29, 'days')._d;
            return [from, new Date()];
          },
        },
        {
          text: 'Текущий месяц',
          onClick() {
            const from = moment().startOf('month')._d;
            const to = moment().endOf('month')._d;
            return [from, to];
          },
        },
        {
          text: 'Прошлый месяц',
          onClick() {
            const from = moment().subtract(1, 'months').startOf('month')._d;
            const to = moment().subtract(1, 'months').endOf('month')._d;
            return [from, to];
          },
        },
      ],
    }
  },

  computed: {
    dateFormat() {
      let date = false;

      if (this.date) {
        date = this.moment(this.date[0]).format('DD.MM.YYYY') + '-' + this.moment(this.date[1]).format('DD.MM.YYYY');
      }

      return date;
    },
  },

  watch: {
    date(date) {
      this.$emit('changeDate', date);

      this.hide();
    },
    actualDates(dates) {
      if (dates[0] == null || dates[1] == null) {
        this.date = '';
        this.hide();
      }
    },
    filtersDate(filtersDate) {
      if (!filtersDate) {
        this.date = '';
      }
    }
  },

  mounted () {
    this.popupItem = this.$el
  },

  methods: {
    hide() {
      this.opened = false;
    },
    clearDate() {
      this.date = '';

      this.hide();
    },
    yesterday(emit) {
      const date = this.moment().subtract(1, 'days')._d;
      const range = [date, date];
      emit(range);
    },
    last7days(emit) {
      const from = this.moment().subtract(6, 'days')._d;
      const range = [from, new Date()];
      emit(range);
    },
    last30days(emit) {
      const from = this.moment().subtract(29, 'days')._d;
      const range = [from, new Date()];
      emit(range);
    },
    thisMonth(emit) {
      const from = this.moment().startOf('month')._d;
      const to = this.moment().endOf('month')._d;
      const range = [from, to];
      emit(range);
    },
    lastMonth(emit) {
      const from = this.moment().subtract(1, 'months').startOf('month')._d;
      const to = this.moment().subtract(1, 'months').endOf('month')._d;
      const range = [from, to];
      emit(range);
    },
    moment
  }
}
</script>

<style>
.filters-item_calendar-title {
  white-space: nowrap;
}

.mx-datepicker-sidebar {
  width: auto;
}

.mx-datepicker-sidebar + .mx-datepicker-content {
  margin-left: 150px;
}

.mx-datepicker-footer {
  display: none;
}

@media screen and (max-width: 990px) {
  .mx-datepicker-sidebar {
    display: none;
  }

  .mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 0;
    border-left: none;
  }

  .mx-datepicker-footer {
    display: block;
  }
}
</style>