<template>
  <div class="school-cabinet_info-table-item d-flex flex-wrap flex-md-nowrap" >
    <div class="school-cabinet_info-table-value school-cabinet_info-table-value--1 mb-3 mb-md-0">
      <div v-if="lesson.planned_at" class="school-cabinet_info-table-date">{{ moment(lesson.planned_at).format('DD.MM.YYYY, HH:mm') }}</div>
      <div class="school-cabinet_info-table-text">{{ lesson.client.name.full }}</div>
    </div>

    <div class="school-cabinet_info-table-value school-cabinet_info-table-value--2 mr-3">
      <div class="school-cabinet_info-table-title d-md-none">Занятие</div>
      <div class="school-cabinet_info-table-text">{{ lesson.tariff.title }}</div>
    </div>

    <div class="school-cabinet_info-table-value">
      <div class="school-cabinet_info-table-title d-md-none">Статус</div>
      <div class="school-cabinet_info-table-text">{{ checkStatus(lesson.status) }}</div>
    </div>

    <div v-if="lesson.status === 'planned' && profile.user.permission.instructor.deleteLessonFromShedule" class="school-cabinet_info-table-value school-cabinet_info-table-value--4 ml-auto mt-3 mt-md-0">
      <div class="school-cabinet_info-table-text text-right">
        <AppLoader2 v-if="isDeletingLesson" :mini="true"  class="justify-content-end" />

        <a v-else @click="deleteConfirmation" href="javascript:void(0)">Убрать из графика</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import AppLoader2 from '@/components/AppLoader/2.vue';

import checkStatus from '@/helpers/checkStatus.js';
import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'InstructorLessonCard',

  components: {
    AppLoader2
  },

  props: {
    lesson: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isDeletingLesson: false
    }
  },

  computed: {
    ...mapGetters(['profile'])
  },

  methods: {
    ...mapActions(['deleteLessonFromShedule']),
    deleteConfirmation() {
      this.$modal.show('dialog', {
        title: 'Убрать из графика',
        text: 'Вы действительно хотите убрать занятие?',
        buttons: [
          {
            title: 'Да',
            handler: () => {
              this.deletingLesson();
              this.$modal.hide('dialog');
            },
          },
          {
            title: 'Нет',
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    deletingLesson() {
      this.isDeletingLesson = true;

      const payload = {
        idInstructor: this.$route.params.id,
        idLesson: this.lesson.lesson_id
      }

      this.deleteLessonFromShedule(payload)
        .then(() => {
          this.$toast.open({
            message: 'Занятие удалено из графика',
            type: 'success'
          });
        })
        .catch((error) => {
          this.errorHandlerForResponse(error);
        })
        .finally(() => {
          this.isDeletingLesson = false;
        });
    },
    moment,
    checkStatus,
    errorHandlerForResponse
  }
}
</script>