<template>
  <div class="school-cabinet__lessons-card d-flex align-items-start flex-wrap flex-md-nowrap pl-0 pr-0 mt-5 pt-0 pb-0">
    <div class="school-cabinet_card-content d-flex">
      <div class="school-cabinet_card-text mt-1">
        <div class="school-cabinet_card-date mb-2">{{ moment(note.created_at).format('DD.MM.YYYY') }}</div>
        <div class="school-cabinet_card-name">{{ note.user.name.full }}</div>
      </div>
    </div>

    <div class="school-cabinet__lessons-card-content mt-3 mt-md-1">
      <div class="school-cabinet_card-info-item">
        <div v-if="note.remind_at" class="school-cabinet_card-info-text mb-1">
          <span :class="{ 'color-red': note.remind_today }">Напоминание: {{ moment(note.remind_at).format('DD.MM.YYYY') }}</span>
        </div>

        <div class="school-cabinet_card-info-title white-space">{{ note.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'InstructorNotesCard',

  props: {
    note: {
      type: Object,
      required: true
    }
  },

  methods: {
    moment,
  }
}
</script>

<style>
.white-space {
  white-space: break-spaces;
}
</style>