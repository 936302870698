import instance from '../instance';
import settings from '../settings';

export default {
  state: {
    lessonsGroupedByClients: null,
    clientInstructors: {
      instructors: [],
      meta: {},
      client: {},
    },
    filtersForlessonsGroupedByClients: {
      page: 1,
      page_size: 20,
      instructor_id: null,
      assigned: null,
      query: ''
    },
    filtersClientInstructors: {
      page: 1,
      page_size: 10,
      query: ''
    },
    loading: {
      lessonsGroupedByClients: true,
      clientInstructors: true,
      createLessons: false,
      changeLessons: false,
      createPackage: false
    }
  },

  getters: {
    assignmentLessonsGroupedByClients: state => state.lessonsGroupedByClients,
    pageLessonsGroupedByClients: state => state.filtersForlessonsGroupedByClients.page,
    assignmentLessonsGroupedByClientsFiltersInstructor: state => Boolean(state.filtersForlessonsGroupedByClients.instructor_id),
    assignmentLessonsGroupedByClientsFiltersAssigned: state => state.filtersForlessonsGroupedByClients.assigned,
    assignmentLessonsGroupedByClientsFiltersApplied: state =>
      Boolean(state.filtersForlessonsGroupedByClients.instructor_id)
      || Boolean(state.filtersForlessonsGroupedByClients.assigned),
    assignmentLessonsGroupedByClientsFilters: state => state.filtersForlessonsGroupedByClients,
    assignmentClientInstructorsAllData: state => state.clientInstructorsAllData,
    assignmentClientInstructors: state => state.clientInstructors,
    loadingAssignment: state => state.loading,
  },

  actions: {
    async getLessonsGroupedByClients({ commit, state }, loading = true) {
      if (loading) {
        commit('START_GET_LESSONS_GROUPED_BY_CLIENTS_LOADING');
      }

      try {
        const requestId = 'lessons';

        const params = Object.fromEntries(Object.entries(state.filtersForlessonsGroupedByClients).filter(([key, value]) => key && value));

        const lessons = await instance.get(`${settings.API_URL}/lessons/clients`, { requestId, params });

        commit('SET_LESSONS_GROUPED_BY_CLIENTS', lessons.data);
      } finally {
        commit('FINISH_GET_LESSONS_GROUPED_BY_CLIENTS_LOADING');
      }
    },
    clearLessonsGroupedByClients({ commit }) {
      commit('CLEAR_LESSONS_GROUPED_BY_CLIENTS');
    },
    changePageLessonsGroupedByClients({ commit }, page) {
      commit('CHANGE_PAGE_LESSONS_GROUPED_BY_CLIENTS', page);
    },
    changeFiltersInstructorIdLessonsGroupedByClients({ commit }, id) {
      commit('CHANGE_FILTERS_INSTRUCTOR_ID_LESSONS_GROUPED_BY_CLIENTS', id);
    },
    changeFiltersSearchLessonsGroupedByClients({ commit }, search) {
      commit('CHANGE_FILTERS_SEARCH_LESSONS_GROUPED_BY_CLIENTS', search);
    },
    changeFiltersAssignedLessonsGroupedByClients({ commit }, assigned) {
      commit('CHANGE_FILTERS_ASSIGNED_LESSONS_GROUPED_BY_CLIENTS', assigned);
    },
    resetFiltersLessonsGroupedByClients({ commit }) {
      commit('CHANGE_PAGE_LESSONS_GROUPED_BY_CLIENTS', 1);

      commit('CHANGE_FILTERS_INSTRUCTOR_ID_LESSONS_GROUPED_BY_CLIENTS', null);

      commit('CHANGE_FILTERS_ASSIGNED_LESSONS_GROUPED_BY_CLIENTS', null);
    },
    setLoadingLessonsGroupedByClients({ commit }) {
      commit('SET_LOADING_LESSONS_GROUPED_BY_CLIENTS');
    },
    async getСlientInstructors({ commit, state }, payload) {
      if (payload.loading) {
        commit('START_GET_CLIENT_INSTRUCTORS_LOADING');
      }

      try {
        const requestId = 'clientInstructors';
        const params = Object.fromEntries(Object.entries(state.filtersClientInstructors).filter(([key, value]) => key && value));

        const clientInstructors = await instance.get(`${settings.API_URL}/instructors`, { requestId, params });

        commit('SET_CLIENT_INSTRUCTORS', clientInstructors.data);
      } finally {
        commit('FINISH_GET_CLIENT_INSTRUCTORS_LOADING');
      }
    },
    clearСlientInstructors({ commit }) {
      commit('CLEAR_CLIENT_INSTRUCTORS');
    },
    changeFiltersSearchСlientInstructors({ commit }, search) {
      commit('CHANGE_FILTERS_SEARCH_CLIENT_INSTRUCTORS', search);
    },
    resetPageСlientInstructors({ commit }) {
      commit('RESET_PAGE_CLIENT_INSTRUCTORS');
    },
    async createLessons({ commit }, lessons) {
      commit('START_CREATE_LESSONS_LOADING');

      try {
        await instance.put(`${settings.API_URL}/lessons/batch`, lessons);
      } finally {
        commit('FINISH_CREATE_LESSONS_LOADING');
      }
    },
    async changeLessons({ commit }, lessons) {
      commit('START_CHANGE_LESSONS_LOADING');

      try {
        await instance.put(`${settings.API_URL}/lessons/batch`, lessons);
      } finally {
        commit('FINISH_CHANGE_LESSONS_LOADING');
      }
    },
    async createPackage({ commit }, packageParent) {
      commit('START_CREATE_PACKAGE_LOADING');

      try {
        await instance.post(`${settings.API_URL}/lessons/packages`, packageParent);
      } finally {
        commit('FINISH_CREATE_PACKAGE_LOADING');
      }
    },
    async deleteLessons({ commit }, lessons) {
      await instance.delete(`${settings.API_URL}/lessons/batch`, { data: lessons} );

      commit('DELETE_LESSONS', lessons.lessons);
    },
    addInstructorInListClientInstructors({ commit }, instructor) {
      commit('ADD_INSTRUCTOR_IN_LIST_CLIENT_INSTRUCTORS', instructor)
    }
  },

  mutations: {
    SET_LESSONS_GROUPED_BY_CLIENTS(state, lessons) {
      state.lessonsGroupedByClients = lessons;
    },
    CLEAR_LESSONS_GROUPED_BY_CLIENTS(state) {
      state.lessonsGroupedByClients = null;
    },
    CHANGE_PAGE_LESSONS_GROUPED_BY_CLIENTS(state, page) {
      state.filtersForlessonsGroupedByClients.page = page;
    },
    CHANGE_FILTERS_INSTRUCTOR_ID_LESSONS_GROUPED_BY_CLIENTS(state, id) {
      state.filtersForlessonsGroupedByClients.instructor_id = id;
    },
    CHANGE_FILTERS_SEARCH_LESSONS_GROUPED_BY_CLIENTS(state, search) {
      state.filtersForlessonsGroupedByClients.query = search;
    },
    CHANGE_FILTERS_ASSIGNED_LESSONS_GROUPED_BY_CLIENTS(state, assigned) {
      state.filtersForlessonsGroupedByClients.assigned = assigned;
    },
    SET_LOADING_LESSONS_GROUPED_BY_CLIENTS(state) {
      state.loading.lessonsGroupedByClients = true;
    },
    START_GET_LESSONS_GROUPED_BY_CLIENTS_LOADING(state) {
      state.loading.lessonsGroupedByClients = true;
    },
    FINISH_GET_LESSONS_GROUPED_BY_CLIENTS_LOADING(state) {
      state.loading.lessonsGroupedByClients = false;
    },

    SET_CLIENT_INSTRUCTORS(state, clientInstructors) {
      state.clientInstructors.client = clientInstructors.client;
      state.clientInstructors.meta = clientInstructors.meta;
      state.clientInstructors.instructors.push(...clientInstructors.instructors);
      state.filtersClientInstructors.page++;
    },
    START_GET_CLIENT_INSTRUCTORS_LOADING(state) {
      state.loading.clientInstructors = true;
    },
    FINISH_GET_CLIENT_INSTRUCTORS_LOADING(state) {
      state.loading.clientInstructors = false;
    },
    CLEAR_CLIENT_INSTRUCTORS(state) {
      state.clientInstructors = {
        instructors: [],
        meta: {},
        client: {},
      }
    },
    ADD_INSTRUCTOR_IN_LIST_CLIENT_INSTRUCTORS(state, instructor) {
      if (!state.clientInstructors.instructors.find(el => el.instructor_id === instructor.instructor_id)) {
        state.clientInstructors.instructors.push(instructor);
      }
    },
    CHANGE_FILTERS_SEARCH_CLIENT_INSTRUCTORS(state, search) {
      state.filtersClientInstructors.page = 1;
      state.clientInstructors.instructors = [];
      state.filtersClientInstructors.query = search;
    },
    RESET_PAGE_CLIENT_INSTRUCTORS(state) {
      state.filtersClientInstructors.page = 1;
    },

    START_CREATE_LESSONS_LOADING(state) {
      state.loading.createLessons = true;
    },
    FINISH_CREATE_LESSONS_LOADING(state) {
      state.loading.createLessons = false;
    },

    START_CHANGE_LESSONS_LOADING(state) {
      state.loading.changeLessons = true;
    },
    FINISH_CHANGE_LESSONS_LOADING(state) {
      state.loading.changeLessons = false;
    },

    START_CREATE_PACKAGE_LOADING(state) {
      state.loading.createPackage = true;
    },
    FINISH_CREATE_PACKAGE_LOADING(state) {
      state.loading.createPackage = false;
    },

    DELETE_LESSONS(state, lessons) {
      state.lessonsGroupedByClients.clients_lessons.forEach(client => {
        if (client.client.client_id === lessons.client_id) {
          client.statistics.total = client.statistics.total - lessons.qty;

          client.lessons.forEach(lesson => {
            if (lesson.tariff.tariff_id === lessons.tariff_id) {

              lesson.statistics.total = lesson.statistics.total - lessons.qty;
            }
          })
        }
      })
    }
  },
};