<template>
  <div class="account-content">
    <div class="settings-top d-md-flex align-items-end">
      <GoBack />
    </div>

    <div class="d-flex">
      <div class="account-subtitle account-subtitle--large pt-0 mb-3">
        <span class="light">Редактировать группу</span>
      </div>
    </div>

    <AppLoader v-if="loadingGetItem" />

    <template v-else>
      <div class="school-cabinet">
        <div class="school-cabinet_white-box">
          <div class="school-cabinet_info-field form-group mb-4">
            <input
              v-model="payload.name"
              @keyup.enter="groupEdit"
              type="text"
              placeholder="Название группы"
              class="form-control form-control--bordered"
            >
          </div>

          <button @click="groupEdit" class="btn btn-blue">
            <template v-if="loadingEditGroup">Редактируем...</template>
            <template v-else>Редактировать</template>
          </button>
        </div>
      </div>

      <div class="school-cabinet_info-delete mt-5">
        <a @click="deleteConfirmation" href="javascript:void(0)">
          <template v-if="loadingRemoveItem">Удаляем...</template>
          <template v-else>Удалить группу</template>
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import GoBack from '@/components/GoBack.vue';
import AppLoader from '@/components/AppLoader/1.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'StudyGroupsEdit',

  components: {
    GoBack,
    AppLoader
  },

  data() {
    return {
      loadingEditGroup: false,
      loadingGetItem: true,
      loadingRemoveItem: false,

      payload: {
        name: '',
      }
    }
  },

  computed: {
    ...mapGetters('studyGroups', {
      group: 'item',
    }),
  },

  async created() {
    try {
      this.clearGroup();

      await this.getGroup(this.$route.params.id);

      this.payload.name = this.group.name;
    } catch (e) {
      this.errorHandlerForResponse(e)
    } finally {
      this.loadingGetItem = false;
    }
  },

  methods: {
    ...mapActions('studyGroups', {
      getGroup: 'getItem',
      clearGroup: 'clearItem',
      editGroup: 'edit',
      removeGroup: 'remove',
    }),
    groupEdit() {
      if (this.loadingEditGroup) {
        return;
      }

      this.loadingEditGroup = true;

      const payload = {
        groupId: this.$route.params.id,
        payload: this.payload
      }

      this.editGroup(payload)
        .then(() => {
          this.$toast.success('Название группы обновлено');
        })
        .catch((e) => {
          this.errorHandlerForResponse(e)
        })
        .finally(() => {
          this.loadingEditGroup = false
        });
    },
    deleteConfirmation() {
      if (this.loadingRemoveItem) {
        return;
      }

      this.$modal.show('dialog', {
        text: 'Вы действительно хотите расформировать и удалить группу?',
        buttons: [
          {
            title: 'Да',
            handler: () => {
              this.loadingRemoveItem = true;

              this.removeGroup(this.$route.params.id)
                .then(() => {
                  this.$router.push('/study-groups');
                  this.$toast.success('Группа удалена');
                })
                .catch((e) => {
                  this.errorHandlerForResponse(e)
                })
                .finally(() => this.loadingRemoveItem = false);
              this.$modal.hide('dialog');
            },
          },
          {
            title: 'Нет',
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    errorHandlerForResponse
  }
}
</script>