<template>
  <div class="school-cabinet_card school-cabinet_card--small">
    <div class="school-cabinet_card-top d-flex align-items-start align-items-md-center">
      <router-link
        :to="`/clients/${client.client_id}`"
        class="school-cabinet_card-content d-flex align-items-center"
      >
        <div class="school-cabinet_card-icon" style="width: 48px; height: 48px">
          <img v-if="client.avatar" :src="client.avatar.url" :alt="client.name.full">
          <img v-else src="@/assets/img/noavatar.svg" :alt="client.name.full">
        </div>
        <div class="school-cabinet_card-text mr-3">
          <div class="school-cabinet_card-name">{{ client.name.full }} {{ client.name.middle }} <span class="school-cabinet_card-status" v-if="client.reminder && client.reminder.today"></span></div>
          <div class="school-cabinet_card-facts school-cabinet_card-facts--fix-width">{{ client.email }}<template v-if="client.phone">,</template> <span v-if="client.phone" class="white-space-nowrap">{{ client.phone }}</span></div>
        </div>
      </router-link>
      
      <div class="cursor-pointer school-cabinet_card-button school-cabinet_card-button--lessons d-flex flex-column flex-md-row justify-content-center align-items-center ml-auto margin-rigth" @click="$emit('openDoc')" v-if="client.document_status !== 'was_not' && client.document_status !== 'nothing_completed'">
        <div class="school-cabinet_card-documents"></div>
      </div>
      <div v-if="client.statistics && profile.user.permission.assignment.view" class="cursor-pointer school-cabinet_card-button school-cabinet_card-button--lessons d-flex flex-column flex-md-row justify-content-center align-items-center mr-0" @click="$emit('open')" :class="{'ml-auto': client.document_status == 'was_not' || client.document_status == 'nothing_completed'}">
        <div class="school-cabinet_card-button-icon"></div>
        <div class="school-cabinet_card-button-title">{{client.statistics.lessons?.total}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ClientCard',

  props: {
    client: {
      typeof: Object,
      required: true
    }
  },

  data() {
    return {
      isLoaderDeletingClient: false
    }
  },

  computed: {
    ...mapGetters(['profile'])
  },
}
</script>

<style>
@media screen and (max-width: 767px) {
  .school-cabinet_card-button--lessons {
    width: 30px !important;
  }
  .school-cabinet_card-documents {
    width: 20px !important;
    min-width: 20px !important;
    height: 20px !important;
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (max-width: 468px) {
  .school-cabinet_card-facts--fix-width {
    max-width: 200px;
  }
  .school-cabinet_card-documents {
    width: 20px !important;
    min-width: 20px !important;
    height: 20px !important;
  }
  .margin-rigth {
    margin-right: 5px;
  }
}
.school-cabinet_card-documents {
  background-color: #F3F3F3;
  border-radius: 34px;
  display: flex;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPg0KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik00IDZWMTlDNCAyMC42NTY5IDUuMzQzMTUgMjIgNyAyMkgxN0MxOC42NTY5IDIyIDIwIDIwLjY1NjkgMjAgMTlWOUMyMCA3LjM0MzE1IDE4LjY1NjkgNiAxNyA2SDRaTTQgNlY1IiBzdHJva2U9IiMxQzI3NEQiIHN0cm9rZS13aWR0aD0iMS41Ii8+DQo8cGF0aCBkPSJNMTggNi4wMDAwMlY2Ljc1MDAySDE4Ljc1VjYuMDAwMDJIMThaTTE1LjcxNzIgMi4zMjYxNEwxNS42MTExIDEuNTgzNjhMMTUuNzE3MiAyLjMyNjE0Wk00LjkxOTU5IDMuODY4NjVMNC44MTM1MyAzLjEyNjE5SDQuODEzNTNMNC45MTk1OSAzLjg2ODY1Wk01LjA3MTA3IDYuNzUwMDJIMThWNS4yNTAwMkg1LjA3MTA3VjYuNzUwMDJaTTE4Ljc1IDYuMDAwMDJWNC4zMDYwNEgxNy4yNVY2LjAwMDAySDE4Ljc1Wk0xNS42MTExIDEuNTgzNjhMNC44MTM1MyAzLjEyNjE5TDUuMDI1NjYgNC42MTExMUwxNS44MjMyIDMuMDY4NkwxNS42MTExIDEuNTgzNjhaTTQuODEzNTMgMy4xMjYxOUMzLjkxNjM4IDMuMjU0MzUgMy4yNSA0LjAyMjcgMy4yNSA0LjkyODk1SDQuNzVDNC43NSA0Ljc2OTE3IDQuODY3NDkgNC42MzM3MSA1LjAyNTY2IDQuNjExMTFMNC44MTM1MyAzLjEyNjE5Wk0xOC43NSA0LjMwNjA0QzE4Ljc1IDIuNjMyNTMgMTcuMjY3OCAxLjM0NzAxIDE1LjYxMTEgMS41ODM2OEwxNS44MjMyIDMuMDY4NkMxNi41NzYzIDIuOTYxMDMgMTcuMjUgMy41NDUzNSAxNy4yNSA0LjMwNjA0SDE4Ljc1Wk01LjA3MTA3IDUuMjUwMDJDNC44OTM3NSA1LjI1MDAyIDQuNzUgNS4xMDYyNyA0Ljc1IDQuOTI4OTVIMy4yNUMzLjI1IDUuOTM0NyA0LjA2NTMyIDYuNzUwMDIgNS4wNzEwNyA2Ljc1MDAyVjUuMjUwMDJaIiBmaWxsPSIjMUMyNzREIi8+DQo8cGF0aCBkPSJNOCAxMkgxNiIgc3Ryb2tlPSIjMUMyNzREIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+DQo8cGF0aCBkPSJNOCAxNS41SDEzLjUiIHN0cm9rZT0iIzFDMjc0RCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPg0KPC9zdmc+);
  width: 25px;
  min-width: 25px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
}
.margin-rigth {
  margin-right: 10px;
}
</style>
