<template>
  <div class="account-content">
    <div class="settings">
      <div class="settings-top d-md-flex align-items-end mb-4">
        <div class="settings-head d-flex align-items-center">
          <div class="settings-title">Администраторы</div>

          <router-link to="/administrators/create" class="settings-head-icon"></router-link>
        </div>

        <div class="settings-search ml-auto d-flex mt-4 mt-md-0">
          <input
            v-model.trim="search"
            v-debounce:1000ms="searchAdministrator"
            type="text"
            class="form-control form-control--search pl-2 pl-md-2"
            placeholder="Поиск по администраторам"
          >

          <button type="button"></button>
        </div>
      </div>

      <AppLoader v-if="loading.administrators" />

      <div v-else class="school-cabinet wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
        <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />

        <template v-if="administrators.users.length">
          <AdministratorCard
            v-for="administrator in administrators.users"
            :key="administrator.user_id"
            :administrator="administrator"
            :permissions-list="permissions"
          />

          <div v-if="administrators.meta.page.total > 1" class="d-flex justify-content-center">
            <paginate
              v-model="page"
              :pageCount="administrators.meta.page.total"
              :clickHandler="clickCallback"
              :prevText="''"
              :nextText="''"
              :containerClass="'pagination'"
              :pageClass="'page-item'"
              :pageLinkClass="'page-link'"
              :prevClass="'page-item'"
              :prevLinkClass="'page-link'"
              :nextClass="'page-item'"
              :nextLinkClass="'page-link'"
              :activeClass="'active'"
            />
          </div>
        </template>

        <p v-else>Администраторов нет</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Paginate from 'vuejs-paginate';

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';
import AdministratorCard from '@/components/AdministratorCard.vue';

export default {
  name: 'AppAdministrators',

  components: {
    AppLoader,
    Paginate,
    AppLoader3,
    AdministratorCard
  },

  data() {
    return {
      page: 1,
      isLoadingPage: false,
      search: ''
    }
  },

  computed: {
    ...mapGetters({
      administrators: 'administrators',
      pageAdministrators: 'pageAdministrators',
      permissions: 'permissionsList',
      loading: 'loadingAdministrators',
      filtersAdministrators: 'filtersAdministrators'
    }),
  },

  async created() {
    this.clearAdministrators();
    this.getPermissionsList();

    if (this.$route.query.page) {
      this.changePageAdministrators(Number(this.$route.query.page));
    }

    if (this.$route.query.search) {
      this.changeFiltersSearchAdministrators(this.$route.query.search);

      this.search = this.$route.query.search;
    }

    if (this.$route.params.page === 1) {
      this.resetFiltersAdministrators();
    }

    this.page = this.pageAdministrators;

    let query = Object.assign({}, this.$route.query);
    query.page = this.pageAdministrators;

    if (this.filtersAdministrators.query) {
      query.search = this.filtersAdministrators.query;

      this.search = this.filtersAdministrators.query;
    }

    this.$router.push({ query }).catch(() => {});

    await this.getAdministrators();

    if (this.administrators && this.pageAdministrators > this.administrators.meta.page.total) {
      await this.changePageAdministrators(this.administrators.meta.page.total);

      this.page = this.pageAdministrators;

      let query = Object.assign({}, this.$route.query);
      query.page = this.pageAdministrators;
      this.$router.push({ query }).catch(() => {});

      this.getAdministrators();
    }
  },

  methods: {
    ...mapActions([
      'getAdministrators',
      'clearAdministrators',
      'changePageAdministrators',
      'changeFiltersSearchAdministrators',
      'resetFiltersAdministrators',
      'getPermissionsList',
    ]),
    clickCallback() {
      this.isLoadingPage = true;

      let query = Object.assign({}, this.$route.query);
      query.page = this.page;

      this.changePageAdministrators(this.page)
        .then(() => {
          this.$router.push({ query }).catch(() => {});

          const loading = false;

          this.getAdministrators(loading)
            .finally(() => this.isLoadingPage = false);
        });
    },
    searchAdministrator(value) {
      this.isLoadingPage = true;

      const search = value.trim();

      this.changePageAdministrators(1);
      this.page = 1;

      let query = Object.assign({}, this.$route.query);
      query.page = '1';

      if (!search) {
        delete query.search;
      } else {
        query.search = search;
      }

      this.$router.push({ query }).catch(() => {});

      this.changeFiltersSearchAdministrators(search);

      const loading = false;

      this.getAdministrators(loading)
        .finally(() => this.isLoadingPage = false);
    },
  }
}
</script>