<template>
  <div class="drop-down-menu">
    <div
      v-click-outside="hide"
      @click="opened = !opened"
      :class="{ 'border-bottom-none': opened && !disabled}"
      class="form-control form-control--bordered text-overflow"
    >
      {{ selected || placeholder }}

      <span
        :class="{ rotate: opened && !disabled }"
        class="form-control--arrow"
      ></span>
    </div>

    <ul v-if="opened && !disabled" class="form-control--custom form-control--max-height">
      <li v-if="selected" @click="reset">Все</li>

      <li
        v-for="(type, name, index) in types"
        :key="index"
        @click="select(type, name)"
        class="text-overflow"
      >
        {{ type }}
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropDownMenuType',

  props: {
    filters: {
      typeof: Boolean,
      required: false
    },
    disabled: {
      typeof: Boolean,
      required: false
    },
    types: {
      typeof: Object,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Тип'
    }
  },

  directives: {
    ClickOutside
  },

  data() {
    return {
      opened: false,
      selected: ''
    }
  },

  watch: {
    filters(filters) {
      if (!filters) {
        this.selected = '';
      }
    }
  },

  created() {
    if (this.filters) {
      this.selected = this.types[this.filters];
    }
  },

  mounted () {
    this.popupItem = this.$el
  },

  methods: {
    hide() {
      this.opened = false;
    },
    select(type, name) {
      this.selected = type;

      this.hide();

      this.$emit('select', name);
    },
    reset() {
      this.selected = '';

      this.hide();

      this.$emit('select', 'reset');
    }
  }
}
</script>

<style>
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-control--max-height {
  max-height: 300px;
  overflow-y: auto;
}
</style>
