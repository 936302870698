<template>
  <div
    v-click-outside="hide"
    class="settings__note-calendar"
  >
    <date-picker
      v-model="date"
      :format="'DD.MM.YYYY'"
      :placeholder="'Напоминание'"
      :editable="false"
      :inputClass="'form-control form-control--bordered'"
      :disabledDate="notBeforeToday"
      class="w-100"
    >
      <template #icon-calendar>
        <i class="fas fa-calendar-alt"></i>
      </template>
    </date-picker>
  </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropDownMenuCalendarNotes',

  components: {
    DatePicker
  },

  directives: {
    ClickOutside
  },

  props: {
    noteAdded: {
      typeof: Boolean,
      required: true
    }
  },

  data() {
    return {
      date: '' ,
      opened: false,
    }
  },

  watch: {
    date(date) {
      if (date) {
        this.$emit('changeDate', this.moment(date).format('YYYY-MM-DD'));
      } else {
        this.$emit('changeDate', null)
      }

      this.hide();
    },
    noteAdded(added) {
      if (added) {
        this.date = '';
      }
    }
  },

  mounted () {
    this.popupItem = this.$el
  },

  methods: {
    hide() {
      this.opened = false;
    },
    notBeforeToday(date) {
      return date < new Date().setHours(0, 0, 0, 0);
    },
    moment
  }
}
</script>

<style>
.settings__note-calendar .mx-datepicker.w-100 {
  width: 100%;
}
</style>