<template>
  <div class="school-cabinet_card school-cabinet_card--small mb-2">
    <div class="school-cabinet_card-top d-flex align-items-center flex-wrap">
      <div class="school-cabinet_card-content school-cabinet_card-content--width-2 d-flex align-items-center mb-3 mb-xl-0">
        <div class="school-cabinet_card-icon" style="width: 48px; height: 48px">
          <img v-if="administrator.avatar" :src="administrator.avatar.url" :alt="administrator.name.full">
          <img v-else src="@/assets/img/noavatar.svg" :alt="administrator.name.full">
        </div>

        <div class="school-cabinet_card-text">
          <div class="school-cabinet_card-name text-decoration-none">{{ administrator.name.full }}</div>
          <div class="school-cabinet_card-facts">{{ administrator.email }}</div>
        </div>
      </div>

      <div class="school-cabinet_card-info max-width--340">
        <div class="school-cabinet_card-info-item mr-0 pr-1">
          <div class="school-cabinet_card-info-title mb-2 mb-md-0"><strong>Разрешения</strong></div>
          <div class="school-cabinet_card-info-text">
            <span
              v-for="(permission, index) in administrator.permissions"
              :key="index"
              class="light"
            >
              {{ getPermissionLabel(permission) }}<template v-if="index !== permissions(administrator.permissions).length - 1">,</template>
            </span>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center ml-auto mt-3 mt-md-0 mb-1 mb-md-0">
        <router-link :to="`/administrators/${administrator.user_id}/edit`" class="school-cabinet_card-edit ml-4"></router-link>

        <div v-if="profile.user.user_id !== administrator.user_id" class="ml-3 ml-md-4 width-23">
          <AppLoader2
            v-if="isLoaderDeletingAdministrator"
            :mini="true"
          />
          <div
            v-else
            @click="deleteConfirmation"
            class="school-cabinet_card-delete"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AppLoader2 from '@/components/AppLoader/2.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'AdministratorCard',

  components: {
    AppLoader2
  },

  props: {
    administrator: {
      typeof: Object,
      required: true
    },
    permissionsList: {
      typeof: Object,
      required: true
    }
  },

  data() {
    return {
      isLoaderDeletingAdministrator: false
    }
  },

  computed: {
    ...mapGetters(['profile'])
  },

  methods: {
    ...mapActions(['deleteAdministrator']),
    deleteConfirmation() {
      this.$modal.show('dialog', {
        text: 'Вы действительно хотите удалить администратора?',
        buttons: [
          {
            title: 'Да',
            handler: () => {
              this.delAdministrator();
              this.$modal.hide('dialog');
            },
          },
          {
            title: 'Нет',
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    delAdministrator() {
      this.isLoaderDeletingAdministrator = true;

      this.deleteAdministrator(this.administrator.user_id)
        .then(() => {
          this.$toast.open({
            message: 'Администратор удален',
            type: 'success'
          });
        })
        .catch((error) => {
          this.errorHandlerForResponse(error);
        })
        .finally(() => this.isLoaderDeletingAdministrator = false)
    },
    permissions(array) {
      const newArray = [];

      array.forEach(el => {
        if(el === 'instructor') newArray.push('Инструкторы');
        if(el === 'client') newArray.push('Клиенты');
        if(el === 'assignment') newArray.push('Распределение');
        if(el === 'analytics') newArray.push('Аналитика');
        if(el === 'user') newArray.push('Администратор');
        if(el === 'user') newArray.push('Администратор');
      });

      return newArray
    },
    getPermissionLabel(permission) {
      return this.permissionsList[permission];
    },
    errorHandlerForResponse
  }
}
</script>

<style>
.school-cabinet_card-info.max-width--340 {
  max-width: 340px;
}
</style>