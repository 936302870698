<template>
  <div class="public-section public-section--white">
    <div class="container">
      <img src="@/assets/img/warning.svg" alt="" class="not-found__img">
      <h1 class="public-title text-center text-md-left">Страница не найдена</h1>

      <div class="public-descr public-mt-40 text-center text-md-left mb-3">Мы не можем найти нужную вам страницу.</div>

      <div class="text-center text-md-left">
        <router-link to="/" class="to-main">Перейти на главную</router-link>
      </div>

      <div class="public-descr public-mt-30 text-center text-md-left">Код ошибки: 404.</div>
      <!-- <div class="public-descr public-mt-30 text-center text-md-left">
        <a href="javascript:void(0)" class="not-found__link public-descr">Главная страница</a>
        <a href="javascript:void(0)" class="not-found__link public-descr">Инструкторы</a>
        <a href="javascript:void(0)" class="not-found__link public-descr">Заявки на обучение</a>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style>
.to-main {
  font-weight: 700;
  font-size: 18px;
}
</style>