export default {
  state: {
    errorsGlobal: false,
  },

  getters: {
    errorsGlobal: state => state.errorsGlobal,
  },

  actions: {
    setGlobalErrors({ commit }) {
      commit('SET_GLOBAL_ERRORS');
    }
  },

  mutations: {
    SET_GLOBAL_ERRORS(state) {
      state.errorsGlobal = false;
    }
  },
};