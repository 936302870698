<template>
  <div class="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</template>

<script>
export default {
  name: 'AppLoader3'
}
</script>

<style>
.spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
  z-index: 10;
}

.spinner div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #0033cc;
  animation: spinner 1.2s linear infinite;
}

.spinner div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}

.spinner div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}

.spinner div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}

.spinner div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}

.spinner div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}

.spinner div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}

.spinner div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}

.spinner div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}

.spinner div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}

@keyframes spinner {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

</style>