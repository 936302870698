<template>
  <div
    @click.self="$emit('hideModal')"
    :class="{ active: isModalTransferClients }"
    class="modal__wrapper"
  >
    <div class="modal__window modal__window--show-ease">
      <div @click="$emit('hideModal')" class="modal__close"></div>

      <div class="modal__title">Перевод клиента из группы <span class="underline">{{ group.name }}</span></div>

      <router-link :to="`/clients/${client.client_id}`" class="school-cabinet_card-content d-flex align-items-center">
        <div class="school-cabinet_card-icon">
          <img v-if="client.avatar" :src="client.avatar.url" alt="Аватар">
          <img v-else src="@/assets/img/noavatar.svg" alt="Аватар">
        </div>
        <div class="school-cabinet_card-text">
          <div class="school-cabinet_card-name">{{ client.name.full }}</div>
          <div class="school-cabinet_card-facts">{{ client.email }}<template v-if="client.email && client.phone">, <br class="mob-only"></template>{{ client.phone }}</div>
        </div>
      </router-link>

      <div class="filters d-flex justify-content-between mb-0 flex-wrap flex-md-nowrap">
        <div class="filters-item filters-item--large mb-2 mb-md-0">
          <div ref="groups" class="filters-select">
            <div class="filters-select-wrapper">
              <div
                v-if="!isGroups"
                @click.stop="openGroups"
                class="filters-select-title"
              >
                Перевести клиента в группу
              </div>
              <input
                v-if="isGroups"
                ref="inputSearchGroup"
                v-model="searchGroup"
                v-debounce:1000ms="searchGroups"
                placeholder="Группа"
                class="filters-select-title input-by-clients"
              >
              <div :class="{ active: isGroups }" class="filters-select-arrow"></div>
            </div>
            <transition name="fade">
              <div
                v-if="isGroups"
                :class="{ active: isGroups }"
                class="filters-select-dropdown filters-select-dropdown--modal"
              >
                <template v-if="computedStudyGroups.length">
                  <div
                    v-for="group in computedStudyGroups"
                    :key="group.study_group_id"
                    :class="{ opacity: isLoadGroups }"
                    class="custom-checkbox"
                  >
                    <input
                      v-model="groupToTransfer"
                      :value="group.study_group_id"
                      :id="group.study_group_id"
                      type="radio"
                      name="district"
                    >
                    <label
                      @click="isGroups = false"
                      :for="group.study_group_id"
                      class="d-flex"
                    >
                      {{ group.name }}
                    </label>
                  </div>

                  <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                    <div slot="spinner">
                      <AppLoader2 :mini="true" />
                    </div>
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                  </infinite-loading>
                </template>

                <p v-else>Группа не найдена</p>
              </div>
            </transition>
          </div>
        </div>
        <button @click="transferClient" class="btn btn-blue">Перевести</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import InfiniteLoading from 'vue-infinite-loading';

import AppLoader2 from '@/components/AppLoader/2.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'ModalTransferClients',

  components: {
    InfiniteLoading,
    AppLoader2
  },

  props: {
    isModalTransferClients: {
      type: Boolean,
      required: true,
      default: false
    },
    client: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data() {
    return {
      isGroups: false,
      groupToTransfer: null,
      page: 1,
      searchGroup: '',
      isLoadGroups: false,
      infiniteId: +new Date(),
    }
  },

  computed: {
    ...mapGetters('studyGroups', {
      studyGroups: 'list',
      meta: 'meta',
      group: 'item',
    }),
    computedStudyGroups() {
      return this.studyGroups.filter(el => el.study_group_id != this.$route.params.id);
    }
  },

  mounted() {
    document.addEventListener('click', this.hideGroups);
  },

  destroyed() {
    document.removeEventListener('click', this.hideGroups);
  },

  methods: {
    ...mapActions('studyGroups', {
      getStudyGroups: 'getList',
      changeFilters: 'changeFilters',
    }),
    hideGroups(event) {
      if (this.$refs.groups?.contains(event.target)) {
        return;
      } else {
        this.isGroups = false;
      }
    },
    infiniteHandler($state) {
      if (this.meta.page.number < this.meta.page.total) {
        this.page++;

        this.changeFilters({ key: 'page', value: this.page });

        this.getStudyGroups('isForInfinity')
          .then(() => {
            $state.loaded();
          })
          .catch((e) => this.errorHandlerForResponse(e));
      } else {
        $state.complete();
      }
    },
    transferClient() {
      if (!this.groupToTransfer) {
        this.$toast.warning('Группа не выбрана');

        return;
      }

      this.$emit('transferClient', this.groupToTransfer);
      this.groupToTransfer = null;
    },
    openGroups() {
      this.isGroups = true;

      this.$nextTick(() => {
        this.$refs.inputSearchGroup.focus();
      })
    },
    async searchGroups(value) {
      try {
        this.isLoadGroups = true;

        const search = value.trim();

        this.page = 1;

        this.changeFilters({ key: 'page', value: this.page });
        this.changeFilters({ key: 'query', value: search ? search : null });
        await this.getStudyGroups();
        this.infiniteId += 1;
      } catch (e) {
        this.errorHandlerForResponse(e);
      } finally {
        this.isLoadGroups = false;
      }
    },
    errorHandlerForResponse
  },
}
</script>

<style>
.modal__wrapper {
  background: rgba(0, 0, 0, 0.3);
}

.modal__window--show-ease {
  animation: showModal .3s;
}

@keyframes showModal {
  0% {
    opacity: 0;
    transform: scale(.6);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>