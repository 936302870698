<template>
  <div class="account-content">
    <div class="settings">
      <div class="settings-top">
        <div class="settings-head">
          <div class="settings-title">Настройки</div>
        </div>
      </div>

      <AppLoader v-if="loading.profile" />

      <div v-else class="school-cabinet">
        <div class="school-cabinet_box">
          <div class="school-cabinet_card school-cabinet_card--large">
            <div class="school-cabinet_card-top d-md-flex">
              <div class="school-cabinet_card-icon">
                <img v-if="profile.user.avatar" :src="profile.user.avatar.url" :alt="profile.user.name.full">
                <img v-else src="@/assets/img/noavatar.svg" :alt="profile.user.name.full">
              </div>
              <div class="school-cabinet_card-text mt-3 pt-1">
                <div class="d-flex align-items-center">
                  <div class="school-cabinet_card-name mb-2 pb-1">{{ profile.user.name.full }}</div>
                </div>
                <div class="school-cabinet_card-facts mb-2 pb-1">{{ profile.user.email }}</div>
                <div class="school-cabinet_card-facts">{{ profile.user.phone }}</div>
              </div>
            </div>
            <router-link to="/settings/edit" class="school-cabinet_card-link mt-5 mt-md-4 justify-content-end">Редактировать профиль</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AppLoader from '@/components/AppLoader/1.vue';

export default {
  name: 'AppSettings',

  components: {
    AppLoader
  },

  computed: {
    ...mapGetters({
      profile: 'profile',
      loading: 'loadingProfile'
    })
  },
}
</script>