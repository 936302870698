import instance from '../instance';
import settings from '../settings';

export default {
  state: {
    activity: null,
    types: null,
    page: 1,
    filters: {
      page: 1,
      page_size: 10,
      date_from: '',
      date_to: '',
      query: null,
      user_id: null,
      type: null
    },
    loading: {
      list: true,
      types: true,
    }
  },

  getters: {
    activity: state => state.activity,
    activityPage: state => state.filters.page,
    activityFilters: state => state.filters,
    activityLoading: state => state.loading.list,
    activityTypes: state => state.types,
    activityTypesLoading: state => state.loading.types,
    activityFiltersApplied: state =>
      Boolean(state.filters.date_to) && Boolean(state.filters.date_from)
      || Boolean(state.filters.user_id)
      || Boolean(state.filters.type)
      || Boolean(state.filters.query),
  },

  actions: {
    async getActivity({ commit, state }, loading = true) {
      if (loading) {
        commit('START_ACTIVITY_LOADING');
      }

      try {
        const requestId = 'activity';

        const params = Object.fromEntries(Object.entries(state.filters).filter(([key, value]) => key && value));

        const activity = await instance.get(`${settings.API_URL}/activities`, { requestId, params });

        commit('SET_ACTIVITY', activity.data);
      } finally {
        commit('FINISH_ACTIVITY_LOADING');
      }
    },
    async getTypes({ commit }) {
      commit('START_TYPES_LOADING');
      try {
        const requestId = 'types';
        const types = await instance.get(`${settings.API_URL}/dict/activity-types`, { requestId });

        commit('SET_TYPES', types.data);
      } finally {
        commit('FINISH_TYPES_LOADING');
      }
    },
    clearActivity({ commit }) {
      commit('CLEAR_ACTIVITY');
    },
    changePageActivity({ commit }, page) {
      commit('CHANGE_PAGE_ACTIVITY', page);
    },
    changeFiltersDateActivity({ commit }, dates) {
      commit('CHANGE_FILTERS_DATES_ACTIVITY', dates);
    },
    changeFiltersSearchActivity({ commit }, search) {
      commit('CHANGE_FILTERS_SEARCH_ACTIVITY', search);
    },
    changeFiltersUserActivity({ commit }, id) {
      commit('CHANGE_FILTERS_USER_ACTIVITY', id);
    },
    changeFiltersTypeActivity({ commit }, type) {
      commit('CHANGE_FILTERS_TYPE_ACTIVITY', type);
    },
    resetFiltersActivity({ commit }) {
      commit('CHANGE_PAGE_ACTIVITY', 1);

      commit('CHANGE_FILTERS_DATES_ACTIVITY', { from: null, to: null });

      commit('CHANGE_FILTERS_USER_ACTIVITY', null);
      commit('CHANGE_FILTERS_SEARCH_ACTIVITY', null);
      commit('CHANGE_FILTERS_TYPE_ACTIVITY', null);
    },
  },

  mutations: {
    SET_TYPES(state, types) {
      state.types = types;
    },
    SET_ACTIVITY(state, activity) {
      state.activity = activity;
    },
    CLEAR_ACTIVITY(state) {
      state.activity = null;
      state.loading.list = true;
      state.loading.types = true;
    },
    CHANGE_PAGE_ACTIVITY(state, page) {
      state.filters.page = page;
    },
    CHANGE_FILTERS_DATES_ACTIVITY(state, dates) {
      state.filters.date_from = dates.from;
      state.filters.date_to = dates.to;
    },
    CHANGE_FILTERS_SEARCH_ACTIVITY(state, search) {
      state.filters.query = search;
    },
    CHANGE_FILTERS_USER_ACTIVITY(state, id) {
      state.filters.user_id = id;
    },
    CHANGE_FILTERS_TYPE_ACTIVITY(state, type) {
      state.filters.type = type;
    },
    START_ACTIVITY_LOADING(state) {
      state.loading.list = true;
    },
    FINISH_ACTIVITY_LOADING(state) {
      state.loading.list = false;
    },
    START_TYPES_LOADING(state) {
      state.loading.types = true;
    },
    FINISH_TYPES_LOADING(state) {
      state.loading.types = false;
    },
  },
};