<template>
	<div>
		<HeaderMainBlock v-if="$route.name === 'Login'" />
		<HeaderBlock v-else />

    <AppLoader v-if="isGlobalLoader" class="paddingY-200" />

		<h3
			v-else-if="errorsGlobal"
			class="errors-global"
		>
			Что-то пошло не так...
			<br>
			перезагрузите страницу
			(можно нажать <span>F5</span>)
		</h3>

		<router-view v-else></router-view>

    <FooterBlock />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import HeaderBlock from '@/components/layout/Header/Header.vue';
import HeaderMainBlock from '@/components/layout/Header/HeaderMain.vue';
import FooterBlock from '@/components/layout/Footer.vue';
import AppLoader from '@/components/AppLoader/1.vue';

export default {
	name: 'BaseLayout',

	components: {
		HeaderBlock,
		HeaderMainBlock,
		FooterBlock,
		AppLoader
	},

	computed: {
		...mapGetters(['errorsGlobal', 'isGlobalLoader'])
	}
}
</script>

<style>
.errors-global {
	padding: 200px 0;
	font-weight: 700;
	font-size: 18px;
	text-align: center;
}

.errors-global span {
	color: rgb(0, 192, 0);
}

.paddingY-200 {
	padding: 200px 0;
}

.form-control {
	font-size: 16px;
}
</style>
