<template>
  <div class="account-content">
    <GoBack />
    <div class="school-cabinet">
      <div class="school-cabinet_white-box pb-md-0">
        <div class="account-subtitle pt-0 mb-3">Добавить заказ</div>
        <div class="school-cabinet_info pt-1">
          <div class="school-cabinet_info-form school-cabinet_info-form--full">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="school-cabinet_info-field school-cabinet_info-field--small">
                <v-select
                  :options="clients"
                  v-if="clients"
                  @input="selectClient"
                  @search="searchClient"
                  :clearable="false"
                  :filterable="false"
                  :searchable="true"
                  placeholder="Пользователь"
                  :get-option-label='getUserName'
                  :get-option-key='option => option.client_id'
                >
                  <template #open-indicator="{ attributes }">
                    <span class="form-control--arrow" v-bind="attributes"></span>
                  </template>
                  <template slot="no-options">
                    Пользователей не найдено
                  </template>
                  <template v-slot:option="option">
                    {{ option.name.first }} {{ option.name.last }} {{ option.name.middle }}
                  </template>
                  <template #spinner="{ loading }">
                    <AppLoader2 :mini="true" v-if="loading" />
                  </template>
                  <template #list-footer>
                    <infinite-loading @infinite="infiniteHandler">
                      <div slot="spinner">
                        <AppLoader2 :mini="true" />
                      </div>
                      <div slot="no-more"></div>
                      <div slot="no-results"></div>
                    </infinite-loading>
                  </template>
                </v-select>
              </div>
              <div class="school-cabinet_info-field school-cabinet_info-field--small">
                <v-select
                  :options="tracks"
                  v-if="tracks"
                  @input="selectTrack"
                  @search="searchTracks"
                  :clearable="false"
                  :filterable="false"
                  :searchable="true"
                  placeholder="Поток"
                  :get-option-label='option => option.title ? `${option.title}` : null'
                  :get-option-key='option => option.track_id'
                >
                  <template #open-indicator="{ attributes }">
                    <span class="form-control--arrow" v-bind="attributes"></span>
                  </template>
                  <template slot="no-options">
                    Потоков не найдено
                  </template>
                  <template v-slot:option="option">
                    {{ option.title }} 
                  </template>
                  <template #spinner="{ loading }">
                    <AppLoader2 :mini="true" v-if="loading" />
                  </template>
                  <template #list-footer>
                    <infinite-loading @infinite="infiniteHandlerTracks">
                      <div slot="spinner">
                        <AppLoader2 :mini="true" />
                      </div>
                      <div slot="no-more"></div>
                      <div slot="no-results"></div>
                    </infinite-loading>
                  </template>
                </v-select>
              </div>
              <div class="school-cabinet_info-field school-cabinet_info-field--small" v-if="order && order.items[0] && order.items[0].product && order.items[0].product.id > 0">
                <v-select
                  :options="tariffs"
                  v-if="tariffs"
                  @search="searchTariff"
                  @input="selectTariff"
                  :clearable="false"
                  :filterable="false"
                  :searchable="true"
                  placeholder="Тариф"
                  :get-option-label='option => option.name ? `${option.name}` : null'
                  :get-option-key='option => option.tariff_id'
                >
                  <template #open-indicator="{ attributes }">
                    <span class="form-control--arrow" v-bind="attributes"></span>
                  </template>
                  <template slot="no-options">
                    Тарифов не найдено
                  </template>
                  <template v-slot:option="option">
                    {{ option.name }} 
                  </template>
                  <template #list-footer>
                    <infinite-loading @infinite="infiniteHandlerTariffs">
                      <div slot="spinner">
                        <AppLoader2 :mini="true" />
                      </div>
                      <div slot="no-more"></div>
                      <div slot="no-results"></div>
                    </infinite-loading>
                  </template>
                </v-select>
              </div>
              <div class="school-cabinet_info-field school-cabinet_info-field--small" v-else>
                <v-select
                  v-if="tariffs"
                  placeholder="Тариф"
                  :disabled="disabledFilters"
                >
                  <template #open-indicator="{ attributes }">
                    <span class="form-control--arrow" v-bind="attributes"></span>
                  </template>
                </v-select>
              </div>
              <div class="school-cabinet_info-field school-cabinet_info-field--small">
                <input type="text" class="form-control form-control--bordered" 
                  placeholder="Промокод, если есть" 
                  v-model="order.promocode.code"
                >
              </div>
              <div class="school-cabinet_info-field school-cabinet_info-field--small">
                <div class="custom-checkbox">
                  <input type="checkbox" name="paymentType" id="installmentPayment" v-model="order.in_installments">
                  <label for="installmentPayment" class="d-flex">В рассрочку</label>
                </div>
              </div>
              <div class="school-cabinet_info-field school-cabinet_info-field--small"></div>
            </div>
            <div>
              <div class="school-cabinet_info-field school-cabinet_info-field--small">
                <div class="option__button" @click="isShowOptions = true">
                  <div class="option__button-icon"></div>
                  <div class="option__button-text">Добавить опцию, если есть</div>
                </div>
              </div>
              <div class="d-flex justify-content-between flex-wrap" v-if="isShowOptions">
                <div class="school-cabinet_info-field school-cabinet_info-field--small">
                  <v-select
                    :options="orderOptions"
                    v-if="orderOptions"
                    @input="selectOption"
                    :clearable="false"
                    :searchable="false"
                    placeholder="Опция"
                    :get-option-label='option => option.name ? `${option.name}` : null'
                    :get-option-key='option => option.option_id'
                  >
                    <template #open-indicator="{ attributes }">
                      <span class="form-control--arrow" v-bind="attributes"></span>
                    </template>
                    <template slot="no-options">
                      Опций не найдено
                    </template>
                    <template v-slot:option="option">
                      {{ option.name }} 
                    </template>
                  </v-select>
                </div>
                <div class="school-cabinet_info-field school-cabinet_info-field--small" v-if="order && order.items[1] && order.items[1].product && order.items[1].product.id > 0">
                  <v-select
                    :options="tariffsForOptions"
                    v-if="tariffsForOptions"
                    @input="selectTariffForOption"
                    :clearable="false"
                    :searchable="true"
                    @search="searchTariffOptions"
                    placeholder="Тариф"
                    :get-option-label='option => option.name ? `${option.name}` : null'
                    :get-option-key='option => option.tariff_id'
                  >
                    <template #open-indicator="{ attributes }">
                      <span class="form-control--arrow" v-bind="attributes"></span>
                    </template>
                    <template slot="no-options">
                      Тарифов не найдено
                    </template>
                    <template v-slot:option="option">
                      {{ option.name }} 
                    </template>
                    <template #list-footer>
                      <infinite-loading @infinite="infiniteHandlerTariffsOptions">
                        <div slot="spinner">
                          <AppLoader2 :mini="true" />
                        </div>
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                      </infinite-loading>
                    </template>
                  </v-select>
                </div>
                <div class="school-cabinet_info-field school-cabinet_info-field--small" v-else>
                  <v-select
                    v-if="tariffs"
                    placeholder="Тариф"
                    :disabled="disabledFilters"
                  >
                    <template #open-indicator="{ attributes }">
                      <span class="form-control--arrow" v-bind="attributes"></span>
                    </template>
                  </v-select>
                </div>
                <div class="school-cabinet_info-field school-cabinet_info-field--small mb-0"></div>
              </div>
            </div>
            <div class="school-cabinet_info-field school-cabinet_info-field--small mt-md-5" style="margin: auto">
              <button class="btn btn-blue btn-full" @click="addOrderClick" v-if="!isLoading">Добавить</button>
              <button class="btn btn-blue btn-full" disabled v-else>Добавляем...</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GoBack from '@/components/GoBack.vue';
import InfiniteLoading from 'vue-infinite-loading';
import AppLoader2 from '@/components/AppLoader/2.vue';
import vSelect from 'vue-select';
import _ from 'lodash';
import errorHandlerForResponse from '@/helpers/errorHandlerForResponse.js';
export default {
  name: 'OrdersCreate',

  components: {
    GoBack,
    vSelect,
    InfiniteLoading,
    AppLoader2,
  },

  data() {
    return {
      isLoading: false,
      isShowOptions: false,
      disabledFilters: true,
      order: {
        client: {
          client_id: '',
        },
        items: [
          {
            product: {
              type: 'course_track',
              id: '',
            },
            tariff_id: '',
          },
        ],
        in_installments: false,
        promocode: {
          code: '',
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      clients: 'filterClients',
      filterClientsMeta: 'filterClientsMeta',
      tariffs: 'tariffsOrder',
      tariffsForOptions: 'tariffsForOptions',
      orderOptions: 'orderOptions',
      orderTariffsMeta: 'orderTariffsMeta',
      pageTracksAmount: 'pageTracksAmount',
      tracks: 'orderTracks',
      tracksTotal: 'tracksTotal',
      tariffsTotal: 'tariffsTotal',
      tariffsOptionsTotal: 'tariffsOptionsTotal',
    })
  },

  async created() {
    await this.clearFilterClients();
    await this.changeFiltersFilterClientsSearch(null);
    await this.getFilterClients();
    await this.getOptions();
    await this.getOrderTracks();
  },

  destroy() {
  },

  methods: {
    ...mapActions([
      'clearFilterClients',
      'changeFiltersFilterClientsSearch',
      'changePageFilterClients',
      'getFilterClients',
      'getOrderTracksTariffs',
      'getOrderOptionsTariffs',
      'getOptions',
      'addOrder',
      'getOrderTracks',
      'clearFilterTracks',
      'setFilterQueryTracks',
      'clearFilterTariffs',
      'setFilterQueryTariffs',
      'clearOptionsTariffs',
      'clearTrackTariffs',
    ]),
    getUserName(option) {
      if (option) {
        if (option.name.middle) {
          return `${option.name.first} ${option.name.last} ${option.name?.middle}`;
        } else {
          return `${option.name.first} ${option.name.last}`;
        }
      }
      return null;
    },
    selectClient(client) {
      this.order.client.client_id = client.client_id;
    },
    selectTrack(track) {
      this.clearFilterTariffs();
      this.clearTrackTariffs();
      this.getOrderTracksTariffs(track.course?.course_id);
      this.order.items[0].product.id = track.track_id;
    },
    selectTariff(tariff) {
      this.order.items[0].tariff_id = tariff.tariff_id;
    },
    selectOption(option) {
      this.clearFilterTariffs();
      this.clearOptionsTariffs();
      this.getOrderOptionsTariffs(option.option_id);
      if (this.order.items.length > 1) {
        this.order.items.splice(1, 1);
      }
      this.order.items.push({
        product: {
          type: 'billing_option',
          id: option.option_id,
        },
        tariff_id: null,
      })
    },
    selectTariffForOption(tariff) {
      this.order.items[1].tariff_id = tariff.tariff_id;
    },
    searchClient(search, loading) {
      this.clearFilterClients();
      this.changePageFilterClients(1);
      this.changeFiltersFilterClientsSearch(search);
      loading(true);
      this.searchClientsAction(search, loading, this);
    },
    searchClientsAction: _.debounce((search, loading, vm) => {
      vm.getFilterClients(false).then(() => {
        loading(false);
      })
    }, 1000),
    searchTracks(search, loading) {
      this.clearFilterTracks();
      this.setFilterQueryTracks(search);
      loading(true);
      this.searchTracksAction(search, loading, this);
    },
    searchTracksAction: _.debounce((search, loading, vm) => {
      vm.getOrderTracks(false).then(() => {
        loading(false);
      })
    }, 1000),
    searchTariff(search, loading) {
      this.clearFilterTariffs();
      this.setFilterQueryTariffs(search);
      loading(true);
      this.searchTariffsAction(search, loading, this);
    },
    searchTariffsAction: _.debounce((search, loading, vm) => {
      vm.getOrderTariffs(false).then(() => {
        loading(false);
      })
    }, 1000),
    searchTariffOptions(search, loading) {
      this.clearFilterTariffs();
      this.setFilterQueryTariffs(search);
      loading(true);
      this.searchTariffsAction(search, loading, this);
    },
    searchTariffsOptionsAction: _.debounce((search, loading, vm) => {
      vm.getOrderOptionsTariffs(false).then(() => {
        loading(false);
      })
    }, 1000),
    infiniteHandler($state) {
      if (this.clients.length < this.filterClientsMeta.entries.total) {
        this.getFilterClients(false)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    infiniteHandlerTariffs($state) {
      if (this.tariffs.length < this.tariffsTotal) {
        this.getOrderTariffs(false)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    infiniteHandlerTariffsOptions($state) {
      if (this.tariffs.length < this.tariffsTotal) {
        this.getOrderOptionsTariffs(false)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    infiniteHandlerTracks($state) {
      if (this.tracks.length < this.tracksTotal) {
        this.getOrderTracks(false)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    addOrderClick() {
      this.isLoading = true;
      let payload = {
        'order': this.order
      }
      this.addOrder(payload).then(() => {
        this.isLoading = false;
        this.$router.push('/orders');

      }).catch((e) => {
				this.isLoading = false;
				this.errorHandlerForResponse(e);
			})
    },
    errorHandlerForResponse,
  }
}
</script>

<style>
.option__button {
  padding: 10px 0; 
  padding-bottom: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.option__button-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #999;
  background-image: url(~@/assets/img/plus-white.svg);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-right: 10px;
}
.option__button-text {
  font-size: 15px;
}
</style>