<template>
  <header class="header header--autoschool">
    <div class="container-school">
      <transition name="fade">
        <HeaderProfileContent
          v-if="isHeaderProfileContent"
          @hideProfileContent="isHeaderProfileContent = false"
        />
      </transition>

      <div class="header-content d-flex align-items-center">
        <div v-if="profile && token" @click="toggleSidebarClose" class="header-burger">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div class="header-logo" v-if="profile">
          <a href="javascript:void(0)" class="header-logo-link" v-if="profile.school.logo" :style="`background-image: url(${profile.school.logo.url})`"></a>
          <a href="javascript:void(0)" class="header-logo-link" v-else :style="`background-image: url(${require ('@/assets/img/logo-standart.svg')}`"></a>
        </div>

        <div v-if="profile && token" class="header-navbar navbar navbar--v2 ml-auto d-flex align-items-center">
          <div @click="isHeaderProfileContent = !isHeaderProfileContent" class="navbar-profile navbar-item align-items-center d-flex cursor-pointer">
            <div style="height: 32px" class="navbar-profile-image">
              <img v-if="profile.user.avatar" :src="profile.user.avatar.url" class="rounded-circle" alt="Аватар">
              <img v-else src="@/assets/img/noavatar.svg" class="rounded-circle" alt="Аватар">
            </div>
            <div class="navbar-profile-title d-none d-md-block">{{ profile.user.name.full }}</div>
            <div class="navbar-profile-arrow ml-0 ml-md-3"></div>
          </div>
        </div>
        <div v-else class="header-navbar ml-auto d-sm-flex">
          <div class="mt-2" style="height: 32px">
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import HeaderProfileContent from '@/components/layout/Header/ProfileContent';

export default {
  name: 'AppHeader',

  components: {
    HeaderProfileContent
  },

  data() {
    return {
      isHeaderProfileContent: false,
    }
  },

  computed: {
    ...mapGetters(['profile', 'token'])
  },

  methods: {
    ...mapActions(['getMenu', 'toggleSidebarClose']),
  },

  mounted() {
    this.getMenu();
  }
}
</script>