<template>
  <div class="account-content">
    <GoBack />

    <AppLoader v-if="loading.client || loading.notes" />

    <template v-else>
      <div class="settings mb-4 pb-1">
        <div class="account-subtitle pt-0 mb-3">Данные о клиенте</div>
        <div class="settings-body settings-body--height-auto pl-4 pr-4">
          <div class="settings_filters d-flex justify-content-between justify-content-md-start ml-md-2 mr-md-2 mb-4">
            <div class="settings_filters-item active">
              <a href="javascript:void(0)">Профиль</a>
            </div>
          </div>
          <div class="account-profile d-block d-md-flex pt-2 pl-md-4">
            <div class="account-profile-photo account-profile-photo--small mt-1">
              <img
                v-if="client.avatar"
                :src="client.avatar.url"
                :alt="client.name.first"
                class="rounded-circle"
              >

              <img
                v-else
                class="rounded-circle"
                src="@/assets/img/noavatar.svg"
                alt="Нет фото"
              >
            </div>

            <div class="account-profile-info ml-md-5 pl-md-2 pt-4 pt-md-0">
              <div class="account-profile-info-row pb-4">
                <div class="row">
                  <div class="col-sm-3">
                    <div class="account-profile-info-label text-dark">Имя</div>
                  </div>
                  <div class="col-sm-9">
                    <div class="d-flex justify-content-between pl-md-4">
                      <div class="account-profile-info-value">{{ client.name.first }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="account-profile-info-row pb-4">
                <div class="row">
                  <div class="col-sm-3">
                    <div class="account-profile-info-label text-dark">Фамилия</div>
                  </div>
                  <div class="col-sm-9">
                    <div class="d-flex justify-content-between pl-md-4">
                      <div class="account-profile-info-value">{{ client.name.last }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="account-profile-info-row pb-4">
                <div class="row">
                  <div class="col-sm-3">
                    <div class="account-profile-info-label text-dark">Отчество</div>
                  </div>
                  <div class="col-sm-9">
                    <div class="d-flex justify-content-between pl-md-4">
                      <div class="account-profile-info-value">{{ client.name.middle }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="account-profile-info-row pb-4">
                <div class="row flex align-items-center">
                  <div class="col-sm-3">
                    <div class="account-profile-info-label text-dark">Пол</div>
                  </div>
                  <div class="col-sm-9">
                    <div class="d-flex justify-content-between pl-md-4">
                      <div class="account-profile-info-value">{{ client.gender === 'female' ? 'Женский' : 'Мужской' }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="account-profile-info-row pb-4" v-if="client.birthdate">
                <div class="row flex align-items-center">
                  <div class="col-sm-3">
                    <div class="account-profile-info-label text-dark">Дата рождения</div>
                  </div>
                  <div class="col-sm-9">
                    <div class="d-flex justify-content-between pl-md-4">
                      <div class="account-profile-info-value">{{ moment(client.birthdate).format('DD.MM.YYYY') }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="account-profile-info-row pb-4">
                <div class="row flex align-items-center">
                  <div class="col-sm-3">
                    <div class="account-profile-info-label text-dark">E-mail</div>
                  </div>
                  <div class="col-sm-9">
                    <div class="d-flex justify-content-between pl-md-4">
                      <div class="account-profile-info-value">{{ client.email }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="account-profile-info-row pb-4">
                <div class="row flex align-items-center">
                  <div class="col-sm-3">
                    <div class="account-profile-info-label text-dark">Телефон</div>
                  </div>
                  <div class="col-sm-9">
                    <div class="d-flex justify-content-between pl-md-4">
                      <div class="account-profile-info-value">{{ client.phone }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2 mb-3">
                <router-link
                  :to="`/clients/${client.client_id}/edit`"
                  v-if="profile.user.permission.client.edit"
                  custom
                  v-slot="{ navigate }"
                >
                  <button
                    @click="navigate"
                    type="button"
                    role="link"
                    class="btn btn-blue"
                  >
                    Редактировать
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="settings">
        <div class="account-subtitle pt-0 mb-3">Заметки по клиенту</div>

        <ClientNotes />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import moment from 'moment';

import AppLoader from '@/components/AppLoader/1.vue';
import ClientNotes from '@/components/Client/Notes/List.vue';
import GoBack from '@/components/GoBack.vue';

export default {
  name: 'ClientProfile',

  components: {
    AppLoader,
    ClientNotes,
    GoBack
  },

  computed: {
    ...mapGetters({
      client: 'client',
      profile: 'profile',
      loading: 'loadingClients',
    })
  },

  created() {
    this.getClient(this.$route.params.id);

    const payload = {
      idClient: this.$route.params.id,
      loading: true
    }

    this.getClientNotes(payload);
  },

  methods: {
    ...mapActions([
      'getClient',
      'getClientNotes'
    ]),
    moment
  }
}
</script>