<template>
  <div class="account-content">
    <GoBack />

    <div class="breadcrumbs breadcrumbs--custom mt-2 mb-4">
      <ul class="d-flex">
        <li>Заметки <template v-if="notesAllData">({{ notesAllData.client.name.full }})</template></li>
      </ul>
    </div>

    <AppLoader v-if="loading.notes" />

    <div v-else class="school-cabinet">
      <div class="school-cabinet_white-box">
        <ClientNotes />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AppLoader from '@/components/AppLoader/1.vue';
import ClientNotes from '@/components/Client/Notes/List.vue';
import GoBack from '@/components/GoBack.vue';

export default {
  name: 'ClientPageNotes',

  components: {
    ClientNotes,
    AppLoader,
    GoBack
  },

  computed: {
    ...mapGetters({
      loading: 'loadingClients',
      notesAllData: 'clientNotesAllData',
    })
  },

  created() {
    const payload = {
      idClient: this.$route.params.id,
      loading: true
    }

    this.getClientNotes(payload);
  },

  methods: {
    ...mapActions(['getClientNotes'])
  }
}
</script>