<template>
  <div class="w-100 d-flex align-items-center justify-content-center" :class="{'h-20': button}">
    <div class="loader" :class="{mini: mini, button: button}">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppLoader1',

    props: {
      mini: Boolean,
      button: Boolean,
    },
  };
</script>

<style>
.loader.button {
  padding: 0 !important;
}

.loader.mini {
  -webkit-transform: scale(0.4);
  -ms-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4);
  margin-top: -8px;
  padding: 10px;
}

button .loader {
  -webkit-transform: scale(0.4);
  -ms-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4);
  margin-top: -8px;
}

button .loader div {
  border-color: #fff transparent transparent transparent;
}

@media screen and (max-width: 760px) {
  .button {
    height: 28px;
  }
}
</style>