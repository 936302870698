<template>
  <div class="account-content">
    <div class="settings">
      <div class="settings-top d-md-flex align-items-end mb-4">
        <div class="settings-head d-flex align-items-center flex-wrap gap-15 w-100">
          <div class="settings-title mr-auto">Взаиморасчеты</div>

          <router-link
            v-if="profile.user.permission.school_settings.view"
            to="/transactions/acts"
            class="btn btn-blue btn-blue--border"
          >
            Акты по выплатам
          </router-link>
          <router-link
            v-if="profile.user.permission.school_settings.view"
            to="/transactions/settings"
            class="btn btn-blue"
          >
            Настройки выплат
          </router-link>
        </div>
      </div>

      <AppLoader v-if="loading.transactions" />

      <div v-else class="school-cabinet wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
        <div class="filters d-flex flex-wrap mb-3">
          <div class="flex-grow-1 flex-xl-grow-0 d-flex flex-wrap flex-md-nowrap">
            <div class="filters-item filters-item--200 flex-grow-1 mb-2 mb-md-0">
              <DropDownMenuCalendar
                :placeholder="'Период'"
                :dateCalendar="dateCalendar"
                :actualDates="[transactionsFilters.created_at_from, transactionsFilters.created_at_to]"
                @changeDate="changeDate"
              />
            </div>

            <div class="filters-item filters-item--200 flex-grow-1 mb-2 mb-md-0" :class="{'reset-hover': resetHover}">
              <v-select
                :options="instructors.instructors"
                @input="selectInstructor"
                @search="searchInstructor"
                :disabled="disabledFilters"
                :clearable="false"
                :searchable="true"
                placeholder="Инструктор"
                :get-option-label='option => option.name ? `${option.name.first} ${option.name.last}` : null'
                :get-option-key='option => option.instructor_id'
                v-model="activeInstructor"
              >
                <template #open-indicator="{ attributes }">
                  <span class="form-control--arrow" v-bind="attributes"></span>
                </template>
                <template #list-header>
                  <li class="vs__dropdown-option vs__dropdown-option--reset" @mouseover="resetHover = true" @mouseleave="resetHover = false" v-if="activeInstructor" @click="resetInstructor">Все</li>
                </template>
                <template slot="no-options">
                  Пользователей не найдено
                </template>
                <template v-slot:option="option">
                  {{ option.name.first }} {{ option.name.last }}
                </template>
                <template #spinner="{ loading }">
                  <AppLoader2 :mini="true" v-if="loading" />
                </template>
                <template #list-footer>
                  <infinite-loading @infinite="infiniteHandler">
                    <div slot="spinner">
                      <AppLoader2 :mini="true" />
                    </div>
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                  </infinite-loading>
                </template>
              </v-select>
            </div>

            <div class="filters-item filters-item--200 flex-grow-1 mb-2 mb-md-0">
              <DropDownMenuStatus
                :types="{ received: 'Новая', confirmed: 'Подтверждена', rejected: 'Отклонена' }"
                :filters="transactionsFilters.status"
                :disabled="disabledFilters"
                :placeholder="'Статус'"
                @select="selectStatus"
              />
            </div>
          </div>

          <transition name="fade--top">
            <button
              v-if="filtersApplied"
              @click="resetFilters"
              type="button"
              class="reset-filters filters-item--100 align-self-center mt-3 mb-3 mt-xl-0 mb-md-0 ml-xl-auto"
            >
              Сбросить фильтры
            </button>
          </transition>
        </div>

        <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />

        <template v-if="transactions && transactions.transactions.length">
          <div class="d-flex flex-column align-items-md-center flex-md-row flex-wrap mb-3">
            <div class="custom-dropdown w-md-auto mb-3 mb-md-0 mr-md-3 ml-auto mr-auto mr-md-0 ml-md-0">
              <a
                @click="toggleExportDropdown"
                class="custom-dropdown__title transaction__check-link"
                href="javascript:void(0)"
              >
                Экспортировать
              </a>
              <transition name="slide-fade">
                <div class="custom-dropdown__list" v-if="exportDropdown">
                  <template v-if="loading.export">
                    <AppLoader2
                      :mini="true"
                      :btn="true"
                      :color="'#ffffff'"
                    />
                  </template>
                  <template v-else>
                    <div class="custom-dropdown__item">
                      <a href="javascript:void(0)" @click="exportAll('Windows-1251')">Windows-1251</a>
                    </div>
                    <div class="custom-dropdown__item">
                      <a href="javascript:void(0)" @click="exportAll('utf-8')">UTF-8</a>
                    </div>
                  </template>
                </div>
              </transition>
            </div>

            <a
              @click="addRemoveAll"
              class="transaction__check-link w-md-auto mb-3 mb-md-0 mr-md-3 ml-auto"
              href="javascript:void(0)"
            >
              <template v-if="transactionIds.length">Отменить выбор</template>
              <template v-else>Отметить всех</template>
            </a>

            <button
              @click="modalConfirm('подтвердить', transactionsConfirm)"
              type="button"
              :class="{ 'btn-green': transactionIds.length, 'btn--transaction-card--disabled': !transactionIds.length }"
              class="btn btn--transaction-card w-md-auto mb-3 mb-md-0 mr-md-3"
            >
              <AppLoader2
                v-if="loadingConfirmTransactions"
                :mini="true"
                :btn="true"
                :color="'#ffffff'"
              />

              <span :class="{ 'opacity--0': loadingConfirmTransactions }">Подтвердить выбранное</span>
            </button>

            <button
              @click="modalConfirm('отклонить', transactionsReject)"
              type="button"
              :class="{ 'btn-red': transactionIds.length, 'btn--transaction-card--disabled': !transactionIds.length }"
              class="btn btn--transaction-card"
            >
              <AppLoader2
                v-if="loadingRejectTransactions"
                :mini="true"
                :btn="true"
                :color="'#ffffff'"
              />

              <span :class="{ 'opacity--0': loadingRejectTransactions }">Отклонить выбранное</span>
            </button>
          </div>

          <TransactionCard
            v-for="transaction in transactions.transactions"
            :key="transaction.transaction_id"
            :transaction="transaction"
            :selected="transactionIds.includes(transaction.transaction_id)"
            :disabledBtn="Boolean(transactionIds.length)"
            @changeTransactionIds="changeTransactionIds(transaction.transaction_id)"
          />

          <div class="d-flex flex-column flex-sm-row align-items-center mt-5">
            <div class="d-flex align-items-center mr-sm-auto mb-4 mb-sm-0">
              <PageSize
                @changePageSize="changePageSize"
                :pageSizeFilter="transactionsFilters.page_size"
                class="mr-3"
              />

              <p>Записей на странице</p>
            </div>

            <div v-if="transactions.meta.page.total > 1">
              <paginate
                v-model="page"
                :pageCount="transactions.meta.page.total"
                :clickHandler="clickCallback"
                :prevText="''"
                :nextText="''"
                :containerClass="'pagination'"
                :pageClass="'page-item'"
                :pageLinkClass="'page-link'"
                :prevClass="'page-item'"
                :prevLinkClass="'page-link'"
                :nextClass="'page-item'"
                :nextLinkClass="'page-link'"
                :activeClass="'active'"
                class="pagination--transactions"
              />
            </div>
          </div>
        </template>

        <p v-else>Нет данных, удовлетворяющих условиям поиска</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Paginate from 'vuejs-paginate';
import moment from 'moment';
import _ from 'lodash';
import vSelect from 'vue-select';
import InfiniteLoading from 'vue-infinite-loading';

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';
import DropDownMenuCalendar from '@/components/DropDownMenu/Calendar.vue';
import DropDownMenuStatus from '@/components/DropDownMenu/Type.vue';
import TransactionCard from '@/components/TransactionCard.vue';
import PageSize from '@/components/PageSize.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'Transactions',

  components: {
    AppLoader,
    AppLoader2,
    AppLoader3,
    Paginate,
    DropDownMenuCalendar,
    DropDownMenuStatus,
    TransactionCard,
    vSelect,
    InfiniteLoading,
    PageSize
  },

  data() {
    return {
      page: 1,
      isLoadingPage: false,
      dateCalendar: [],
      resetHover: false,
      disabledFilters: false,
      activeInstructor: null,
      loadingConfirmTransactions: false,
      loadingRejectTransactions: false,
      transactionIds: [],
      exportDropdown: false,
    }
  },

  computed: {
    ...mapGetters('transactions', {
      transactions: 'transactions',
      transactionsFilters: 'filters',
      loading: 'loading',
      filtersApplied: 'filtersApplied',
    }),
    ...mapGetters({
      instructors: 'assignmentClientInstructors',
      instructor: 'instructor',
      profile: 'profile',
    }),
    loadingButtons() {
      return this.loadingConfirmTransactions || this.loadingRejectTransactions;
    }
  },

  async created() {
    this.clearTransactions();

    if (this.$route.params.page === 1) {
      this.resetFiltersTransactions();
    }

    this.clearСlientInstructors();
    this.resetPageСlientInstructors();

    await this.getСlientInstructors({loading: true});

    if (this.$route.query.instructor_id) {
      this.changeFiltersInstructorTransactions(this.$route.query.instructor_id);
      await this.getInstructor(this.$route.query.instructor_id);
      this.activeInstructor = this.instructor;
    }

    if (this.$route.query.page) {
      this.changePageTransactions(Number(this.$route.query.page));
    }

    if (this.$route.query.page_size) {
      this.changePageSizeTransactions(Number(this.$route.query.page_size));
    }

    if (this.$route.query.status) {
      this.changeFiltersStatusTransactions(this.$route.query.status);
    }

    this.page = this.transactionsFilters.page;

    let query = Object.assign({}, this.$route.query);
    query.page = this.transactionsFilters.page;

    this.$router.push({ query }).catch(() => {});

    if (this.$route.query.created_at_from && this.$route.query.created_at_to) {
      const date = {
        from: moment(this.$route.query.created_at_from, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        to: moment(this.$route.query.created_at_to, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      }

      this.changeFiltersDateTransactions(date);

      this.dateCalendar = [moment(this.$route.query.created_at_from, 'DD-MM-YYYY')._d, moment(this.$route.query.created_at_to, 'DD-MM-YYYY')._d];
    }

    await this.getTransactions();

    if (this.transactions && this.transactionsFilters.page > this.transactions.meta.page.total) {
      await this.changePageTransactions(this.transactions.meta.page.total);

      this.page = this.transactionsFilters.page;

      let query = Object.assign({}, this.$route.query);
      query.page = this.transactionsFilters.page;
      this.$router.push({ query }).catch(() => {});

      this.getTransactions();
    }
  },

  methods: {
    ...mapActions('transactions', {
      getTransactions: 'getTransactions',
      clearTransactions: 'clearTransactions',
      changePageTransactions: 'changePage',
      changeFiltersDateTransactions: 'changeFiltersDate',
      resetFiltersTransactions: 'resetFilters',
      changeFiltersInstructorTransactions: 'changeFiltersInstructor',
      changeFiltersStatusTransactions: 'changeFiltersStatus',
      confirmTransactions: 'confirmTransactions',
      rejectTransactions: 'rejectTransactions',
      changePageSizeTransactions: 'changeFiltersPageSize',
      exportTransactions: 'exportTransactions',
    }),
    ...mapActions([
      'getСlientInstructors',
      'getСlientInstructor',
      'resetPageСlientInstructors',
      'clearСlientInstructors',
      'changeFiltersSearchСlientInstructors',
      'getInstructor',
    ]),
    changeDate(dateCalendar) {
      if (this.isLoadingPage) {
        return;
      }

      this.isLoadingPage = true;

      this.changePageTransactions(1);
      this.transactionIds = [];
      this.page = 1;

      let date = null;

      if (dateCalendar) {
        date = {
          from: moment(dateCalendar[0]).format('YYYY-MM-DD'),
          to: moment(dateCalendar[1]).format('YYYY-MM-DD'),
        }

        let query = Object.assign({}, this.$route.query);
        query.page = '1';
        query.created_at_from = moment(dateCalendar[0]).format('DD-MM-YYYY');
        query.created_at_to = moment(dateCalendar[1]).format('DD-MM-YYYY');
        this.$router.push({ query }).catch(() => {});
      } else {
        date = {
          from: null,
          to: null,
        }

        let query = Object.assign({}, this.$route.query);
        query.page = '1';
        delete query.created_at_from;
        delete query.created_at_to;
        this.$router.push({ query }).catch(() => {});
      }

      this.changeFiltersDateTransactions(date);

      const loading = false;

      this.getTransactions(loading)
        .finally(() => this.isLoadingPage = false);
    },
    clickCallback() {
      this.isLoadingPage = true;

      this.transactionIds = [];

      let query = Object.assign({}, this.$route.query);
      query.page = this.page;

      this.changePageTransactions(this.page)
        .then(() => {
          this.$router.push({ query }).catch(() => {});

          const loading = false;

          this.getTransactions(loading)
            .finally(() => this.isLoadingPage = false);
        });
    },
    infiniteHandler($state) {
      if (this.instructors.meta.page.number < this.instructors.meta.page.total) {
        const payload = {
          loading: false,
        }

        this.getСlientInstructors(payload)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    searchInstructor(search, loading) {
      this.resetPageСlientInstructors();
      this.changeFiltersSearchСlientInstructors(search);
      loading(true);
      this.searchInstructorAction(search, loading, this);
    },
    searchInstructorAction: _.debounce((search, loading, vm) => {
      const payload = {
        loading: false
      }
      vm.getСlientInstructors(payload).then(() => {
        loading(false);
      })
    }, 1000),
    resetInstructor() {
      this.changeFiltersInstructorTransactions(null);
      this.activeInstructor = null;
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.changePageTransactions(1);
      this.transactionIds = [];

      const loading = false;

      this.getTransactions(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      delete query.instructor_id;
      this.$router.push({ query }).catch(() => {});
    },
    selectInstructor(instructor) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.changeFiltersInstructorTransactions(instructor.instructor_id);
      this.changePageTransactions(1);
      this.transactionIds = [];

      const loading = false;

      this.getTransactions(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      query.instructor_id = instructor.instructor_id;
      this.$router.push({ query }).catch(() => {});
    },
    selectStatus(status) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.changePageTransactions(1);
      this.transactionIds = [];

      if (status === 'reset') {
        this.changeFiltersStatusTransactions(null);

        let query = Object.assign({}, this.$route.query);
        query.page = 1;
        delete query.status;
        this.$router.replace({ query }).catch(() => {});
      } else {
        this.changeFiltersStatusTransactions(status);

        let query = Object.assign({}, this.$route.query);
        query.page = 1;
        query.status = status;
        this.$router.replace({ query }).catch(() => {});
      }

      const loading = false;

      this.getTransactions(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    resetFilters() {
      if (this.disabledFilters) {
        return;
      }

      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.resetFiltersTransactions();
      this.transactionIds = [];

      const loading = false;

      this.getTransactions(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      this.activeInstructor = null;
      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      delete query.created_at_from;
      delete query.created_at_to;
      delete query.instructor_id;
      delete query.status;
      this.$router.push({ query }).catch(() => {});
    },
    changeTransactionIds(index) {
      const transactionIndex = this.transactionIds.indexOf(index);

      if (transactionIndex > -1) {
        this.transactionIds.splice(transactionIndex, 1);
      } else {
        this.transactionIds.push(index);
      }
    },
    modalConfirm(text, method) {
      if (!this.transactionIds.length) {
        this.$toast.warning('Ничего не выбрано');

        return;
      }

      if (this.loadingButtons) {
        return;
      }

      this.$modal.show('dialog', {
        text: `Вы действительно хотите ${text} выбранное?`,
        buttons: [
          {
            title: 'Да',
            handler: () => {
              method();
              this.$modal.hide('dialog');
            },
          },
          {
            title: 'Нет',
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    transactionsConfirm() {
      this.loadingConfirmTransactions = true;

      this.confirmTransactions({ transaction_ids: this.transactionIds })
        .then(() => this.transactionIds = [])
        .catch(e => this.errorHandlerForResponse(e))
        .finally(() => this.loadingConfirmTransactions = false);
    },
    transactionsReject() {
      this.loadingRejectTransactions = true;

      this.rejectTransactions({ transaction_ids: this.transactionIds })
        .then(() => this.transactionIds = [])
        .catch(e => this.errorHandlerForResponse(e))
        .finally(() => this.loadingRejectTransactions = false);
    },
    addRemoveAll() {
      if (this.transactionIds.length) {
        this.transactionIds = [];
      } else {
        const transactionIds = this.transactions.transactions
          .filter(el => el.status !== 'confirmed' && el.status !== 'rejected')
          .map(el => el.transaction_id);

        this.transactionIds.push(...transactionIds);
      }
    },
    exportAll(charset) {
      this.exportTransactions(charset).then(() => {
        this.exportDropdown = false;
      })
    },
    toggleExportDropdown() {
      this.exportDropdown = !this.exportDropdown;
    },
    changePageSize(valPageSize) {
      this.isLoadingPage = true;
      this.disabledFilters = true;

      this.changePageSizeTransactions(valPageSize);
      this.changePageTransactions(1);

      this.page = 1;

      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      query.page_size = valPageSize;
      this.$router.push({ query }).catch(() => {});

      const loading = false;

      this.getTransactions(loading)
        .finally(() => {
          this.isLoadingPage = false;
          this.disabledFilters = false;
        });
    },
    errorHandlerForResponse
  }
}
</script>

<style>
  .btn--transaction-card--disabled {
    background-color: #555555;
    color: #fff;
  }
  .transaction__check-link {
    border: none;
    border-bottom: 1px dashed #000;
    color: #000;
    line-height: 1;
    align-self: center;
  }

  .pagination--transactions {
    padding-top: 0 !important;
  }
  .custom-dropdown {
    position: relative;
    z-index: 10;
  }
  .custom-dropdown__item a:hover {
    background-color: rgba(255,255,255,0.1);
  }
  .custom-dropdown__item a {
    color: #fff !important;
    padding: 12px 10px;
    display: block;
    font-size: 14px;
  }
  .custom-dropdown__list {
    position: absolute;
    top: 29px;
    left: 0;
    min-height: 76px;
    display: block;
    right: 0;
    margin: auto;
    width: 100%;
    padding-bottom: 0;
    background-color: rgba(0,0,0,0.9);
  }
  .custom-dropdown__list:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 3.5px 7px 3.5px;
    border-color: transparent transparent rgba(0,0,0,0.9) transparent;
    position: absolute;
    top: -7px;
    left: 0;
    right: 0;
    margin: auto;
  }
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.btn-blue.btn-blue--border {
    background: none !important;
    border: 1px solid #0033CC !important;
    color: #0033CC !important;
}
</style>
