import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import instance from '@/store/instance';
import Meta from 'vue-meta';
import firstPageFromPermissions from '@/helpers/firstPageFromPermissions';

import Main from '@/views/index.vue';
import Login from '@/views/Auth/Login.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';
import Instructors from '@/views/Instructors/index.vue';
import InstructorProfile from '@/views/Instructors/Profile/index.vue';
import InstructorTariffs from '@/views/Instructors/Tariffs/index.vue';
import InstructorLessons from '@/views/Instructors/Lessons/index.vue';
import InstructorCreate from '@/views/Instructors/Create/index.vue';
import InstructorNotes from '@/views/Instructors/Notes/index.vue';
import InstructorEdit from '@/views/Instructors/Edit/index.vue';
import InstructorChangePassword from '@/views/Instructors/Edit/ChangePassword/index.vue';
import Clients from '@/views/Clients/index.vue';
import ClientProfile from '@/views/Clients/Profile.vue';
import ClientLessons from '@/views/Clients/Lessons/index.vue';
import ClientCreate from '@/views/Clients/Create/index.vue';
import ClientNotes from '@/views/Clients/Notes/index.vue';
import ClientEdit from '@/views/Clients/Edit/index.vue';
import ClientChangePassword from '@/views/Clients/Edit/ChangePassword/index.vue';
import StudyGroups from '@/views/StudyGroups/index.vue';
import StudyGroupsCreate from '@/views/StudyGroups/Create.vue';
import StudyGroupsClients from '@/views/StudyGroups/Item/Clients.vue';
import StudyGroupsEdit from '@/views/StudyGroups/Item/Edit.vue';
import Assignment from '@/views/Assignment/index.vue';
import AssignmentCreateLessons from '@/views/Assignment/CreateLessons/index.vue';
import Analytics from '@/views/Analytics/index.vue';
import Settings from '@/views/Settings/index.vue';
import OrganizationSettings from '@/views/OrganizationSettings/index.vue';
import OrganizationRequisites from '@/views/OrganizationSettings/requisites.vue';
import SettingsEdit from '@/views/Settings/Edit/index.vue';
import SettingsChangePassword from '@/views/Settings/Edit/ChangePassword/index.vue';
import Administrators from '@/views/Administrators/index.vue';
import AdministratorCreate from '@/views/Administrators/Create/index.vue';
import AdministratorEdit from '@/views/Administrators/Edit/index.vue';
import AdministratorChangePassword from '@/views/Administrators/Edit/ChangePassword/index.vue';
import Activity from '@/views/Activity/index.vue';
import Transactions from '@/views/Transactions/index.vue';
import TransactionsSettings from '@/views/Transactions/Settings.vue';
import TransactionsActs from '@/views/Transactions/Acts.vue';
import PageNotFound from '@/views/PageNotFound/index.vue';
import Services from '@/views/Services/index.vue';
import Integrations from '@/views/Services/Integrations.vue';
import Exams from '@/views/Services/Exams.vue';
import Reports from '@/views/Reports/index.vue';
import ReportsComments from '@/views/Reports/Comments/index.vue';
import Orders from '@/views/Orders/index.vue';
import OrdersCreate from '@/views/Orders/Create/index.vue';
import OrdersEdit from '@/views/Orders/Edit/index.vue';

Vue.use(VueRouter);
Vue.use(Meta);

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'Main',
      component: Main,
      meta: {
        title: 'LevelApp',
        requiredAuth: true,
      },
      children: [
        {
          path: '/instructors',
          name: 'Instructors',
          component: Instructors,
          meta: {
            title: 'Инструкторы',
            permissions: ['instructor', 'client', 'assignment', 'analytics'],
            requiredAuth: true,
          },
        },
        {
          path: '/instructors/:id/profile',
          name: 'InstructorProfile',
          component: InstructorProfile,
          meta: {
            title: 'Профиль инструктора',
            permissions: ['instructor', 'client', 'assignment', 'analytics'],
            requiredAuth: true,
          },
        },
        {
          path: '/instructors/:id/tariffs',
          name: 'InstructorTariffs',
          component: InstructorTariffs,
          meta: {
            title: 'Тарифы инструктора',
            permissions: ['instructor', 'client', 'assignment', 'analytics'],
            requiredAuth: true,
          },
        },
        {
          path: '/instructors/:id/lessons',
          name: 'InstructorLessons',
          component: InstructorLessons,
          meta: {
            title: 'Занятия инструктора',
            permissions: ['instructor', 'assignment', 'analytics'],
            requiredAuth: true,
          },
        },
        {
          path: '/instructors/create',
          name: 'InstructorCreate',
          component: InstructorCreate,
          meta: {
            title: 'Добавить инструктора',
            permissions: ['instructor'],
            requiredAuth: true,
          },
        },
        {
          path: '/instructors/:id/notes',
          name: 'InstructorNotes',
          component: InstructorNotes,
          meta: {
            title: 'Заметки инструктора',
            permissions: ['instructor', 'assignment'],
            requiredAuth: true,
          },
        },
        {
          path: '/instructors/:id/edit',
          name: 'InstructorEdit',
          component: InstructorEdit,
          meta: {
            title: 'Редактировать профиль инструктора',
            permissions: ['instructor'],
            requiredAuth: true,
          },
        },
        {
          path: '/instructors/:id/edit/change-password',
          name: 'InstructorChangePassword',
          component: InstructorChangePassword,
          meta: {
            title: 'Сменить пароль инструктора',
            permissions: ['instructor'],
            requiredAuth: true,
          },
        },
        {
          path: '/clients',
          name: 'Clients',
          component: Clients,
          meta: {
            title: 'Клиенты',
            permissions: ['instructor', 'client', 'assignment', 'analytics'],
            requiredAuth: true,
          },
        },
        {
          path: '/clients/create',
          name: 'ClientCreate',
          component: ClientCreate,
          meta: {
            title: 'Добавить клиента',
            permissions: ['client'],
            requiredAuth: true,
          },
        },
        {
          path: '/clients/:id',
          name: 'ClientProfile',
          component: ClientProfile,
          meta: {
            title: 'Профиль клиента',
            permissions: ['instructor', 'client', 'assignment', 'analytics'],
            requiredAuth: true,
          },
        },
        {
          path: '/clients/:id/lessons',
          name: 'ClientLessons',
          component: ClientLessons,
          meta: {
            title: 'Занятия клиента',
            permissions: ['client', 'assignment', 'analytics'],
            requiredAuth: true,
          },
        },
        {
          path: '/clients/:id/notes',
          name: 'ClientNotes',
          component: ClientNotes,
          meta: {
            title: 'Заметки клиента',
            permissions: ['client', 'assignment'],
            requiredAuth: true,
          },
        },
        {
          path: '/clients/:id/edit',
          name: 'ClientEdit',
          component: ClientEdit,
          meta: {
            title: 'Редактировать профиль клиента',
            permissions: ['client'],
            requiredAuth: true,
          },
        },
        {
          path: '/clients/:id/edit/change-password',
          name: 'ClientChangePassword',
          component: ClientChangePassword,
          meta: {
            title: 'Сменить пароль клиента',
            permissions: ['client'],
            requiredAuth: true,
          },
        },
        {
          path: '/study-groups',
          name: 'StudyGroups',
          component: StudyGroups,
          meta: {
            title: 'Группы обучения',
            permissions: ['assignment'],
            requiredAuth: true,
          },
        },
        {
          path: '/study-groups/create',
          name: 'StudyGroupsCreate',
          component: StudyGroupsCreate,
          meta: {
            title: 'Создать группу обучения',
            permissions: ['assignment'],
            requiredAuth: true,
          },
        },
        {
          path: '/study-groups/:id/clients',
          name: 'StudyGroupsClients',
          component: StudyGroupsClients,
          meta: {
            title: 'Клиенты группы обучения',
            permissions: ['assignment'],
            requiredAuth: true,
          },
        },
        {
          path: '/study-groups/:id/edit',
          name: 'StudyGroupsEdit',
          component: StudyGroupsEdit,
          meta: {
            title: 'Редактировать группу',
            permissions: ['assignment'],
            requiredAuth: true,
          },
        },
        {
          path: '/assignment',
          name: 'Assignment',
          component: Assignment,
          meta: {
            title: 'Распределение',
            permissions: ['assignment'],
            requiredAuth: true,
          },
        },
        {
          path: '/assignment/:id/create-lessons',
          name: 'AssignmentCreateLessons',
          component: AssignmentCreateLessons,
          meta: {
            title: 'Добавить занятия',
            permissions: ['assignment'],
            requiredAuth: true,
          },
        },
        {
          path: '/analytics',
          name: 'Analytics',
          component: Analytics,
          meta: {
            title: 'Аналитика',
            permissions: ['analytics'],
            requiredAuth: true,
          },
        },
        {
          path: '/reports',
          name: 'Reports',
          component: Reports,
          meta: {
            title: 'Отчеты о занятиях',
            permissions: ['reports'],
            requiredAuth: true,
          },
        },
        {
          path: '/reports/:id/comments',
          name: 'ReportsComments',
          component: ReportsComments,
          meta: {
            title: 'Комментарии к отчету',
            requiredAuth: true,
          },
        },
        {
          path: '/orders',
          name: 'Orders',
          component: Orders,
          meta: {
            title: 'Заказы',
            permissions: ['orders'],
            requiredAuth: true,
          },
        },
        {
          path: '/orders/create',
          name: 'OrdersCreate',
          component: OrdersCreate,
          meta: {
            title: 'Добавить заказ',
            permissions: ['orders'],
            requiredAuth: true,
          },
        },
        {
          path: '/orders/:id/edit',
          name: 'OrdersEdit',
          component: OrdersEdit,
          meta: {
            title: 'Редактировать заказ',
            permissions: ['orders'],
            requiredAuth: true,
          },
        },
        {
          path: '/settings',
          name: 'Settings',
          component: Settings,
          meta: {
            title: 'Настройки',
            requiredAuth: true,
          },
        },
        {
          path: '/organization-settings',
          name: 'OrganizationSettings',
          component: OrganizationSettings,
          meta: {
            title: 'Настройки организации',
            requiredAuth: true,
          },
        },
        {
          path: '/organization-settings/requisites',
          name: 'OrganizationRequisites',
          component: OrganizationRequisites,
          meta: {
            title: 'Настройки организации',
            requiredAuth: true,
          },
        },
        {
          path: '/settings/edit',
          name: 'SettingsEdit',
          component: SettingsEdit,
          meta: {
            title: 'Редактирование профиля',
            requiredAuth: true,
          },
        },
        {
          path: '/settings/edit/change-password',
          name: 'SettingsChangePassword',
          component: SettingsChangePassword,
          meta: {
            title: 'Сменить пароль',
            requiredAuth: true,
          },
        },
        {
          path: '/administrators',
          name: 'Administrators',
          component: Administrators,
          meta: {
            title: 'Администраторы',
            permissions: ['user'],
            requiredAuth: true,
          },
        },
        {
          path: '/administrators/create',
          name: 'AdministratorCreate',
          component: AdministratorCreate,
          meta: {
            title: 'Добавить администратора',
            permissions: ['user'],
            requiredAuth: true,
          },
        },
        {
          path: '/administrators/:id/edit',
          name: 'AdministratorEdit',
          component: AdministratorEdit,
          meta: {
            title: 'Редактировать профиль администратора',
            permissions: ['user'],
            requiredAuth: true,
          },
        },
        {
          path: '/administrators/:id/edit/change-password',
          name: 'AdministratorChangePassword',
          component: AdministratorChangePassword,
          meta: {
            title: 'Сменить пароль администратора',
            permissions: ['user'],
            requiredAuth: true,
          },
        },
        {
          path: '/activity',
          name: 'Activity',
          component: Activity,
          meta: {
            title: 'Журнал действий',
            permissions: ['activity'],
            requiredAuth: true,
          },
        },
        {
          path: '/transactions',
          name: 'Transactions',
          component: Transactions,
          meta: {
            title: 'Взаиморасчеты',
            permissions: ['settlements'],
            requiredAuth: true,
          },
        },
        {
          path: '/transactions/settings',
          name: 'TransactionsSettings',
          component: TransactionsSettings,
          meta: {
            title: 'Выплаты инструкторам',
            permissions: ['school_settings'],
            requiredAuth: true,
          },
        },
        {
          path: '/transactions/acts',
          name: 'TransactionsActs',
          component: TransactionsActs,
          meta: {
            title: 'Акты',
            permissions: ['school_settings'],
            requiredAuth: true,
          },
        },
        {
          path: '/services',
          name: 'Services',
          component: Services,
          meta: {
            title: 'Сервисы',
            requiredAuth: true,
          },
        },
        {
          path: '/services/integrations',
          name: 'Integrations',
          component: Integrations,
          meta: {
            title: 'Интеграции',
            permissions: ['service-hub'],
            requiredAuth: true,
          },
        },
        {
          path: '/services/exams',
          name: 'Exams',
          component: Exams,
          meta: {
            title: 'Экзамены',
            permissions: ['service-exams'],
            requiredAuth: true,
          },
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Вход',
        requiredAuth: false,
      },
    },
    {
      path: '/reset-password/:token',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: {
        title: 'Сброс пароля',
        requiredAuth: false,
      },
    },
    {
      path: '/404',
      name: 'PageNotFound',
      component: PageNotFound,
      meta: {
        title: 'Страница не найдена',
        requiredAuth: false,
      },
    },
    {
      path: '*',
      redirect: '/404'
    },
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

function hasPermissionsNeeded(to, permissionsProfile) {
  const permission = permissionsProfile.some(permProfile => to.meta.permissions.includes(permProfile));

  return permission;
}

router.beforeEach((to, from, next) => {
  if (!store.getters.token && to.meta.requiredAuth == true) {
    store.dispatch('setFirstPageWithoutAuthorization', to.path);

    next('/login');
  } else if (store.getters.token && !store.getters.profile) {
    store.dispatch('changeGlobalLoader');

    store.dispatch('getProfile')
      .then(() => {
        if (to.path === '/account') {
          next(firstPageFromPermissions(store.getters.profile.user.permissions));
        }

        if (to.meta.permissions) {
          if (hasPermissionsNeeded(to, store.getters.profile.user.permissions)) {
            next();
          } else {
            next('/404');
          }
        } else {
          next();
        }
      })
      .catch(() => {
        store.dispatch('clearToken');

        next('/login');
      })
      .finally(() => {
        store.dispatch('changeGlobalLoader');
      })
  } else if (store.getters.profile && to.path === '/account') {
    next(firstPageFromPermissions(store.getters.profile.user.permissions));
  } else if (store.getters.profile) {
    if (to.meta.permissions) {
      if (hasPermissionsNeeded(to, store.getters.profile.user.permissions)) {
        next();
      } else {
        next('/404');
      }
    } else {
      next();
    }
  } else {
    next();
  }

  instance.cancelAll();
})

export default router;
