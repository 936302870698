<template>
  <div class="school-cabinet__lessons-card d-flex justify-content-between flex-wrap flex-md-nowrap">
    <div class="school-cabinet_card-content d-flex">
      <router-link
        :to="`/instructors/${lessonsPackage.package.instructor.instructor_id}/profile`"
        class="school-cabinet_card-icon"
      >
        <img v-if="lessonsPackage.package.instructor.avatar" :src="lessonsPackage.package.instructor.avatar.url" :alt="lessonsPackage.package.instructor.name.full">
        <img v-else src="@/assets/img/noavatar.svg" alt="Нет фото">
      </router-link>

      <div class="school-cabinet_card-text mt-1 width-244">
        <router-link
          :to="`/instructors/${lessonsPackage.package.instructor.instructor_id}/profile`"
          class="school-cabinet_card-name"
        >
          {{ lessonsPackage.package.instructor.name.full }}
        </router-link>
        <div class="school-cabinet_card-facts mr-md-3">{{ lessonsPackage.package.instructor.email }}<template v-if="lessonsPackage.package.instructor.phone">,</template> <span class="white-space-nowrap">{{ lessonsPackage.package.instructor.phone }}</span> </div>
      </div>
    </div>

    <div class="school-cabinet__lessons-card-content mt-3 mt-md-1">
      <div class="d-flex justify-content-between">
        <div class="school-cabinet_card-info-item">
          <div class="school-cabinet_card-info-text mb-1">{{ lessonsPackage.package.tariff.title }} | {{ lessonsPackage.package.tariff.price }} руб.</div>
          <div class="school-cabinet_card-info-title">Занятий <strong>всего <span class="color-blue">{{ lessonsPackage.package.qty }}</span></strong></div>
        </div>
      </div>

      <div class="school-cabinet_card-info-title mt-2">
        <p>{{ lessonsPackage.note }}</p>

        <p><strong>Сообщение клиенту:</strong> {{ lessonsPackage.package.message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientLessonsPackagesCard',

  props: {
    lessonsPackage: {
      typeof: Object,
      required: true
    }
  }
}
</script>

<style>
.white-space-nowrap {
  white-space: nowrap;
}
</style>