<template>
  <div class="drop-down-menu">
    <div
      v-click-outside="hide"
      @click="opened = !opened"
      :class="{ 'border-bottom-none': opened && !disabled}"
      class="form-control form-control--bordered"
    >
      {{ choice.name}}

      <span
        :class="{ rotate: opened && !disabled }"
        class="form-control--arrow"
      ></span>
    </div>

    <ul v-if="opened && !disabled" class="form-control--custom">
      <li
        v-for="(choice, index) in listWithoutSelected"
        :key="index"
        @click="select(choice)"
      >
        {{ choice.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropDownMenuLessonsOrPackage',

  props: {
    disabled: {
      typeof: Boolean,
      required: false
    }
  },

  directives: {
    ClickOutside,
  },

  data() {
    return {
      opened: false,
      choice: '',
      list: [
        {
          name: 'Добавить занятия',
          value: 'lessons'
        },
        {
          name: 'Сформировать пакет',
          value: 'package'
        },
      ]
    }
  },

  computed: {
    listWithoutSelected() {
      return this.list.filter(el => el.value !== this.choice.value);
    }
  },

  created() {
    this.choice = this.list[0];
  },

  mounted() {
    this.popupItem = this.$el
  },

  methods: {
    hide() {
      this.opened = false;
    },
    select(choice) {
      this.choice = choice;

      this.hide();

      this.$emit('select', choice);
    },
  }
}
</script>
