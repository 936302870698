<template>
  <div class="account-content">
    <!-- <GoBack /> -->

    <AppLoader v-if="loading.lessons" />

    <div v-else class="school-cabinet">
      <div class="school-cabinet_title mb-4">Занятия ({{ lessonsAllData.client.name.full }})</div>

      <div v-if="lessons.length" class="school-cabinet_white-box" >
        <div class="school-cabinet_info">
          <div class="school-cabinet_info-table mt-2 mt-md-1 mr-md-2">
            <div class="school-cabinet_info-table-head mb-3 d-none d-md-flex">
              <div class="school-cabinet_info-table-title school-cabinet_info-table-title--1"></div>
              <div class="school-cabinet_info-table-title school-cabinet_info-table-title--2">Занятие</div>
              <div class="school-cabinet_info-table-title school-cabinet_info-table-title--3">Статус</div>
              <div class="school-cabinet_info-table-title"></div>
            </div>

            <ClientLessonCard
              v-for="lesson in lessons"
              :key="lesson.lesson_id"
              :lesson="lesson"
            />

            <infinite-loading @infinite="infiniteHandler">
              <div slot="spinner">
                <AppLoader2 :mini="true" />
              </div>
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>
        </div>
      </div>

      <p v-else>Занятий нет</p>
      </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import VModal from 'vue-js-modal';
import moment from 'moment';

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import ClientLessonCard from '@/components/Client/Lessons/Card.vue';
// import GoBack from '@/components/GoBack.vue';

Vue.use(VModal, { dialog: true });

export default {
  name: 'ClientLessons',

  components: {
    AppLoader,
    AppLoader2,
    ClientLessonCard,
    InfiniteLoading,
    // GoBack,
  },

  computed: {
    ...mapGetters({
      lessonsAllData: 'clientLessonAllData',
      lessons: 'clientLessons',
      loading: 'loadingClients',
    }),
  },

  created() {
    this.clearClientLessons();
    this.changePageClientLessons(1);

    const payload = {
      idClient: this.$route.params.id,
      loading: true
    }

    this.getClientLessons(payload);
  },

  methods: {
    ...mapActions([
      'getClientLessons',
      'clearClientLessons',
      'changePageClientLessons',
    ]),
    infiniteHandler($state) {
      if (this.lessons.length < this.lessonsAllData.meta.entries.total && this.lessonsAllData.lessons.length) {
        const payload = {
          idClient: this.$route.params.id,
          loading: false
        }

        this.getClientLessons(payload)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.reset();
      }
    },
    moment
  }
}
</script>