export default function firstPageFromPermissions(permissions) {
  if (!permissions.length) {
    return '/settings';
  }

  switch (permissions[0]) {
    case 'services':
      return '/services';
    case 'instructor':
      return '/instructors';
    case 'client':
      return '/clients';
    case 'assignment':
      return '/assignment';
    case 'analytics':
      return '/analytics';
    case 'user':
      return '/administrators';
  }
}