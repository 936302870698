<template>
  <div class="account-content">
    <GoBack />

    <AppLoader v-if="loading.tariffs" />

    <div v-else class="school-cabinet">
      <div class="school-cabinet_white-box">
        <div class="school-cabinet_info">
          <template v-if="profile.user.permission.instructor.tariffsChange">
            <div class="school-cabinet_info-title text-left mb-4 pb-1"><strong>Добавить тариф</strong></div>

            <div class="school-cabinet_info-form school-cabinet_info-form--full">
              <div class="school-cabinet_info-row">
                <div class="d-flex justify-content-between">
                  <div class="school-cabinet_info-field">
                    <div class="school-cabinet_info-row-label">Категория</div>
                    <DropDownMenuCategory
                      :list="driverCategories"
                      @selected="selectCategory"
                    />
                  </div>

                  <div class="school-cabinet_info-field">
                    <div class="school-cabinet_info-row-label">КПП</div>
                    <DropDownMenuTransmission
                      :list="listTransmission"
                      @selected="selectTransmission"
                    />
                  </div>
                </div>
              </div>
              <div class="school-cabinet_info-row mb-5">
                <div class="d-flex justify-content-between">
                  <div class="school-cabinet_info-field">
                    <div class="school-cabinet_info-row-label">Продолжительность</div>
                    <DropDownMenuDurations
                      :list="durations"
                      @selected="selectDuration"
                    />
                  </div>

                  <div class="school-cabinet_info-field form-group">
                    <div class="school-cabinet_info-row-label">Цена, руб.</div>
                    <input
                      v-model.trim="tariff.tariff.price"
                      @focus="hideErrorHighlight('price'); $v.tariff.tariff.price.$reset()"
                      type="text"
                      placeholder="Введите цену"
                      :class="{ 'form-control--error': $v.tariff.tariff.price.$error || errors.includes('price') }"
                      class="form-control form-control--bordered"
                    >

                    <div class="text-error" v-if="!$v.tariff.tariff.price.required && $v.tariff.tariff.price.$dirty">Поле обязательно для заполнения</div>
                    <div class="text-error" v-if="!$v.tariff.tariff.price.numeric && $v.tariff.tariff.price.$dirty">Введите сумму</div>
                  </div>
                </div>
              </div>
              <div class="school-cabinet_info-button text-center">
                <button
                  v-if="loading.newTariff"
                  class="btn btn-blue btn-blue--custom"
                >
                  <AppLoader2 :mini="true" :color="'#ffffff'" />
                </button>

                <button
                  v-else
                  @click="tariffAdd"
                  class="btn btn-blue btn-blue--custom"
                >
                  Добавить
                </button>
              </div>
            </div>
          </template>

          <div v-if="tariffs.length" class="tariff__list">
            <div class="h3">Добавленные тарифы</div>

            <div class="tariff__rows">
              <TariffItem
                v-for="tariff in tariffs"
                :key="tariff.tariff_id"
                :tariff="tariff"
              />

              <infinite-loading @infinite="infiniteHandler">
                <div slot="spinner">
                  <AppLoader2 :mini="true" class="mt-4" />
                </div>
                <div slot="no-more"></div>
                <div slot="no-results"></div>
              </infinite-loading>
            </div>
          </div>

          <div v-else class="school-cabinet_info-note text-center">У инструктора пока нет добавленных тарифов</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import { mapActions, mapGetters } from 'vuex';
import Vuelidate from 'vuelidate';
import VModal from 'vue-js-modal';
import InfiniteLoading from 'vue-infinite-loading';

import TariffItem from '@/components/Instructor/TariffItem.vue';
import DropDownMenuDurations from '@/components/DropDownMenu/Durations.vue';
import DropDownMenuCategory from '@/components/DropDownMenu/Category.vue';
import DropDownMenuTransmission from '@/components/DropDownMenu/Transmission.vue';
import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import GoBack from '@/components/GoBack.vue';

import { required, numeric } from 'vuelidate/lib/validators'

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';
import hideErrorHighlight from '@/helpers/hideErrorHighlight';

Vue.use(Vuelidate);
Vue.use(VModal, { dialog: true });

export default {
  name: 'AppTariffs',

  components: {
    TariffItem,
    DropDownMenuDurations,
    DropDownMenuCategory,
    DropDownMenuTransmission,
    AppLoader,
    AppLoader2,
    InfiniteLoading,
    GoBack
  },

  data() {
    return {
      tariff: {
        tariff: {
          driver_category: 'B',
          transmission: 'AT',
          duration: 60,
          price: '',
        }
      },
      listTransmission: ['АКПП', 'МКПП'],
      errors: []
    }
  },

  computed: {
    ...mapGetters({
      tariffs: 'tariffs',
      tariffsAllData: 'tariffsAllData',
      loading: 'loadingInstructors',
      durations: 'durations',
      driverCategories: 'driverCategories',
      profile: 'profile'
    })
  },

  created() {
    const payload = {
      idInstructor: this.$route.params.id,
      loading: true
    }

    this.getTariffs(payload);

    this.getDurations();
    this.getDriverCategories();
  },

  beforeDestroy() {
    this.clearTariffs();
    this.resetPageTariffs();
  },

  methods: {
    ...mapActions([
      'getTariffs',
      'addTariff',
      'clearTariffs',
      'resetPageTariffs',
      'getDurations',
      'getDriverCategories',
    ]),
    tariffAdd() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const data = {
          idInstructor: this.$route.params.id,
          tariff: this.tariff,
        }

        this.addTariff(data)
          .then(() => {
            this.tariff.tariff.price = '';

            this.$v.$reset();

            this.$toast.open({
              message: 'Тариф добавлен',
              type: 'success'
            });
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);

            if (error.response.data.errors) {
              this.errorsCheck(error.response.data.errors);
            }
          })
      }
    },
    selectDuration(li) {
      this.tariff.tariff.duration = li.duration;
    },
    selectTransmission(li) {
      li === 'АКПП'
        ? this.tariff.tariff.transmission = 'AT'
        : this.tariff.tariff.transmission = 'MT';
    },
    selectCategory(li) {
      this.tariff.tariff.driver_category = li.category;
    },
    infiniteHandler($state) {
      if (this.tariffs.length < this.tariffsAllData.meta.entries.total && this.tariffsAllData.tariffs.length) {
        const payload = {
          idInstructor: this.$route.params.id,
          loading: false
        }

        this.getTariffs(payload)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    errorsCheck(errors) {
      Object.keys(errors).forEach(el => {
        if (el === 'tariff.price') {
          this.errors.push('price');
        }
      })
    },
    errorHandlerForResponse,
    hideErrorHighlight,
  },

  validations: {
    tariff: {
      tariff: {
        price: {
          required,
          numeric
        },
      }
    }
  }
}
</script>