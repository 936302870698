<template>
  <div class="account-content">
    <GoBack />

    <AppLoader v-if="loading.comments || loading.report" />

    <template v-else>
      {{ report }}
      <div class="account-top mb-3 justify-content-start  flex-row"> 
        <div class="account-subtitle pt-0 mr-4">{{ report.title }}</div>
        <div class="account-report-status active"
          v-if="report.status === 'completed'"
        >
          {{ getReportStatus(report.status) }}
        </div>
      </div>
      <div class="report">
        <div class="wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
          <div class="report__chat">
            <div class="report__messege"
              v-for="comment in comments"
              :key="comment.comment_id"
              :comment="comment"
            >
              <div class="report__messege-avatar">
                <img v-if="comment.user.avatar_url" :src="comment.user.avatar_url" class="report__messege-img">
                <img v-else src="@/assets/img/noavatar.svg" class="report__messege-img">
              </div>
              <div class="report__messege-body">
                <div class="report__messege-info">
                  <div class="report__messege-name">
                    {{ comment.user.name.first }} {{ comment.user.name.last }}
                  </div>
                  <div class="report__messege-date">
                    {{ formatDate(comment.created_at) }}
                  </div>
                </div>                           
                <div class="report__messege-text">
                  <span v-html="comment.text"></span>
                </div>
              </div>
            </div>
          </div> 
          <form class="report__form">
            <div class="report__select">
              <DropDownMenuTeachers
                :list="report.teachers.users"
                :placeholder="'Преподаватель'"
                @select="selectTeacher"
                :clientId="comment.comment.user_id"
              />
            </div>
            <div class="report__checkbox" v-if="report.status !== 'completed'">
              <div class="custom-checkbox">
                <input v-model="comment.comment.is_completed" type="checkbox" name="district" id="district-2">
                <label for="district-2" class="d-flex">Занятие зачтено</label>
              </div>
            </div>
            <div class="report__textarea">
              <div class="form-control-container">
                <textarea 
                  v-model="comment.comment.text"
                  cols="30" 
                  rows="10" 
                  placeholder="Ответить" 
                  class="form-control  form-control--bordered"
                >
                </textarea>
                <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />
                <button v-if="comment.comment.text.length > 1" @click="createComment" type="button" class="form-control-send" :style="`background-image: url(${require ('@/assets/img/send-active.svg')}`"></button>
                <button v-else type="button" class="form-control-send" style="cursor: default;"></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import GoBack from '@/components/GoBack.vue';
import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';
import DropDownMenuTeachers from '@/components/DropDownMenu/DropDownMenuTeachers.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';
export default {
  name: 'ReportComments',

  components: {
    GoBack,
    DropDownMenuTeachers,
    AppLoader,
    AppLoader3,
  },

  data() {
    return {
      isLoadingPage: false,
      comment: {
        comment: {
          text: '',
          user_id: null,
          is_completed: false,
        }
      },
    }
  },

  created() {
    let payload_comments = {
      id: this.$route.params.id,
      laoading: true,
    }
    this.getComments(payload_comments).then(() => {
      if (this.report.status === 'completed') {
        this.comment.comment.is_completed = true
      }
    })
  },

  destroyed() {
    this.clearReportsComments();
  },

  computed: {
    ...mapGetters({
      comments: 'comments',
      report: 'report',
      loading: 'loadingReports',
    })
  },
  methods: {
    ...mapActions([
      'getComments',
      'createReportComment',
      'clearReportsComments',
    ]),
    getReportStatus(code) {
      if (code === 'completed') {
        return 'Зачтено'
      }
    },
    formatDate(date) {
			return moment(date).format('DD.MM.YYYY, HH:mm');
		},
    createComment() {
      if (!this.comment.comment.text) {
        this.$toast.open({
          message: 'Введите текст',
          type: 'warning'
        });

        return;
      }

      this.isLoadingPage = true;

      const payload = {
        id: this.$route.params.id,
        comment: this.comment,
      }

      this.createReportComment(payload)
        .then(() => {
          let payload_comments = {
            id: this.$route.params.id,
            laoading: false,
          }
          this.getComments(payload_comments)
            .then(() => {
              this.comment.comment.text = '';

              this.$toast.open({
                message: 'Комментарий создан',
                type: 'success'
              });
            })
            .catch((error) => {
              this.errorHandlerForResponse(error);
            })
            .finally(() => {
              this.isLoadingPage = false
            });
        })
        .catch((error) => {

          this.errorHandlerForResponse(error);
        });
    },
    selectTeacher(user) {
      this.comment.comment.user_id = user.user_id;
    },
    errorHandlerForResponse
  }
}
</script>

<style>
.report__messege-text {
  white-space: break-spaces;
}
</style>