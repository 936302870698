<template>
  <div class="account-content">
    <GoBack />

    <div class="school-cabinet">
      <div class="school-cabinet_white-box">
        <div class="school-cabinet_info">
          <div class="school-cabinet_info-title text-center">Вы можете создать учетную запись для сотрудников <br>автошколы. Заполните необходимые поля, и предоставьте <br>им данные для входа на платформу LevelApp.</div>
          <div class="school-cabinet_info-form school-cabinet_info-form--profile">
            <div class="school-cabinet_info-row mb-4">
              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="administrator.user.name.first"
                  @focus="hideErrorHighlight('name.first'); $v.administrator.user.name.first.$reset()"
                  type="text"
                  placeholder="Имя"
                  :class="{ 'form-control--error': $v.administrator.user.name.first.$error || errors.includes('name.first') }"
                  class="form-control form-control--bordered"
                >
                <div class="text-error" v-if="!$v.administrator.user.name.first.required && $v.administrator.user.name.first.$dirty">Поле обязательно для заполнения</div>
                <div class="text-error" v-if="!$v.administrator.user.name.first.alpha && $v.administrator.user.name.first.$dirty">Вводите только буквы</div>
              </div>

              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="administrator.user.name.last"
                  @focus="hideErrorHighlight('name.last'); $v.administrator.user.name.last.$reset()"
                  type="text"
                  placeholder="Фамилия"
                  :class="{ 'form-control--error': $v.administrator.user.name.last.$error || errors.includes('name.last') }"
                  class="form-control form-control--bordered"
                >
                <div class="text-error" v-if="!$v.administrator.user.name.last.required && $v.administrator.user.name.last.$dirty">Поле обязательно для заполнения</div>
                <div class="text-error" v-if="!$v.administrator.user.name.last.alpha && $v.administrator.user.name.last.$dirty">Вводите только буквы</div>
              </div>

              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="administrator.user.name.middle"
                  @focus="hideErrorHighlight('name.middle'); $v.administrator.user.name.middle.$reset()"
                  type="text"
                  placeholder="Отчество"
                  :class="{ 'form-control--error': $v.administrator.user.name.middle.$error || errors.includes('name.middle') }"
                  class="form-control form-control--bordered"
                >
                <div class="text-error" v-if="!$v.administrator.user.name.middle.alpha && $v.administrator.user.name.middle.$dirty">Вводите только буквы</div>
              </div>

              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="administrator.user.email"
                  @focus="hideErrorHighlight('email'); $v.administrator.user.email.$reset()"
                  type="text"
                  placeholder="E-mail"
                  :class="{ 'form-control--error': $v.administrator.user.email.$error || errors.includes('email') }"
                  class="form-control form-control--bordered"
                >
                <div class="text-error" v-if="!$v.administrator.user.email.required && $v.administrator.user.email.$dirty">Поле обязательно для заполнения</div>
                <div class="text-error" v-if="!$v.administrator.user.email.email && $v.administrator.user.email.$dirty">Введите вашу почту</div>
              </div>

              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="administrator.user.password"
                  @focus="hideErrorHighlight('password'); $v.administrator.user.password.$reset()"
                  type="password"
                  placeholder="Пароль"
                  :class="{ 'form-control--error': $v.administrator.user.password.$error || errors.includes('password') }"
                  class="form-control form-control--bordered"
                >
                <div class="text-error" v-if="!$v.administrator.user.password.required && $v.administrator.user.password.$dirty">Поле обязательно для заполнения</div>
                <div class="text-error" v-if="!$v.administrator.user.password.minLength && $v.administrator.user.password.$dirty">Пароль должен быть не менее 8 символов</div>
              </div>

              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="administrator.user.password_confirmation"
                  @focus="hideErrorHighlight('password_confirmation'); $v.administrator.user.password_confirmation.$reset()"
                  type="password"
                  placeholder="Повторите пароль"
                  :class="{ 'form-control--error': $v.administrator.user.password_confirmation.$error || errors.includes('password_confirmation') }"
                  class="form-control form-control--bordered"
                >
                <div class="text-error" v-if="!$v.administrator.user.password_confirmation.sameAsPassword && $v.administrator.user.password_confirmation.$dirty">Пароль не совпадает</div>
              </div>
            </div>

            <div class="school-cabinet_info-row form-group mb-5">
              <div class="school-cabinet_info-row-label mb-2">Разрешения</div>
              <div class="school-cabinet_info-field">
                <div class="radio d-flex flex-wrap align-items-center">
                  <div class="custom-checkbox" v-for="(premission, key) in permissions" :key="key">
                      <input
                        v-model="administrator.user.permissions"
                        type="checkbox"
                        name="district"
                        :id="key"
                        :value="key"
                      >
                      <label :for="key" class="d-flex">{{premission}}</label>
                    </div>
                </div>
              </div>

              <div class="text-error" v-if="!$v.administrator.user.permissions.required && $v.administrator.user.permissions.$dirty">Выберите разрешение</div>
            </div>

            <button v-if="loading.newAdministrator" class="btn btn-green btn-full btn-blue--custom">
              <AppLoader2 :mini="true" :color="'#ffffff'" />
            </button>

            <button v-else @click="addAdministrator" class="btn btn-green btn-full btn-blue--custom">Добавить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Vuelidate from 'vuelidate';

import { required, email, sameAs, minLength, helpers } from 'vuelidate/lib/validators';
const alpha = helpers.regex('alpha', /^[a-zA-Zа-яА-Я]*$/);

import AppLoader2 from '@/components/AppLoader/2.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';
import hideErrorHighlight from '@/helpers/hideErrorHighlight';
import GoBack from '@/components/GoBack.vue';

Vue.use(VueToast);
Vue.use(Vuelidate);

export default {
  name: 'AdministratorCreate',

  components: {
    AppLoader2,
    GoBack
  },

  data() {
    return {
      administrator: {
        user: {
          name: {
            first: '',
            last: '',
            middle: ''
          },
          email: '',
          password: '',
          password_confirmation: '', // опциональное поле
          permissions: []
        }
      },
      errors: []
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loadingAdministrators',
      permissions: 'permissionsList',
    })
  },

  async created() {
    await this.getPermissionsList();
  },

  methods: {
    ...mapActions([
      'createAdministrator',
      'getPermissionsList',
    ]),
    addAdministrator() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.$toast.open({
          message: 'Заполните все поля правильно',
          type: 'warning'
        });
      } else {
        this.createAdministrator(this.administrator)
          .then(() => {
            this.$toast.open({
              message: 'Учетная запись успешно создана',
              type: 'success'
            });

            this.$router.push('/administrators');
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);

            if (error.response.data.errors) {
              this.errorsCheck(error.response.data.errors);
            }
          });
      }
    },
    errorsCheck(errors) {
      Object.keys(errors).forEach(el => {
        switch (el) {
          case 'user.name.first':
            this.errors.push('name.first');
            break;
          case 'user.name.last':
            this.errors.push('name.last');
            break;
          case 'user.name.middle':
            this.errors.push('name.middle');
            break;
          case 'user.email':
            this.errors.push('email');
            break;
          case 'user.password':
            this.errors.push('password');
            break;
          case 'user.password_confirmation':
            this.errors.push('password_confirmation');
            break;
        }
      })
    },
    errorHandlerForResponse,
    hideErrorHighlight
  },

  validations: {
    administrator: {
      user: {
        email: {
          required,
          email
        },
        name: {
          first: {
            required,
            alpha
          },
          last: {
            required,
            alpha
          },
          middle: {
            alpha
          }
        },
        password: {
          required,
          minLength: minLength(8)
        },
        password_confirmation: {
          sameAsPassword: sameAs('password')
        },
        permissions: {
          required
        },
      }
    }
  }
}
</script>