<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="account-subtitle pt-0">Занятия клиента</div>
        <a href="javascript:void(0)" class="school-cabinet__lessons-link d-flex align-items-center">
          <i class="fas fa-list"></i>

          <router-link
            v-if="clientLessons"
            :to="`/clients/${clientLessons.client.client_id}/lessons`"
            target="_blank"
            class="school-cabinet__lessons-link-text"
          >
            Список занятий
          </router-link>

          <div
            v-else
            class="school-cabinet__lessons-link-text"
          >
            Список занятий
          </div>
        </a>
      </div>

      <AppLoader2 v-if="loading.clientLessonsGroupedInstructor" :middle="true" />

      <template v-else>
        <template v-if="clientLessons.lessons_statistics.lessons.length">
          <ClientLessonsGroupedInstructorCard
            v-for="(lesson, index) in clientLessons.lessons_statistics.lessons"
            :key="index"
            :lesson="lesson"
            :client="clientLessons.client"
          />
        </template>

        <p v-else>Занятий нет</p>
      </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AppLoader2 from '@/components/AppLoader/2.vue';
import ClientLessonsGroupedInstructorCard from '@/components/Client/Lessons/GroupedInstructor/Card.vue';

export default {
  name: 'ClientLessonsGroupedInstructorList',

  components: {
    ClientLessonsGroupedInstructorCard,
    AppLoader2
  },

  props: {
    clientLessons: {
      typeof: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loadingClients'
    })
  }
}
</script>