<template>
  <div class="school-cabinet_card">
    <div class="school-cabinet_card-top d-flex align-items-center flex-wrap">
      <router-link :to="`/study-groups/${group.study_group_id}/clients`" class="school-cabinet_card-content d-flex align-items-center mb-3 mb-md-0">
        <div class="school-cabinet_card-text">
          <div class="school-cabinet_card-name">{{ group.name }}</div>
        </div>
      </router-link>
      <div class="school-cabinet_card-info ml-md-auto mr-3 mr-md-5">
        <!-- <div class="school-cabinet_card-info-item">
          <div class="school-cabinet_card-info-text mb-1"><span class="light">Дата экзамена</span></div>
          <div class="school-cabinet_card-info-title mb-0"><strong>12.11.2022</strong></div>
        </div> -->
      </div>
      <router-link :to="`/study-groups/${group.study_group_id}/clients`" class="school-cabinet_card-button school-cabinet_card-button--lessons school-cabinet_card-button--group d-flex justify-content-center align-items-center mr-0 ml-auto ml-md-0">
        <div class="school-cabinet_card-button-icon"></div>
        <div class="school-cabinet_card-button-title">{{ group.clients_count }}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudyGroupsCard',

  props: {
    group: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
}
</script>