<template>
  <div class="account-content">
    <iframe :src="`${url}?token=${bearer}`" frameborder="0" width="100%" style="min-height: 130vh;"></iframe>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ServicesIntegrations',
  data() {
    return {
      bearer: localStorage.getItem('token') 
    }
  },
  computed: {
    url() {
      return this.profile?.external_services.find(s => s.type === 'hub')?.url;
    },
    ...mapGetters({
      profile: 'profile',
    })
  }
}
</script>
