import instance from '../instance';
import settings from '../settings';

export default {
  state: {
    administrators: null,
    administrator: null,
    pageAdministrators: 1,
    filtersAdministrators: {
      page: 1,
      page_size: 20,
      query: ''
    },
    loading: {
      administrators: true,
      administrator: true,
      editAdministrator: false,
      changePassword: false,
      newAdministrator: false
    }
  },

  getters: {
    administrators: state => state.administrators,
    pageAdministrators: state => state.filtersAdministrators.page,
    filtersAdministrators: state => state.filtersAdministrators,
    administrator: state => state.administrator,
    loadingAdministrators: state => state.loading,
  },

  actions: {
    async getAdministrators({ commit, state }, loading = true) {
      if (loading) {
        commit('START_GET_ADMINISTRATORS_LOADING');
      }

      try {
        const requestId = 'administrators';

        const params = Object.fromEntries(Object.entries(state.filtersAdministrators).filter(([key, value]) => key && value));

        const administrators = await instance.get(`${settings.API_URL}/users`, { requestId, params });

        commit('SET_ADMINISTRATORS', administrators.data);
      } finally {
        commit('FINISH_GET_ADMINISTRATORS_LOADING');
      }
    },
    clearAdministrators({ commit }) {
      commit('CLEAR_ADMINISTRATORS');
    },
    changePageAdministrators({ commit }, page) {
      commit('CHANGE_PAGE_ADMINISTRATORS', page);
    },
    changeFiltersSearchAdministrators({ commit }, search) {
      commit('CHANGE_FILTERS_SEARCH_ADMINISTRATORS', search);
    },
    resetFiltersAdministrators({ commit }) {
      commit('CHANGE_PAGE_ADMINISTRATORS', 1);

      commit('CHANGE_FILTERS_SEARCH_ADMINISTRATORS', null);
    },
    async getAdministrator({ commit }, idAdministrator) {
      commit('START_GET_ADMINISTRATOR_LOADING');

      try {
        const administrator = await instance.get(`${settings.API_URL}/users/${idAdministrator}`);

        commit('SET_ADMINISTRATOR', administrator.data.user);
      } finally {
        commit('FINISH_GET_ADMINISTRATOR_LOADING');
      }
    },
    clearAdministrator({ commit }) {
      commit('CLEAR_ADMINISTRATOR');
    },
    async editAdministrator({ commit }, payload) {
      commit('START_EDIT_ADMINISTRATOR_LOADING');

      try {
        const newDataAdministrator = await instance.patch(`${settings.API_URL}/users/${payload.idAdministrator}`, payload.administrator);

        return newDataAdministrator.data;
      } finally {
        commit('FINISH_EDIT_ADMINISTRATOR_LOADING');
      }
    },
    async changePasswordAdministrator({ commit }, payload) {
      commit('START_CHANGE_PASSWORD_ADMINISTRATOR_LOADING');

      try {
        await instance.post(`${settings.API_URL}/users/${payload.idAdministrator}/password`, payload.password);
      } finally {
        commit('FINISH_CHANGE_PASSWORD_ADMINISTRATOR_LOADING');
      }
    },
    async createAdministrator({ commit }, data) {
      commit('START_CREATE_ADMINISTRATOR_LOADING');

      try {
        await instance.post(`${settings.API_URL}/users`, data);
      } finally {
        commit('FINISH_CREATE_ADMINISTRATOR_LOADING');
      }
    },
    async deleteAdministrator({ commit }, idAdministrator) {
      await instance.delete(`${settings.API_URL}/users/${idAdministrator}`);

      commit('DELETE_ADMINISTRATOR_FROM_ADMINISTRATORS', idAdministrator);
    },
  },

  mutations: {
    SET_ADMINISTRATORS(state, administrators) {
      state.administrators = administrators;
    },
    CLEAR_ADMINISTRATORS(state) {
      state.administrators = null;
    },
    CHANGE_PAGE_ADMINISTRATORS(state, page) {
      state.filtersAdministrators.page = page;
    },
    CHANGE_FILTERS_SEARCH_ADMINISTRATORS(state, search) {
      state.filtersAdministrators.query = search;
    },
    START_GET_ADMINISTRATORS_LOADING(state) {
      state.loading.administrators = true;
    },
    FINISH_GET_ADMINISTRATORS_LOADING(state) {
      state.loading.administrators = false;
    },

    SET_ADMINISTRATOR(state, administrator) {
      state.administrator = administrator;
    },
    START_GET_ADMINISTRATOR_LOADING(state) {
      state.loading.administrator = true;
    },
    FINISH_GET_ADMINISTRATOR_LOADING(state) {
      state.loading.administrator = false;
    },
    CLEAR_ADMINISTRATOR(state) {
      state.administrator = null;
    },

    START_EDIT_ADMINISTRATOR_LOADING(state) {
      state.loading.editAdministrator = true;
    },
    FINISH_EDIT_ADMINISTRATOR_LOADING(state) {
      state.loading.editAdministrator = false;
    },

    START_CHANGE_PASSWORD_ADMINISTRATOR_LOADING(state) {
      state.loading.changePassword = true;
    },
    FINISH_CHANGE_PASSWORD_ADMINISTRATOR_LOADING(state) {
      state.loading.changePassword = false;
    },

    START_CREATE_ADMINISTRATOR_LOADING(state) {
      state.loading.newAdministrator = true;
    },
    FINISH_CREATE_ADMINISTRATOR_LOADING(state) {
      state.loading.newAdministrator = false;
    },

    DELETE_ADMINISTRATOR_FROM_ADMINISTRATORS(state, idAdministrator) {
      const indexAdministrator = state.administrators.users.findIndex(el => el.user_id === idAdministrator);

      if (indexAdministrator !== -1) {
        state.administrators.users.splice(indexAdministrator, 1);
      }
    },
  },
};