export default function setPermission(profile) {
  const profileNew = Object.assign({}, profile);

  profileNew.user.permission = {};

  profileNew.user.permission.instructor = {};
  profileNew.user.permission.client = {};
  profileNew.user.permission.assignment = {};
  profileNew.user.permission.analytics = {};
  profileNew.user.permission.user = {};
  profileNew.user.permission.activity = {};
  profileNew.user.permission.settlements = {};
  profileNew.user.permission.school_settings = {};
  profileNew.user.permission.school_requisites = {};
  profileNew.user.permission.service_hub = {};
  profileNew.user.permission.service_exams = {};
  profileNew.user.permission.reports = {};
  profileNew.user.permission.orders = {};

  if (profileNew.user.permissions.includes('instructor')) {
    profileNew.user.permission.instructor['view'] = true;
    profileNew.user.permission.instructor['create'] = true;
    profileNew.user.permission.instructor['delete'] = true;
    profileNew.user.permission.instructor['edit'] = true;
    profileNew.user.permission.instructor['notes'] = true;
    profileNew.user.permission.instructor['lessons'] = true;
    profileNew.user.permission.instructor['tariffs'] = true;
    profileNew.user.permission.instructor['tariffsChange'] = true;
    profileNew.user.permission.instructor['clients'] = true;

    //profileNew.user.permission.client['view'] = true;
  }

  if (profileNew.user.permissions.includes('client')) {
    profileNew.user.permission.client['view'] = true;
    profileNew.user.permission.client['create'] = true;
    profileNew.user.permission.client['delete'] = true;
    profileNew.user.permission.client['edit'] = true;
    profileNew.user.permission.client['lessons'] = true;
    profileNew.user.permission.client['notes'] = true;
  }

  if (profileNew.user.permissions.includes('assignment')) {
    profileNew.user.permission.client['view'] = true;
    profileNew.user.permission.client['lessons'] = true;
    profileNew.user.permission.client['notes'] = true;
    profileNew.user.permission.client['returnToAvailable'] = true;

    profileNew.user.permission.assignment['view'] = true;
  }

  if (profileNew.user.permissions.includes('analytics')) {
    profileNew.user.permission.analytics['view'] = true;
  }

  if (profileNew.user.permissions.includes('user')) {
    profileNew.user.permission.user['view'] = true;
  }

  if (profileNew.user.permissions.includes('activity')) {
    profileNew.user.permission.activity['view'] = true;
  }

  if (profileNew.user.permissions.includes('settlements')) {
    profileNew.user.permission.settlements['view'] = true;
  }

  if (profileNew.user.permissions.includes('school_settings')) {
    profileNew.user.permission.school_settings['view'] = true;
  }

  if (profileNew.user.permissions.includes('school_requisites')) {
    profileNew.user.permission.school_requisites['view'] = true;
  }
  if (profileNew.user.permissions.includes('service-hub')) {
    profileNew.user.permission.service_hub['view'] = true;
  }
  if (profileNew.user.permissions.includes('service-exams')) {
    profileNew.user.permission.service_exams['view'] = true;
  }
  if (profileNew.user.permissions.includes('reports')) {
    profileNew.user.permission.reports['view'] = true;
  }
  if (profileNew.user.permissions.includes('orders')) {
    profileNew.user.permission.orders['view'] = true;
  }
  if (profileNew.user.permissions.includes('delete-payments')) {
    profileNew.user.permission.orders['payments_delete'] = true;
  }

  if (profileNew.user.permissions.includes('create-orders')) {
    profileNew.user.permission.orders['edit'] = true;
  }

  return profileNew;
}