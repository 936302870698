<template>
  <div class="drop-down-menu">
    <div
      v-click-outside="hide"
      @click="opened = !opened"
      :class="{ 'border-bottom-none': opened && !disabled}"
      class="form-control form-control--bordered"
    >
      {{ status.name || 'Статус'}}

      <span
        :class="{ rotate: opened && !disabled }"
        class="form-control--arrow"
      ></span>
    </div>

    <ul v-if="opened && !disabled" class="form-control--custom">
      <li v-if="status" @click="resetStatus">Любой статус</li>

      <li
        v-for="(status, index) in statuses"
        :key="index"
        @click="select(status)"
      >
        {{ status.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

const statuses = [
  {
    name: 'Доступно',
    value: 'available'
  },
  {
    name: 'Запланировано',
    value: 'planned'
  },
  {
    name: 'Началось',
    value: 'in_progress'
  },
  {
    name: 'Успешно завершилось',
    value: 'completed'
  }
]

export default {
  name: 'DropDownMenuStatus',

  props: {
    filtersStatus: {
      typeof: String,
      required: true
    },
    disabled: {
      typeof: Boolean,
      required: false
    }
  },

  directives: {
    ClickOutside,
  },

  data() {
    return {
      opened: false,
      status: '',
      statuses: statuses
    }
  },

  // computed: {
  //   ...mapGetters({
  //     statusFilters: 'instructorLessonsFiltersStatus'
  //   })
  // },

  watch: {
    filtersStatus(filtersStatus) {
      if (!filtersStatus) {
        this.status = '';
      }
    }
  },

  created() {
    if (this.statusFilters) {
      this.status = this.statuses.find(el => el.value === this.statusFilters);
    }
  },

  mounted () {
    this.popupItem = this.$el
  },

  methods: {
    hide() {
      this.opened = false;
    },
    select(status) {
      this.status = status;

      this.hide();

      this.$emit('select', status.value);
    },
    resetStatus() {
      this.status = '';

      this.hide();

      this.$emit('select', 'reset');
    }
  }
}
</script>
