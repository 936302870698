<template>
  <div class="school-cabinet_card">
    <div class="school-cabinet_card-top d-flex align-items-center flex-wrap">
      <router-link
        :to="`/instructors/${instructor.instructor_id}/profile`"
        class="school-cabinet_card-icon"
        style="width: 48px; height: 48px"
      >
        <img v-if="instructor.avatar" :src="instructor.avatar.url" :alt="instructor.name.full">
        <img v-else src="@/assets/img/noavatar.svg" :alt="instructor.name.full">
      </router-link>

      <div class="school-cabinet_card-text">
        <router-link
          :to="`/instructors/${instructor.instructor_id}/profile`"
          class="d-flex align-items-center"
        >
          <div class="school-cabinet_card-name">{{ instructor.name.full }} {{ instructor.name.middle }}</div>
          <div v-if="instructor.pro.status === 'active'" class="school-cabinet_card-pro d-flex align-items-center justify-content-center">PRO</div>
        </router-link>

        <div class="school-cabinet_card-facts">{{ instructor.email }}<template v-if="instructor.phone">,</template> <span v-if="instructor.phone" class="white-space-nowrap">{{ instructor.phone }}</span></div>
      </div>

      <div ref="cardButtonsMobile" class="school-cabinet_card-buttons d-flex flex-nowrap d-md-none mt-4 mt-md-3 flex-wrap align-items-center mb-3">
        <router-link
          v-if="profile.user.permission.instructor.lessons && showButtons.lessons"
          :to="`/instructors/${instructor.instructor_id}/lessons`"
          class="school-cabinet_card-button d-flex justify-content-center align-items-center"
        >
          <div class="school-cabinet_card-button-icon">
            <i class="far fa-calendar-alt"></i>
          </div>
          <div ref="lessonsLink" class="school-cabinet_card-button-title">Занятия</div>
        </router-link>

        <router-link
          v-if="profile.user.permission.instructor.tariffs && showButtons.tariffs"
          :to="`/instructors/${instructor.instructor_id}/tariffs`"
          class="school-cabinet_card-button justify-content-center align-items-center d-flex"
        >
          <div class="school-cabinet_card-button-icon">
            <i class="fas fa-bars"></i>
          </div>
          <div ref="tariffsLink" class="school-cabinet_card-button-title">Тарифы</div>
        </router-link>

        <router-link
          v-if="profile.user.permission.instructor.clients && showButtons.clients"
          :to="{ name: 'Clients', query: { instructor_id: instructor.instructor_id } }"
          class="school-cabinet_card-button justify-content-center align-items-center d-flex"
        >
          <div class="school-cabinet_card-button-icon">
            <i class="fas fa-users"></i>
          </div>
          <div ref="clientsLink" class="school-cabinet_card-button-title">Клиенты</div>
        </router-link>

        <router-link
          v-if="profile.user.permission.instructor.notes && showButtons.notes"
          :to="`/instructors/${instructor.instructor_id}/notes`"
          class="school-cabinet_card-button d-flex justify-content-center align-items-center"
        >
          <div ref="notesLink" class="school-cabinet_card-button-title">Заметки ({{ instructor.statistics.notes }})</div>
        </router-link>

        <div class="school-cabinet_card-buttons-options d-none" :class="{ 'd-block': cardButtonsOptions }">
          <div ref="optionsIcon" class="school-cabinet_card-buttons-options-icon"></div>

          <div
            v-if="optionsMenu"
            ref="optionsMenu"
            class="school-cabinet_card-buttons-options-menu d-block"
          >
            <div v-if="profile.user.permission.instructor.tariffs && !showButtons.tariffs" class="school-cabinet_card-buttons-options-item">
              <router-link :to="`/instructors/${instructor.instructor_id}/tariffs`">Тарифы</router-link>
            </div>

            <div v-if="profile.user.permission.instructor.clients && !showButtons.clients" class="school-cabinet_card-buttons-options-item">
              <router-link :to="{ name: 'Clients', query: { instructor_id: instructor.instructor_id } }">Клиенты</router-link>
            </div>

            <div v-if="profile.user.permission.instructor.lessons && !showButtons.lessons" class="school-cabinet_card-buttons-options-item">
              <router-link :to="`/instructors/${instructor.instructor_id}/lessons`">Занятия</router-link>
            </div>

            <div v-if="profile.user.permission.instructor.notes && !showButtons.notes" class="school-cabinet_card-buttons-options-item">
              <router-link :to="`/instructors/${instructor.instructor_id}/notes`">Заметки ({{ instructor.statistics.notes }})</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center ml-auto">
        <router-link
          v-if="profile.user.permission.instructor.edit"
          :to="`/instructors/${instructor.instructor_id}/edit`"
          class="school-cabinet_card-edit ml-4"
        ></router-link>
      </div>
    </div>

    <div class="school-cabinet_card-buttons d-none d-md-flex mt-3 flex-wrap">
      <router-link
        v-if="profile.user.permission.instructor.lessons"
        :to="`/instructors/${instructor.instructor_id}/lessons`"
        class="school-cabinet_card-button d-flex justify-content-center align-items-center"
      >
        <div class="school-cabinet_card-button-icon">
          <i class="far fa-calendar-alt"></i>
        </div>
        <div class="school-cabinet_card-button-title">Занятия</div>
      </router-link>

      <router-link
        v-if="profile.user.permission.instructor.tariffs"
        :to="`/instructors/${instructor.instructor_id}/tariffs`"
        class="school-cabinet_card-button justify-content-center align-items-center d-flex"
      >
        <div class="school-cabinet_card-button-icon">
          <i class="fas fa-bars"></i>
        </div>
        <div class="school-cabinet_card-button-title">Тарифы</div>
      </router-link>

      <router-link
        v-if="profile.user.permission.instructor.clients"
        :to="{ name: 'Clients', query: { instructor_id: instructor.instructor_id } }"
        class="school-cabinet_card-button justify-content-center align-items-center d-flex"
      >
        <div class="school-cabinet_card-button-icon">
          <i class="fas fa-users"></i>
        </div>
        <div class="school-cabinet_card-button-title">Клиенты</div>
      </router-link>

      <router-link
        v-if="profile.user.permission.instructor.notes"
        :to="`/instructors/${instructor.instructor_id}/notes`"
        class="school-cabinet_card-button d-flex justify-content-center align-items-center"
      >
        <div class="school-cabinet_card-button-title">Заметки ({{ instructor.statistics.notes }})</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InstructorCard',

  props: {
    instructor: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      optionsMenu: false,
      cardButtonsOptions: false,
      showButtons: {
        tariffs: true,
        clients: true,
        lessons: true,
        notes: true,
      },
    }
  },

  computed: {
    ...mapGetters(['profile'])
  },

  mounted() {
    if (this.$refs.cardButtonsMobile.children.length > 3) {
      this.cardButtonsOptions = true;

      if (this.$refs.cardButtonsMobile.children[2].contains(this.$refs.clientsLink) || this.$refs.cardButtonsMobile.children[3]?.contains(this.$refs.clientsLink)) {
        this.showButtons.clients = false;
      }

      if (this.$refs.cardButtonsMobile.children[2].contains(this.$refs.tariffsLink) || this.$refs.cardButtonsMobile.children[3]?.contains(this.$refs.tariffsLink)) {
        this.showButtons.tariffs = false;
      }

      if (this.$refs.cardButtonsMobile.children[2].contains(this.$refs.lessonsLink) || this.$refs.cardButtonsMobile.children[3]?.contains(this.$refs.lessonsLink)) {
        this.showButtons.lessons = false;
      }

      if (this.$refs.cardButtonsMobile.children[2].contains(this.$refs.notesLink) || this.$refs.cardButtonsMobile.children[3]?.contains(this.$refs.notesLink)) {
        this.showButtons.notes = false;
      }
    }

    document.addEventListener('click', this.hideDropDownMenu);
  },

  destroyed() {
    document.removeEventListener('click', this.hideDropDownMenu);
  },

  methods: {
    hideDropDownMenu(event) {
      if (this.$refs.optionsIcon.contains(event.target)) {
        this.optionsMenu = !this.optionsMenu;
      } else {
        this.optionsMenu = false;
      }
    },
  }
}
</script>