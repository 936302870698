import Vue from 'vue'

import router from '../router'
import store from './index'

import axios from 'axios';
import axiosCancel from 'axios-cancel';

import settings from './settings';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

if (settings.BEARER) localStorage.setItem('token', settings.BEARER);

const headers = {
  'Authorization': `Bearer ${localStorage.getItem('token')}`,
  'Content-Type': 'application/json',
};

const instance = axios.create({
  headers,
});

instance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response && error.response.status === 401) {
    store.dispatch('logOut')
      .then(() => {
        store.dispatch('clearToken');
        store.dispatch('clearProfile');

        router.push('/login');
      });
  }

  if (error.response && error.response.status === 403) {
    router.push('/404');

    Vue.$toast.open({
      message: 'У вас нет прав доступа',
      type: 'error'
    });
  }

  if (error.response && error.response.status === 404) {
    router.push('/404');
  }

  if (error.response && error.response.status >= 500) {
    store.dispatch('setGlobalErrors');
  }

  return Promise.reject(error)
})

axiosCancel(instance);

export default instance;
