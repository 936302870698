<template>
  <div>
    <div
      v-click-outside="hide"
      @click.self="opened = !opened"
      class="filters-item_calendar"
    >
      <div @click.self="opened = !opened" class="filters-item_calendar-title">{{ dateFormat || placeholder }}</div>

      <div
        v-if="dateFormat && !disabled"
        @click="clearDate"
        class="ml-auto"
      >
        &#10006;
      </div>

      <div
        v-else
        @click.self="opened = !opened"
        class="filters-item_calendar-arrow"
      ></div>
    </div>

    <div v-if="opened && !disabled" ref="calendar">
      <date-picker
        @change="changeDate"
        @clear="clearDate"
        v-model="date"
        range
        inline
      >
      </date-picker>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropDownMenuCalendarOrders',

  components: {
    DatePicker
  },

  directives: {
    ClickOutside
  },

  props: {
    dateCalendar: {
      typeof: Array,
      required: false
    },
    filtersDate: {
      typeof: Boolean,
      required: false
    },
    disabled: {
      typeof: Boolean,
      required: false
    },
    placeholder: {
      typeof: String,
      default: 'Дата',
      required: false
    },
    actualDates: {
      typeof: [Object, null],
      required: false
    },
    dates: {
      typeof: Array,
      required: false
    }
  },

  data() {
    return {
      date: this.dateCalendar && this.dateCalendar.length ? this.dateCalendar : '' ,
      opened: false,
    }
  },

  computed: {
    dateFormat() {
      let date = false;

      if (this.date) {
        date = this.moment(this.actualDates.from).format('DD.MM.YYYY') + '-' + this.moment(this.actualDates.to).format('DD.MM.YYYY');
      }

      return date;
    },
  },

  watch: {
    actualDates(dates) {
      if (dates) {
        this.date = dates;
        this.hide();
      } else {
        this.date = '';
        this.hide();
      }
    },
    filtersDate(filtersDate) {
      if (!filtersDate) {
        this.date = '';
      }
    }
  },

  mounted () {
    this.popupItem = this.$el
  },

  methods: {
    hide() {
      this.opened = false;
    },
    changeDate(date) {
      let dateFormatted = {
        'from': moment(date[0]).format('YYYY-MM-DD'),
        'to': moment(date[1]).format('YYYY-MM-DD'),
      }
      this.$emit('changeDate', dateFormatted)
      this.opened = false
    },
    clearDate() {
      this.$emit('changeDate', null)
      this.opened = false
      this.date = null;
    },
    moment
  }
}
</script>

<style>
.filters-item_calendar-title {
  white-space: nowrap;
}

.mx-datepicker-sidebar {
  width: auto;
}

.mx-datepicker-sidebar + .mx-datepicker-content {
  margin-left: 150px;
}

.mx-datepicker-footer {
  display: none;
}

@media screen and (max-width: 990px) {
  .mx-datepicker-sidebar {
    display: none;
  }

  .mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 0;
    border-left: none;
  }

  .mx-datepicker-footer {
    display: block;
  }
}
</style>