import instance from '../instance';
import settings from '../settings';
import moment from 'moment';

export default {
  state: {
    instructors: null,
    instructor: null,
    instructorProfile: null,
    tariffsAllData: null,
    tariffs: [],
    tariffsLevelAppAllData: null,
    tariffsLevelApp: [],
    lessons: null,
    activatedTariff: null,
    deactivatedTariff: null,
    deletedTariff: null,
    notesAllData: null,
    notes: [],
    pageTariffs: 1,
    pageTariffsLevelApp: 1,
    pageNotes: 1,
    filtersForInstructors: {
      page: 1,
      page_size: 20,
      query: '',
      registered_from: '',
      registered_to: ''
    },
    filtersForInstructorLessons: {
      page: 1,
      page_size: 20,
      client_id: '',
      date_from: '',
      date_to: '',
      status: ''
    },
    loading: {
      instructors: true,
      instructor: true,
      newInstructor: false,
      editInstructor: false,
      deleteInstructor: false,
      changePassword: false,
      instructorProfile: true,
      tariffs: true,
      tariffsLevelApp: true,
      newTariff: false,
      lessons: true,
      notes: true,
      createNote: false
    }
  },

  getters: {
    instructors: state => state.instructors,
    pageInstructors: state => state.filtersForInstructors.page,
    filtersForInstructors: state => state.filtersForInstructors,
    instructor: state => state.instructor,
    instructorProfile: state => state.instructorProfile,
    tariffsAllData: state => state.tariffsAllData,
    tariffs: state => state.tariffs,
    tariffsLevelAppAllData: state => state.tariffsLevelAppAllData,
    tariffsLevelApp: state => state.tariffsLevelApp,
    instructorLessons: state => state.lessons,
    instructorLessonsPage: state => state.filtersForInstructorLessons.page,
    instructorLessonsFiltersStatus: state => state.filtersForInstructorLessons.status,
    instructorLessonsFiltersDate: state => Boolean(state.filtersForInstructorLessons.date_from) && Boolean(state.filtersForInstructorLessons.date_to),
    instructorLessonsFiltersClient: state => Boolean(state.filtersForInstructorLessons.client_id),
    instructorLessonsFiltersApplied: state =>
      Boolean(state.filtersForInstructorLessons.client_id)
      || Boolean(state.filtersForInstructorLessons.date_from) && Boolean(state.filtersForInstructorLessons.date_to)
      || Boolean(state.filtersForInstructorLessons.status),
    instructorNotesAllData: state => state.notesAllData,
    instructorNotes: state => state.notes,
    loadingInstructors: state => state.loading,
  },

  actions: {
    async getInstructors({ commit, state }, loading = true) {
      if (loading) {
        commit('START_GET_INSTRUCTORS_LOADING');
      }

      try {
        const requestId = 'instructors';

        const params = Object.fromEntries(Object.entries(state.filtersForInstructors).filter(([key, value]) => key && value));

        const instructors = await instance.get(`${settings.API_URL}/instructors`, { requestId, params });

        commit('SET_INSTRUCTORS', instructors.data);
      } finally {
        commit('FINISH_GET_INSTRUCTORS_LOADING');
      }
    },
    clearInstructors({ commit }) {
      commit('CLEAR_INSTRUCTORS');
    },
    changePageInstructors({ commit }, page) {
      commit('CHANGE_PAGE_INSTRUCTORS', page);
    },
    changeSearchInstructors({ commit }, search) {
      commit('CHANGE_SEARCH_INSTRUCTORS', search);
    },
    changeFiltersDateInstructors({ commit }, date) {
      commit('CHANGE_FILTERS_DATE_INSTRUCTORS', date);
    },
    resetFiltersInstructors({ commit }) {
      commit('CHANGE_PAGE_INSTRUCTORS', 1);

      commit('CHANGE_SEARCH_INSTRUCTORS', null);

      commit('CHANGE_FILTERS_DATE_INSTRUCTORS', { from: null, to: null });
    },
    addInstructorInListInstructors({ commit }, instructor) {
      commit('ADD_INSTRUCTOR_IN_LIST_INSTRUCTORS', instructor)
    },
    setLoadingInstructors({ commit }) {
      commit('SET_LOADING_INSTRUCTORS');
    },
    async instructorPause({ commit }, idInstructor) {
      const instructor = await instance.post(`${settings.API_URL}/instructors/${idInstructor}/pause`);

      commit('SET_INSTRUCTOR_PAUSE_OR_ACTIVATE', instructor.data.instructor);
    },
    async instructorActivate({ commit }, idInstructor) {
      const instructor = await instance.post(`${settings.API_URL}/instructors/${idInstructor}/activate`);

      commit('SET_INSTRUCTOR_PAUSE_OR_ACTIVATE', instructor.data.instructor);
    },
    async getInstructor({ commit }, idInstructor) {
      commit('START_GET_INSTRUCTOR_LOADING');

      try {
        const instructor = await instance.get(`${settings.API_URL}/instructors/${idInstructor}`);

        commit('SET_INSTRUCTOR', instructor.data.instructor);
      } finally {
        commit('FINISH_GET_INSTRUCTOR_LOADING');
      }
    },
    clearInstructor({ commit }) {
      commit('CLEAR_INSTRUCTOR');
    },
    async createInstructor({ commit }, instructor) {
      commit('START_CREATE_INSTRUCTOR_LOADING');

      try {
        await instance.post(`${settings.API_URL}/instructors`, instructor);
      } finally {
        commit('FINISH_CREATE_INSTRUCTOR_LOADING');
      }
    },
    async deleteInstructor({ commit }, idInstructor) {
      commit('START_DELETE_INSTRUCTOR_LOADING');

      try {
        await instance.delete(`${settings.API_URL}/instructors/${idInstructor}`);
      } finally {
        commit('FINISH_DELETE_INSTRUCTOR_LOADING');
      }
    },
    async editInstructor({ commit }, payload) {
      commit('START_EDIT_INSTRUCTOR_LOADING');
      payload.instructor.birthdate = moment(payload.instructor.birthdate, 'DD.MM.YYYY').format('YYYY-MM-DD');

      try {
        await instance.patch(`${settings.API_URL}/instructors/${payload.idInstructor}`, payload.instructor);
      } finally {
        commit('FINISH_EDIT_INSTRUCTOR_LOADING');
        payload.instructor.birthdate = moment(payload.instructor.birthdate, 'YYYY-MM-DD').format('DD.MM.YYYY');
      }
    },
    async changePasswordInstructor({ commit }, payload) {
      commit('START_CHANGE_PASSWORD_INSTRUCTOR_LOADING');

      try {
        await instance.post(`${settings.API_URL}/instructors/${payload.idInstructor}/password`, payload.password);
      } finally {
        commit('FINISH_CHANGE_PASSWORD_INSTRUCTOR_LOADING');
      }
    },
    async getInstructorProfile({ commit }, idInstructor) {
      commit('START_GET_INSTRUCTOR_PROFILE_LOADING');

      try {
        const instructorProfile = await instance.get(`${settings.API_URL}/instructors/${idInstructor}/profile`);

        commit('SET_INSTRUCTOR_PROFILE', instructorProfile.data);
      } finally {
        commit('FINISH_GET_INSTRUCTOR_PROFILE_LOADING');
      }
    },
    clearInstructorProfile({ commit }) {
      commit('CLEAR_INSTRUCTOR_PROFILE');
    },
    async getTariffs({ commit, state }, payload) {
      if (payload.loading) {
        commit('START_GET_TARIFFS_LOADING');
      }

      try {
        const tariffsAllData = await instance.get(`${settings.API_URL}/instructors/${payload.idInstructor}/tariffs?page=${state.pageTariffs}`);

        commit('SET_TARIFFS', tariffsAllData.data);
      } finally {
        commit('FINISH_GET_TARIFFS_LOADING');
      }
    },
    async getTariffsSelect({ commit, state }, payload) {
      commit('START_GET_TARIFFS_LOADING');
      try {
        const tariffsAllData = await instance.get(`${settings.API_URL}/instructors/${payload.idInstructor}/tariffs?page=${state.pageTariffs}`);

        commit('SET_TARIFFS', tariffsAllData.data);
      } finally {
        commit('FINISH_GET_TARIFFS_LOADING');
      }
    },
    clearTariffs({ commit }) {
      commit('CLEAR_TARIFFS');
    },
    finishTariffsLoading({ commit }) {
      commit('FINISH_GET_TARIFFS_LOADING');
    },
    startTariffsLoading({ commit }) {
      commit('START_GET_TARIFFS_LOADING');
    },
    resetPageTariffs({ commit }) {
      commit('RESET_PAGE_TARIFFS');
    },
    async getTariffsLevelApp({ commit, state }, payload) {
      if (payload.loading) {
        commit('START_GET_TARIFFS_LEVEL_APP_LOADING');
      }

      try {
        const tariffsAllData = await instance.get(`${settings.API_URL}/instructors/${payload.idInstructor}/tariffs/levelapp?page=${state.pageTariffsLevelApp}`);

        commit('SET_TARIFFS_LEVEL_APP', tariffsAllData.data);
      } finally {
        commit('FINISH_GET_TARIFFS_LEVEL_APP_LOADING');
      }
    },
    clearTariffsLevelApp({ commit }) {
      commit('CLEAR_TARIFFS_LEVEL_APP');
    },
    resetPageTariffsLevelApp({ commit }) {
      commit('RESET_PAGE_TARIFFS_LEVEL_APP');
    },
    startTariffsLevelAppLoading({ commit }) {
      commit('START_GET_TARIFFS_LEVEL_APP_LOADING');
    },
    async addTariff({ commit }, data) {
      commit('START_ADD_TARIFF_LOADING');

      try {
        const tariff = await instance.post(`${settings.API_URL}/instructors/${data.idInstructor}/tariffs`, data.tariff);

        commit('SET_NEW_TARIFF', tariff.data.tariff);
      } finally {
        commit('FINISH_ADD_TARIFF_LOADING');
      }
    },
    async tariffActivate({ commit }, data) {
      const activatedTariff = await instance.post(`${settings.API_URL}/instructors/${data.idInstructor}/tariffs/${data.idTariff}/activate`);

      commit('SET_ACTIVATED_TARIFF', activatedTariff.data);
    },
    async tariffInactivate({ commit }, data) {
      const deactivatedTariff = await instance.post(`${settings.API_URL}/instructors/${data.idInstructor}/tariffs/${data.idTariff}/inactivate`);

      commit('SET_DEACTIVATED_TARIFF', deactivatedTariff.data);
    },
    async tariffDeleting({ commit }, data) {
      const deletedTariff = await instance.delete(`${settings.API_URL}/instructors/${data.idInstructor}/tariffs/${data.idTariff}`);

      commit('SET_DELETED_TARIFF', deletedTariff.data.tariff);
    },
    async getInstructorLessons({ commit, state }, payload) {
      if (payload.loading) {
        commit('START_GET_INSTRUCTOR_LESSONS_LOADING');
      }

      try {
        const requestId = 'instructorLessons';

        const params = Object.fromEntries(Object.entries(state.filtersForInstructorLessons).filter(([key, value]) => key && value));

        const lessons = await instance.get(`${settings.API_URL}/instructors/${payload.idInstructor}/lessons`, { requestId, params });

        commit('SET_INSTRUCTOR_LESSONS', lessons.data);
      } finally {
        commit('FINISH_GET_INSTRUCTOR_LESSONS_LOADING');
      }
    },
    clearInstructorLessons({ commit }) {
      commit('CLEAR_INSTRUCTOR_LESSONS');
    },
    changePageInstructorLessons({ commit }, page) {
      commit('CHANGE_PAGE_INSTRUCTOR_LESSONS', page);
    },
    changeFiltersInstructorLessonsClientId({ commit }, idClient) {
      commit('CHANGE_FILTERS_INSTRUCTOR_LESSONS_CLIENT_ID', idClient)
    },
    changeFiltersInstructorLessonsStatus({ commit }, status) {
      commit('CHANGE_FILTERS_INSTRUCTOR_LESSONS_STATUS', status)
    },
    changeFiltersInstructorLessonsDate({ commit }, date) {
      commit('CHANGE_FILTERS_INSTRUCTOR_LESSONS_DATE', date);
    },
    resetFiltersInstructorLessons({ commit }) {
      commit('CHANGE_FILTERS_INSTRUCTOR_LESSONS_CLIENT_ID', null);
      commit('CHANGE_FILTERS_INSTRUCTOR_LESSONS_STATUS', null);
      commit('CHANGE_FILTERS_INSTRUCTOR_LESSONS_DATE', { from: null, to: null });
    },
    setLoadingInstructorLessons({ commit }) {
      commit('SET_LOADING_INSTRUCTOR_LESSONS');
    },
    async deleteLessonFromShedule({ commit }, payload) {
      const deleteLesson = await instance.post(`${settings.API_URL}/instructors/${payload.idInstructor}/lessons/${payload.idLesson}/cancel`);

      const lesson = {
        id: payload.idLesson,
        lesson: deleteLesson.data.lesson
      }

      commit('DELETE_LESSON_FROM_SHEDULE', lesson);
    },
    async getInstructorNotes({ commit, state }, payload) {
      if (payload.loading) {
        commit('START_GET_INSTRUCTOR_NOTES_LOADING');
      }

      if (payload.setNotesAfterCreate) {
        commit('RESET_PAGE_INSTRUCTOR_NOTES');
      }

      try {
        const notesAllData = await instance.get(`${settings.API_URL}/instructors/${payload.idInstructor}/notes?page=${state.pageNotes}`);

        if (payload.setNotesAfterCreate) {
          commit('CLEAR_INSTRUCTOR_NOTES');

          commit('SET_INSTRUCTOR_NOTES', notesAllData.data);
        } else {
          commit('SET_INSTRUCTOR_NOTES', notesAllData.data);
        }
      } finally {
        commit('FINISH_GET_INSTRUCTOR_NOTES_LOADING');
      }
    },
    clearInstructorNotes({ commit }) {
      commit('CLEAR_INSTRUCTOR_NOTES');
    },
    resetPageInstructorNotes({ commit }) {
      commit('RESET_PAGE_INSTRUCTOR_NOTES');
    },
    async createInstructorNote({ commit }, payload) {
      commit('START_CREATE_INSTRUCTOR_NOTE_LOADING');

      try {
        await instance.post(`${settings.API_URL}/instructors/${payload.idInstructor}/notes`, payload.note);
      } finally {
        commit('FINISH_CREATE_INSTRUCTOR_NOTE_LOADING');
      }
    },
    async deleteInstructorNote({ commit }, payload) {
      await instance.delete(`${settings.API_URL}/instructors/${payload.idInstructor}/notes/${payload.idNote}`);

      commit('DELETE_INSTRUCTOR_NOTE', payload.idNote);
    },
  },

  mutations: {
    SET_INSTRUCTORS(state, instructors) {
      state.instructors = instructors;
    },
    CLEAR_INSTRUCTORS(state) {
      state.instructors = null;
    },
    START_GET_INSTRUCTORS_LOADING(state) {
      state.loading.instructors = true;
    },
    FINISH_GET_INSTRUCTORS_LOADING(state) {
      state.loading.instructors = false;
    },
    CHANGE_PAGE_INSTRUCTORS(state, page) {
      state.filtersForInstructors.page = page;
    },
    CHANGE_SEARCH_INSTRUCTORS(state, search) {
      state.filtersForInstructors.query = search;
    },
    CHANGE_FILTERS_DATE_INSTRUCTORS(state, date) {
      state.filtersForInstructors.registered_from = date.from;

      state.filtersForInstructors.registered_to = date.to;
    },
    ADD_INSTRUCTOR_IN_LIST_INSTRUCTORS(state, instructor) {
      if (!state.instructors.instructors.find(el => el.instructor_id === instructor.instructor_id)) {
        state.instructors.instructors.push(instructor);
      }
    },
    SET_LOADING_INSTRUCTORS(state) {
      state.loading.instructors = true;
    },

    SET_INSTRUCTOR_PAUSE_OR_ACTIVATE(state, instructor) {
      if (state.instructors) {
        const indexInstructor = state.instructors.instructors.findIndex(el => el.instructor_id === instructor.instructor_id);

        state.instructors.instructors.splice(indexInstructor, 1, instructor);
      }

      if (state.instructor) {
        state.instructor.paused = instructor.paused;
      }
    },

    START_CREATE_INSTRUCTOR_LOADING(state) {
      state.loading.newInstructor = true;
    },
    FINISH_CREATE_INSTRUCTOR_LOADING(state) {
      state.loading.newInstructor = false;
    },

    START_DELETE_INSTRUCTOR_LOADING(state) {
      state.loading.deleteInstructor = true;
    },
    FINISH_DELETE_INSTRUCTOR_LOADING(state) {
      state.loading.deleteInstructor = false;
    },

    DELETE_INSTRUCTOR_FROM_INSTRUCTORS(state, idInstructor) {
      const indexInstructor = state.instructors.instructors.findIndex(el => el.instructor_id === idInstructor);

      if (indexInstructor !== -1) {
        state.instructors.instructors.splice(indexInstructor, 1);
      }
    },

    SET_INSTRUCTOR(state, instructor) {
      state.instructor = instructor;
    },
    START_GET_INSTRUCTOR_LOADING(state) {
      state.loading.instructor = true;
    },
    FINISH_GET_INSTRUCTOR_LOADING(state) {
      state.loading.instructor = false;
    },
    START_EDIT_INSTRUCTOR_LOADING(state) {
      state.loading.editInstructor = true;
    },
    FINISH_EDIT_INSTRUCTOR_LOADING(state) {
      state.loading.editInstructor = false;
    },
    START_CHANGE_PASSWORD_INSTRUCTOR_LOADING(state) {
      state.loading.changePassword = true;
    },
    FINISH_CHANGE_PASSWORD_INSTRUCTOR_LOADING(state) {
      state.loading.changePassword = false;
    },
    CLEAR_INSTRUCTOR(state) {
      state.instructor = null;
    },

    SET_INSTRUCTOR_PROFILE(state, instructorProfile) {
      state.instructorProfile = instructorProfile;
    },
    START_GET_INSTRUCTOR_PROFILE_LOADING(state) {
      state.loading.instructorProfile = true;
    },
    FINISH_GET_INSTRUCTOR_PROFILE_LOADING(state) {
      state.loading.instructorProfile = false;
    },
    CLEAR_INSTRUCTOR_PROFILE(state) {
      state.instructorProfile = null;
    },

    SET_TARIFFS(state, tariffsAllData) {
      state.tariffsAllData = tariffsAllData;

      if (tariffsAllData.tariffs.length) {
        state.tariffs.push(...tariffsAllData.tariffs);

        state.pageTariffs++;
      }
    },
    CLEAR_TARIFFS(state) {
      state.tariffsAllData = null;
      state.tariffs = [];
    },
    START_GET_TARIFFS_LOADING(state) {
      state.loading.tariffs = true;
    },
    FINISH_GET_TARIFFS_LOADING(state) {
      state.loading.tariffs = false;
    },
    RESET_PAGE_TARIFFS(state) {
      state.pageTariffs = 1;
    },

    SET_TARIFFS_LEVEL_APP(state, tariffsAllData) {
      state.tariffsLevelAppAllData = tariffsAllData;

      if (tariffsAllData.tariffs.length) {
        state.tariffsLevelApp.push(...tariffsAllData.tariffs);

        state.pageTariffsLevelApp++;
      }
    },
    CLEAR_TARIFFS_LEVEL_APP(state) {
      state.tariffsLevelAppAllData = null;
      state.tariffsLevelApp = [];
    },
    START_GET_TARIFFS_LEVEL_APP_LOADING(state) {
      state.loading.tariffsLevelApp = true;
    },
    FINISH_GET_TARIFFS_LEVEL_APP_LOADING(state) {
      state.loading.tariffsLevelApp = false;
    },
    RESET_PAGE_TARIFFS_LEVEL_APP(state) {
      state.pageTariffsLevelApp = 1;
    },

    SET_ACTIVATED_TARIFF(state, tariff) {
      state.activatedTariff = tariff;
    },
    SET_DEACTIVATED_TARIFF(state, tariff) {
      state.deactivatedTariff = tariff;
    },
    SET_DELETED_TARIFF(state, tariff) {
      state.deletedTariff = tariff;

      const indexTariff = state.tariffs.findIndex(el => el.tariff_id == tariff.tariff_id);

      state.tariffs.splice(indexTariff, 1);
    },

    SET_NEW_TARIFF(state, tariff) {
      if (state.tariffsAllData.meta.page.entries < state.tariffsAllData.meta.page.size
        || state.tariffsAllData.meta.entries.total === state.tariffsAllData.meta.entries.to) {
        state.tariffs.push(tariff);
      }
    },
    START_ADD_TARIFF_LOADING(state) {
      state.loading.newTariff = true;
    },
    FINISH_ADD_TARIFF_LOADING(state) {
      state.loading.newTariff = false;
    },

    SET_INSTRUCTOR_LESSONS(state, lessons) {
      state.lessons = lessons;
    },
    CLEAR_INSTRUCTOR_LESSONS(state) {
      state.lessons = null;
    },
    CHANGE_PAGE_INSTRUCTOR_LESSONS(state, page) {
      state.filtersForInstructorLessons.page = page;
    },
    CHANGE_FILTERS_INSTRUCTOR_LESSONS_CLIENT_ID(state, idClient) {
      state.filtersForInstructorLessons.client_id = idClient;
    },
    CHANGE_FILTERS_INSTRUCTOR_LESSONS_STATUS(state, status) {
      state.filtersForInstructorLessons.status = status;
    },
    CHANGE_FILTERS_INSTRUCTOR_LESSONS_DATE(state, date) {
      state.filtersForInstructorLessons.date_from = date.from;

      state.filtersForInstructorLessons.date_to = date.to;
    },
    SET_LOADING_INSTRUCTOR_LESSONS(state) {
      state.loading.lessons = true;
    },
    START_GET_INSTRUCTOR_LESSONS_LOADING(state) {
      state.loading.lessons = true;
    },
    FINISH_GET_INSTRUCTOR_LESSONS_LOADING(state) {
      state.loading.lessons = false;
    },

    DELETE_LESSON_FROM_SHEDULE(state, lesson) {
      const indexLesson = state.lessons.lessons.findIndex(el => el.lesson_id === lesson.id);

      state.lessons.lessons.splice(indexLesson, 1, lesson.lesson);
    },

    SET_INSTRUCTOR_NOTES(state, notesAllData) {
      state.notesAllData = notesAllData;

      if (notesAllData.notes.length) {
        state.notes.push(...notesAllData.notes);

        state.pageNotes++;
      }
    },
    CLEAR_INSTRUCTOR_NOTES(state) {
      state.notesAllData = null;
      state.notes = [];
    },
    RESET_PAGE_INSTRUCTOR_NOTES(state) {
      state.pageNotes = 1;
    },
    START_GET_INSTRUCTOR_NOTES_LOADING(state) {
      state.loading.notes = true;
    },
    FINISH_GET_INSTRUCTOR_NOTES_LOADING(state) {
      state.loading.notes = false;
    },

    START_CREATE_INSTRUCTOR_NOTE_LOADING(state) {
      state.loading.createNote = true;
    },
    FINISH_CREATE_INSTRUCTOR_NOTE_LOADING(state) {
      state.loading.createNote = false;
    },

    DELETE_INSTRUCTOR_NOTE(state, idNote) {
      const indexNote = state.notes.findIndex(el => el.note_id == idNote);

      state.notes.splice(indexNote, 1);
    }
  },
};
