<template>
  <div class="account-content">
    <div class="settings">
      <div class="settings-top d-md-flex align-items-end">
        <div class="settings-head d-flex align-items-center">
          <div class="settings-title">Инструкторы</div>

          <router-link
            v-if="profile.user.permission.instructor.create"
            to="/instructors/create"
            class="settings-head-icon"
          ></router-link>
        </div>
        <div class="settings-search ml-auto d-flex mt-4 mt-md-0">
          <input
            v-model.trim="search"
            v-debounce:1000ms="searchInstructor"
            type="text"
            class="form-control form-control--search pl-2 pl-md-2"
            placeholder="Поиск по инструкторам"
          >

          <button type="button"></button>
        </div>
      </div>

      <AppLoader v-if="loading.instructors" />

      <div v-else class="school-cabinet">
        <div class="filters">
          <div class="filters-item">
            <DropDownMenuCalendar
              :placeholder="'Дата регистрации'"
              :dateCalendar="dateCalendar"
              @changeDate="changeDate"
            />
          </div>
        </div>

        <div class="wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
          <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />

          <template v-if="instructors.instructors.length">
            <InstructorCard
              v-for="instructor in instructors.instructors"
              :key="instructor.instructor_id"
              :instructor="instructor"
            />

            <div v-if="instructors.meta.page.total > 1" class="d-flex justify-content-center">
              <paginate
                v-model="page"
                :pageCount="instructors.meta.page.total"
                :clickHandler="clickCallback"
                :prevText="''"
                :nextText="''"
                :containerClass="'pagination'"
                :pageClass="'page-item'"
                :pageLinkClass="'page-link'"
                :prevClass="'page-item'"
                :prevLinkClass="'page-link'"
                :nextClass="'page-item'"
                :nextLinkClass="'page-link'"
                :activeClass="'active'"
              />
            </div>
          </template>

          <p v-else>Совпадений не найдено</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Paginate from 'vuejs-paginate';
import vueDebounce from 'vue-debounce';
import moment from 'moment';
import VModal from 'vue-js-modal';

import InstructorCard from '@/components/Instructor/Card.vue';
import AppLoader from '@/components/AppLoader/1.vue';
import DropDownMenuCalendar from '@/components/DropDownMenu/Calendar.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';

Vue.use(vueDebounce);
Vue.use(VModal, { dialog: true });

export default {
  name: 'AppInstructors',

  components: {
    InstructorCard,
    AppLoader,
    Paginate,
    DropDownMenuCalendar,
    AppLoader3
  },

  data() {
    return {
      page: 1,
      isLoadingPage: false,
      search: '',
      dateCalendar: []
    }
  },

  computed: {
    ...mapGetters({
      instructors: 'instructors',
      loading: 'loadingInstructors',
      pageInstructors: 'pageInstructors',
      filtersForInstructors: 'filtersForInstructors',
      profile: 'profile'
    })
  },

  async created() {
    this.clearInstructors();

    if (this.$route.query.page) {
      this.changePageInstructors(Number(this.$route.query.page));
    }

    if (this.$route.query.search) {
      this.changeSearchInstructors(this.$route.query.search);
    }

    if (this.$route.query.registered_from && this.$route.query.registered_to) {
      const date = {
        from: moment(this.$route.query.registered_from, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        to: moment(this.$route.query.registered_to, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      }

      this.changeFiltersDateInstructors(date);

      this.dateCalendar = [moment(this.$route.query.registered_from, 'DD-MM-YYYY')._d, moment(this.$route.query.registered_to, 'DD-MM-YYYY')._d];
    }

    if (this.$route.params.page === 1) {
      this.changePageInstructors(1);

      const date = {
        from: null,
        to: null
      }

      this.changeFiltersDateInstructors(date);

      this.changeSearchInstructors(null);
    }

    this.page = this.pageInstructors;

    let query = Object.assign({}, this.$route.query);
    query.page = this.pageInstructors;

    if (this.filtersForInstructors.query) {
      this.search = this.filtersForInstructors.query;

      query.search = this.filtersForInstructors.query;
    }

    if (this.filtersForInstructors.registered_from && this.filtersForInstructors.registered_to) {
      this.dateCalendar = [moment(this.filtersForInstructors.registered_from, 'YYYY-MM-DD')._d, moment(this.filtersForInstructors.registered_to, 'YYYY-MM-DD')._d];

      query.registered_from = moment(this.filtersForInstructors.registered_from, 'YYYY-MM-DD').format('DD-MM-YYYY');
      query.registered_to = moment(this.filtersForInstructors.registered_to, 'YYYY-MM-DD').format('DD-MM-YYYY');
    }

    this.$router.push({ query }).catch(() => {});

    await this.getInstructors();

    if (this.instructors && this.pageInstructors > this.instructors.meta.page.total) {
      await this.changePageInstructors(this.instructors.meta.page.total);

      this.page = this.pageInstructors;

      let query = Object.assign({}, this.$route.query);
      query.page = this.pageInstructors;
      this.$router.push({ query }).catch(() => {});

      this.getInstructors();
    }
  },

  methods: {
    ...mapActions([
      'getInstructors',
      'clearInstructors',
      'changePageInstructors',
      'changeSearchInstructors',
      'changeFiltersDateInstructors'
    ]),
    clickCallback() {
      this.isLoadingPage = true;

      let query = Object.assign({}, this.$route.query);
      query.page = this.page;

      this.changePageInstructors(this.page)
        .then(() => {
          this.$router.push({ query }).catch(() => {});

          const loading = false;

          this.getInstructors(loading)
            .finally(() => this.isLoadingPage = false);
        });
    },
    searchInstructor(value) {
      this.isLoadingPage = true;

      const search = value.trim();

      this.changePageInstructors(1);
      this.page = 1;

      let query = Object.assign({}, this.$route.query);
      query.page = '1';

      if (!search) {
        delete query.search;
      } else {
        query.search = search;
      }

      this.$router.push({ query }).catch(() => {});

      this.changeSearchInstructors(search);

      const loading = false;

      this.getInstructors(loading)
        .finally(() => this.isLoadingPage = false);
    },
    changeDate(dateCalendar) {
      this.isLoadingPage = true;

      this.changePageInstructors(1);
      this.page = 1;

      let date = null;

      if (dateCalendar) {
        date = {
          from: moment(dateCalendar[0]).format('YYYY-MM-DD'),
          to: moment(dateCalendar[1]).format('YYYY-MM-DD'),
        }

        let query = Object.assign({}, this.$route.query);
        query.page = '1';
        query.registered_from = moment(dateCalendar[0]).format('DD-MM-YYYY');
        query.registered_to = moment(dateCalendar[1]).format('DD-MM-YYYY');
        this.$router.push({ query }).catch(() => {});
      } else {
        date = {
          from: null,
          to: null,
        }

        let query = Object.assign({}, this.$route.query);
        query.page = '1';
        delete query.registered_from;
        delete query.registered_to;
        this.$router.push({ query }).catch(() => {});
      }

      this.changeFiltersDateInstructors(date);

      const loading = false;

      this.getInstructors(loading)
        .finally(() => this.isLoadingPage = false);
    },
    moment
  }
}
</script>

<style>
  .form-control--search {
    margin-bottom: -10px !important;
    height: 40px !important;
    border: 1px solid rgba(0,0,0,0.2) !important;
    background-position: 95% 50% !important;
    padding-left: 20px !important;
  }
  .settings-search button {
    top: 11px !important;
    height: 39px !important;
    width: 46px !important;
  }
</style>