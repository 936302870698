import instance from '../../instance';
import settings from '../../settings';

export default {
  namespaced: true,

  state: {
    list: [],
    meta: null,
    filters: {
      page: 1,
    },
    item: null,
    loading: {
      list: true,
    }
  },

  getters: {
    list: state => state.list,
    meta: state => state.meta,
    filters: state => state.filters,
    loading: state => state.loading,
  },

  actions: {
    async getList({ commit, state }, groupId) {
      try {
        const list = await instance.get(`${settings.API_URL}/school-study-groups/${groupId}/clients`, { params: state.filters });

        commit('SET_LIST', list.data);
      } finally {
        commit('CHANGE_LIST_LOADING', false);
      }
    },
    changeFilters({ commit }, payload) {
      commit('CHANGE_FILTERS', payload);
    },
    clearList({ commit }) {
      commit('CLEAR_LIST');
    },
    async add({ commit }, payload) {
      const item = await instance.post(`${settings.API_URL}/school-study-groups/${payload.groupId}/add-clients`, payload.clients);

      commit('studyGroups/SET_ITEM', item.data, { root: true });
    },
    async transferAndRemove({ commit }, payload) {
      const item = await instance.post(`${settings.API_URL}/school-study-groups/${payload.groupId}/remove-clients`, payload.payload);

      commit('studyGroups/SET_ITEM', item.data, { root: true });
    },
  },

  mutations: {
    SET_LIST(state, list) {
      state.list = list.clients;
      state.meta = list.meta;
    },
    CLEAR_LIST(state) {
      state.list = [];
      state.meta = null;
      state.filters = { page: 1 };
      state.loading.list = true;
    },
    CHANGE_LIST_LOADING(state, status) {
      state.loading.list = status;
    },
    CHANGE_FILTERS(state, payload) {
      state.filters[payload.key] = payload.value;
    }
  },
};