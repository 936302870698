<template>
  <div class="account-content">
    <div class="settings">
      <div class="settings-top d-flex align-items-end">
        <div class="settings-head d-flex align-items-center">
          <div class="settings-title">Отчеты о занятиях</div>
        </div>
      </div>

      <AppLoader v-if="loadingReports.reports" />

      <div v-else class="school-cabinet">
        <div class="filters d-flex flex-wrap">
          <div class="filters-item mb-2">
            <v-select
              :options="tracks"
              v-if="tracks"
              @input="selectTrack"
              @search="searchTracks"
              :disabled="disabledFilters"
              :clearable="false"
              :filterable="false"
              :searchable="true"
              placeholder="Поток"
              :get-option-label='option => option.title ? `${option.title}` : null'
              :get-option-key='option => option.track_id'
              v-model="activeTrack"
            >
              <template #open-indicator="{ attributes }">
                <span class="form-control--arrow" v-bind="attributes"></span>
              </template>
              <template #list-header>
                <li class="vs__dropdown-option vs__dropdown-option--reset" @mouseover="resetHover = true" @mouseleave="resetHover = false" v-if="activeTrack" @click="resetTrack">Все</li>
              </template>
              <template slot="no-options">
                Потоков не найдено
              </template>
              <template v-slot:option="option">
                {{ option.title }} 
              </template>
              <template #spinner="{ loading }">
                <AppLoader2 :mini="true" v-if="loading" />
              </template>
              <template #list-footer>
                <infinite-loading @infinite="infiniteHandlerTracks">
                  <div slot="spinner">
                    <AppLoader2 :mini="true" />
                  </div>
                  <div slot="no-more"></div>
                  <div slot="no-results"></div>
                </infinite-loading>
              </template>
            </v-select>
          </div>
          <div class="filters-item filters-item--big mb-2">
            <DropDownMenuCalendarComment
              :placeholder="'Дата последнего комментария'"
              @changeDate="changeDate"
              :actualDates="dates"
            />
          </div>
          <div class="filters-item mb-2">
            <v-select
              :options="clients"
              v-if="clients"
              @input="selectClient"
              @search="searchClient"
              :disabled="disabledFilters"
              :clearable="false"
              :searchable="true"
              placeholder="Клиент"
              :get-option-label='option => option.name ? `${option.name.first} ${option.name.last} ${option.name.middle}` : null'
              :get-option-key='option => option.client_id'
              v-model="activeTrack"
            >
              <template #open-indicator="{ attributes }">
                <span class="form-control--arrow" v-bind="attributes"></span>
              </template>
              <template #list-header>
                <li class="vs__dropdown-option vs__dropdown-option--reset" @mouseover="resetHover = true" @mouseleave="resetHover = false" v-if="activeTrack" @click="resetClient">Все</li>
              </template>
              <template slot="no-options">
                Пользователей не найдено
              </template>
              <template v-slot:option="option">
                {{ option.name.first }} {{ option.name.last }} {{ option.name.middle }}
              </template>
              <template #spinner="{ loading }">
                <AppLoader2 :mini="true" v-if="loading" />
              </template>
              <template #list-footer>
                <infinite-loading @infinite="infiniteHandler">
                  <div slot="spinner">
                    <AppLoader2 :mini="true" />
                  </div>
                  <div slot="no-more"></div>
                  <div slot="no-results"></div>
                </infinite-loading>
              </template>
            </v-select>
          </div>
          <div class="filters-item mb-2">
            <DropDownMenuStatuses
              :list="statuses"
              @select="selectStatus"
              :placeholder="'Статус'"
              :searchable="false"
              @reset="resetStatus"
              :active="status ? status.name : null"
            />
          </div>
          <transition name="fade--top">
            <button
              v-if="filtersApplied"
              @click="resetFilters"
              type="button"
              class="reset-filters filters-item--100 align-self-center mt-3 mb-3 mt-xl-0 mb-md-0 ml-xl-auto"
            >
              Сбросить фильтры
            </button>
          </transition>
        </div>
        <div class="school-cabinet_wrapper">
          <div class="school-cabinet_wrapper-content" data-simplebar data-simplebar-auto-hide="false">
            <div class="school-cabinet_table school-cabinet_table--reports">
              <div class="school-cabinet_table-title school-cabinet_table-title--1">Клиент</div>
              <div class="school-cabinet_table-title school-cabinet_table-title--2">Занятие</div>
              <div class="school-cabinet_table-title school-cabinet_table-title--3">Последний коммент.</div>
              <div class="school-cabinet_table-title school-cabinet_table-title--4">Статус</div>
            </div>
            <div class="wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
              <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />
              
              <template v-if="reports.length">
                <div 
                  class="school-cabinet_card school-cabinet_card--reports"
                  v-for="report in reports"
                  :key="report.lesson_id"
                  :report="report"
                >
                  <div class="school-cabinet_card-top d-flex align-items-center flex-wrap">
                    <div class="school-cabinet_card-content school-cabinet_card-content--reports d-flex align-items-center mb-3 mb-xl-0">
                      <div class="school-cabinet_card-icon">
                        <img v-if="report.user.avatar_url" :src="report.user.avatar_url">
                        <img v-else src="@/assets/img/noavatar.svg">
                      </div>
                      <div class="school-cabinet_card-text">
                        <router-link :to="`/clients/${report.user.user_id}`" class="school-cabinet_card-name">
                          {{ report.user.name.first }} {{ report.user.name.last }} <!--<span class="school-cabinet_card-status"></span>-->
                        </router-link>
                        <div class="school-cabinet_card-facts">{{ report.track.title }}</div> <!--СТАТИКА-->
                      </div>
                    </div>
                    <div class="school-cabinet_card-info school-cabinet_card-info--lesson">
                      <div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
                        <div class="school-cabinet_card-info-text mb-1"> 
                          <strong>{{ report.title }}</strong>
                        </div>
                        <div class="school-cabinet_card-info-title">
                          <span class="light">{{ report.last_comment.text }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="school-cabinet_card-info school-cabinet_card-info--comment">
                      <div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
                        <div class="school-cabinet_card-info-title">
                          <span class="light-title">{{ formatDate(report.last_comment.created_at) }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="school-cabinet_card-info school-cabinet_card-info--status">
                      <div class="school-cabinet_card-info-item mr-0 pr-0 pr-md-1">
                        <div
                          class="school-cabinet_card-info-status" 
                          :class="{counted: report.status === 'completed', message: report.status === 'completed_wait', unchecked: report.status === 'wait', uncounted: report.status === 'not_completed'}"
                        >
                          {{ getReportStatus(report.status) }}
                        </div>
                      </div>
                    </div>
                    <div 
                      class="school-cabinet_card-open" 
                      :class="{active: report.lesson_id === activeReport}" 
                      @click="openComments(report.lesson_id)"
                    ></div>
                  </div>
                  <DropDownReports :report="report" v-if="report.lesson_id === activeReport" @closeComments="activeReport = 0" />
                </div>
                <div v-if="pageReportsTotal > 1" class="d-flex justify-content-center">
                  <paginate
                    v-model="pageReports"
                    :pageCount="pageReportsTotal"
                    :clickHandler="clickCallback"
                    :prevText="''"
                    :nextText="''"
                    :containerClass="'pagination'"
                    :pageClass="'page-item'"
                    :pageLinkClass="'page-link'"
                    :prevClass="'page-item'"
                    :prevLinkClass="'page-link'"
                    :nextClass="'page-item'"
                    :nextLinkClass="'page-link'"
                    :activeClass="'active'"
                  />
                </div>
              </template>

              <p v-else>Отчетов нет</p>
            </div>
          </div>
        </div>   
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';

import Paginate from 'vuejs-paginate';

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';
import InfiniteLoading from 'vue-infinite-loading';

//import DropDownMenuTracks from '@/components/DropDownMenu/DropDownMenuTracks.vue';
import DropDownMenuStatuses from '@/components/DropDownMenu/DropDownMenuStatuses.vue';
import DropDownMenuCalendarComment from '@/components/DropDownMenu/CalendarComment.vue';

import DropDownReports from '@/components/DropDownReports.vue';

import vSelect from 'vue-select';
export default {
  name: 'Reports',

  components: {
    AppLoader,
    AppLoader2,
    AppLoader3,
    InfiniteLoading,
    //DropDownMenuTracks,
    DropDownMenuStatuses,
    DropDownMenuCalendarComment,
    vSelect,
    Paginate,
    DropDownReports,
  },

  data() {
    return {
      code: false,
      clientId: null,
      resetHover: false,
      activeClient: null,
      disabledFilters: false,
      isLoadingPage: false,
      dateCalendar: [],
      status: null,
      track: null,
      dates: null,
      activeTrack: null,
      activeReport: 0,
    }
  },

  computed: {
    ...mapGetters({
      reports: 'reports',
      pageReports: 'pageReports',
      loadingReports: 'loadingReports',
      tracks: 'tracks',
      statuses: 'statuses',
      clients: 'filterClients',
      filterClientsMeta: 'filterClientsMeta',
      pageReportsTotal: 'pageReportsTotal',
      pageTracksAmount: 'tracksReportTotal',
      filtersApplied: 'reportsFiltersApplied',
    })
  },

  async created() {
    this.setLoadingReports();
    await this.getReports();

    if (this.reports && this.pageReports > this.reports.total) {
      await this.changePageReports(this.reports.total);

      this.page = this.pageReports;

      let query = Object.assign({}, this.$route.query);
      query.page = this.pageReports;
      this.$router.push({ query }).catch(() => {});

      this.getInstructors();
    }

    await this.getTracks();
    await this.getStatuses();
    this.clearFilterClients();
    this.changePageFilterClients(1);
    this.changeFiltersFilterClientsSearch(null);
    await this.getFilterClients();

    if (this.$route.params.page === 1) {
      this.resetFiltersReports();
    }
  },

  destroy() {
    this.resetFiltersReports();
    this.clearReportsComments();
  },

  methods: {
    ...mapActions([
      'getReports',
      'getTracks',
      'getStatuses',
      'setLoadingReports',
      'getFilterClients',
      'changePageFilterClients',
      'changeFiltersFilterClientsSearch',
      'clearFilterClients',
      'changeFiltersForReportsClient',
      'changeFiltersForReportsTrack',
      'changeFiltersForReportsStatus',
      'changeFiltersDateLastCommentForReports',
      'changePageReports',
      'resetFiltersReports',
      'clearFilterReportsTracks',
      'setFilterQueryReportsTracks',
    ]),
    clickCallback(page) {
      this.isLoadingPage = true;
      this.activeReport = 0;

      let query = Object.assign({}, this.$route.query);
      query.page = this.page;

      this.changePageReports(page)
        .then(() => {
          this.$router.push({ query }).catch(() => {});

          const loading = false;

          this.getReports(loading)
            .finally(() => this.isLoadingPage = false);
        });
    },
    getReportStatus(code) {
      if (code === 'completed') {
        return 'Зачтено'
      }
      if (code === 'completed_wait') {
        return 'Сообщение'
      }
      if (code === 'not_completed') {
        return 'Не зачтено'
      }
      if (code === 'wait') {
        return 'Проверить'
      }
    },
    formatDate(date) {
			return moment(date).format('DD.MM.YYYY');
		},
    resetClient() {
      this.activeClient = null;
      this.isLoadingPage = true;
      this.disabledFilters = true;
      this.clientId = null;

      this.clearFilterClients();
      this.changePageFilterClients(1);
      this.changeFiltersForReportsClient(null);

      const loading = false;
      this.getReports(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    searchClient(search, loading) {
      this.clearFilterClients();
      this.changePageFilterClients(1);
      this.changeFiltersFilterClientsSearch(search);
      loading(true);
      this.searchClientsAction(search, loading, this);
    },
    searchClientsAction: _.debounce((search, loading, vm) => {
      vm.getFilterClients(false).then(() => {
        loading(false);
      })
    }, 1000),
    infiniteHandler($state) {
      if (this.clients.length < this.filterClientsMeta.entries.total) {
        this.getFilterClients(false)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    searchTracks(search, loading) {
      this.clearFilterReportsTracks();
      this.setFilterQueryReportsTracks(search);
      loading(true);
      this.searchTracksAction(search, loading, this);
    },
    searchTracksAction: _.debounce((search, loading, vm) => {
      vm.getTracks(false).then(() => {
        loading(false);
      })
    }, 1500),
    infiniteHandlerTracks($state) {
      console.log(this.tracks);
      console.log(this.pageTracksAmount);
      setTimeout(() => {
        if (this.tracks.length < this.pageTracksAmount) {
          this.getTracks(false)
            .then(() => {
              $state.loaded();
            });
        } else {
          $state.complete();
        }
      }, 600)
    },
    selectClient(client) {
      this.isLoadingPage = true;
      this.disabledFilters = true;
      this.clientId = client.client_id;
      this.changeFiltersForReportsClient(client.client_id);

      const loading = false;
      this.getReports(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    selectTrack(track) {
      this.isLoadingPage = true;
      this.disabledFilters = true;
      this.track = track;
      this.changeFiltersForReportsTrack(track.track_id);
      
      const loading = false;
      this.getReports(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    resetTrack() {
      this.isLoadingPage = true;
      this.activeTrack = null;
      this.disabledFilters = true;
      this.track = null;
      this.changeFiltersForReportsTrack(null);
      
      const loading = false;
      this.getReports(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    selectStatus(status) {
      this.isLoadingPage = true;
      this.disabledFilters = true;
      this.status = status;
      this.changeFiltersForReportsStatus(status.code);

      const loading = false;
      this.getReports(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    resetStatus() {
      this.isLoadingPage = true;
      this.disabledFilters = true;
      this.status = null;
      this.changeFiltersForReportsStatus(null);

      const loading = false;
      this.getReports(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });
    },
    async changeDate(dateCalendar) {
      this.isLoadingPage = true;

      let date = null;

      if (dateCalendar) {
        date = dateCalendar;
      } else {
        date = null;
      }

      this.dates = date;

      await this.changeFiltersDateLastCommentForReports(date);

      const loading = false;

      this.getReports(loading)
        .finally(() => this.isLoadingPage = false);
    },
    resetFilters() {
      if (this.disabledFilters) {
        return;
      }

      this.isLoadingPage = true;
      this.disabledFilters = true;
      this.dates = null;

      this.resetFiltersReports();
      this.status = null;
      this.track = null;

      const loading = false;

      this.getReports(loading)
        .finally(() => {
          this.page = 1;

          this.disabledFilters = false;
          this.isLoadingPage = false;
        });

      this.activeClient = null;
      this.activeTrack = null;
      let query = Object.assign({}, this.$route.query);
      query.page = 1;
      delete query.track_id;
      delete query.user_id
      delete query.status;
      delete query.last_comment_date;
      this.$router.push({ query }).catch(() => {});
    },
    openComments(id) {
      if (this.activeReport == id) {
        this.activeReport = 0
      } else {
        this.activeReport = id
      }
    },
    moment
  }
}
</script>

<style>
.filters-item--big {
  width: 270px !important; 
}
.school-cabinet_card-info-status:hover {
  color: white;
}
.school-cabinet_card-info-title span {
  width: 100%;
  height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.report__messege-text {
  white-space: break-spaces;
}
</style>