<template>
  <div class="account-content">
    <div class="settings-top d-md-flex align-items-end">
      <GoBack />
    </div>

    <div class="d-flex">
      <div class="account-subtitle account-subtitle--large pt-0 mb-3">
        <span class="light">Создать группу обучения</span>
      </div>
    </div>

    <div class="school-cabinet">
      <div class="school-cabinet_white-box">
        <div class="school-cabinet_info-field form-group mb-4">
          <input
            v-model="payload.name"
            @keyup.enter="groupCreate"
            type="text"
            placeholder="Название группы"
            class="form-control form-control--bordered"
          >
        </div>

        <SearchClients :isCreate="true" @groupCreate="groupCreate" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import GoBack from '@/components/GoBack.vue';

import SearchClients from '@/components/StudyGroups/SearchClients.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'StudyGroupsCreate',

  components: {
    GoBack,
    SearchClients
  },

  data() {
    return {
      loadingCreateGroup: false,

      payload: {
        name: '',
        clients: []
      }
    }
  },

  computed: {
    ...mapGetters({
      profile: 'profile',
    }),
  },

  methods: {
    ...mapActions('studyGroups', {
      createGroup: 'create'
    }),
    groupCreate(clients) {
      if (this.loadingCreateGroup) {
        return;
      }

      this.loadingCreateGroup = true;

      this.payload.clients = clients;

      this.payload.school_id = this.profile.school.school_id;

      if (!this.payload.clients?.length) {
        delete this.payload.clients;
      }

      this.createGroup(this.payload)
        .then(() => {
          this.$router.push('/study-groups');
          this.$toast.success('Группа создана');
        })
        .catch((e) => {
          this.errorHandlerForResponse(e)
        })
        .finally(() => {
          if (!this.payload.clients) {
            this.payload.clients = [];
          }

          this.loadingCreateGroup = false
        });
    },
    errorHandlerForResponse
  }
}
</script>