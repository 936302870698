<template>
  <div class="tariff__item d-flex align-items-center flex-wrap">
    <div class="tariff__name">{{ tariff.title }}</div>

    <div class="tariff__price">
      {{ tariff.price }} руб.
    </div>

    <div v-if="profile.user.permission.instructor.tariffsChange" class="tariff__toggle ml-auto">
      <label
        :for="`checkbox-${tariff.tariff_id}`"
        class="toggle-box toggle-box--white"
      >

      <input
        v-model="checkbox"
        @change="includeOrdisableTariff"
        type="checkbox"
        :id="`checkbox-${tariff.tariff_id}`"
        :disabled="isToggle"
        class="toggle"
      >
        <div class="toggle-handler"></div>
      </label>
    </div>

    <div
      v-if="profile.user.permission.instructor.tariffsChange"
      @click="deleteConfirmation"
      class="tariff__remove"
    >
      <AppLoader2 v-if="isLoaderDeletingTariff" :mini="true" />

      <div v-else class="tariff__remove-icon"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

import AppLoader2 from '@/components/AppLoader/2.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'TariffItem',

  components: {
    AppLoader2
  },

  props: {
    tariff: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      checkbox: false,
      isLoaderDeletingTariff: false,
      isToggle: false
    }
  },

  computed: {
    ...mapGetters(['profile'])
  },

  created() {
    this.checkbox = this.tariff.active;
  },

  methods: {
    ...mapActions(['tariffActivate', 'tariffInactivate', 'tariffDeleting']),
    includeOrdisableTariff() {
      this.isToggle = true;

      const data = {
        idInstructor: this.$route.params.id,
        idTariff: this.tariff.tariff_id
      }

      if (this.checkbox) {
        this.tariffActivate(data)
          .then(() => {
            this.$toast.open({
              message: 'Тариф активирован',
              type: 'success'
            });
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);
          })
          .finally(() => this.isToggle = false);
      } else {
        this.tariffInactivate(data)
          .then(() => {
            this.$toast.open({
              message: 'Тариф деактивирован',
              type: 'success'
            });
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);
          })
          .finally(() => this.isToggle = false);
      }
    },
    deletingTariff() {
      this.isLoaderDeletingTariff = true;

      const data = {
        idInstructor: this.$route.params.id,
        idTariff: this.tariff.tariff_id
      }

      this.tariffDeleting(data)
        .then(() => {
          this.$toast.open({
            message: 'Тариф удален',
            type: 'success'
          });
        })
        .catch((error) => {
          this.errorHandlerForResponse(error);
        })
        .finally(() => {
          this.isLoaderDeletingTariff = false;
        });
    },
    deleteConfirmation() {
      this.$modal.show('dialog', {
        title: 'Удалить тариф',
        text: 'Вы действительно хотите удалить тариф?',
        buttons: [
          {
            title: 'Да',
            handler: () => {
              this.deletingTariff();
              this.$modal.hide('dialog');
            },
          },
          {
            title: 'Нет',
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    errorHandlerForResponse
  }
}
</script>