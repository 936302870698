<template>
  <div class="account-content">
    <GoBack />

    <div class="account-subtitle pt-0 mb-3">Редактировать профиль</div>

    <AppLoader v-if="loading.instructor" />

    <template v-else>
      <div class="school-cabinet mb-5">
        <div class="school-cabinet_white-box">
          <div class="school-cabinet_info">
            <div class="school-cabinet_info-form">
              <div class="school-cabinet_info-row">
                <div class="school-cabinet_card-toggle">
                  <label class="toggle-box toggle-box--white" for="checkbox">
                  <input
                    v-model="activated"
                    @change="activateOrPause(instructorReal.instructor_id)"
                    type="checkbox"
                    :disabled="toggle"
                    class="toggle"
                    id="checkbox"
                  >
                    <div class="toggle-handler"></div>
                  </label>
                </div>
              </div>

              <div class="school-cabinet_info-row">
                <div class="school-cabinet_info-field form-group mb-4">
                  <input
                    v-model.trim="instructor.instructor.name.first"
                    @focus="hideErrorHighlight('name.first'); $v.instructor.instructor.name.first.$reset()"
                    type="text"
                    placeholder="Имя"
                    :class="{ 'form-control--error': $v.instructor.instructor.name.first.$error || errors.includes('name.first') }"
                    class="form-control form-control--bordered"
                  >

                  <div class="text-error" v-if="!$v.instructor.instructor.name.first.required && $v.instructor.instructor.name.first.$dirty">Поле обязательно для заполнения</div>
                  <div class="text-error" v-if="!$v.instructor.instructor.name.first.alpha && $v.instructor.instructor.name.first.$dirty">Вводите только буквы</div>
                </div>

                <div class="school-cabinet_info-field form-group mb-4">
                  <input
                    v-model.trim="instructor.instructor.name.last"
                    @focus="hideErrorHighlight('name.last'); $v.instructor.instructor.name.last.$reset()"
                    type="text"
                    placeholder="Фамилия"
                    :class="{ 'form-control--error': $v.instructor.instructor.name.last.$error || errors.includes('name.last') }"
                    class="form-control form-control--bordered"
                  >

                  <div class="text-error" v-if="!$v.instructor.instructor.name.last.required && $v.instructor.instructor.name.last.$dirty">Поле обязательно для заполнения</div>
                  <div class="text-error" v-if="!$v.instructor.instructor.name.last.alpha && $v.instructor.instructor.name.last.$dirty">Вводите только буквы</div>
                </div>

                <div class="school-cabinet_info-field form-group mb-4">
                  <input
                    v-model.trim="instructor.instructor.name.middle"
                    @focus="hideErrorHighlight('name.middle'); $v.instructor.instructor.name.middle.$reset()"
                    type="text"
                    placeholder="Отчество"
                    :class="{ 'form-control--error': $v.instructor.instructor.name.middle.$error || errors.includes('name.middle') }"
                    class="form-control form-control--bordered"
                  >

                  <div class="text-error" v-if="!$v.instructor.instructor.name.middle.required && $v.instructor.instructor.name.middle.$dirty">Поле обязательно для заполнения</div>
                  <div class="text-error" v-if="!$v.instructor.instructor.name.middle.alpha && $v.instructor.instructor.name.middle.$dirty">Вводите только буквы</div>
                </div>
              </div>
              <div class="school-cabinet_info-row">
                <div class="school-cabinet_info-row-label">Пол</div>
                <div class="school-cabinet_info-field">
                  <div class="radio d-flex align-items-center">
                    <label class="radio-container">Мужской
                      <input
                        v-model.trim="instructor.instructor.gender"
                        type="radio"
                        name="gender"
                        value="male"
                      >
                      <span class="radio-checkmark"></span>
                    </label>

                    <label class="radio-container">Женский
                      <input
                        v-model.trim="instructor.instructor.gender"
                        type="radio"
                        name="gender"
                        value="female"
                      >
                      <span class="radio-checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="school-cabinet_info-row">
                <div class="school-cabinet_info-row-label">Дата рождения</div>
                <div class="school-cabinet_info-field form-group mb-4">
                  <input
                    v-model.trim="instructor.instructor.birthdate"
                    @focus="hideErrorHighlight('birthdate'); $v.instructor.instructor.birthdate.$reset()"
                    v-mask="'##.##.####'"
                    type="text"
                    placeholder="ДД.ММ.ГГГГ"
                    :class="{ 'form-control--error': $v.instructor.instructor.birthdate.$error || errors.includes('birthdate') }"
                    class="form-control form-control--bordered"
                  >

                  <div class="text-error" v-if="!$v.instructor.instructor.birthdate.required && $v.instructor.instructor.birthdate.$dirty">Поле обязательно для заполнения</div>
                </div>

                <div class="school-cabinet_info-field form-group mb-4">
                  <input
                    v-model.trim="instructor.instructor.email"
                    @focus="hideErrorHighlight('email'); $v.instructor.instructor.email.$reset()"
                    type="text"
                    placeholder="E-mail"
                    :class="{ 'form-control--error': $v.instructor.instructor.email.$error || errors.includes('email') }"
                    class="form-control form-control--bordered"
                  >

                  <div class="text-error" v-if="!$v.instructor.instructor.email.required && $v.instructor.instructor.email.$dirty">Поле обязательно для заполнения</div>
                  <div class="text-error" v-if="!$v.instructor.instructor.email.email && $v.instructor.instructor.email.$dirty">Введите вашу почту</div>
                </div>

                <div class="school-cabinet_info-field form-group mb-4">
                  <input
                    v-model.trim="instructor.instructor.phone"
                    @focus="hideErrorHighlight('phone')"
                    type="text"
                    placeholder="Телефон"
                    :class="{ 'form-control--error': errors.includes('phone') }"
                    class="form-control form-control--bordered"
                  >
                </div>

                <button v-if="loading.editInstructor" class="btn btn-green btn-full btn-blue--custom">
                  <AppLoader2 :mini="true" :color="'#ffffff'" />
                </button>

                <button v-else @click="instructorEdit" class="btn btn-green btn-full btn-blue--custom">Сохранить</button>
              </div>

              <router-link :to="`/instructors/${instructorReal.instructor_id}/edit/change-password`" class="school-cabinet_card-link school-cabinet_card-link--bg school-cabinet_card-link--change-password justify-content-between mt-5">Сменить пароль</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="school-cabinet_info-delete mt-5">
        <AppLoader2
          v-if="loading.deleteInstructor"
          :mini="true"
        />

        <a
          v-else
          @click="deleteConfirmation"
          href="javascript:void(0)"
        >
          Удалить пользователя
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Vuelidate from 'vuelidate';
import { VueMaskDirective }  from 'v-mask'
import moment from 'moment';

import { email, required, helpers } from 'vuelidate/lib/validators';
const alpha = helpers.regex('alpha', /^[a-zA-Zа-яА-Я]*$/);

import AppLoader2 from '@/components/AppLoader/2.vue';
import AppLoader from '@/components/AppLoader/1.vue';
import GoBack from '@/components/GoBack.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';
import hideErrorHighlight from '@/helpers/hideErrorHighlight';

Vue.use(VueToast);
Vue.use(Vuelidate);

export default {
  name: 'InstructorEditing',

  components: {
    AppLoader2,
    AppLoader,
    GoBack
  },

  directives: {
    'mask': VueMaskDirective
  },

  data() {
    return {
      instructor: {
        instructor: {
          email: '',
          name: {
            first: '',
            middle: '',
            last: ''
          },
          gender: '',
          birthdate: '',
          phone: '',
        }
      },
      activated: null,
      toggle: false,
      errors: []
    }
  },

  computed: {
    ...mapGetters({
      instructorReal: 'instructor',
      loading: 'loadingInstructors'
    })
  },

  async created() {
    await this.getInstructor(this.$route.params.id);

    this.instructor.instructor.email = this.instructorReal.email;
    this.instructor.instructor.name.first = this.instructorReal.name.first;
    this.instructor.instructor.name.last = this.instructorReal.name.last;
    this.instructor.instructor.name.middle = this.instructorReal.name.middle;
    this.instructor.instructor.gender = this.instructorReal.gender;
    this.instructor.instructor.birthdate = this.moment(this.instructorReal.birthdate).format('DD.MM.YYYY');
    this.instructor.instructor.phone = this.instructorReal.phone;

    this.activated = !this.instructorReal.paused;
  },

  beforeDestroy() {
    this.clearInstructor();
  },

  methods: {
    ...mapActions([
      'getInstructor',
      'editInstructor',
      'clearInstructor',
      'instructorActivate',
      'instructorPause',
      'deleteInstructor'
    ]),
    instructorEdit() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      } else {
        const instructor = {
          instructor: JSON.parse(JSON.stringify(this.instructor.instructor))
        };

        if (instructor.instructor.birthdate) {
          instructor.instructor.birthdate = this.moment(instructor.instructor.birthdate, 'DD-MM-YYYY').format('YYYY-MM-DD');
        }

        const payload = {
          idInstructor: this.$route.params.id,
          instructor
        }

        this.editInstructor(payload)
          .then(() => {
            this.$toast.open({
              message: 'Данные инструктора успешно изменены',
              type: 'success'
            });
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);

            if (error.response.data.errors) {
              this.errorsCheck(error.response.data.errors);
            }
          });
      }
    },
    errorsCheck(errors) {
      Object.keys(errors).forEach(el => {
        switch (el) {
          case 'instructor.name.first':
            this.errors.push('name.first');
            break;
          case 'instructor.name.last':
            this.errors.push('name.last');
            break;
          case 'instructor.name.middle':
            this.errors.push('name.middle');
            break;
          case 'instructor.birthdate':
            this.errors.push('birthdate');
            break;
          case 'instructor.email':
            this.errors.push('email');
            break;
          case 'instructor.phone':
            this.errors.push('phone');
            break;
        }
      })
    },
    activateOrPause(idInstructor) {
      this.toggle = true;

      if (this.instructorReal.paused) {
        this.instructorActivate(idInstructor)
          .then(() => {
            this.$toast.open({
              message: 'Инструктор активирован',
              type: 'success'
            });
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);
          })
          .finally(() => this.toggle = false);
      } else {
        this.instructorPause(idInstructor)
          .then(() => {
            this.$toast.open({
              message: 'Инструктор деактивирован',
              type: 'success'
            });
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);
          })
          .finally(() => this.toggle = false);
      }
    },
    deleteConfirmation() {
      this.$modal.show('dialog', {
        text: 'Вы действительно хотите удалить инструктора?',
        buttons: [
          {
            title: 'Да',
            handler: () => {
              this.delInstructor();
              this.$modal.hide('dialog');
            },
          },
          {
            title: 'Нет',
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    delInstructor() {
      this.deleteInstructor(this.$route.params.id)
        .then(() => {
          this.$toast.open({
            message: 'Инструктор удален',
            type: 'success'
          });

          this.$router.push('/instructors');
        })
        .catch((error) => {
          this.errorHandlerForResponse(error);
        })
    },
    hideErrorHighlight,
    moment,
    errorHandlerForResponse
  },

  validations: {
    instructor: {
      instructor: {
        email: {
          required,
          email
        },
        name: {
          first: {
            required,
            alpha
          },
          middle: {
            required,
            alpha
          },
          last: {
            required,
            alpha
          }
        },
        gender: {
          required
        },
        birthdate: {
          required
        },
      }
    }
  }
}
</script>

<style>
.school-cabinet_card-link--change-password {
  border: 1px solid #e8e8e8;
}

.custom-button {
  padding: 0;
  font-size: 16px;
  color: rgb(215 0 0);
  background: transparent;
}
</style>