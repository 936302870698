<template>
  <div :class="{ 'flex-md-nowrap': isCreate }" class="filters d-flex justify-content-between flex-wrap">
    <div class="filters-item filters-item--large mb-2 mb-md-0">
      <div ref="clientsForAddGroup" class="filters-select">
        <div class="filters-select-wrapper">
          <div
            v-if="!isClientsForAddGroup"
            @click.stop="openClientsForAddGroup"
            class="filters-select-title filters-select-title--desk"
          >
            Добавить одного или несколько клиентов в группу
          </div>
          <div
            v-if="!isClientsForAddGroup"
            @click.stop="openClientsForAddGroup"
            class="filters-select-title filters-select-title--mob"
          >
            Добавить клиента(ов) в группу
          </div>
          <input
            v-if="isClientsForAddGroup"
            ref="inputSearchClient"
            v-model="searchClient"
            v-debounce:1000ms="searchClients"
            placeholder="Клиент"
            class="filters-select-title input-by-clients"
          >
          <div :class="{ active: isClientsForAddGroup }" class="filters-select-arrow"></div>
        </div>
        <transition name="fade">
          <div
            v-if="isClientsForAddGroup"
            :class="{ active: isClientsForAddGroup }"
            class="filters-select-dropdown"
          >
            <template v-if="filterClients.length">
              <div
                v-for="client in filterClients"
                :key="client.client_id"
                :class="{ opacity: isLoadClients }"
                class="custom-checkbox custom-checkbox--transition "
              >
                <input
                  v-model="clientsForAddGroup"
                  :value="client.client_id"
                  :id="client.client_id"
                  type="checkbox"
                  name="district"
                >
                <label :for="client.client_id" class="d-flex">
                  {{ client.name.full }}
                </label>
              </div>

              <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                <div slot="spinner">
                  <AppLoader2 :mini="true" />
                </div>
                <div slot="no-more"></div>
                <div slot="no-results"></div>
              </infinite-loading>
            </template>

            <p v-else>Клиент не найден</p>
          </div>
        </transition>
      </div>
    </div>
    <button @click="clientsAdd" class="btn btn-blue">Добавить</button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import InfiniteLoading from 'vue-infinite-loading';
import AppLoader2 from '@/components/AppLoader/2.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'SearchClients',

  components: {
    InfiniteLoading,
    AppLoader2,
  },

  props: {
    isLoadingPage: {
      type: Boolean,
      required: false
    },
    isCreate: {
      type: Boolean,
      required: false
    },
  },

  data() {
    return {
      isClientsForAddGroup: false,
      searchClient: '',
      isLoadClients: false,
      infiniteId: +new Date(),
      clientsForAddGroup: [],
    }
  },

  computed: {
    ...mapGetters({
      filterClients: 'filterClients',
      filterClientsMeta: 'filterClientsMeta',
    }),
  },

  created() {
    try {
      this.clearFilterClients();
      this.changePageFilterClients(1);
      this.changeFiltersFilterClientsSearch(null);
      this.getFilterClients();
    } catch (e) {
      this.errorHandlerForResponse(e);
    }
  },

  mounted() {
    document.addEventListener('click', this.hideClientsForAddGroup);
  },

  destroyed() {
    document.removeEventListener('click', this.hideClientsForAddGroup);
  },

  methods: {
    ...mapActions([
      'getFilterClients',
      'getFilterClientsForStudyGroups',
      'changePageFilterClients',
      'changeFiltersFilterClientsSearch',
      'clearFilterClients',
    ]),
    ...mapActions('studyGroups/clients', {
      getClients: 'getList',
      addClients: 'add',
    }),
    async searchClients(value) {
      try {
        this.isLoadClients = true;

        const search = value.trim();

        this.changePageFilterClients(1);
        this.changeFiltersFilterClientsSearch(search);
        await this.getFilterClientsForStudyGroups();
        this.infiniteId += 1;
      } catch (e) {
        this.errorHandlerForResponse(e);
      } finally {
        this.isLoadClients = false;
      }
    },
    openClientsForAddGroup() {
      this.isClientsForAddGroup = true;

      this.$nextTick(() => {
        this.$refs.inputSearchClient.focus();
      })
    },
    hideClientsForAddGroup(event) {
      if (this.$refs.clientsForAddGroup?.contains(event.target)) {
        return;
      } else {
        this.isClientsForAddGroup = false;
      }
    },
    clientsAdd() {
      if (this.isCreate) {
        this.$emit('groupCreate', this.clientsForAddGroup);

        return;
      }

      if (this.isLoadingPage) {
        return;
      }

      if (!this.clientsForAddGroup.length) {
        return;
      }

      this.$emit('changeIsLoadingPage', true);

      const payload = {
        groupId: this.$route.params.id,
        clients: {
          clients: this.clientsForAddGroup
        }
      }

      this.addClients(payload)
        .then(() => {
          this.clientsForAddGroup = [];

          this.getClients(this.$route.params.id)
            .catch((e) => this.errorHandlerForResponse(e))
            .finally(() => this.$emit('changeIsLoadingPage', false));
        })
        .catch((e) => {
          this.errorHandlerForResponse(e)
          this.$emit('changeIsLoadingPage', false);
        });
    },
    infiniteHandler($state) {
      if (this.filterClientsMeta.page.number < this.filterClientsMeta.page.total) {
        this.getFilterClients(false)
          .then(() => {
            $state.loaded();
          })
          .catch((e) => this.errorHandlerForResponse(e));
      } else {
        $state.complete();
      }
    },
    errorHandlerForResponse
  },
}
</script>

<style lang="scss">
.filters-select-dropdown {
  max-height: 400px;
  overflow-y: auto;

  &--modal {
    max-height: 200px;
  }
}

.filters-select-dropdown::-webkit-scrollbar {
  width: 10px;
}

.filters-select-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.filters-select-dropdown::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
}

.filters-select-dropdown::-webkit-scrollbar-thumb:hover {
  background: rgb(139, 139, 139);
}

.input-by-clients {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

.custom-checkbox--transition {
  transition: .2s;
}
</style>