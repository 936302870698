<template>
	<div class="account-content">
		<div class="settings">
			<div class="settings-top d-flex align-items-end">
				<div class="settings-head d-flex align-items-center">
					<div class="settings-title">Сервисы</div>  
				</div>
			</div>   
		</div>
		<div class="services">
			<div class="services__list">
				<router-link to="/services/exams" class="services__item" v-if="profile.user.permission.service_exams.view">
					<div class="services__icon">
						<img src="@/assets/img/exam.svg" alt="">
					</div>
					<div class="services__name">
						Экзамены
					</div>
				</router-link>
				<router-link to="/services/integrations" class="services__item" v-if="profile.user.permission.service_hub.view">
					<div class="services__icon">
						<img src="@/assets/img/integrations.svg" alt="">
					</div>
					<div class="services__name">
						Интеграции
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'Services',
	computed: {
	...mapGetters(['profile']),
	},
}
</script>
