<template>
  <div class="school-cabinet_card">
    <div class="school-cabinet_card-top d-flex align-items-center">
      <router-link :to="`/clients/${client.client_id}`" class="school-cabinet_card-content d-flex align-items-center">
        <div class="school-cabinet_card-icon">
          <img v-if="client.avatar" :src="client.avatar.url" alt="Аватар">
          <img v-else src="@/assets/img/noavatar.svg" alt="Аватар">
        </div>
        <div class="school-cabinet_card-text">
          <div class="school-cabinet_card-name">
            {{ client.name.full }}
            <span v-if="client.reminder && client.reminder.today" class="school-cabinet_card-status"></span>
          </div>
          <div class="school-cabinet_card-facts">{{ client.email }}<template v-if="client.email && client.phone">, <br class="mob-only"></template>{{ client.phone }}</div>
        </div>
      </router-link>

      <a href="javascript:void(0)" class="school-cabinet_card-button school-cabinet_card-button--lessons school-cabinet_card-button--hide d-flex flex-column flex-md-row justify-content-center align-items-center mr-0 ml-auto">
        <div class="school-cabinet_card-button-icon"></div>
        <div class="school-cabinet_card-button-title">112</div>
      </a>

      <div ref="menuBlock" class="school-cabinet_menu ml-2 ml-md-4">
        <div @click="isMenuBlock = !isMenuBlock" class="school-cabinet_menu-btn"></div>
        <transition name="fade">
          <div
            v-if="isMenuBlock"
            :class="{ active: isMenuBlock }"
            class="school-cabinet_menu-block"
          >
            <div @click="openModalTransferClients" class="school-cabinet_menu-item">
              <a href="javascript:void(0)">Перевести в другую группу</a>
            </div>
            <div @click="removeFromGroup" class="school-cabinet_menu-item">
              <a href="javascript:void(0)">Убрать из группы</a>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudyGroupsClientCard',

  props: {
    client: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data() {
    return {
      isMenuBlock: false
    }
  },

  mounted() {
    document.addEventListener('click', this.hideMenuBlock);
  },

  destroyed() {
    document.removeEventListener('click', this.hideMenuBlock);
  },

  methods: {
    removeFromGroup() {
      this.$emit('removeFromGroup');
      this.isMenuBlock = false;
    },
    hideMenuBlock(event) {
      if (this.$refs.menuBlock?.contains(event.target)) {
        return;
      } else {
        this.isMenuBlock = false;
      }
    },
    openModalTransferClients() {
      this.$emit('openModalTransferClients');
      this.isMenuBlock = false;
    },
  },
}
</script>

<style>
.school-cabinet_card-button--hide {
  visibility: hidden;
}
</style>