<template>
  <div ref="pofileContent" class="header-profile-content pt-3" >
    <div v-if="profile">
      <!-- <router-link
        v-if="profile.user.permission.instructor.view"
        :to="{ name: 'Instructors', params: { page: 1 } }"
        class="header-profile-link d-flex text-black"
      >
        <div class="header-profile-link-icon">
          <i class="my-clients"></i>
        </div>
        <div @click="$emit('hideProfileContent')" class="header-profile-link-link">
          <div>Инструкторы</div>
        </div>
      </router-link>

      <router-link
        v-if="profile.user.permission.client.view"
        :to="{ name: 'Clients', params: { page: 1 } }"
        class="header-profile-link d-flex text-black"
      >
        <div class="header-profile-link-icon">
          <i class="user"></i>
        </div>
        <div @click="$emit('hideProfileContent')" class="header-profile-link-link">
          <div>Клиенты</div>
        </div>
      </router-link>

      <router-link
        v-if="profile.user.permission.assignment.view"
        :to="{ name: 'StudyGroups', params: { page: 1 } }"
        class="header-profile-link d-flex text-black"
      >
        <div class="header-profile-link-icon">
          <i class="clients"></i>
        </div>
        <div @click="$emit('hideProfileContent')" class="header-profile-link-link">
          <div>Группы обучения</div>
        </div>
      </router-link> -->

      <!-- <router-link
        v-if="profile.user.permission.assignment.view"
        :to="{ name: 'Assignment', params: { page: 1 } }"
        class="header-profile-link d-flex text-black"
      >
        <div class="header-profile-link-icon">
          <i class="timing"></i>
        </div>
        <div @click="$emit('hideProfileContent')" class="header-profile-link-link">
          <div>Распределение</div>
        </div>
      </router-link> -->

      <!-- <router-link
        v-if="profile.user.permission.analytics.view"
        :to="{ name: 'Analytics', query: { registered_from: dayOfWeekFrom(-6), registered_to: dayOfWeekTo(7) }, params: { page: 1 } }"
        class="header-profile-link d-flex text-black"
      >
        <div class="header-profile-link-icon">
          <i class="analitics"></i>
        </div>
        <div @click="$emit('hideProfileContent')" class="header-profile-link-link">
          <div>Аналитика</div>
        </div>
      </router-link> -->

      <router-link to="/settings" class="header-profile-link d-flex text-black">
        <div class="header-profile-link-icon">
          <i class="settings"></i>
        </div>
        <div @click="$emit('hideProfileContent')" class="header-profile-link-link">
          <div>Настройки пользователя</div>
        </div>
      </router-link>

      <!-- <router-link
        v-if="profile.user.permission.user.view"
        :to="{ name: 'Administrators', params: { page: 1 } }"
        class="header-profile-link d-flex text-black"
      >
        <div class="header-profile-link-icon">
          <i class="administrators"></i>
        </div>
        <div @click="$emit('hideProfileContent')" class="header-profile-link-link">
          <div>Администраторы</div>
        </div>
      </router-link>

      <router-link
        v-if="profile.user.permission.settlements.view"
        :to="{ name: 'Transactions', query: { status: 'received', created_at_from: dayOfWeekFrom(1), created_at_to: dayOfWeekTo(7) }, params: { page: 1 } }"
        class="header-profile-link d-flex text-black"
      >
        <div class="header-profile-link-icon">
          <i class="card"></i>
        </div>
        <div @click="$emit('hideProfileContent')" class="header-profile-link-link">
          <div>Взаиморасчеты</div>
        </div>
      </router-link>

      <router-link
        v-if="profile.user.permission.user.view"
        :to="{ name: 'Activity', query: { date_from: dayOfWeekFrom(1), date_to: dayOfWeekTo(7) }, params: { page: 1 } }"
        class="header-profile-link d-flex text-black"
      >
        <div class="header-profile-link-icon">
          <i class="views"></i>
        </div>
        <div @click="$emit('hideProfileContent')" class="header-profile-link-link">
          <div>Журнал действий</div>
        </div>
      </router-link> -->

      <a
        @click="logout"
        href="javascript:void(0)"
        class="header-profile-link d-flex header-profile-link--logout"
      >
        <div class="header-profile-link-icon">
          <i class="fas fa-sign-out-alt"></i>
        </div>
        <div class="header-profile-link-link">
          <div>Выход</div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import moment from 'moment';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'HeaderProfileContent',

  computed: {
    ...mapGetters(['profile']),
  },

  mounted() {
    document.addEventListener('click', this.hideProfileContent);
  },

  destroyed() {
    document.removeEventListener('click', this.hideProfileContent);
  },

  methods: {
    ...mapActions(['logOut', 'clearToken', 'clearProfile']),
    logout() {
      this.logOut()
        .then(() => {
          this.clearToken();
          this.clearProfile();

          this.$emit('hideProfileContent');

          this.$router.push('/login');
        })
        .catch((error) => {
          this.errorHandlerForResponse(error);
        });
    },
    hideProfileContent(event) {
      const navBar = document.querySelector('.navbar-profile');

      if (this.$refs.pofileContent.contains(event.target) || navBar.contains(event.target)) {
        return
      } else {
        this.$emit('hideProfileContent');
      }
    },
    dayOfWeekFrom(from) {
      return moment().day(from).format('DD-MM-YYYY');
    },
    dayOfWeekTo(to) {
      return moment().day(to).format('DD-MM-YYYY');
    },
    errorHandlerForResponse
  }
}
</script>

<style>
.header-profile-link-icon .user,
.header-profile-link-icon .clients,
.header-profile-link-icon .timing,
.header-profile-link-icon .analitics,
.header-profile-link-icon .settings,
.header-profile-link-icon .administrators,
.header-profile-link-icon .card,
.header-profile-link-icon .views {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-left: -2px;
}

.header-profile-link-icon .user {
  background: url(~@/assets/img/user-icon.svg) no-repeat center;
}

.header-profile-link-icon .clients {
  background: url(~@/assets/img/clients.svg) no-repeat center;
}

.header-profile-link-icon .timing {
  background: url(~@/assets/img/timing.svg) no-repeat center;
}

.header-profile-link-icon .analitics {
  background: url(~@/assets/img/analitics.svg) no-repeat center;
}

.header-profile-link-icon .settings {
  background: url(~@/assets/img/settings.svg) no-repeat center;
}

.header-profile-link-icon .administrators {
  background: url(~@/assets/img/key.svg) no-repeat center;
}

.header-profile-link-icon .card {
  background: url(~@/assets/img/card.svg) no-repeat center;
}

.header-profile-link-icon .views {
  background: url(~@/assets/img/eye.svg) no-repeat center;
}
</style>