import instance from '../../instance';
import settings from '../../settings';

export default {
  state: {
    filterUser: null,
    filterUsers: [],
    filterUsersMeta: [],
    filtersForFilterUsers: {
      page: 1,
      page_size: 20,
      query: '',
    },
    filterUsersLoading: {
      users: true,
    }
  },

  getters: {
    filterUsers: state => state.filterUsers,
    filterUser: state => state.filterUser,
    filterUsersMeta: state => state.filterUsersMeta,
    filtersForFilterUsers: state => state.filtersForFilterUsers,
    filterUsersLoading: state => state.filterUsersLoading.users,
  },

  actions: {
    async getFilterUsers({ commit, state }, loading = true) {
      if (loading) {
        commit('START_GET_FILTER_USERS_LOADING');
      }

      try {
        const requestId = 'usersFilter';

        const params = Object.fromEntries(Object.entries(state.filtersForFilterUsers).filter(([key, value]) => key && value));

        const users = await instance.get(`${settings.API_URL}/users`, { requestId, params } );

        commit('SET_FILTER_USERS', users.data);
      } finally {
        commit('FINISH_GET_FILTER_USERS_LOADING');
      }
    },
    async getFilterUser({ commit, state }, id) {
      try {
        const requestId = 'usersFilter';

        const params = Object.fromEntries(Object.entries(state.filtersForFilterUsers).filter(([key, value]) => key && value));

        const user = await instance.get(`${settings.API_URL}/users/${id}`, { requestId, params } );

        commit('SET_FILTER_USER', user.data);
      } finally {
        commit('FINISH_GET_FILTER_USERS_LOADING');
      }
    },
    clearFilterUsers({ commit }) {
      commit('CLEAR_FILTER_USERS');
    },
    changePageFilterUsers({ commit }, page) {
      commit('CHANGE_PAGE_FILTER_USERS', page);
    },
    changeFiltersFilterUsersSearch({ commit }, search) {
      commit('CHANGE_FILTERS_FILTERS_USERS_SEARCH', search);
    },
    resetFiltersFilterUsers({ commit }) {
      commit('CHANGE_PAGE_FILTER_USERS', 1);
      commit('CHANGE_FILTERS_FILTERS_USERS_SEARCH', null);
    },
    addUserInFilterListUsers({ commit }, user) {
      commit('ADD_USER_IN_FILTER_LIST_USERS', user);
    },
    setLoadingUsers({ commit }) {
      commit('START_GET_FILTER_USERS_LOADING');
    },
  },

  mutations: {
    START_GET_FILTER_USERS_LOADING(state) {
      state.filterUsersLoading.users = true;
    },
    FINISH_GET_FILTER_USERS_LOADING(state) {
      state.filterUsersLoading.users = false;
    },
    CLEAR_FILTER_USERS(state) {
      state.filterUsers = [];
    },
    CHANGE_PAGE_FILTER_USERS(state, page) {
      state.filtersForFilterUsers.page = page;
    },
    SET_FILTER_USERS(state, users) {
      state.filterUsers.push(...users.users);
      state.filterUsersMeta = users.meta;
      state.filtersForFilterUsers.page++;
    },
    SET_FILTER_USER(state, user) {
      state.filterUser = user.user;
    },
    CHANGE_FILTERS_FILTERS_USERS_SEARCH(state, search) {
      state.filtersForFilterUsers.query = search;
    },
    ADD_USER_IN_FILTER_LIST_USERS(state, user) {
      if (!state.filterUsers.find(el => el.user_id === user.user_id)) {
        state.filterUsers.unshift(user);
      }
    },

  },
};