export default function checkStatus(status) {
  switch (status) {
    case 'created':
      return 'Создано, но не доступно'
    case 'available':
      return 'Доступно'
    case 'planned':
      return 'Запланировано'
    case 'in_progress':
      return 'Началось'
    case 'time_over':
      return 'Завершилось по времени'
    case 'completed':
      return 'Успешно завершилось'
  }
}