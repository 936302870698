import instance from '../instance';
import settings from '../settings';

export default {
  namespaced: true,

  state: {
    acts: null,
    instructors: null,
    filters: {
      date_from: null,
      date_to: null,
      query: '',
    },
    loading: {
      acts: true,
      instructors: true,
      request: false,
    }
  },

  getters: {
    acts: state => state.acts,
    instructors: state => state.instructors,
    filters: state => state.filters,
    filtersApplied: state => state.filters.date_from && state.filters.date_to,
    loading: state => state.loading,
  },

  actions: {
    async getInstructors({ commit, state }, loading = true) {
      if (loading) {
        commit('START_LOADING');
      }

      const params = Object.fromEntries(Object.entries(state.filters).filter(([key, value]) => key && value));

      try {
        const instructors = await instance.get(`${settings.API_URL}/documents/reports/instructors`, { params: params });

        commit('SET_INSTRUCTORS', instructors.data);
      } finally {
        commit('FINISH_LOADING');
      }
    },
    async getActs({ commit }) {
      const acts = await instance.get(`${settings.API_URL}/documents/reports`);

      commit('SET_ACTS', acts.data);
    },
    async requestActs({ commit }, payload) {
      commit('START_LOADING_REQUEST');
      try {
        await instance.post(`${settings.API_URL}/documents/reports`, payload);

      } finally {
        commit('FINISH_LOADING_REQUEST');
      }
    },
    changeFiltersDate({ commit }, dates) {
      commit('CHANGE_FILTERS_DATES', dates);
    },
    resetFilters({ commit }) {
      commit('CHANGE_FILTERS_DATES', { date_from: null, date_to: null, query: '' });
    },
    changeSearchInstructors({ commit }, search) {
      commit('CHANGE_SEARCH_INSTRUCTORS', search);
    },
  },

  mutations: {
    SET_INSTRUCTORS(state, instructors) {
      state.instructors = instructors.reports;
    },
    SET_ACTS(state, acts) {
      state.acts = acts.reports;
    },
    CHANGE_FILTERS_DATES(state, dates) {
      state.filters.date_from = dates.from;
      state.filters.date_to = dates.to;
    },
    START_LOADING(state) {
      state.loading.instructors = true;
    },
    FINISH_LOADING(state) {
      state.loading.instructors = false;
    },
    START_LOADING_REQUEST(state) {
      state.loading.request = true;
    },
    FINISH_LOADING_REQUEST(state) {
      state.loading.request = false;
    },
    CHANGE_SEARCH_INSTRUCTORS(state, search) {
      state.filters.query = search;
    },
  },
};
