<template>
  <div class="drop-down-menu">
    <div
      v-click-outside="hide"
      @click="opened = !opened"
      :class="{ 'border-bottom-none': opened}"
      class="form-control form-control--bordered"
    >
      {{ selected }}

      <span
        :class="{ rotate: opened }"
        class="form-control--arrow"
      ></span>
    </div>

    <ul v-if="opened" class="form-control--custom">
      <li
        v-for="(li, index) in list"
        :key="index"
        @click="select(li)"
      >
        {{ li }}
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropDownMenuTransmission',

  props: {
    list: {
      typeof: Array,
      required: true
    }
  },

  directives: {
    ClickOutside
  },

  data() {
    return {
      opened: false,
      selected: ''
    }
  },

  created() {
    this.selected = this.list[0];
  },

  mounted () {
    this.popupItem = this.$el
  },

  methods: {
    hide() {
      this.opened = false;
    },
    select(li) {
      this.selected = li;

      this.hide();

      this.$emit('selected', li);
    }
  }
}
</script>
