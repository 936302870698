<template>
  <div class="school-cabinet__lessons-card d-flex justify-content-between flex-wrap flex-md-nowrap mb-2">
    <div class="school-cabinet_card-content d-flex">
      <router-link
        :to="`/instructors/${lesson.instructor.instructor_id}/profile`"
        class="school-cabinet_card-icon"
      >
        <img v-if="lesson.instructor.avatar" :src="lesson.instructor.avatar.url" :alt="lesson.instructor.name.full">
        <img v-else src="@/assets/img/noavatar.svg" alt="Нет фото">
      </router-link>

      <div class="school-cabinet_card-text mt-1 width-244">
        <router-link
          :to="`/instructors/${lesson.instructor.instructor_id}/profile`"
          class="school-cabinet_card-name"
        >
          {{ lesson.instructor.name.full }}
        </router-link>
        <div class="school-cabinet_card-facts mr-md-3">{{ lesson.instructor.email }}<template v-if="lesson.instructor.phone">,</template> <span class="white-space-nowrap">{{ lesson.instructor.phone }}</span></div>
      </div>
    </div>

    <div class="school-cabinet__lessons-card-content mt-3 mt-md-1">
      <div class="d-flex justify-content-between">
        <div class="school-cabinet_card-info-item">
          <div class="school-cabinet_card-info-text mb-1">{{ lesson.tariff.title }} | {{ lesson.tariff.price }} руб.</div>
          <div class="school-cabinet_card-info-title">Занятий <strong>всего <span class="color-blue">{{ lesson.statistics.total }}</span></strong> / <strong>запланировано <span class="color-blue">{{ lesson.statistics.planned }}</span></strong> / <strong>осталось <span class="color-blue">{{ lesson.statistics.available }}</span></strong></div>
        </div>

        <div @click="isEdit = !isEdit" class="school-cabinet_card-edit mt-1" v-if="lesson.tariff.active == true && lesson.tariff.archived == false"></div>
      </div>

      <div v-if="lesson.note" class="school-cabinet_card-info-title mt-2">{{ lesson.note }}</div>

      <div :class="{ 'd-block': isEdit }" class="school-cabinet__lessons-card-more mt-4">
        <div class="d-flex align-items-center justify-content-between mb-2 pb-1">
          <div class="school-cabinet_card-info-text">Изменить количество занятий:</div>

          <div class="school-cabinet__lessons-card-calc">
            <button @click="lessons.lessons.qty > 0 ? lessons.lessons.qty-- : null" class="button-calc button-calc--minus"><i class="fas fa-minus"></i></button>

            <span>{{ lessons.lessons.qty }}</span>

            <button @click="lessons.lessons.qty++" class="button-calc button-calc--plus"><i class="fas fa-plus"></i></button>
          </div>
        </div>

        <div class="school-cabinet_card-info-text mb-2">Редактировать заметку:</div>

        <div class="school-cabinet__lessons-card-field">
          <textarea
            v-model="lessons.note"
            cols="30"
            rows="10"
            class="form-control form-control--bordered"
          ></textarea>
        </div>

        <div class="school-cabinet__lessons-card-button mt-3 mb-1">
          <AppLoader2
            v-if="isLoadingChangeLessons"
            :mini="true"
          />

          <a
            v-else
            @click="lessonsChange"
            href="javascript:void(0)"
            class="line-height-22"
          >
            Сохранить
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import AppLoader2 from '@/components/AppLoader/2.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'ClientLessonsGroupedInstructorCard',

  components: {
    AppLoader2
  },

  props: {
    lesson: {
      typeof: Object,
      required: true
    },
    client: {
      typeof: Object,
      required: true
    }
  },

  data() {
    return {
      lessons: {
        lessons: {
          instructor_id: '',
          client_id: '',
          tariff_id: '',
          qty: ''
        },
        note: ''
      },
      isEdit: false,
      isLoadingChangeLessons: false
    }
  },

  created() {
    this.lessons.lessons.instructor_id = this.lesson.instructor.instructor_id;
    this.lessons.lessons.client_id = this.client.client_id;
    this.lessons.lessons.tariff_id = this.lesson.tariff.tariff_id;
    this.lessons.lessons.qty = this.lesson.statistics.available;
    this.lessons.note = this.lesson.note;
  },

  methods: {
    ...mapActions(['changeLessons', 'getClientLessonsGroupedInstructor']),
    lessonsChange() {
      this.isLoadingChangeLessons = true;

      this.changeLessons(this.lessons)
        .then(() => {
          const payload = {
            idClient: this.client.client_id,
            loading: false
          }

          this.getClientLessonsGroupedInstructor(payload)
            .then(() => {
              this.$toast.open({
                message: 'Изменения сохранены',
                type: 'success'
              });

              this.isEdit = false;
            })
            .catch((error) => {
              this.errorHandlerForResponse(error);
            })
            .finally(() => this.isLoadingChangeLessons = false)
        })
        .catch((error) => {
          this.isLoadingChangeLessons = false;

          this.errorHandlerForResponse(error);
        })
    },
    errorHandlerForResponse
  }
}
</script>

<style>
.line-height-22 {
  line-height: 20px;
}

@media screen and (min-width: 768px) {
  .width-244 {
    width: 244px;
  }
}

.white-space-nowrap {
  white-space: nowrap;
}
.school-cabinet_card-facts {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>