<template>
  <div class="account-content">
    <div class="settings-top d-md-flex align-items-end">
      <GoBack style="margin-bottom: 0 !important" />

      <div class="settings-search ml-auto d-flex mt-4 mt-md-0">
        <input
          v-model.trim="search"
          v-debounce:1000ms="searchByClients"
          type="text"
          class="form-control form-control--search pl-2 pl-md-2"
          placeholder="Поиск по клиентам"
        >
        <button></button>
      </div>
    </div>

    <div class="d-flex">
      <div class="account-subtitle account-subtitle--large pt-0 mb-3">
        <span class="light">Клиенты группы</span>
        <template v-if="group">
          {{ group.name }} <span class="light">({{ group.clients_count }})</span>
        </template>
      </div>
      <router-link
        v-if="group"
        :to="`/study-groups/${group.study_group_id}/edit`"
        class="account-button-edit ml-3"
      ></router-link>
    </div>

    <AppLoader v-if="loading.list" />

    <div v-else class="school-cabinet">
      <SearchClients :isLoadingPage="isLoadingPage" @changeIsLoadingPage="changeIsLoadingPage" />

      <div class="wrapper-for-spinner" :class="{ opacity: isLoadingPage }">
        <AppLoader3 v-if="isLoadingPage" class="wrapper-for-spinner__spinner" />

        <template v-if="clients.length">
          <ClientCard
            v-for="client in clients"
            :key="client.client_id"
            :client="client"
            @openModalTransferClients="openModalTransferClients(client)"
            @removeFromGroup="removeFromGroup(client)"
          />

          <div v-if="meta.page.total > 1" class="d-flex justify-content-center">
            <paginate
              v-model="page"
              :pageCount="meta.page.total"
              :clickHandler="clickCallback"
              :prevText="''"
              :nextText="''"
              :containerClass="'pagination'"
              :pageClass="'page-item'"
              :pageLinkClass="'page-link'"
              :prevClass="'page-item'"
              :prevLinkClass="'page-link'"
              :nextClass="'page-item'"
              :nextLinkClass="'page-link'"
              :activeClass="'active'"
            />
          </div>
        </template>

        <p v-else>Данных нет...</p>
      </div>
    </div>

    <ModalTransferClients
      v-if="group && clientForModal"
      :isModalTransferClients="isModalTransferClients"
      :client="clientForModal"
      @transferClient="transferClient"
      @hideModal="isModalTransferClients = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Paginate from 'vuejs-paginate';

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader3 from '@/components/AppLoader/3.vue';
import GoBack from '@/components/GoBack.vue';
import ModalTransferClients from '@/components/ModalTransferClients.vue';

import ClientCard from '@/components/StudyGroups/Client/Card';
import SearchClients from '@/components/StudyGroups/SearchClients.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'StudyGroupsClients',

  components: {
    Paginate,
    AppLoader,
    AppLoader3,
    GoBack,
    ModalTransferClients,
    ClientCard,
    SearchClients,
  },

  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      isLoadingPage: false,
      search: '',
      isModalTransferClients: false,
      clientForModal: null,
    }
  },

  computed: {
    ...mapGetters('studyGroups/clients', {
      clients: 'list',
      meta: 'meta',
      loading: 'loading',
    }),
    ...mapGetters('studyGroups', {
      group: 'item',
    }),
    ...mapGetters({
      profile: 'profile',
    }),
  },

  created() {
    try {
      this.clearClients();
      this.clearGroup();

      this.addQueryFromUrl();

      this.getClients(this.$route.params.id);
      this.getGroup(this.$route.params.id);

      this.clearStudyGroups();
      this.changeFiltersStudyGroups({ key: 'school_id', value: this.profile.school.school_id });
      this.getStudyGroups();

      if (this.$route.query.query) {
        this.search = this.$route.query.query;
      }
    } catch (e) {
      this.errorHandlerForResponse(e);
    }
  },

  methods: {
    ...mapActions('studyGroups/clients', {
      getClients: 'getList',
      clearClients: 'clearList',
      changeFilters: 'changeFilters',
      transferAndRemoveClients: 'transferAndRemove',
    }),
    ...mapActions('studyGroups', {
      getStudyGroups: 'getList',
      clearStudyGroups: 'clearList',
      changeFiltersStudyGroups: 'changeFilters',
      getGroup: 'getItem',
      clearGroup: 'clearItem',
    }),
    clickCallback() {
      this.isLoadingPage = true;

      this.changeFilters({ key: 'page', value: this.page })

      let query = Object.assign({}, this.$route.query);
      query.page = this.page;
      this.$router.replace({ query }).catch(() => {});

      this.getClients(this.$route.params.id)
        .catch((e) => this.errorHandlerForResponse(e))
        .finally(() => this.isLoadingPage = false);
    },
    addQueryFromUrl() {
      if (!this.isEmptyObj(this.$route.query)) {
        Object.entries(this.$route.query).forEach(([key, value]) => {
          this.changeFilters({ key, value });
        })
      }
    },
    isEmptyObj(obj) {
      for (let key in obj) {
        return false;
      }

      return true;
    },
    searchByClients(value) {
      this.isLoadingPage = true;

      const search = value.trim();

      this.changeFilters({ key: 'page', value: 1 });
      this.changeFilters({ key: 'query', value: search ? search : null });

      this.getClients(this.$route.params.id)
        .finally(() => {
          this.page = 1;
          this.isLoadingPage = false;
        });

      let query = Object.assign({}, this.$route.query);
      query.page = '1';
      if (!search) {
        delete query.query;
      } else {
        query.query = search;
      }
      this.$router.replace({ query }).catch(() => {});
    },
    removeFromGroup(client) {
      this.isLoadingPage = true;

      const payload = {
        groupId: this.$route.params.id,
        payload: {
          clients: [client.client_id]
        }
      }

      this.transferAndRemoveClients(payload)
        .then(() => {
          this.$toast.success('Клиент удален из группы');

          this.getClients(this.$route.params.id)
            .catch((e) => this.errorHandlerForResponse(e))
            .finally(() => this.isLoadingPage = false);
        })
        .catch((e) => {
          this.errorHandlerForResponse(e)
          this.isLoadingPage = false;
        });
    },
    transferClient(groupToTransfer) {
      this.isModalTransferClients = false;
      this.isLoadingPage = true;

      const payload = {
        groupId: this.$route.params.id,
        payload: {
          clients: [this.clientForModal.client_id],
          study_group_id: groupToTransfer
        }
      }

      this.transferAndRemoveClients(payload)
        .then(() => {
          this.$toast.success('Клиент перемещен в другую группу');

          this.getClients(this.$route.params.id)
            .catch((e) => this.errorHandlerForResponse(e))
            .finally(() => this.isLoadingPage = false);
        })
        .catch((e) => {
          this.errorHandlerForResponse(e)
          this.isLoadingPage = false;
        });
    },
    openModalTransferClients(client) {
      this.clientForModal = client;
      this.isModalTransferClients = true;
    },
    changeIsLoadingPage(val) {
      this.isLoadingPage = val;
    },
    errorHandlerForResponse
  }
}
</script>