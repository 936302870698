<template>
  <div>
    <template v-if="!successSubmit">
      <div id="restore" class="auth-restore js-auth-restore mt-2 pt-2">
       <form action="javascript:void(0)" method="post" id="restoreForm" class="d-flex justify-content-between">
         <input type="text" placeholder="Введите e-mail" name="email" v-model.trim="email" @focus="$v.email.$reset()" class="form-control form-control--bordered mr-3 ml-md-0">
         <button class="btn btn-green" @click="submit" v-if="!loading">Ок</button>
         <button class="btn btn-green cursor-default bg-gray text-black" v-else>...</button>
       </form>
       <div class="auth-restore-text pt-3">Пожалуйста, укажите e-mail, который Вы использовали при регистрации. </div>
      </div>
    </template>
    <template v-else>
      <div class="h3 text-green mt-3">Ссылка отправлена</div>
      <p class="text pt-2 text-muted">Проверьте ваш E-mail. Мы отправли на него ссылку для изменения пароля</p>
      <div class="pt-2"><a href="javascript:void(0)" class="text text-blue" @click="successSubmit = false">Отправить еще раз</a></div>
    </template>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Vuelidate from 'vuelidate';
  import VueToast from 'vue-toast-notification';
  import 'vue-toast-notification/dist/theme-sugar.css';
  import { mapActions, mapGetters } from 'vuex';
  import { required, email } from 'vuelidate/lib/validators';

  Vue.use(Vuelidate);
  Vue.use(VueToast);

  export default {
    name: 'PasswordReset',

    data() {
      return {
        email: '',
        successSubmit: false,
      }
    },

    computed: {
      ...mapGetters({
        loading: 'loadingResetRequest'
      })
    },

    methods: {
      ...mapActions(['resetPasswordRequest']),
      submit() {
        this.$v.$touch()

        if (this.$v.$invalid) {
          this.$toast.open({
            message: 'Введите корректный e-mail',
            type: 'error'
          });
        } else {
          this.resetPasswordRequest(this.email)
            .then(() => {
              this.$toast.open({
                message: 'Ссылка отправлена на ваш e-mail',
                type: 'success'
              });
              this.email = '';
              this.successSubmit = true;
            })
            .catch((error) => {
              this.$toast.open({
                message: error.response.data.errors['email'][0],
                type: 'error'
              });
            });
        }
      },
    },

    validations: {
      email: {
        required,
        email
      },
    }
  }
</script>