<template>
  <div class="account-content">
    <GoBack />

    <div class="settings">
      <div class="settings-top d-md-flex align-items-end mb-4">
        <div class="settings-head d-flex align-items-center w-100">
          <div class="settings-title">Настройки выплат</div>
        </div>
      </div>

      <AppLoader v-if="loading.payer" />

      <div v-else class="shcool-cabinet">
        <div class="school-cabinet_white-box">
          <div class="tabs-nav pt-0 mb-4">
            <div class="tabs-nav-list d-flex flex-column flex-sm-row flex-wrap">
              <div class="tabs-nav-item" :class="{active: tab === 1}">
                <a href="javascript:void(0)" class="tabs-nav-link" @click="tab = 1">Реквизиты</a>
              </div>
              <div class="tabs-nav-item" :class="{active: tab === 2}">
                <a href="javascript:void(0)" class="tabs-nav-link" @click="tab = 2">Лимиты</a>
              </div>
              <div class="tabs-nav-item" :class="{active: tab === 3}">
                <a href="javascript:void(0)" class="tabs-nav-link" @click="tab = 3">Расписание выплат</a>
              </div>
            </div>
          </div>

          <div v-if="tab === 1">
            <div class="row pb-3">
              <div class="col-md-12 mb-3">
                <label class="mb-2 d-block text-muted">Название компании</label>
                <input
                  v-model.trim="payer.requisites.name"
                  placeholder="Название компании"
                  class="form-control form-control--bordered"
                >
              </div>
              <div class="col-md-4 mb-3">
                <label class="mb-2 d-block text-muted">ИНН</label>
                <input
                  v-model.trim="payer.requisites.inn"
                  placeholder="ИНН"
                  class="form-control form-control--bordered"
                >
              </div>
              <div class="col-md-4 mb-3">
                <label class="mb-2 d-block text-muted">КПП</label>
                <input
                  v-model.trim="payer.requisites.kpp"
                  placeholder="КПП"
                  class="form-control form-control--bordered"
                >
              </div>
              <div class="col-md-4 mb-3">
                <label class="mb-2 d-block text-muted">ОГРН</label>
                <input
                  v-model.trim="payer.requisites.ogrn"
                  placeholder="ОГРН"
                  class="form-control form-control--bordered"
                >
              </div>
              <div class="col-md-12 mb-3">
                <label class="mb-2 d-block text-muted">Почтовый адрес</label>
                <textarea
                  v-model.trim="payer.requisites.postal_address"
                  placeholder="Почтовый адрес"
                  class="form-control form-control--bordered"
                ></textarea>
              </div>
              <div class="col-md-12 mb-3">
                <label class="mb-2 d-block text-muted">Юридический адрес</label>
                <textarea
                  v-model.trim="payer.requisites.legal_address"
                  placeholder="Юридический адрес"
                  class="form-control form-control--bordered"
                ></textarea>
              </div>
              <div class="col-md-4 mb-3">
                <label class="mb-2 d-block text-muted">Р/С</label>
                <input
                  v-model.trim="payer.requisites.bank_account"
                  placeholder="Р/С"
                  class="form-control form-control--bordered"
                >
              </div>
              <div class="col-md-4 mb-3">
                <label class="mb-2 d-block text-muted">Банк</label>
                <input
                  v-model.trim="payer.requisites.bank_name"
                  placeholder="Банк"
                  class="form-control form-control--bordered"
                >
              </div>
              <div class="col-md-4 mb-3">
                <label class="mb-2 d-block text-muted">ИНН банка</label>
                <input
                  v-model.trim="payer.requisites.bank_inn"
                  placeholder="ИНН банка"
                  class="form-control form-control--bordered"
                >
              </div>
              <div class="col-md-4 mb-3">
                <label class="mb-2 d-block text-muted">БИК</label>
                <input
                  v-model.trim="payer.requisites.bank_bik"
                  placeholder="БИК"
                  class="form-control form-control--bordered"
                >
              </div>
              <div class="col-md-4 mb-3">
                <label class="mb-2 d-block text-muted">К/С</label>
                <input
                  v-model.trim="payer.requisites.bank_correspondent_account"
                  placeholder="К/С"
                  class="form-control form-control--bordered"
                >
              </div>
              <div class="col-md-12 mb-3">
                <label class="mb-2 d-block text-muted">Адрес банка</label>
                <textarea
                  v-model.trim="payer.requisites.bank_address"
                  placeholder="Адрес банка"
                  class="form-control form-control--bordered"
                ></textarea>
              </div>
            </div>
          </div>

          <div v-if="tab === 2">
            <div class="row pb-3">
              <div class="col-md-6 mb-3">
                <label class="mb-2 d-block text-muted">Минимальная сумма</label>
                <input
                  v-model.trim="payer.outgo_settings.min_amount"
                  placeholder="Минимальная сумма"
                  class="form-control form-control--bordered"
                >
              </div>
              <div class="col-md-6 mb-3">
                <label class="mb-2 d-block text-muted">Максимальная сумма</label>
                <input
                  v-model.trim="payer.outgo_settings.max_amount"
                  placeholder="Максимальная сумма"
                  class="form-control form-control--bordered"
                >
              </div>
            </div>
          </div>

          <div v-if="tab === 3">
            <div class="d-flex align-items-center flex-wrap time-row pt-3 pb-3 mb-2 border-bottom">
              <div class="time-row__title mb-3 mb-sm-0">Понедельник</div>

              <div class="d-flex align-items-center flex-1">
                <div class="time-row__select mr-2">
                  <select
                    v-model="payer.schedule.mon"
                    :disabled="!payer.schedule.mon"
                    class="form-control form-control--bordered"
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <option value="9">C 9 до 10</option>
                    <option value="10">C 10 до 11</option>
                    <option value="11">C 11 до 12</option>
                    <option value="12">C 12 до 13</option>
                    <option value="13">C 13 до 14</option>
                    <option value="14">C 14 до 15</option>
                    <option value="15">C 15 до 16</option>
                    <option value="16">C 16 до 17</option>
                    <option value="17">C 17 до 18</option>
                    <option v-if="!payer.schedule.mon" :value="payer.schedule.mon">Отключено</option>
                  </select>
                </div>
                <div class="time-row__toggle ml-auto">
                  <label class="toggle-box toggle-box--white" for="checkbox1">
                  <input
                    v-model="payer.schedule.mon"
                    type="checkbox"
                    class="toggle"
                    id="checkbox1"
                  >
                    <div class="toggle-handler"></div>
                  </label>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center flex-wrap time-row pt-3 pb-3 mb-2 border-bottom">
              <div class="time-row__title mb-3 mb-sm-0">Вторник</div>

              <div class="d-flex align-items-center flex-1">
                <div class="time-row__select mr-2">
                  <select
                    v-model="payer.schedule.tue"
                    :disabled="!payer.schedule.tue"
                    class="form-control form-control--bordered"
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <option value="9">C 9 до 10</option>
                    <option value="10">C 10 до 11</option>
                    <option value="11">C 11 до 12</option>
                    <option value="12">C 12 до 13</option>
                    <option value="13">C 13 до 14</option>
                    <option value="14">C 14 до 15</option>
                    <option value="15">C 15 до 16</option>
                    <option value="16">C 16 до 17</option>
                    <option value="17">C 17 до 18</option>
                    <option v-if="!payer.schedule.tue" :value="payer.schedule.tue">Отключено</option>
                  </select>
                </div>
                <div class="time-row__toggle ml-auto">
                  <label class="toggle-box toggle-box--white" for="checkbox2">
                  <input
                    v-model="payer.schedule.tue"
                    type="checkbox"
                    class="toggle"
                    id="checkbox2"
                  >
                    <div class="toggle-handler"></div>
                  </label>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center flex-wrap time-row pt-3 pb-3 mb-2 border-bottom">
              <div class="time-row__title mb-3 mb-sm-0">Среда</div>

              <div class="d-flex align-items-center flex-1">
                <div class="time-row__select mr-2">
                  <select
                    v-model="payer.schedule.wed"
                    :disabled="!payer.schedule.wed"
                    class="form-control form-control--bordered"
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <option value="9">C 9 до 10</option>
                    <option value="10">C 10 до 11</option>
                    <option value="11">C 11 до 12</option>
                    <option value="12">C 12 до 13</option>
                    <option value="13">C 13 до 14</option>
                    <option value="14">C 14 до 15</option>
                    <option value="15">C 15 до 16</option>
                    <option value="16">C 16 до 17</option>
                    <option value="17">C 17 до 18</option>
                    <option v-if="!payer.schedule.wed" :value="payer.schedule.wed">Отключено</option>
                  </select>
                </div>
                <div class="time-row__toggle ml-auto">
                  <label class="toggle-box toggle-box--white" for="checkbox3">
                  <input
                    v-model="payer.schedule.wed"
                    type="checkbox"
                    class="toggle"
                    id="checkbox3"
                  >
                    <div class="toggle-handler"></div>
                  </label>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center flex-wrap time-row pt-3 pb-3 mb-2 border-bottom">
              <div class="time-row__title mb-3 mb-sm-0">Четверг</div>

              <div class="d-flex align-items-center flex-1">
                <div class="time-row__select mr-2">
                  <select
                    v-model="payer.schedule.thu"
                    :disabled="!payer.schedule.thu"
                    class="form-control form-control--bordered"
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <option value="9">C 9 до 10</option>
                    <option value="10">C 10 до 11</option>
                    <option value="11">C 11 до 12</option>
                    <option value="12">C 12 до 13</option>
                    <option value="13">C 13 до 14</option>
                    <option value="14">C 14 до 15</option>
                    <option value="15">C 15 до 16</option>
                    <option value="16">C 16 до 17</option>
                    <option value="17">C 17 до 18</option>
                    <option v-if="!payer.schedule.thu" :value="payer.schedule.thu">Отключено</option>
                  </select>
                </div>
                <div class="time-row__toggle ml-auto">
                  <label class="toggle-box toggle-box--white" for="checkbox4">
                  <input
                    v-model="payer.schedule.thu"
                    type="checkbox"
                    class="toggle"
                    id="checkbox4"
                  >
                    <div class="toggle-handler"></div>
                  </label>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center flex-wrap time-row pt-3 pb-3 mb-2 border-bottom">
              <div class="time-row__title mb-3 mb-sm-0">Пятница</div>

              <div class="d-flex align-items-center flex-1">
                <div class="time-row__select mr-2">
                  <select
                    v-model="payer.schedule.fri"
                    :disabled="!payer.schedule.fri"
                    class="form-control form-control--bordered"
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <option value="9">C 9 до 10</option>
                    <option value="10">C 10 до 11</option>
                    <option value="11">C 11 до 12</option>
                    <option value="12">C 12 до 13</option>
                    <option value="13">C 13 до 14</option>
                    <option value="14">C 14 до 15</option>
                    <option value="15">C 15 до 16</option>
                    <option value="16">C 16 до 17</option>
                    <option value="17">C 17 до 18</option>
                    <option v-if="!payer.schedule.fri" :value="payer.schedule.fri">Отключено</option>
                  </select>
                </div>
                <div class="time-row__toggle ml-auto">
                  <label class="toggle-box toggle-box--white" for="checkbox5">
                  <input
                    v-model="payer.schedule.fri"
                    type="checkbox"
                    class="toggle"
                    id="checkbox5"
                  >
                    <div class="toggle-handler"></div>
                  </label>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center flex-wrap time-row pt-3 pb-3 mb-2 border-bottom">
              <div class="time-row__title mb-3 mb-sm-0">Суббота</div>

              <div class="d-flex align-items-center flex-1">
                <div class="time-row__select mr-2">
                  <select
                    v-model="payer.schedule.sat"
                    :disabled="!payer.schedule.sat"
                    class="form-control form-control--bordered"
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <option value="9">C 9 до 10</option>
                    <option value="10">C 10 до 11</option>
                    <option value="11">C 11 до 12</option>
                    <option value="12">C 12 до 13</option>
                    <option value="13">C 13 до 14</option>
                    <option value="14">C 14 до 15</option>
                    <option value="15">C 15 до 16</option>
                    <option value="16">C 16 до 17</option>
                    <option value="17">C 17 до 18</option>
                    <option v-if="!payer.schedule.sat" :value="payer.schedule.sat">Отключено</option>
                  </select>
                </div>
                <div class="time-row__toggle ml-auto">
                  <label class="toggle-box toggle-box--white" for="checkbox6">
                  <input
                    v-model="payer.schedule.sat"
                    type="checkbox"
                    class="toggle"
                    id="checkbox6"
                  >
                    <div class="toggle-handler"></div>
                  </label>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center flex-wrap time-row pt-3 pb-3 mb-2">
              <div class="time-row__title mb-3 mb-sm-0">Воскресенье</div>

              <div class="d-flex align-items-center flex-1">
                <div class="time-row__select mr-2">
                  <select
                    v-model="payer.schedule.sun"
                    :disabled="!payer.schedule.sun"
                    class="form-control form-control--bordered"
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <option value="9">C 9 до 10</option>
                    <option value="10">C 10 до 11</option>
                    <option value="11">C 11 до 12</option>
                    <option value="12">C 12 до 13</option>
                    <option value="13">C 13 до 14</option>
                    <option value="14">C 14 до 15</option>
                    <option value="15">C 15 до 16</option>
                    <option value="16">C 16 до 17</option>
                    <option value="17">C 17 до 18</option>
                    <option v-if="!payer.schedule.sun" :value="payer.schedule.sun">Отключено</option>
                  </select>
                </div>
                <div class="time-row__toggle ml-auto">
                  <label class="toggle-box toggle-box--white" for="checkbox7">
                  <input
                    v-model="payer.schedule.sun"
                    type="checkbox"
                    class="toggle"
                    id="checkbox7"
                  >
                    <div class="toggle-handler"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mt-4">
            <a @click="submit" href="javascript:void(0)" class="btn d-inline-block btn-blue position-relative">
              <AppLoader2
                v-if="loading.updatePayer"
                :mini="true"
                :btn="true"
                :color="'#ffffff'"
              />

              <span :class="{ 'opacity--0': loading.updatePayer }">Сохранить</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import GoBack from '@/components/GoBack.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';

export default {
  name: 'TransactionsSettings',

  components: {
    AppLoader,
    AppLoader2,
    GoBack,
  },

  data() {
    return {
      tab: 1,
      payer: {
        requisites: {
          name: null,
          inn: null,
          kpp: null,
          ogrn: null,
          postal_address: null,
          legal_address: null,
          bank_account: null,
          bank_name: null,
          bank_inn: null,
          bank_bik: null,
          bank_correspondent_account: null,
          bank_address: null
        },
        schedule: {
          mon: null,
          tue: null,
          wed: null,
          thu: null,
          fri: null,
          sat: null,
          sun: null
        },
        outgo_settings: {
          min_amount: null,
          max_amount: null
        }
      },
    }
  },

  computed: {
    ...mapGetters('school', {
      currentPayer: 'payer',
      loading: 'loading'
    })
  },

  watch: {
    'payer.schedule.mon'(val) {
      if (val === true) {
        this.payer.schedule.mon = 9;
      }
      if (val === false) {
        this.payer.schedule.mon = null;
      }
    },
    'payer.schedule.tue'(val) {
      if (val === true) {
        this.payer.schedule.tue = 9;
      }
      if (val === false) {
        this.payer.schedule.tue = null;
      }
    },
    'payer.schedule.wed'(val) {
      if (val === true) {
        this.payer.schedule.wed = 9;
      }
      if (val === false) {
        this.payer.schedule.wed = null;
      }
    },
    'payer.schedule.thu'(val) {
      if (val === true) {
        this.payer.schedule.thu = 9;
      }
      if (val === false) {
        this.payer.schedule.thu = null;
      }
    },
    'payer.schedule.fri'(val) {
      if (val === true) {
        this.payer.schedule.fri = 9;
      }
      if (val === false) {
        this.payer.schedule.fri = null;
      }
    },
    'payer.schedule.sat'(val) {
      if (val === true) {
        this.payer.schedule.sat = 9;
      }
      if (val === false) {
        this.payer.schedule.sat = null;
      }
    },
    'payer.schedule.sun'(val) {
      if (val === true) {
        this.payer.schedule.sun = 9;
      }
      if (val === false) {
        this.payer.schedule.sun = null;
      }
    },
  },

  async created() {
    try {
      await this.getPayer();

      this.payer = JSON.parse(JSON.stringify(this.currentPayer));
    } catch (e) {
      this.errorHandlerForResponse(e);
    }
  },

  methods: {
    ...mapActions('school', [
      'getPayer',
      'updatePayer'
    ]),
    submit() {
      if (this.loading.updatePayer) {
        return;
      }

      delete this.payer.payer_id;

      this.updatePayer({ payer: this.payer })
        .then(() => this.$toast.success('Данные обновлены'))
        .catch(e => this.errorHandlerForResponse(e));
    },
    errorHandlerForResponse
  }
}
</script>

<style>
  .time-row__title {
    min-width: 200px;
  }
  .time-row__select select {
    width: 300px;
  }
  .position-relative {
    position: relative;
  }
  .flex-1 {
    flex: 1;
  }

  @media screen and (max-width: 767px) {
    .time-row__select select {
      width: 200px;
    }
  }
</style>