<template>
  <div class="activity-card">
    <div class="activity-card__data">
      <div class="activity-card__date">{{formatDate(log.created_at)}}</div>
      <div class="activity-card__action">{{types[log.type]}}</div>
      <div class="activity-card__operator">Оператор: <strong>{{log.user.name.full}}</strong></div>
    </div>
    <div class="activity-card__description">{{log.description}}</div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ActivityCard',
  props: {
    log: {
      typeof: Object,
      required: true
    },
    types: {
      typeof: Object,
      required: true
    },
  },
  methods: {
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm:ss')
    },
  }
}
</script>

<style lang="scss">
.activity-card {
  padding: 18px 20px;
  border-radius: 5px;
  background-color: #ffffff;

  &__data {
    display: flex;
    align-items: baseline;
    margin-bottom: 16px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
    }
  }

  &__date {
    font-size: 15px;
    color: #555555;
    margin-right: 15px;
  }

  &__action {
    font-weight: 700;
  }

  &__operator {
    font-size: 15px;
    color: #555555;
    margin-left: auto;

    @media screen and (max-width: 767px) {
      margin-left: 0;
    }

    strong {
      font-weight: 700;
      font-size: 16px;
      color: #000000;
      margin-left: 10px;
    }
  }

  &__description {
    line-height: 1.25;
  }
}
</style>