<template>
	<div class="account-content">
		<div class="settings mb-4 pb-1">
			<div class="settings-top d-flex align-items-end">
				<div class="settings-head d-flex align-items-center">
					<div class="settings-title">Настройки организации</div>
				</div>
			</div>
			<div class="settings-body pl-4 pr-4">
				<div class="settings_filters ml-md-2 mr-md-2 mb-4">
					<div class="settings_filters-content">
						<div class="d-flex">
							<div class="settings_filters-item active">
								<a href="javascript:void(0)">Общие сведения</a>
							</div>
							<div class="settings_filters-item">
								<router-link to="/organization-settings/requisites">Реквизиты</router-link>
							</div>
						</div>
					</div>
				</div>
				<div v-if="croppedImage" class="school-cabinet">
					<ImageCropper
						:image="croppedImage"
						@save="onSaveLogo"
						@cancel="croppedImage = null"
					/>
				</div>
				<div class="school-cabinet" v-else>
					<AppLoader v-if="loading.school" />
					<div class="school-cabinet_info pt-2" v-else>
						<div class="school-cabinet_info-form school-cabinet_info-form--full">
							<div class="school-cabinet_info-field school-cabinet_info-field--full mb-4">
								<input
									v-model.trim="organization.name"
									@focus="hideErrorHighlight('name'); $v.organization.name.$reset()"
									type="text"
									placeholder="Полное наименование"
									:class="{ 'form-control--error': $v.organization.name.$error || errors.includes('name') }"
									class="form-control form-control--bordered"
								>

								<div class="text-error" v-if="!$v.organization.name.required && $v.organization.name.$dirty">Поле обязательно для заполнения</div>
							</div>
							<div class="school-cabinet_info-field school-cabinet_info-field--full mb-4">
								<input
									v-model.trim="organization.legal_name"
									@focus="hideErrorHighlight('legal_name'); $v.organization.legal_name.$reset()"
									type="text"
									placeholder="Юридическое наименование"
									:class="{ 'form-control--error': $v.organization.legal_name.$error || errors.includes('legal_name') }"
									class="form-control form-control--bordered"
								>

								<div class="text-error" v-if="!$v.organization.legal_name.required && $v.organization.legal_name.$dirty">Поле обязательно для заполнения</div>
							</div>
							<div class="school-cabinet_info-field school-cabinet_info-field--full">
								<input type="text" class="form-control form-control--bordered" placeholder="Сокращенное наименование" v-model="organization.short_name">
							</div>
							<div class="school-cabinet_info-field school-cabinet_info-field--full mb-4">
								<input
										v-model.trim="organization.legal_address"
										@focus="hideErrorHighlight('legal_address'); $v.organization.legal_address.$reset()"
										type="text"
										placeholder="Юр. адрес"
										:class="{ 'form-control--error': $v.organization.legal_address.$error || errors.includes('legal_address') }"
										class="form-control form-control--bordered"
									>

									<div class="text-error" v-if="!$v.organization.legal_address.required && $v.organization.legal_address.$dirty">Поле обязательно для заполнения</div>
							</div>
							<div class="school-cabinet_info-field school-cabinet_info-field--full mb-4">
								<input
										v-model.trim="organization.head_position"
										@focus="hideErrorHighlight('head_position'); $v.organization.head_position.$reset()"
										type="text"
										placeholder="Должность руководителя"
										:class="{ 'form-control--error': $v.organization.head_position.$error || errors.includes('head_position') }"
										class="form-control form-control--bordered"
									>

									<div class="text-error" v-if="!$v.organization.head_position.required && $v.organization.head_position.$dirty">Поле обязательно для заполнения</div>
							</div>
							<div class="school-cabinet_info-field school-cabinet_info-field--full mb-4">
								<input
										v-model.trim="organization.head_full_name"
										@focus="hideErrorHighlight('head_full_name'); $v.organization.head_full_name.$reset()"
										type="text"
										placeholder="ФИО руководителя"
										:class="{ 'form-control--error': $v.organization.head_full_name.$error || errors.includes('head_full_name') }"
										class="form-control form-control--bordered"
									>

									<div class="text-error" v-if="!$v.organization.head_full_name.required && $v.organization.head_full_name.$dirty">Поле обязательно для заполнения</div>
							</div>
							<div class="school-cabinet_info-row">
								<div class="d-flex justify-content-between flex-wrap">
									<div class="school-cabinet_info-field school-cabinet_info-field--small mb-4">
										<input
											v-model.trim="organization.phone"
											@focus="hideErrorHighlight('phone'); $v.organization.phone.$reset()"
											type="text"
											placeholder="Телефон"
											:class="{ 'form-control--error': $v.organization.phone.$error || errors.includes('phone') }"
											class="form-control form-control--bordered"
										>

										<div class="text-error" v-if="!$v.organization.phone.required && $v.organization.phone.$dirty">Поле обязательно для заполнения</div>
									</div>
									<div class="school-cabinet_info-field school-cabinet_info-field--small mb-4">
										<input
											v-model.trim="organization.email"
											@focus="hideErrorHighlight('email'); $v.organization.email.$reset()"
											type="text"
											placeholder="E-mail"
											:class="{ 'form-control--error': $v.organization.email.$error || errors.includes('email') }"
											class="form-control form-control--bordered"
										>

										<div class="text-error" v-if="!$v.organization.email.required && $v.organization.email.$dirty">Поле обязательно для заполнения</div>
									</div>
									<div class="school-cabinet_info-field school-cabinet_info-field--small mb-4">
										<input
											v-model.trim="organization.site"
											@focus="hideErrorHighlight('site'); $v.organization.site.$reset()"
											type="text"
											placeholder="Сайт"
											:class="{ 'form-control--error': $v.organization.site.$error || errors.includes('site') }"
											class="form-control form-control--bordered"
											>

											<div class="text-error" v-if="!$v.organization.site.required && $v.organization.site.$dirty">Поле обязательно для заполнения</div>
									</div>
									<div class="school-cabinet_info-field school-cabinet_info-field--small mb-4">
										<input
											v-model.trim="organization.licence"
											@focus="hideErrorHighlight('licence'); $v.organization.licence.$reset()"
											type="text"
											placeholder="Номер лицензии"
											:class="{ 'form-control--error': $v.organization.licence.$error || errors.includes('licence') }"
											class="form-control form-control--bordered"
										>

										<div class="text-error" v-if="!$v.organization.licence.required && $v.organization.licence.$dirty">Поле обязательно для заполнения</div>
									</div>
									<div class="school-cabinet_info-field school-cabinet_info-field--small mb-4">
										<input
											v-model.trim="organization.date"
											@focus="hideErrorHighlight('date'); $v.organization.date.$reset()"
											type="text"
											placeholder="Дата выдачи"
											:class="{ 'form-control--error': $v.organization.date.$error || errors.includes('date') }"
											class="form-control form-control--calendar-icon form-control--bordered"
											id="calendar-modal"
										>

										<div class="text-error" v-if="!$v.organization.date.required && $v.organization.date.$dirty">Поле обязательно для заполнения</div>
									</div>
									<div class="school-cabinet_info-field school-cabinet_info-field--small mb-4">
										<input
											v-model.trim="organization.licence_issued_by"
											@focus="hideErrorHighlight('licence_issued_by'); $v.organization.licence_issued_by.$reset()"
											type="text"
											placeholder="Кем выдана"
											:class="{ 'form-control--error': $v.organization.licence_issued_by.$error || errors.includes('licence_issued_by') }"
											class="form-control form-control--bordered"
										>

										<div class="text-error" v-if="!$v.organization.licence_issued_by.required && $v.organization.licence_issued_by.$dirty">Поле обязательно для заполнения</div>
									</div>
								</div>
							</div>
							<label class="create-image mt-4 mb-3">
								<div class="create-image__content">
									<div class="create-image__icon"></div>
									<div class="create-image__text">Загрузить логотип</div>
								</div>

								<input
									type="file"
									ref="logo-input"
									@change="changeLogo"
									style="width: 0; height: 100; opacity: 0;"
								>
							</label>
							<div class="school-cabinet_info-button text-center mb-3 mb-md-0">
								<button class="btn btn-blue" @click="save" v-if="!loading.updateSchool">Сохранить</button>
								<button class="btn btn-blue" disabled v-else>Сохраняем...</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import { mapGetters, mapActions } from 'vuex';
	import AirDatepicker from 'air-datepicker';
	import 'air-datepicker/air-datepicker.css';
	import AppLoader from '@/components/AppLoader/1.vue';
	import Vuelidate from 'vuelidate';
	import moment from 'moment';

	import { required } from 'vuelidate/lib/validators';

	import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';
	import hideErrorHighlight from '@/helpers/hideErrorHighlight';

	import ImageCropper from '@/components/ImageCropperLogo.vue';

	Vue.use(Vuelidate);

	export default {
		name: 'OrganizationSettings',

		components: {
			AppLoader,
			ImageCropper
		},

		data() {
			return {
				organization: {
					school_id: '',
					name: '',
					legal_name: '',
					short_name: '',
					licence: '',
					legal_address: '',
					head_full_name: '',
					head_position: '',
					phone: '',
					email: '',
					site: '',
					date: '',
					licence_issued_by: '',
				},
				croppedImage: null,
				loadingChangeLogo: false,
				errors: []
			}
		},

		async mounted() {
			await this.getSchool(this.profile.school.school_id);
			this.organization.school_id = this.profile.school.school_id;
			this.organization.name = this.school.school_general_information.name;
			this.organization.legal_name = this.school.school_general_information.legal_name;
			this.organization.head_full_name = this.school.school_general_information.head_full_name;
			this.organization.head_position = this.school.school_general_information.head_position;
			this.organization.short_name = this.school.school_general_information.short_name;
			this.organization.licence = this.school.school_general_information.licence;
			this.organization.legal_address = this.school.school_general_information.legal_address;
			this.organization.phone = this.school.school_general_information.phone;
			this.organization.email = this.school.school_general_information.email;
			this.organization.site = this.school.school_general_information.site;
			this.organization.licence_issued_by = this.school.school_general_information.licence_issued_by;
			if (this.school.school_general_information.licence_issued_at) {
				this.organization.date = moment(this.school.school_general_information.licence_issued_at, 'YYYY-MM-DD h:m:s').format('DD.MM.YYYY');
			}


			let that = this;

			new AirDatepicker('#calendar-modal', {
				onSelect({date, formattedDate}) {
					console.log(date);
					that.organization.date = formattedDate
				}
			});
		},

		methods: {
			...mapActions({
				getSchool: 'school/getSchool',
				updateSchool: 'school/updateSchool',
				updateLogo: 'school/updateLogo',
				getProfile: 'getProfile',
			}),
			save() {
				this.$v.$touch()

				if (this.$v.$invalid) {
					return
				} else {
					this.errors = [];
					this.updateSchool(this.organization)
					.then(() => {
						this.$toast.open({
							message: 'Данные успешно сохранены',
							type: 'success'
						})
					})
					.catch((error) => {
						this.errorHandlerForResponse(error);

						if (error.response.data.errors) {
							this.errorsCheck(error.response.data.errors);
						}
					})
				}
			},
			changeLogo() {
				this.croppedImage = this.$refs['logo-input'].files[0];
			},
			onSaveLogo(image) {
				this.loadingChangeLogo = true;

				image.toBlob(async (logo) => {

					try {
						const formData = new FormData();

						formData.append('logo', logo);

						let payload = {
							logo: formData,
							id: this.profile.school.school_id
						}

						await this.updateLogo(payload);

						await this.getProfile();

						this.$toast.open({
							message: 'Логотип успешно изменен',
							type: 'success'
						});
					} catch (error) {
						this.errorHandlerForResponse(error);
					} finally {
						this.loadingChangeLogo = false;
					}
				});
			},
			errorsCheck(errors) {
				Object.keys(errors).forEach(el => {
					switch (el) {
						case 'name':
							this.errors.push('name');
							break;
						case 'legal_name':
							this.errors.push('legal_name');
							break;
						case 'licence':
							this.errors.push('licence');
							break;
						case 'legal_address':
							this.errors.push('legal_address');
							break;
						case 'head_full_name':
							this.errors.push('head_full_name');
							break;
						case 'head_position':
							this.errors.push('head_position');
							break;
						case 'phone':
							this.errors.push('phone');
							break;
						case 'email':
							this.errors.push('email');
							break;
						case 'site':
							this.errors.push('site');
							break;
						case 'date':
							this.errors.push('date');
							break;
						case 'licence_issued_by':
							this.errors.push('licence_issued_by');
							break;
					}
				})
			},
			hideErrorHighlight,
			errorHandlerForResponse
		},

		computed: {
			...mapGetters({
				school: 'school/school',
				profile: 'profile',
				loading: 'school/loading',
			})
		},

		validations: {
			organization: {
				name: {
					required,
				},
				legal_name: {
					required,
				},
				licence: {
					required,
				},
				licence_issued_by: {
					required,
				},
				date: {
					required,
				},
				legal_address: {
					required,
				},
				head_position: {
					required,
				},
				head_full_name: {
					required,
				},
				phone: {
					required,
				},
				email: {
					required,
				},
				site: {
					required,
				},
			}
		},
	}
</script>
