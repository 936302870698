<template>
  <div class="drop-down-menu">
    <div
      v-click-outside="hide"
      @click="opened = true"
      :class="{ 'border-bottom-none': opened && !disabled}"
      class="form-control form-control--bordered"
    >
      <input
        v-model="search"
        v-debounce:1000ms="searching"
        type="text"
        :placeholder="placeholder"
        :disabled="disabled"
        v-if="!clientId"
      >
      <div v-else>
        {{ list.find(user => user.user_id == clientId).name.first }} {{ list.find(user => user.user_id == clientId).name.last }}
      </div>

      <span
        :class="{ rotate: opened && !disabled }"
        class="form-control--arrow"
      ></span>
    </div>

    <ul v-if="opened && !disabled" class="form-control--custom">
      <li v-if="search" @click="reset">Все</li>

      <li
        v-for="(li, index) in list"
        :key="index"
        @click="select(li)"
      >
        {{ li.name.first }} {{ li.name.last }}
      </li>

      <li v-if="list.length < 1">Пользователь не найден</li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue';

import ClickOutside from 'vue-click-outside';
import vueDebounce from 'vue-debounce';

Vue.use(vueDebounce);

export default {
  name: 'DropDownMenuTeachers',

  props: {
    list: {
      typeof: Array,
      required: true
    },
    instructorId: {
      typeof: [Boolean, Number],
      required: false
    },
    clientId: {
      typeof: [Boolean, Number],
      required: false
    },
    placeholder: {
      typeof: String,
      required: false
    },
    filters: {
      typeof: Boolean,
      required: false
    },
    disabled: {
      typeof: Boolean,
      required: false
    }
  },

  directives: {
    ClickOutside,
  },

  data() {
    return {
      opened: false,
      search: ''
    }
  },

  watch: {
    filters(filters) {
      if (!filters) {
        this.search = '';
      }
    }
  },

  created() {
    if (this.instructorId) {
      this.search = this.list.find(el => el.instructor_id == this.instructorId).name.full;
    }

    if (this.clientId) {
      this.search = this.list.find(el => el.client_id == this.clientId).name.full;
    }
  },

  mounted () {
    this.popupItem = this.$el
  },

  methods: {
    hide() {
      this.opened = false;
    },
    select(li) {
      this.search = li.name.full;

      this.hide();

      this.$emit('select', li);
    },
    searching(value) {
      this.$emit('search', value);
    },
    reset() {
      this.search = '';

      this.hide();

      this.$emit('search', this.search);
    }
  }
}
</script>
