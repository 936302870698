<template>
  <div class="account-content">
    <GoBack />

    <div class="account-subtitle pt-0 mb-3">Редактировать профиль</div>

    <AppLoader v-if="loading.client" />

    <div v-else class="school-cabinet">
      <div class="school-cabinet_white-box">
        <div class="school-cabinet_info">
          <div class="school-cabinet_info-form">
            <div class="school-cabinet_info-row">
              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="client.client.name.first"
                  @focus="hideErrorHighlight('name.first'); $v.client.client.name.first.$reset()"
                  type="text"
                  placeholder="Имя"
                  :class="{ 'form-control--error': $v.client.client.name.first.$error || errors.includes('name.first') }"
                  class="form-control form-control--bordered"
                >

                <div class="text-error" v-if="!$v.client.client.name.first.required && $v.client.client.name.first.$dirty">Поле обязательно для заполнения</div>
                <div class="text-error" v-if="!$v.client.client.name.first.alpha && $v.client.client.name.first.$dirty">Вводите только буквы</div>
              </div>

              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="client.client.name.last"
                  @focus="hideErrorHighlight('name.last'); $v.client.client.name.last.$reset()"
                  type="text"
                  placeholder="Фамилия"
                  :class="{ 'form-control--error': $v.client.client.name.last.$error || errors.includes('name.last') }"
                  class="form-control form-control--bordered"
                >

                <div class="text-error" v-if="!$v.client.client.name.last.required && $v.client.client.name.last.$dirty">Поле обязательно для заполнения</div>
                <div class="text-error" v-if="!$v.client.client.name.last.alpha && $v.client.client.name.last.$dirty">Вводите только буквы</div>
              </div>

              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="client.client.name.middle"
                  @focus="hideErrorHighlight('name.middle'); $v.client.client.name.middle.$reset()"
                  type="text"
                  placeholder="Отчество"
                  :class="{ 'form-control--error': $v.client.client.name.middle.$error || errors.includes('name.middle') }"
                  class="form-control form-control--bordered"
                >

                <div class="text-error" v-if="!$v.client.client.name.middle.alpha && $v.client.client.name.middle.$dirty">Вводите только буквы</div>
              </div>
            </div>
            <div class="school-cabinet_info-row">
              <div class="school-cabinet_info-row-label">Пол</div>
              <div class="school-cabinet_info-field">
                <div class="radio d-flex align-items-center">
                  <label class="radio-container">Мужской
                    <input
                      v-model.trim="client.client.gender"
                      type="radio"
                      name="gender"
                      value="male"
                    >
                    <span class="radio-checkmark"></span>
                  </label>

                  <label class="radio-container">Женский
                    <input
                      v-model.trim="client.client.gender"
                      type="radio"
                      name="gender"
                      value="female"
                    >
                    <span class="radio-checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="school-cabinet_info-row">
              <div class="school-cabinet_info-row-label">Дата рождения</div>
              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="client.client.birthdate"
                  @focus="hideErrorHighlight('birthdate'); $v.client.client.birthdate.$reset()"
                  v-mask="'##.##.####'"
                  type="text"
                  placeholder="ДД.ММ.ГГГГ"
                  :class="{ 'form-control--error': $v.client.client.birthdate.$error || errors.includes('birthdate') }"
                  class="form-control form-control--bordered"
                >

                <div class="text-error" v-if="!$v.client.client.birthdate.required && $v.client.client.birthdate.$dirty">Поле обязательно для заполнения</div>
              </div>

              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="client.client.email"
                  @focus="hideErrorHighlight('email'); $v.client.client.email.$reset()"
                  type="text"
                  placeholder="E-mail"
                  :class="{ 'form-control--error': $v.client.client.email.$error || errors.includes('email') }"
                  class="form-control form-control--bordered"
                >

                <div class="text-error" v-if="!$v.client.client.email.required && $v.client.client.email.$dirty">Поле обязательно для заполнения</div>
                <div class="text-error" v-if="!$v.client.client.email.email && $v.client.client.email.$dirty">Введите вашу почту</div>
              </div>

              <div class="school-cabinet_info-field form-group mb-4">
                <input
                  v-model.trim="client.client.phone"
                  @focus="hideErrorHighlight('phone')"
                  v-mask="'+7 (###) ###-##-##'"
                  type="text"
                  placeholder="Телефон"
                  :class="{ 'form-control--error': errors.includes('phone') }"
                  class="form-control form-control--bordered"
                >
              </div>

              <button v-if="loading.editClient" class="btn btn-green btn-full btn-blue--custom">
                <AppLoader2 :mini="true" :color="'#ffffff'" />
              </button>

              <button v-else @click="clientEdit" class="btn btn-green btn-full btn-blue--custom">Сохранить</button>
            </div>

            <router-link :to="`/clients/${clientReal.client_id}/edit/change-password`" class="school-cabinet_card-link school-cabinet_card-link--bg school-cabinet_card-link--change-password justify-content-between mt-5">Сменить пароль</router-link>
          </div>
        </div>
      </div>

      <div class="school-cabinet_info-delete mt-5">
        <AppLoader2
          v-if="loading.deleteClient"
          :mini="true"
        />

        <a
          v-else
          @click="deleteConfirmation"
          href="javascript:void(0)"
        >
          Удалить пользователя
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Vuelidate from 'vuelidate';
import { VueMaskDirective }  from 'v-mask'
import moment from 'moment';

import { email, required, helpers } from 'vuelidate/lib/validators';
const alpha = helpers.regex('alpha', /^[a-zA-Zа-яА-Я]*$/);

import AppLoader2 from '@/components/AppLoader/2.vue';
import AppLoader from '@/components/AppLoader/1.vue';
import GoBack from '@/components/GoBack.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';
import hideErrorHighlight from '@/helpers/hideErrorHighlight';

Vue.use(VueToast);
Vue.use(Vuelidate);

export default {
  name: 'ClientEdit',

  components: {
    AppLoader2,
    AppLoader,
    GoBack
  },

  directives: {
    'mask': VueMaskDirective
  },

  data() {
    return {
      client: {
        client: {
          email: '',
          name: {
            first: '',
            middle: '',
            last: ''
          },
          gender: '',
          birthdate: '',
          phone: '',
        }
      },
      errors: []
    }
  },

  computed: {
    ...mapGetters({
      clientReal: 'client',
      loading: 'loadingClients'
    })
  },

  async created() {
    await this.getClient(this.$route.params.id);

    this.client.client.email = this.clientReal.email;
    this.client.client.name.first = this.clientReal.name.first;
    this.client.client.name.last = this.clientReal.name.last;
    this.client.client.name.middle = this.clientReal.name.middle;
    this.client.client.gender = this.clientReal.gender;
    this.client.client.birthdate = this.moment(this.clientReal.birthdate).format('DD.MM.YYYY');
    this.client.client.phone = this.clientReal.phone;
  },

  beforeDestroy() {
    this.clearClient();
  },

  methods: {
    ...mapActions([
      'getClient',
      'editClient',
      'clearClient',
      'deleteClient'
    ]),
    clientEdit() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      } else {
        const client = {
          client: JSON.parse(JSON.stringify(this.client.client))
        };

        if (client.client.birthdate) {
          client.client.birthdate = this.moment(client.client.birthdate, 'DD-MM-YYYY').format('YYYY-MM-DD');
        }

        const payload = {
          idClient: this.$route.params.id,
          client
        }

        this.editClient(payload)
          .then(() => {
            this.$toast.open({
              message: 'Данные клиента успешно изменены',
              type: 'success'
            });
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);

            if (error.response.data.errors) {
              this.errorsCheck(error.response.data.errors);
            }
          });
      }
    },
    errorsCheck(errors) {
      Object.keys(errors).forEach(el => {
        switch (el) {
          case 'client.name.first':
            this.errors.push('name.first');
            break;
          case 'client.name.last':
            this.errors.push('name.last');
            break;
          case 'client.name.middle':
            this.errors.push('name.middle');
            break;
          case 'client.birthdate':
            this.errors.push('birthdate');
            break;
          case 'client.email':
            this.errors.push('email');
            break;
          case 'client.phone':
            this.errors.push('phone');
            break;
        }
      })
    },
    deleteConfirmation() {
      this.$modal.show('dialog', {
        text: 'Вы действительно хотите удалить клиента?',
        buttons: [
          {
            title: 'Да',
            handler: () => {
              this.delClient();
              this.$modal.hide('dialog');
            },
          },
          {
            title: 'Нет',
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    delClient() {
      this.deleteClient(this.$route.params.id)
        .then(() => {
          this.$toast.open({
            message: 'Клиент удален',
            type: 'success'
          });

          this.$router.push('/clients');
        })
        .catch((error) => {
          this.errorHandlerForResponse(error);
        })
    },
    moment,
    errorHandlerForResponse,
    hideErrorHighlight
  },

  validations: {
    client: {
      client: {
        email: {
          required,
          email
        },
        name: {
          first: {
            required,
            alpha
          },
          middle: {
            alpha
          },
          last: {
            required,
            alpha
          }
        },
        gender: {
          required
        },
        birthdate: {
          required
        },
      }
    }
  }
}
</script>

<style>
.school-cabinet_card-link--change-password {
  border: 1px solid #e8e8e8;
}
</style>