<template>
  <div class="school-cabinet_card school-cabinet_card--small">
    <div class="school-cabinet_card-top d-flex align-items-center flex-wrap">
      <div class="school-cabinet_card-content school-cabinet_card-content--width-1 d-flex align-items-center">
        <router-link
          :to="`/instructors/${instructor.instructor.instructor_id}/profile`"
          class="school-cabinet_card-icon"
          style="width: 48px; height: 48px"
        >
          <img v-if="instructor.instructor.avatar" :src="instructor.instructor.avatar.url" :alt="instructor.instructor.name.full">
          <img v-else src="@/assets/img/noavatar.svg" :alt="instructor.instructor.name.full">
        </router-link>

        <div class="school-cabinet_card-text school-cabinet_card-text--fix-width">
          <router-link
            :to="`/instructors/${instructor.instructor.instructor_id}/profile`"
            class="school-cabinet_card-name"
          >
            {{ instructor.instructor.name.full }}
          </router-link>

          <div class="school-cabinet_card-facts">{{ instructor.instructor.email }}<template v-if="instructor.instructor.phone">,</template> <span v-if="instructor.instructor.phone" class="white-space-nowrap">{{ instructor.instructor.phone }}</span></div>
        </div>
      </div>

      <div class="school-cabinet_card-info mt-3 mt-xl-0 mb-1 mb-md-0">
        <div class="school-cabinet_card-info-list d-flex flex-wrap">
          <div class="school-cabinet_card-info-item school-cabinet_card-info-item--small mr-5 mr-md-0 pr-md-1 mb-3 mb-md-0">
            <div class="school-cabinet_card-info-title">Всего</div>
            <div class="school-cabinet_card-info-text">{{ instructor.analytics.lessons.total }}</div>
          </div>

          <div class="school-cabinet_card-info-item school-cabinet_card-info-item--small ml-md-4 pl-md-3 mr-md-0 pr-md-1 mb-3 mb-md-0">
            <div class="school-cabinet_card-info-title">Запланировано</div>
            <div class="school-cabinet_card-info-text">{{ instructor.analytics.lessons.planned }}</div>
          </div>

          <div class="school-cabinet_card-info-item school-cabinet_card-info-item--small mr-5 ml-md-4 pl-md-3 mr-md-0 pr-md-1">
            <div class="school-cabinet_card-info-title">Завершено</div>
            <div class="school-cabinet_card-info-text">{{ instructor.analytics.lessons.completed }}</div>
          </div>

          <div class="school-cabinet_card-info-item school-cabinet_card-info-item--small ml-md-4 pl-md-3">
            <div class="school-cabinet_card-info-title">Сумма</div>
            <div class="school-cabinet_card-info-text">{{ instructor.analytics.payout.total }} р.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsInstructorCard',

  props: {
    instructor: {
      type: Object,
      required: true
    }
  },
}
</script>

<style>
.school-cabinet_card-text--fix-width {
  max-width: 300px;
}
</style>