<template>
  <header class="header header--main header--main-v2">
    <div class="container container--school">
      <div class="header-content d-flex align-items-center pb-2 pb-lg-3 pt-lg-3 pt-1">
        <div
          ref="burger"
          @click="isHeaderBurger = !isHeaderBurger"
          :class="{ open: isHeaderBurger }"
          class="header-burger mr-1 mt-1"
        >
          <span></span>
          <span></span>
          <span></span>
          <transition name="fade">
            <div
              v-if="isHeaderBurger"
              :class="{ active: isHeaderBurger }"
              class="header-burger-content"
            >
              <ul>
                <li>
                  <a href="/faq" class="header-profile-link">Вопрос - ответ</a>
                </li>
                <li>
                  <a href="/contacts" class="header-profile-link">Контакты</a>
                </li>
              </ul>
            </div>
          </transition>
        </div>
        <div class="header-logo mt-2 ml-4">
          <a href="/" class="header-logo-link"></a>
        </div>
        <div class="header-button ml-auto d-none d-md-block">
          <a href="/#connect">Подключиться</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderMain',

  data() {
    return {
      isHeaderBurger: false
    }
  },

  mounted() {
    document.addEventListener('click', this.hideBurgerContent);
  },

  destroyed() {
    document.removeEventListener('click', this.hideBurgerContent);
  },

  methods: {
    hideBurgerContent(e) {
      if (this.$refs.burger.contains(e.target)) {
        return;
      } else {
        this.isHeaderBurger = false;
      }
    }
  },
}
</script>