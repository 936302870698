import instance from '../../instance';
import settings from '../../settings';
import clients from './clients';

export default {
  namespaced: true,

  state: {
    list: [],
    meta: null,
    filters: {
      page: 1,
    },
    item: null,
    loading: {
      list: true,
    }
  },

  getters: {
    list: state => state.list,
    meta: state => state.meta,
    filters: state => state.filters,
    item: state => state.item,
    loading: state => state.loading,
  },

  actions: {
    async getList({ commit, state }, payload) {
      try {
        const list = await instance.get(`${settings.API_URL}/school-study-groups`, { params: state.filters });

        if (payload === 'isForInfinity') {
          commit('SET_LIST_FOR_INFINITY', list.data);
        } else {
          commit('SET_LIST', list.data);
        }
      } finally {
        commit('CHANGE_LIST_LOADING', false);
      }
    },
    async getItem({ commit }, groupId) {
      const item = await instance.get(`${settings.API_URL}/school-study-groups/${groupId}`);

      commit('SET_ITEM', item.data);
    },
    changeFilters({ commit }, payload) {
      commit('CHANGE_FILTERS', payload);
    },
    clearList({ commit }) {
      commit('CLEAR_LIST');
    },
    clearItem({ commit }) {
      commit('CLEAR_ITEM');
    },
    async create(context, payload) {
      await instance.post(`${settings.API_URL}/school-study-groups`, payload);
    },
    async edit({ commit }, payload) {
      const item = await instance.patch(`${settings.API_URL}/school-study-groups/${payload.groupId}`, payload.payload);

      commit('SET_ITEM', item.data);
    },
    async remove(context, groupId) {
      await instance.delete(`${settings.API_URL}/school-study-groups/${groupId}`);
    },
  },

  mutations: {
    SET_LIST(state, list) {
      state.list = list.study_groups;
      state.meta = list.meta;
    },
    SET_LIST_FOR_INFINITY(state, list) {
      state.list.push(...list.study_groups);
      state.meta = list.meta;
    },
    SET_ITEM(state, item) {
      state.item = item.study_group;
    },
    CLEAR_LIST(state) {
      state.list = [];
      state.meta = null;
      state.filters = { page: 1 };
      state.loading.list = true;
    },
    CLEAR_ITEM(state) {
      state.item = null;
    },
    CHANGE_LIST_LOADING(state, status) {
      state.loading.list = status;
    },
    CHANGE_FILTERS(state, payload) {
      state.filters[payload.key] = payload.value;
    }
  },

  modules: {
    clients
  }
};