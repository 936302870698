<template>
  <div class="account-content">
    <GoBack />

    <div class="account-subtitle pt-0 mb-3">Сменить пароль</div>

    <div class="school-cabinet">
      <div class="school-cabinet_white-box">
        <div class="school-cabinet_info">
          <div class="school-cabinet_info-form">
            <!-- <div class="school-cabinet_info-row">
              <div class="school-cabinet_info-row-label">Старый пароль</div>
              <div class="school-cabinet_info-field form-group">
                <input
                  v-model.trim="$v.password.password.current_password.$model"
                  type="password"
                  :class="{ 'form-control--error': $v.password.password.current_password.$error }"
                  class="form-control form-control--bordered"
                >
                <div class="text-error" v-if="!$v.password.password.current_password.required && $v.password.password.current_password.$dirty">Поле обязательно для заполнения</div>
              </div>
            </div> -->

            <div class="school-cabinet_info-row">
              <div class="school-cabinet_info-row-label">Новый пароль</div>
              <div class="school-cabinet_info-field form-group">
                <input
                  v-model.trim="password.password.password"
                  @focus="hideErrorHighlight('password'); $v.password.password.password.$reset(); $v.password.password.password_confirmation.$reset()"
                  type="password"
                  :class="{ 'form-control--error': $v.password.password.password.$error || errors.includes('password') }"
                  class="form-control form-control--bordered"
                >
                <div class="text-error" v-if="!$v.password.password.password.required && $v.password.password.password.$dirty">Поле обязательно для заполнения</div>
                <div class="text-error" v-if="!$v.password.password.password.minLength && $v.password.password.password.$dirty">Пароль должен быть не менее 6 символов</div>
              </div>
            </div>

            <div class="school-cabinet_info-row">
              <div class="school-cabinet_info-row-label">Подтверждение пароля</div>
              <div class="school-cabinet_info-field form-group">
                <input
                  v-model.trim="password.password.password_confirmation"
                  @focus="hideErrorHighlight('password_confirmation'); $v.password.password.password_confirmation.$reset()"
                  type="password"
                  :class="{ 'form-control--error': $v.password.password.password_confirmation.$error || errors.includes('password_confirmation') }"
                  class="form-control form-control--bordered"
                >
                <div class="text-error" v-if="!$v.password.password.password_confirmation.sameAsPassword && $v.password.password.password_confirmation.$dirty">Пароль не совпадает</div>
              </div>
            </div>

            <div class="school-cabinet_info-row">
              <button v-if="loading.changePassword" class="btn btn-blue btn-full btn-blue--custom">
                <AppLoader2 :mini="true" :color="'#ffffff'" />
              </button>

              <button @click="clientChangePassword" v-else class="btn btn-blue btn-full btn-blue--custom">Сохранить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Vuelidate from 'vuelidate';

import { required, sameAs, minLength } from 'vuelidate/lib/validators'

import AppLoader2 from '@/components/AppLoader/2.vue';
import GoBack from '@/components/GoBack.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';
import hideErrorHighlight from '@/helpers/hideErrorHighlight';

Vue.use(VueToast);
Vue.use(Vuelidate);

export default {
  name: 'ClientChangePassword',

  components: {
    AppLoader2,
    GoBack
  },

  data() {
    return {
      password: {
        password: {
          // current_password: '',
          password: '',
          password_confirmation: ''
        }
      },
      errors: []
    }
  },

  computed: {
    ...mapGetters({
      client: 'client',
      loading: 'loadingClients'
    })
  },

  created() {
    this.getClient(this.$route.params.id);
  },

  beforeDestroy() {
    this.clearClient();
  },

  methods: {
    ...mapActions(['getClient', 'changePasswordClient', 'clearClient']),
    clientChangePassword() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      } else {
        const payload = {
          idClient: this.$route.params.id,
          password: this.password
        }

        this.changePasswordClient(payload)
          .then(() => {
            this.$toast.open({
              message: 'Пароль успешно изменен',
              type: 'success'
            });

            this.$router.push(`/clients/${this.$route.params.id}/edit`);
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);

            if (error.response.data.errors) {
              this.errorsCheck(error.response.data.errors);
            }
          });
      }
    },
    errorsCheck(errors) {
      Object.keys(errors).forEach(el => {
        switch (el) {
          case 'password.password':
            this.errors.push('password');
            break;
          case 'password.password_confirmation':
            this.errors.push('password_confirmation');
            break;
        }
      })
    },
    errorHandlerForResponse,
    hideErrorHighlight
  },

  validations: {
    password: {
      password: {
        // current_password: {
        //   required
        // },
        password: {
          required,
          minLength: minLength(6)
        },
        password_confirmation: {
          sameAsPassword: sameAs('password')
        }
      }
    }
  }
}
</script>