<template>
  <section class="site-body">
    <div class="auth mt-5 mb-5">
      <div class="row justify-content-center">
        <div class="col-lg-12 col-md-12 col-12">
          <h4 class="title text-center mb-4 pb-2 auth-title">Авторизация</h4>

          <form @submit.prevent="logIn">
            <div class="form-group mb-4">
              <input
                v-model.trim="user.email"
                @focus="$v.user.email.$reset()"
                id="email"
                placeholder="Ваш e-mail"
                :class="{ 'form-control--error': $v.user.email.$error }"
                class="form-control form-control--bordered"
              >

              <div class="text-error" v-if="!$v.user.email.required && $v.user.email.$dirty">Поле обязательно для заполнения</div>
              <div class="text-error" v-if="!$v.user.email.email && $v.user.email.$dirty">Введите email правильно</div>
            </div>

            <div class="form-group mb-4">
              <input
                v-model.trim="user.password"
                @focus="$v.user.password.$reset()"
                type="password"
                id="password"
                placeholder="Пароль"
                :class="{ 'form-control--error': $v.user.password.$error }"
                class="form-control form-control--bordered"
              >

              <div class="text-error" v-if="!$v.user.password.required && $v.user.password.$dirty">Поле обязательно для заполнения</div>
            </div>

            <button v-if="loadingAuth.user || loadingProfile.profileForLogin" type="button" class="btn btn-green btn-full mb-4">
              <AppLoader2 :mini="true" :color="'#ffffff'" />
            </button>

            <button v-else class="btn btn-green btn-full mb-4">
              Войти
            </button>
          </form>
          <div class="auth-links d-flex justify-content-between mt-4 pt-2"><a href="javascript:void(0)" class="js-show-restore" @click="forgotPassword = !forgotPassword">Забыли пароль?</a></div>
          <transition name="slide">
            <PasswordReset v-if="forgotPassword" />
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Vuelidate from 'vuelidate';

import { required, email } from 'vuelidate/lib/validators'

import AppLoader2 from '@/components/AppLoader/2.vue';
import PasswordReset from '@/components/Auth/PasswordReset.vue';

import errorHandlerForResponse from '@/helpers/errorHandlerForResponse';
import firstPageFromPermissions from '@/helpers/firstPageFromPermissions';

Vue.use(VueToast);
Vue.use(Vuelidate);

export default {
  name: 'Auth',

  components: {
    AppLoader2,
    PasswordReset
  },

  data() {
    return {
      user: {
        email: '',
        password : ''
      },
      forgotPassword: false,
    }
  },

  computed: {
    ...mapGetters({
      profile: 'profile',
      token: 'token',
      loadingAuth: 'loadingAuth',
      loadingProfile: 'loadingProfile',
      firstPageWithoutAuthorization: 'firstPageWithoutAuthorization'
    })
  },

  created() {
    if (this.token) {
      if (this.profile) {
        if (this.firstPageWithoutAuthorization) {
          this.$router.push(this.firstPageWithoutAuthorization);
        } else {
          this.$router.push(`${this.firstPageFromPermissions(this.profile.user.permissions)}`);
        }
      } else {
        this.getProfile('profileForLogin')
          .then(() => {
            if (this.firstPageWithoutAuthorization) {
              this.$router.push(this.firstPageWithoutAuthorization);
            } else {
              this.$router.push(`${this.firstPageFromPermissions(this.profile.user.permissions)}`);
            }
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);
          });
        this.$router.push('/').catch(() => {});
      }
    }
  },

  methods: {
    ...mapActions(['auth', 'setToken', 'getProfile']),
    logIn() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.$toast.open({
          message: 'Заполните все поля правильно',
          type: 'warning'
        });
      } else {
        this.auth(this.user)
          .then(() => {
            this.getProfile('profileForLogin')
              .then(() => {
                if (this.firstPageWithoutAuthorization) {
                  this.$router.push(this.firstPageWithoutAuthorization);
                } else {
                  this.$router.push(`${this.firstPageFromPermissions(this.profile.user.permissions)}`);
                }
              })
              .catch((error) => {
                this.errorHandlerForResponse(error);
              });
          })
          .catch((error) => {
            this.errorHandlerForResponse(error);
          });
      }
    },
    errorHandlerForResponse,
    firstPageFromPermissions
  },

  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
      }
    }
  }
}
</script>

<style>
  .slide-enter-active, .slide-leave-active {transition: all 300ms linear;max-height: 200px;}
  .slide-enter, .slide-leave-to {max-height: 0;opacity: 0;}
</style>