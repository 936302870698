export default function errorHandlerForResponse(error) {
  if (error.response?.data.errors) {
    Object.keys(error.response.data.errors).forEach(key => {

      error.response.data.errors[key].forEach(value => {
        this.$toast.open({
          message: value,
          type: 'error',
          duration: 5000
        });
      })
    });
  } else {
    this.$toast.open({
      message: error,
      type: 'error',
      duration: 5000
    });
  }
}